import { get_token,get_user_details} from "@/router";
import Swal from 'sweetalert2';


export default {

    mounted(){
        this.token_exists = get_token();
        this.fetch_status();
        this.fetchIncidentReports().then(() =>{
        this.$nextTick(() => {
            $('.table').DataTable({
            paging: true,
            searching: true,
            ordering: true,
            info: true,
            lengthChange: true,
            pageLength: 10,
            columnDefs: [
                { width: '20%', targets: 5 }
            ],
              language: {
                paginate: {
                  previous: "Previous",
                  next: "Next"
                },
                info: "Showing _START_ to _END_ of _TOTAL_ entries",
                infoEmpty: "No entries available"
              },
               sortColumn: '',
               sortDirection: 'asc'
            });
          });
        });
    },
    data() {
        return({

        reports:{},
        selectedReport:{},
        selectedIncidentStatus:{},
        selectedUser:{},
        user_details:get_user_details(),
        selectedIncidentButton:{},
        availableStatuses:{},
        status_id:'',
        
        })
    },
    methods: {

        getToken() {
            return get_token();
        },

        getIdFromURL(param) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(param);
          },

        openReportModal(report) {
            this.selectedReport = report;
            this.selectedIncidentStatus = this.selectedReport.incident_status
            this.selectedUser = this.selectedReport.user

            $('#viewReportModal').modal('show');
        },

        fetchIncidentReports(){
            return new Promise((resolve) => {
                $.ajax({
                method: 'GET',
                url: `${process.env.VUE_APP_BACKEND_API_URL}/report_incident_management/retrieve_incident_report/`,
                headers: {
                    'Authorization': `Token ${this.getToken()}`,
                    'Content-Type': 'application/json'
                },
                success: (response) => {

                this.reports = response.data
                const reportId = this.getIdFromURL('incident_report_id');
                if (reportId) {
                const report = this.reports.find(report => report.id === parseInt(reportId,10));
                if (report) {
                    this.openReportModal(report);
                }
                }
                    resolve();
                },
                });
            });
    
        },

        openUpdateStatusModal(report) {
            this.selectedReport = report;
            this.selectedIncidentButton = report.submit_incident;
            this.selectedIncidentStatus = report.incident_status;
            this.newStatus = report.incident_status;
                $("#updateStatusModal").modal("show");
            },
    
            updateReportStatus(){
            this.isLoading=true;
    
            const report_data={
                'report_id':this.selectedReport.id,
                'new_status_id':this.status_id.id
            }
            $.ajax({
                url:`${process.env.VUE_APP_BACKEND_API_URL}/report_incident_management/update_incident_report_status/`,
                method:`POST`,
                headers:{
                    'Content-Type':'application/json'
                },
                data:JSON.stringify(report_data),
                success:(response)=>{
                    if(response.status==='success'){
                        Swal.fire({
                            icon: 'success',
                            title: 'Report Updated Successfully',
                            text: 'Your report has been updated.',
                            showConfirmButton: false,
                            timer: 1000
                          }).then(() => {
                            window.location.reload();
                          });
                    }
                },
            })
            },
    
            fetch_status() {
                this.isLoading = true;
          
                $.ajax({
                  url: `${process.env.VUE_APP_BACKEND_API_URL}/report_incident_management/get_status/`,
                  type: 'GET',
                  headers: {
                    Authorization: `Token ${get_token()}`,
                    'Content-Type': 'application/json',
                  },
                  success: (response) => {
    
                      if (response.status === 'success') {
                          this.report_status = response.data;
    
                    }
                  },
                  complete: () => {
                    this.isLoading = false;
                  },
                });
              },
    }
}

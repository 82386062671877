import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';
import { get_token } from "@/router";
import Swal from "sweetalert2";


export default {
  components: {
    Sidebar,
    Navbar
  },
  data() {
    return ({
      report_name: '',
      report_url: null,
      topics:[],
      fileName:'',
      portals:[],
      selectedPortalId: null,
      selectedVisibility: 'public',
      upload_media: '',
      is_public: true,
      errors: {},
      isSubmitting: false,
    });
  },

  props: ['topic_id'],

  mounted(){
    this.fecthTopics();
    this.setupTextareaAutoResize();
  },
  methods: {
    toggleSidebar() {
      this.$refs.sidebar.$el.classList.toggle("close");
    },
    getToken() {
      return get_token();
    },
    validationFeedForm() {
      this.errors = {};
      let valid = true;

      if (!this.report_name) {
        this.errors.report_name = 'Report name is required';
        valid = false;
      }

      return valid;
    },
    submit_form() {
      if(!this.validationFeedForm()) {
        return;
      }
        Swal.fire({
          title: 'Submitting...',
          text: 'Please wait while we process your request.',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append('report_name', this.report_name);
      if (this.report_url) {
        formData.append('report_link', this.report_url);
      }
      formData.append('topic_id', this.$route.params.topic_id);
      formData.append('creater', this.$route.params.topic_id);
      this.report_description = this.$refs.quillReportEditorRef.getHTML()
      formData.append('description', this.report_description);
      formData.append('is_public', this.isPublic);
      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_report_api/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          Swal.close();
          const status = response.status;

          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Feed Created',
              showConfirmButton:false,
              timer:2000,

            }).then(() => {
              this.resetForm();
              window.location.reload();
            });
            $('#create_report').hide();
          }
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;

          if (response_data.status==="error") {

            this.errors.report_name=response_data.message
          }

        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },

    deleteReport(report_id) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            type: 'DELETE',
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/delete_report_api/`,
            data: {report_id: report_id},
            headers: {
              'Authorization': `Token ${this.getToken()}`,
            },
            success: () => {
              Swal.fire(
                {
                  icon: 'success',
                  title: 'Feed deleted',
                  showConfirmButton: false,
                  timer: 2000
                }
              );
              this.reports = this.reports.filter(report => report.id !== report_id);
            },
            error: () => {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to delete the feed. Please try again.',
              });
            }
          });
        }
      });
    },

    resetForm() {
      this.report_name = '';
      this.report_url = '';
      this.topic = '';
      this.upload_media = null;
      this.is_public = true;
      this.errors = {};
      this.name='';
      if (this.$refs.quillEditorRef) {
        this.$refs.quillEditorRef.setText('');
    }
      this.fileName='';
    },

    fecthTopics() {
      const portal_id = this.$route.params.portal_id;
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_topics_api`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: {portal_id: portal_id},
        success: (response) => {
          var status = response.status
          if (status == "success") {
            this.topics = response.topics
            if (this.topics.length === 0) {
              Swal.fire({
                icon: 'info',
                title: 'No Topics Found',
                text: 'No topics are available for the selected portal.',
                timer: 3000
              });
            }
          }
        },

        complete: () => {
          this.isLoading = false;
        }
      });
    },
      setupTextareaAutoResize() {
        const reportTextarea = this.$refs.reportTextarea;
        if (reportTextarea) {
          this.autoResizeTextarea(reportTextarea);
          reportTextarea.addEventListener('input', () => this.autoResizeTextarea(reportTextarea));
        }

        const hubTextarea = this.$refs.hubTextarea;
        if (hubTextarea) {
          this.autoResizeTextarea(hubTextarea);
          hubTextarea.addEventListener('input', () => this.autoResizeTextarea(hubTextarea));
        }
      },
      autoResizeTextarea(textarea) {
        if (textarea) {
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight}px`;
        }
      },
    }
  };
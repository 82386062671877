<div>
    <Sidebar ref="sidebar"></Sidebar>
    <section class="home-section">
      <Navbar @toggle-sidebar="toggleSidebar"></Navbar>
      <div class="row mt-4">
        <div class="col-md-4 mb-2">
          <div class="card shadow rounded-25 bg-card-primary rounded-5 border-0">
              <div class="card-body">
                  <div class="d-flex justify-content-between px-md-1">
                      <div>
                      <h6 class="fw-bold text-secondary">Total number of users</h6>
                      <p class="mb-0 sub_heading text-xsm">
                        {{ total_users }}
                      </p>
                      </div>
                      <div class="align-self-center rounded-circle p-2 shadow bg-dark-purple">
                        <i class='bx bxs-user-plus fs-2 color-yellow'></i>
                      </div>
                  </div>
                  <a type="button" href="/create_user" class="btn btn-primary text-xxs shadow rounded-5"><i class="bi bi-eye"></i> View</a>
              </div>
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="card shadow rounded-25 bg-card-primary rounded-5 border-0">
              <div class="card-body">
                  <div class="d-flex justify-content-between px-md-1">
                      <div>
                        <h6 class="fw-bold text-secondary">Total number of portals</h6>
                      <p class="mb-0 sub_heading text-xsm">
                        {{ total_portals }}
                      </p>
                      </div>
                      <div class="align-self-center rounded-circle p-2 shadow bg-dark-purple">
                        <i class='bx bxs-user-plus fs-2 color-yellow'></i>
                      </div>
                  </div>
                  <a type="button" href="/create_portal" class="btn btn-primary text-xxs shadow rounded-5"><i class="bi bi-eye"></i> View</a>
              </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card shadow rounded-25 bg-card-primary rounded-5 border-0 mb-2">
              <div class="card-body">
                  <div class="d-flex justify-content-between px-md-1">
                      <div>
                        <h6 class="fw-bold text-secondary">Total number of feeds</h6>
                      <p class="mb-0 sub_heading text-xsm">
                        {{ total_reports }}
                      </p>
                      </div>
                      <div class="align-self-center rounded-circle p-2 shadow bg-dark-purple">
                        <i class='bx bxs-user-plus fs-2 color-yellow'></i>
                      </div>
                  </div>
                  <a type="button" href="/create_portal" class="btn btn-primary text-xxs shadow rounded-5"><i class="bi bi-eye"></i> View</a>
              </div>
          </div>
        </div>

    </div>

    </section>
  </div>
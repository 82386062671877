import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';
import Swal from 'sweetalert2';
import { get_token } from "@/router";

export default {
    components: {
        Sidebar,
        Navbar
    },

    mounted() {
        this.get_membership_types();
        this.fetch_memberships();
    },

    data() {
        return ({
            memberships: [],
            membership_name: '',
            membership_description: '',
            membership_price: 0,
            membership_type: '',
            payment_date: '',
            membership_types: [],
            errors: {},
            isSubmitting: false,
            isLoading: false,
            token_exists: false,  // Added token_exists initialization
        });
    },


    methods: {
        getToken() {
            return get_token();
        },
        toggleSidebar() {
            this.$refs.sidebar.$el.classList.toggle("close");
        },

        get_membership_types() {
            this.isLoading = true;
            this.token_exists = get_token();

            $.ajax({
                url: `${process.env.VUE_APP_BACKEND_API_URL}/membership_management/get_membership_types/`,
                method: "GET",
                headers: this.token_exists
                    ? {
                        Authorization: `Token ${this.getToken()}`,
                        "Content-Type": "application/json",
                    }
                    : {},
                success: (response) => {
                    this.membership_types = response.data.membership_type;
                },
                complete: () => {
                    this.isLoading = false;
                },
            });
        },

        createMembership() {
            if (!this.validateMembershipForm()) {
                return;
            }

            Swal.fire({
                title: 'Submitting...',
                text: 'Please wait while we process your request.',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            this.isSubmitting = true;

            const formData = new FormData();
            formData.append('membership_name', this.membership_name);
            formData.append('description', this.membership_description);
            formData.append('membership_type', this.membership_type);
            formData.append('price', this.membership_price);
            formData.append('payment_date', this.payment_date);

            $.ajax({
                type: 'POST',
                url: `${process.env.VUE_APP_BACKEND_API_URL}/membership_management/create_membership/`,
                data: formData,
                processData: false,
                contentType: false,
                headers: {
                    'Authorization': `Token ${this.getToken()}`,
                },
                success: (response) => {
                    const status = response.status;

                    if (status === "success") {
                        Swal.fire({
                            icon: "success",
                            title: "Membership created",
                            showConfirmButton: false,
                            timer: 1000
                        }).then(() => {
                            this.fetch_memberships();
                            this.resetForm();
                            $('#create_membership').modal('hide');
                        });
                    }
                },
                error: (response) => {
                    Swal.close();
                    const response_data = response.responseJSON;
                    if (response_data) {
                        this.errors = response_data;
                    }
                },
                complete: () => {
                    this.isSubmitting = false;
                },
            });
        },

        fetch_memberships() {
            this.token_exists = get_token();

            $.ajax({
                url: `${process.env.VUE_APP_BACKEND_API_URL}/membership_management/filter_memberships/`,
                method: "POST",
                headers: this.token_exists
                    ? {
                        Authorization: `Token ${this.getToken()}`,
                        "Content-Type": "application/json",
                    }
                    : {},
                success: (response) => {
                    this.memberships = response.data.memberships;

                    this.$nextTick(() => {
                        $('.table').DataTable().destroy();  // Destroy previous instance if exists
                        $('.table').DataTable({
                            paging: true,
                            searching: true,
                            ordering: true,
                            info: true,
                            lengthChange: true,
                            pageLength: 10,
                            language: {
                                paginate: {
                                    previous: "Previous",
                                    next: "Next"
                                },
                                info: "Showing _START_ to _END_ of _TOTAL_ entries",
                                infoEmpty: "No entries available"
                            }
                        });
                    });
                },
                complete: () => {
                    this.isLoading = false;
                },
            });
        },

        validateMembershipForm() {
            this.errors = {};
            let isValid = true;
            if (!this.membership_name.trim()) {
                this.errors.membership_name = 'Membership name is required.';
                isValid = false;
            }

            if (!this.membership_type) {
                this.errors.membership_type = 'Membership type is required.';
                isValid = false;
            }

            return isValid;
        },


        resetForm() {
            this.membership_name = '';
            this.membership_description = '';
            this.membership_type = '';
            this.errors = {};
          },

    }

}
import {get_token,get_user_details} from "@/router";
import Swal from 'sweetalert2';


const domain=window.location.hostname;

export default {
  mounted() {
    this.fetchPortals();
    this.fetch_users();
    this.fetchPortalMembers();
    this.token_exists = get_token();
    this.user_details=get_user_details();
    this.fetchUsersWithRoles();
    this.fetch_all_Portal_admin();
    this.$emit("update_portal_count", this.portal_member_count);
    if (domain.includes('workbook')) {
      this.workbook_domain = true;
    }
    if (domain.includes('workstreams') || domain.includes('demo')){
      this.stagging_domain = true;
    }
    this.fetch_Portal_admin();
    window.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  },

  props: {
    topics: {
      type: Array,
      required: true
    },
    is_public: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    '$route.params.portal_id': {
      handler(newPortalId) {
        this.portal_id = newPortalId;
        this.members = [];
        this.portal_creater = null;

        this.fetchPortals();
        this.fetchPortalMembers();
        this.fetchUsersWithRoles();
      },
      immediate: true // Run it immediately when the component is mounted
    }
  },

  data() {
    return ({
      portals: [],
      members: [],
      users:[],
      selectedUserIds:[],
      portal_id:'',
      is_public: true,
      isLoading: false,
      token_exists: false,
      isSubmitting: false,
      errors: {},
      searchQuery: "",
      portal_creater:'',
      user_details: {
        "user_role": '',
        "first_name": '',
        "last_name": '',
      },
      portal_users:[],
      portals_roles:[],
      status:"",
      workbook_domain: false,
      stagging_domain: false,
      portal_admin_button:false,
      portal_admin: false,
      adminUserIds: new Set(),
    });
  },

  computed: {

    portal_id(){
      return this.$route.params.portal_id;
    },
    portal_member_count() {
      return this.members.length;
    },
    filteredUsers() {
      if (!this.searchQuery) {
        return this.users;
      }
      const query = this.searchQuery.toLowerCase();
      return this.users.filter(user => 
        user.first_name.toLowerCase().includes(query) ||
        user.last_name.toLowerCase().includes(query)
      );
    },

    filteredMembers() {
      if (!this.searchQuery) {
        return this.members.filter(user => user.id !== this.portal_creater)
      }
      const query = this.searchQuery.toLowerCase();
      return this.members.filter(user => 
        user.id !== this.portal_creater &&(
        user.first_name.toLowerCase().includes(query) ||
        user.last_name.toLowerCase().includes(query))
      );
    },
    areAllSelected() {
      const filteredIds = this.filteredUsers.map(user => user.id);
      return filteredIds.every(id => this.selectedUserIds.includes(id)) && filteredIds.length > 0;
    },
    SelectAllMembers() {
      const filteredIds = this.filteredMembers.map(user => user.id);
      return filteredIds.every(id => this.selectedUserIds.includes(id)) && filteredIds.length > 0;
    }
  },

  methods: {
    getToken() {
      return get_token();
    },
    get_user_id() {
      const user_id = localStorage.getItem('user_id') || '';
      return user_id;
  },

  getProfileLink(member) {
    // Check if the user is an admin
    if (member.role.role === 'admin') {
      window.location.href=`/customer_profile?user_id=${member.id}`;
      return ``;
    } else if (member.role.role === 'customer') {
      // Return the link for customer profiles
        window.location.href=`/customer_profile?user_id=${member.id}`;
    } else {
      // Return a default profile link for other roles
      return ``;
    }
  },

  handleTopicClick(topicId) {
    this.$emit('topic_selected', topicId); 
  },
  toggleSelectAll() {
    const filteredIds = this.filteredUsers.map(user => user.id);
    if (this.areAllSelected) {

      this.selectedUserIds = this.selectedUserIds.filter(id => !filteredIds.includes(id));
    } else {
      this.selectedUserIds = [...new Set([...this.selectedUserIds, ...filteredIds])];
    }
  },
  toggleSelectAllMembers() {
    const filteredIds = this.filteredMembers.map(user => user.id);
    if (this.SelectAllMembers) {
      this.selectedUserIds = this.selectedUserIds.filter(id => !filteredIds.includes(id));
    } else {
      this.selectedUserIds = [...new Set([...this.selectedUserIds, ...filteredIds])];
    }
  },



    fetchPortals() {
      this.isLoading = true;
      this.portal_id=this.$route.params.portal_id
      $.ajax({
      url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_portals_api/`,
      method: 'POST',
      headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
      },
      success: (data) => {
          this.portals = data.portals;
          const visible=this.portals.find(portal_id => portal_id.id === parseInt(this.portal_id, 10));
          if (visible) {
            this.is_public = visible.is_public;
            this.fetchUsersWithRoles()
          }
      },
      complete: () => {
          this.isLoading = false;
      }
      });
  },

  fetch_users() {
    return new Promise((resolve) => {

      const portalId = this.$route.params.portal_id;
    $.ajax({
      type: 'GET',
      data:{portal_id:portalId},
      url: process.env.VUE_APP_BACKEND_API_URL + '/system_management/retrieve_users/',
      headers: {
        'Authorization': `Token ${this.getToken()}`,
      },
      success: (response) => {
        this.users = response.data.users_list;
        resolve();
      },
    });
  });
  },

  addPortalMembers() {
    const baseUrl = `${window.location.origin}${this.$route.path}`
    if (this.selectedUserIds.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please select at least one  user.',
        showConfirmButton: true
      });
      return;
    }
    this.isSubmitting = true;
    const requestData = {
      portal_id: this.$route.params.portal_id,
      user_id: this.selectedUserIds,
      base_url: baseUrl
    };
    $.ajax({
      method: "POST",
      url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_portal_members/`,
      contentType: "application/json",
      headers: {
        'Authorization': `Token ${this.getToken()}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(requestData),
      success: (response) => {
        const status = response.status;
        const newMembers = response.members.map(member => member.user);
        if (status === "success") {
          Swal.fire({
            icon: 'success',
            title: 'Members Added Successfully',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            newMembers.forEach(user => {
              this.members.push(user);});
              this.users = this.users.filter(user => !this.selectedUserIds.includes(user.id));
            this.selectedUserIds = [];
            $('#add_portal_member_modal').modal('hide');
            this.searchQuery=""
          });
        }
      },
      complete: () => {
        this.isSubmitting = false;
      }
    });
  },
  deletePortalMembers() {
    if (this.selectedUserIds.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please select at least one user to remove.',
        showConfirmButton: true
      });
      return;
    }
    this.isSubmitting = true;
    const requestData = {
      portal_id: this.$route.params.portal_id,
      user_id: this.selectedUserIds,
    };
    $.ajax({
      method: "DELETE", 
      url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_portal_members/`,
      contentType: "application/json",
      headers: {
        'Authorization': `Token ${this.getToken()}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(requestData),
      success: (response) => {
        const status = response.status;
        
        if (status === "success") {
          Swal.fire({
            icon: 'success',
            title: 'Members Removed Successfully',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            this.selectedUserIds.forEach(userId => {
              const removedMember = this.members.find(member => member.id === userId);
              if (removedMember) {
                this.users.push(removedMember);
              }
            });
            this.members = this.members.filter(member => !this.selectedUserIds.includes(member.id));
            this.selectedUserIds = [];
            $('#delete_portal_member_modal').modal('hide');
            this.searchQuery = "";
          });
        }
      },
      complete: () => {
        this.isSubmitting = false;
      }
    });
  },

  fetchPortalMembers() {

    this.members = [];
    this.portal_creater = null;

    if(!this.portal_id) return;


    this.isLoading = true;
    $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_portal_members/`,
        method: "GET",
        data: { portal_id: this.portal_id },
        headers: {
            "Authorization": `Token ${this.getToken()}`,
            "Content-Type": "application/json"
        },
        success: (response) => {
            if (response.status === "success") {
                this.members = response.data.members;
                this.portal_creater = response.data.portal.creater_id;
            }
        },
        error: (xhr) => {
            console.error("Error fetching portal members:", xhr);
        },
        complete: () => {
            this.isLoading = false;
        }
    });
  },

  fetchPositionsAssigned(portal_id) {
    const payload = {
      filter_values: {
        id: portal_id
      }
    }
    $.ajax({
      type: "POST",
      url: process.env.VUE_APP_BACKEND_API_URL + "/portal_management/get_portal_assigned_position_api/",
      headers: {
        "Authorization": `Token ${this.getToken()}`,
        "Content-Type": "application/json"
      },
      data: JSON.stringify(payload),
      success: (response) => {
        if (response.status === "success") {
          this.portals_roles = response.portal_positions;
        }
      },
    });
  },
  assign_position_to_portal_user(event, portal_id,user_id) {

      const position_id = event.target.value;
      const payload = {
        user_id: user_id,
        position_id: position_id,
        portal_id: portal_id
      };

        $.ajax({
          type: "POST",
          url: process.env.VUE_APP_BACKEND_API_URL + "/portal_management/assign_roles_to_user_api/",
          headers: {
            "Authorization": `Token ${this.getToken()}`,
            "Content-Type": "application/json"
          },
          data: JSON.stringify(payload),
          success: (response) => {
            if (response.status === "success") {
              event.target.value = "";
              const user =this.portal_users.find(user => user.id === user_id);
              if (user) {
                user.positions.push({
                  position_id: position_id,
                  position_name: response.portal_positions[0].portal_position_name
                });
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: response.message || "Failed to assign position.",
              });
            }
          },
        });
      },

      fetchUsersWithRoles() {
        this.portal_id = this.$route.params.portal_id;
        const payload = {
          portal_id: this.portal_id,
        };
        this.fetchPositionsAssigned(this.portal_id);
      
        $.ajax({
          type: "POST",
          url: process.env.VUE_APP_BACKEND_API_URL + "/portal_management/get_assigned_users_roles_api/",
          headers: {
            Authorization: `Token ${this.getToken()}`,
            "Content-Type": "application/json"
          },
          data: JSON.stringify(payload),
          success: (response) => {
            if (response.status === "success") {
              const userMap = new Map();
      
              response.portal_positions.forEach(item => {
                const user = item.portal_access.user;
                const position = item.portal_position.position;
      
                if (!userMap.has(user.id)) {
                  userMap.set(user.id, {
                    id: user.id,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    positions: []
                  });
                }
      
                userMap.get(user.id).positions.push({
                  position_id: position.id,
                  position_name: position.name
                });
              });

              this.members.forEach(member => {
                if (!userMap.has(member.id)) {
                  userMap.set(member.id, {
                    id: member.id,
                    first_name: member.first_name,
                    last_name: member.last_name,
                    email: member.email,
                    role:member.role,
                    positions: []
                  });
                }
              });
      
              this.users.forEach(user => {
                if (!userMap.has(user.id)) {
                  userMap.set(user.id, {
                    id: user.id,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    positions: []
                  });
                }
              });

              this.portal_users = Array.from(userMap.values()).filter(user => 
                this.members.some(member => member.id === user.id)
              );
            }
          },
        });
      },
      remove_position(user_id, position_id, posIndex, portal_id) {
        const data = {
          user_id: user_id,
          portal_id: portal_id,
          position_id: position_id,
        };

        $.ajax({
          type: "POST",
          url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_user_assign_role_api/`,
          headers: {
            Authorization: `Token ${this.getToken()}`,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(data),
          dataType: "json",
          success: (response_data) => {
            if (response_data.status === "success") {
              this.portal_users = this.portal_users.map(user => {
                if (user.id === user_id) {
                  user.positions = user.positions.filter((_, index) => index !== posIndex);
                }
                return user;
              });
              
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Can not delete assigned position.',
                text: 'Please try again',
              });
            }
          }
        });
      },
      available_positions(portal, user) {
        const filtered_positions = portal.positions.filter(p => 
          !user.positions.some(up => String(up.position_id) === String(p.id))
        );
        return filtered_positions;
      },
      assign_portal_admin(user_id) {
        const portal_id = this.$route.params.portal_id;

        const payload = {
          user_id: user_id,
          portal_id: portal_id,
        };

        this.portal_id = this.$route.params.portal_id;

        $.ajax({
          url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/assign_plartform_admin_api/`,
          method: 'POST',
          data: JSON.stringify(payload),
          headers: {
            'Authorization': `Token ${this.getToken()}`,
            'Content-Type': 'application/json',
          },
          success: (data) => {
            if (data.status === 'success') {
              this.fetch_all_Portal_admin()
              Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Portal Admin assigned successfully.',
                timer: 3000,
                timerProgressBar: true,
              });
            } 
            else if (data.status === 'error') {
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: data.message,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          },
          error: (xhr) => {

            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: xhr.responseJSON?.message || 'Position already assigned.',
              timer: 3000,
              timerProgressBar: true,
            });
          },
          complete: () => {
            this.isLoading = false;
          },
        });
      },


fetch_Portal_admin() {

  const portal_id = this.$route.params.portal_id;
  const user_id = localStorage.getItem('user_id') || '';

  const payload = {
    filter_values: {
      portal_id: portal_id,
      user_id: user_id
    }
  };

  $.ajax({
    url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_plartform_admin_api/`,
    method: 'POST',
    headers: {
      'Authorization': `Token ${this.getToken()}`,
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(payload),
    success: (data) => {
      const isActive = data.portal_admin_detalails[0].user.is_active;
      if (isActive) {
        this.portal_admin = true;
        this.portal_admin_button = true;
      } else {
        this.portal_admin = false;
        this.portal_admin_button = false;
      }
    },
  
    error: () => {
      this.portal_admin = false;
      this.portal_admin_button = false;
    },

    complete: () => {
      this.isLoading = false;
    }
  });
},

get_member_id(user_id) {
  localStorage.setItem('user_id', user_id);
  this.fetch_Portal_admin();
  this.fetchUsersWithRoles();

  if (this.activeDropdown === user_id) {
    this.activeDropdown = null;
  } else {
    this.activeDropdown = user_id;
  }
},
handleClickOutside(event) {
  if (!event.target.closest('.reply_dropdown')) {
    this.activeDropdown = null;
  }
},

unassign_portal_admin(user_id) {
  const portal_id = this.$route.params.portal_id;

  const payload = {
    user_id: user_id,
    portal_id: portal_id,
  };


  $.ajax({
    url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/unassign_plartform_admin_api/`,
    method: 'POST',
    data: JSON.stringify(payload),
    headers: {
      'Authorization': `Token ${this.getToken()}`,
      'Content-Type': 'application/json',
    },
    success: (data) => {
      if (data.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'success!',
          text: data.message,
          timer: 3000,
          timerProgressBar: true,
        });
        this.fetch_all_Portal_admin()
      } 
      else if (data.status === 'error') {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: data.message,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
    error: (xhr) => {

      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: xhr.responseJSON?.message || 'Position already assigned.',
        timer: 3000,
        timerProgressBar: true,
      });
    },
    complete: () => {
      this.isLoading = false;
    },
  });
},

fetch_all_Portal_admin() {
  this.fetchUsersWithRoles();

  const portal_id = this.$route.params.portal_id;
  const payload = {
    filter_values: {
      portal_id: portal_id,
    }
  };

  $.ajax({
    url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_plartform_admin_api/`,
    method: 'POST',
    headers: {
      'Authorization': `Token ${this.getToken()}`,
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(payload),
    success: (data) => {
      this.adminUserIds = new Set(data.portal_admin_detalails.map(detail => detail.user.id));
    },
  
    error: () => {
      this.adminUserIds = new Set();
    },

    complete: () => {
      this.isLoading = false;
    }
  });
},
  }
}
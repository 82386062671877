<!-- Navbar -->
<HomeNavBar @new_report="addReportToHome" @new_hub="addNewExchangeHub" @addPortal="addNewPortal" @newSegment="addNewSegement" @new_temp_post="addTemporaryPost" />
<!-- Main Content -->

    <div class="row content-section h-100">
      <!-- Sidebar for Medium to Large Screens (992px and up) -->
      <div class="col-lg-3 d-none d-lg-block h-100">
        <MenuSideBar />
      </div>
      <!-- Middle Content -->
      <div class="col-lg-6 col-md-12 col-12 ">
        <!-- Mobile & iPad View: Left & Right Alignment -->
         <div class="banner-pills">
          <div class="d-flex d-md-none w-100 text-center bg-banner-gradient">
            <ul class="pills-billing-tab nav nav-pills  mt-2 w-100 " id="pills-billing-tab" role="tablist">
              <li class="nav-item flex-fill text-center" role="presentation">
                <button class="nav-link active w-100 text-center px-3" id="normal_loans_tab" data-bs-toggle="pill"
                  data-bs-target="#normal_loans_info" type="button" role="tab" aria-controls="normal_loans_info"
                  aria-selected="true">
                  Your Feed
                </button>
              </li>
              <li class="nav-item flex-fill text-center" role="presentation">
                <button class="nav-link w-100 text-center px-3" id="top_loans_tab" data-bs-toggle="pill"
                  data-bs-target="#top_loans_info" type="button" role="tab" aria-controls="top_loans_info"
                  aria-selected="false">
                  Your Voice
                </button>
              </li>
            </ul>
          </div>
         </div>
        
        
        <div class="card bg-white rounded-3 banner-card  rounded-5 d-none d-md-flex ">
          <div class="card-body border-0">
          </div>
          <div class="card-footer border-0">
            <h5 class="text-white text-center">{{$welcomeText}}</h5>
            <div class="justify-content-center w-100 banner-pills">
              
              <ul class="pills-billing-tab nav nav-pills mb-3 justify-content-center" id="pills-billing-tab-md" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active text-xs fw-500 px-4" id="normal_loans_tab_md" data-bs-toggle="pill"
                    data-bs-target="#normal_loans_info" type="button" role="tab" aria-controls="normal_loans_info"
                    aria-selected="true">
                    Your Feed
                  </button>
                </li>
                <li class="nav-item ms-4" role="presentation">
                  <button class="nav-link text-xs fw-500 px-4" id="top_loans_tab_md" data-bs-toggle="pill"
                    data-bs-target="#top_loans_info" type="button" role="tab" aria-controls="top_loans_info"
                    aria-selected="false">
                    Your Voice
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
    
        <div class="post-section mt-3 overflow-auto p-3 h-150 position-relative full-width-mobile scroll-container">
          <div class="tab-content content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="normal_loans_info" role="tabpanel"
              aria-labelledby="normal_loans_tab">
              <div class="d-flex align-items-center temporary-post" v-if="temporaryPost" id="temp-post" >
                <p class="text-xs fw-bold">{{ temporaryPost.report_name }} - Posting...</p>
                <div class="spinner-border ms-auto  text-secondary" role="status" aria-hidden="true"></div>
              </div>
              <div v-if="reports.length">
                <div v-for="report in reports" :key="report.id" :id="'post-' + report.id"
                  class="card card-hover mb-4 p-3 d-flex flex-column border-bottom rounded-5 border-0 full-width-mobile shadow"
                  @click="display_comment_modal(report)">
                  <div class="row">
                    <div class="col">
                      <div class="d-flex flex-start">
                        <i class='bx bxs-user-circle text-secondary fs-1'></i>
                        <div class="flex-grow-1 flex-shrink-1">
                          <div>
                            <div class="d-flex justify-content-between align-items-center">
                              <p class="fw-500 mt-1 lh-1 text-xs">{{ report.creater.first_name }} {{
                                report.creater.last_name }} <span
                                  class="badge bg-warning text-dark mx-2 text-white shadow-sm"><i
                                    class='bx bxs-bulb'></i>{{report.topic_name}}</span><span v-if="report.is_pinned"><i
                                    class='bx bxs-pin'></i></span><br><span class="text-xxs"><i
                                    class="bx bxs-stopwatch"></i> {{ report.publication_date }}</span>
                              </p>
                              <div class="dropstart" @click.stop
                                v-if="String(report.creater.id) === String(user_id) && token_exists ||user_details.user_role=='admin'&& token_exists">
                                <a href="" class="fw-bold text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                  aria-expanded="false">
                                  <i class="bi bi-three-dots-vertical"></i><span class="small"></span>
                                </a>
                                <ul class="dropdown-menu custom-dropdown lh-1" aria-labelledby="dropdownMenuButton">

                                  <a v-if="user_details.user_role=='admin'"
                                    class="dropdown-item text-xs border-bottom fw-bold" href=""
                                    @click.prevent="togglePinReport(report)">
                                    <i :class="report.is_pinned ? 'bx bx-pin fs-6' : 'bx bxs-pin fs-6'"></i>
                                    {{ report.is_pinned ? 'Unpin' : 'Pin' }}
                                  </a>
                                  <li>
                                    <a class="dropdown-item text-xs fw-bold" href="" data-bs-toggle="modal"
                                      data-bs-target="#edit_report_modal" @click="edit_report(report)"
                                      v-if="report.total_comments ==0 && report.like_count==0">
                                      <i class="bx bxs-edit fs-6"></i> Edit
                                    </a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item text-xs fw-bold" href=""
                                      @click.prevent="deleteReport(report.id)">
                                      <i class='bx bxs-trash-alt fs-6'></i> Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="fw-500 text-xs">{{ report.name }}<a v-if="report.report_files"
                          :href="report.report_link" class="text-xxs d-block mb-1">
                          <span class="text-dark fw-bold"></span> {{ report.report_link }}</a></p>

                      <div class="text-xs display-6">
                        <span v-html="getDisplayedReportDescription(report)"></span>
                        <span v-if="shouldShowReadMore(report)" class="text-secondary cursor-pointer text-xxs"
                          @click="toggleReportReadMore(report.id)">
                          <!-- Check if it should show 'Read More' and render the icon accordingly -->
                          {{ isReportExpanded(report.id) ? 'Read Less' : 'Read More' }}
                          <i v-if="!isReportExpanded(report.id)" class='bx bx-down-arrow-circle'></i>
                          <i v-else class='bx bx-up-arrow-circle'></i>
                        </span>
                      </div>

                      <div class="d-flex flex-column" v-if="report.report_files && report.report_files.length">
                        <div v-for="(fileObj, index) in report.report_files" :key="index" class="mb-3">
                          <!-- Image -->
                          <img v-if="fileObj.file.file_url.includes('.jpg') ||
                                    fileObj.file.file_url.includes('.png') || 
                                    fileObj.file.file_url.includes('.jpeg')" :src="fileObj.file.file_url"
                            alt="Feed Image" class="img-fluid rounded-5 w-100 h-50"
                            @click="display_comment_modal(report)" />

                          <!-- Audio -->
                          <audio v-else-if="fileObj.file.file_url.includes('.mp3') ||
                                          fileObj.file.file_url.includes('.wav')" controls class="w-100">
                            <source :src="fileObj.file.file_url" type="audio/mp3">
                            Your browser does not support the audio element.
                          </audio>

                          <!-- Video -->
                          <video v-else-if="fileObj.file.file_url.includes('.mp4')" controls
                            class="img-fluid rounded-5 w-100" @click="display_comment_modal(report)">
                            <source :src="fileObj.file.file_url" type="video/mp4">
                            Your browser does not support the video tag.
                          </video>

                          <div v-else class="uploaded-file" @click="display_comment_modal(report)">
                            <img :src="getFileIcon(fileObj.file.file_url)" class="file-icon small-icon" />
                            <span class="file-name">{{ getFileName(fileObj.file.file_url) }}</span>
                            <a :href="fileObj.file.file_url" download :title="getFileName(fileObj.file.file_url)" class="download-btn" @click.stop>
                              <img src="https://cdn-icons-png.flaticon.com/128/724/724933.png" class="download-icon" />
                            </a>
                          </div>

                        </div>
                      </div>

                      <div class="text-gray mx-2 mt-1 text-end">
                        <span class="me-2 mt-2">{{ report.like_count || 0 }} <i class='bx bxs-like '></i></span>
                        <span>{{ report.total_comments }} <i class='bx bxs-comment'></i></span>
                      </div>
                      <div class="d-flex justify-content-between align-items-center ">
                        <div class="mb-3 ms-2 me-3 mt-1">
                          <span class="d-flex color-purple " :id="'like-button' + report.id"
                            @click.stop="likeReport(report)"><i class="bi bi-hand-thumbs-up fw-bold"></i><span
                              class="text-purple text-xs mt-1 mx-1">Like</span></span>
                          <span :id="'liked-button' + report.id" class="d-none d-flex text-primary "
                            @click.stop="unlikeReport(report)"><i class="bi bi-hand-thumbs-up-fill"></i><span
                              class="text-primary text-xs mt-1 mx-1">Unlike</span> </span>
                        </div>

                        <div class="input-group mb-3 me-2 rounded-5 w-100 position-relative mt-1"
                          @click="display_comment_modal(report)">

                          <input type="text" class="form-control text-xs rounded-start rounded-end"
                            placeholder="Write a comment .." readonly>
                        </div>
                        <div class="mb-3 me-2 menu mt-1" @click.stop>
                          <input type="checkbox" :id="'menu-open-' + report.id" class="menu-open" name="menu-open" />
                          <span><label :for="'menu-open-' + report.id"
                              class="menu-open-button text-xs color-purple d-flex" @click="boon"><i
                                class="bi bi-share"></i><span class="color-purple mx-1 ">Share</span></label></span>

                          <a @click="copyReportLinkToClipboard(report)" class="menu-item ">
                            <span @click="closeMenu(report.id)"><i
                                class="bi bi-link fs-4 copy text-primary text-xxs"></i></span>
                          </a>

                          <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(baseUrl(report,'report'))"
                            target="_blank" class="menu-item facebook-share-btn" @click="closeMenu(report.id)">
                            <i class="bi bi-facebook"></i>
                          </a>
                          <a :href="'https://twitter.com/intent/tweet?url=' + encodeURIComponent(baseUrl(report,'report'))"
                            target="_blank" class="menu-item twitter-share-btn" @click="closeMenu(report.id)">
                            <i class="bi bi-twitter-x"></i>
                          </a>
                          <a :href="'mailto:?subject=Check%20this%20out&body=' + encodeURIComponent(baseUrl(report,'report'))"
                            target="_blank" class="menu-item gmail-share-btn" @click="closeMenu(report.id)">
                            <i class="bi bi-google"></i>
                          </a>
                          <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(baseUrl(report,'report'))"
                            target="_blank" class="menu-item linkedin-share-btn" @click="closeMenu(report.id)">
                            <i class="bi bi-linkedin "></i>
                          </a>
                          <a :href="'https://api.whatsapp.com/send?text=' + encodeURIComponent(baseUrl(report,'report'))"
                            target="_blank" class="menu-item whatsapp-share-btn" @click="closeMenu(report.id)">
                            <i class="bi bi-whatsapp "></i>
                          </a>
                          <a :href="'https://t.me/share/url?url=' + encodeURIComponent(baseUrl(report,'report'))"
                            target="_blank" class="menu-item telegram-share-btn" @click="closeMenu(report.id)">
                            <i class="bi bi-telegram "></i>
                          </a>

                          <!-- toaster -->
                          <div aria-live="polite" aria-atomic="true" class="position-fixed custom-toast">
                            <div id="copyToast" class="toast align-items-center text-bg-success border-0" role="alert"
                              aria-live="assertive" aria-atomic="true">
                              <div class="d-flex">
                                <div class="toast-body">
                                </div>
                                <button type="button" class="btn-close btn-close-white me-2 m-auto"
                                  data-bs-dismiss="toast" aria-label="Close"></button>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <div v-else class=" text-center">No available Feeds</div>
            </div>
            <div class="tab-pane fade" id="top_loans_info" role="tabpanel" aria-labelledby="top_loans_tab">
              <div v-if="exchange_hub_data.length">
                <div v-for="exchange_hub in exchange_hub_data" :key="exchange_hub.id"
                  class="card card-hover bg-white border-0 mb-3 report-card p-2 shadow rounded-5 full-width-mobile"
                  @click="display_xHub_comment(exchange_hub)">
                  <div class="row flex-grow-1">
                    <div class="col">
                      <div class="d-flex flex-start">
                        <i class='bx bxs-user-circle text-secondary fs-2'></i>
                        <div class="flex-grow-1 flex-shrink-1">
                          <div>
                            <div class="d-flex justify-content-between align-items-center">
                              <p class="fw-500 mt-1 lh-1 text-xs">{{ exchange_hub.creater.first_name }} {{
                                exchange_hub.creater.last_name }} <span
                                  class="badge bg-warning text-dark mx-2 text-white shadow-sm"><i
                                    class='bx bxs-bulb'></i>{{exchange_hub.topic_name}}</span><br><span
                                  class="text-xxs"><i class="bx bxs-stopwatch"></i> {{ exchange_hub.publication_date
                                  }}</span>
                              </p>

                              <div class="dropstart" @click.stop>
                                <a href="" class="fw-bold text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                  aria-expanded="false">
                                  <i class="bi bi-three-dots-vertical"></i><span class="small"></span>
                                </a>
                                <ul class="dropdown-menu custom-dropdown" aria-labelledby="dropdownMenuButton">
                                  <li>
                                    <a class="dropdown-item text-xs fw-bold" href="" data-bs-toggle="modal"
                                      data-bs-target="#edit_voice_modal" @click="edit_voice(exchange_hub)"
                                      v-if="exchange_hub.total_comments==0 && exchange_hub.like_count==0">
                                      <i class="bx bxs-edit fs-6"></i> Edit
                                    </a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item text-xs fw-bold" href=""
                                      @click.prevent="deleteExchangeHub(exchange_hub.id)">
                                      <i class='bx bxs-trash-alt fs-6'></i> Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <p class="fw-500 text-xs">{{ exchange_hub.name }}<a v-if="exchange_hub.document"
                          class="text-xxs d-block mb-1">
                          <span class="text-dark fw-bold"></span> {{ exchange_hub.report_link }}</a></p>

                      <div class="text-xs display-6">
                        <span v-html="getDisplayedHubDescription(exchange_hub)"></span>
                        <span v-if="showReadMore(exchange_hub)" class="text-secondary cursor-pointer"
                          @click="toggleHubReadMore(exchange_hub.id)">
                          {{ isHubExpanded(exchange_hub.id) ? 'Read Less' : 'Read More' }}
                        </span>
                      </div>


                      <div class="d-flex flex-column" v-if="exchange_hub.document && exchange_hub.document.length">
                        <div v-for="(fileObj, index) in exchange_hub.document" :key="index" class="mb-3">
                          <!-- Image -->
                          <img v-if="fileObj.file.file_url.includes('.jpg') ||
                          fileObj.file.file_url.includes('.png') ||
                          fileObj.file.file_url.includes('.jpeg')" :src="fileObj.file.file_url" alt="Feed Image"
                            class="img-fluid rounded-5 w-100 h-50" @click="display_comment_modal(report)" />

                          <!-- Audio -->
                          <audio
                            v-else-if="fileObj.file.file_url.includes('.mp3') || fileObj.file.file_url.includes('.wav')"
                            controls class="w-100">
                            <source :src="fileObj.file.file_url" type="audio/mp3">
                            Your browser does not support the audio element.
                          </audio>

                          <!-- Video -->
                          <video v-else-if="fileObj.file.file_url.includes('.mp4')" controls
                            class="img-fluid rounded-5 w-100">
                            <source :src="fileObj.file.file_url" type="video/mp4">
                            Your browser does not support the video tag.
                          </video>

                          <!-- PDF -->
                          <iframe v-else-if="fileObj.file.file_url.includes('.pdf')"
                            :src="'https://docs.google.com/viewer?url=' + encodeURIComponent(pdf_file_url) + '&embedded=true'"
                            frameborder="0" width="100%" height="500px">
                          </iframe>

                          <!-- Word Document -->
                          <iframe
                            v-else-if="fileObj.file.file_url.includes('.docx') || fileObj.file.file_url.includes('.doc')"
                            :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(fileObj.file.file_url)"
                            frameborder="0" width="100%" height="500px">
                          </iframe>
                        </div>
                      </div>
                      <div class="text-secondary mx-2 mt-1 text-end">
                        <span class="me-2 mt-2">{{ exchange_hub.like_count || 0 }} <i class='bx bxs-like '></i></span>
                        <span>{{ exchange_hub.total_comments }} <i class='bx bxs-comment'></i></span>
                      </div>
                      <div class="d-flex justify-content-between align-items-center ">
                        <div class="mb-3 ms-2 me-3 mt-1">
                          <span class="d-flex text-secondary " :id="'react-button' + exchange_hub.id"
                            @click.stop="likeExchangeHub(exchange_hub)"><i
                              class="bi bi-hand-thumbs-up fw-bold"></i><span
                              class="text-secondary text-xs mt-1 mx-1">Like</span></span>
                          <span :id="'reacted-button' + exchange_hub.id" class="d-none d-flex text-primary "
                            @click.stop="unlikeExchangeHub(exchange_hub)"><i class="bi bi-hand-thumbs-up-fill"></i><span
                              class="text-primary text-xs mt-1 mx-1">Unlike</span> </span>
                        </div>

                        <div class="input-group mb-3 me-2 rounded-5 w-100 position-relative mt-1"
                          @click="display_xHub_comment(exchange_hub)">

                          <input type="text" class="form-control text-xs rounded-start rounded-end"
                            placeholder="Write a comment .." readonly>
                        </div>
                        <div class="mb-3 me-2 menu mt-1" @click.stop>
                          <input type="checkbox" :id="'menu-open-' + exchange_hub.id" class="menu-open"
                            name="menu-open" />
                          <span><label :for="'menu-open-' + exchange_hub.id"
                              class="menu-open-button text-xs text-secondary d-flex" @click="boon"><i
                                class="bi bi-share"></i>
                              <span class="text-secondary mx-1 ">Share</span></label></span>
                          <a @click="copyVoiceLinkToClipboard(exchange_hub)" class="menu-item ">
                            <span @click="closeFeedMenu(exchange_hub.id)"><i
                                class="bi bi-link fs-4 copy text-primary text-xxs"></i></span>
                          </a>
                          <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(baseUrl(exchange_hub,'exchange_hub'))"
                            target="_blank" class="menu-item facebook-share-btn"
                            @click="closeFeedMenu(exchange_hub.id)">
                            <i class="bi bi-facebook"></i>
                          </a>
                          <a :href="'https://twitter.com/intent/tweet?url=' + encodeURIComponent(baseUrl(exchange_hub,'exchange_hub'))"
                            target="_blank" class="menu-item twitter-share-btn" @click="closeFeedMenu(exchange_hub.id)">
                            <i class="bi bi-twitter-x"></i>
                          </a>
                          <a :href="'mailto:?subject=Check%20this%20out&body=' + encodeURIComponent(baseUrl(exchange_hub,'exchange_hub'))"
                            target="_blank" class="menu-item gmail-share-btn" @click="closeFeedMenu(exchange_hub.id)">
                            <i class="bi bi-google"></i>
                          </a>
                          <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(baseUrl(exchange_hub,'exchange_hub'))"
                            target="_blank" class="menu-item linkedin-share-btn"
                            @click="closeFeedMenu(exchange_hub.id)">
                            <i class="bi bi-linkedin"></i>
                          </a>
                          <a :href="'https://api.whatsapp.com/send?text=' + encodeURIComponent(baseUrl(exchange_hub,'exchange_hub'))"
                            target="_blank" class="menu-item whatsapp-share-btn"
                            @click="closeFeedMenu(exchange_hub.id)">
                            <i class="bi bi-whatsapp"></i>
                          </a>
                          <a :href="'https://t.me/share/url?url=' + encodeURIComponent(baseUrl(exchange_hub,'exchange_hub'))"
                            target="_blank" class="menu-item telegram-share-btn" @click="closeFeedMenu(report.id)">
                            <i class="bi bi-telegram"></i>
                          </a>

                          <div aria-live="polite" aria-atomic="true" class="position-fixed custom-toast">
                            <div id="copyTheToast" class="toast align-items-center text-bg-success border-0"
                              role="alert" aria-live="assertive" aria-atomic="true">
                              <div class="d-flex">
                                <div class="toast-body">
                                </div>
                                <button type="button" class="btn-close btn-close-white me-2 m-auto"
                                  data-bs-dismiss="toast" aria-label="Close"></button>
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>

                    </div>

                  </div>
                </div>
              </div>
              <div v-else class="text-center">No available voices</div>
            </div>

          </div>

        </div>
      </div>
       
      <div class="col-lg-3 col-md-4 col-12 d-lg-block">
        <UserEvents/>
        <AllPortals/>
        <AllGroups/>
      </div>


    </div>
  <div class="modal fade" id="edit_voice_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">edit your voice</h5>
          <button type="button" class="btn-close  text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form @submit.prevent="edit_createHub">
          <div class="modal-body">
            <div class="mb-3">
              <label for="name" class="form-label text-xs fw-500">Feed Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control rounded-5 text-xs" id="edit_name" value="edit_name"
                placeholder="Enter voice name" v-model="edit_name">
              <p v-if="errors.edit_voice_name" class="text-danger text-xs">{{ errors.edit_voice_name }}</p>
            </div>
            <QuillEditor theme="snow" v-model="edit_description" ref="quillHubRef" class="quill-editor"
              toolbar="full" />


            <div class="d-flex mt-2 mx-1 justify-content-between align-items-start">
              <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-center">
                <label for="editVoiceFileInput"
                  class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                  <i class='bx bx-image-add file-upload-icon'></i> Add voice content
                </label>
                <input type="file" id="editVoiceFileInput" class="file-input d-none" @change="handleHomeFileUpload" />
                <div v-if="editFileName" class="text-xs text-secondary fst-italic mt-1">
                  <span class="fw-500">File name:</span> {{ editFileName }}
                </div>
                <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>
              </div>

              <div class="file-upload ms-5 my-2 mx-3 flex-grow-1 d-flex flex-column align-items-center">
                <label for="audioInput"
                  class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                  <i class='bx bx-music'></i> Upload Audio
                </label>
                <input type="file" accept="audio/*" id="audioInput" class="file-input d-none"
                  @change="handleAudioUpload" />
                <div v-if="audioError" class="text-xs text-danger fst-italic mt-1">{{ audioError }}</div>
              </div>
              <div class="d-flex flex-column align-items-center my-2 flex-grow-1">
                <div class="d-flex flex-row align-items-center mb-3">
                  <button type="button"
                    class="btn btn-primary text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center"
                    @click="startRecording" v-if="!isRecording">
                    <i class='bx bx-microphone'></i> Record
                  </button>
                  <button type="button"
                    class="btn btn-danger text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center"
                    @click="stopRecording" v-if="isRecording">
                    <i class='bx bx-stop'></i> Stop
                  </button>
                </div>
                <div v-if="audio_file_name" class="text-xs text-secondary fw-500 mb-3 audio-file">
                  <i class='bx bx-music'></i> Recorded: {{ audio_file_name }}
                </div>
              </div>
            </div>


            <div class="d-flex flex-column" v-if="edit_documents.document && edit_documents.document.length">
              <div class="row g-3">
                <div v-for="(fileObj, index) in edit_documents.document" :key="index" class="col-4 mb-3">

                  <img
                    v-if="fileObj.file.file_url.includes('.jpg') || fileObj.file.file_url.includes('.png') || fileObj.file.file_url.includes('.jpeg')"
                    :src="fileObj.file.file_url" alt="File Image" class="img-fluid rounded-5"
                    style="max-height: 150px; object-fit: cover;" />


                  <audio v-else-if="fileObj.file.file_url.includes('.mp3') || fileObj.file.file_url.includes('.wav')"
                    controls class="w-100 rounded-5">
                    <source :src="fileObj.file.file_url" type="audio/mp3">
                    Your browser does not support the audio element.
                  </audio>


                  <video v-else controls class="img-fluid rounded-5 w-100"
                    style="max-height: 150px; object-fit: cover;">
                    <source :src="fileObj.file.file_url" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
          </div>
        </form>

      </div>
    </div>
  </div>

  <div class="modal fade" id="edit_report_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">edit your feed</h5>
          <button type="button" class="btn-close  text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form @submit.prevent="edit_report_data">
          <div class="modal-body">
            <div class="mb-3">
              <label for="name" class="form-label text-xs fw-500">Feed Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control rounded-5 text-xs" id="report_name" value="report_name"
                placeholder="Enter voice name" v-model="report_name">
              <p v-if="errors.edit_report_name" class="text-danger text-xs">{{ errors.edit_report_name }}</p>
            </div>
            <QuillEditor theme="snow" v-model="report_description" ref="quillReportRef" class="quill-editor"
              toolbar="full" />
            <div class="d-flex mt-2 mx-1 justify-content-between align-items-start">
              <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-center">
                <label for="editReportFileInput"
                  class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                  <i class='bx bx-image-add file-upload-icon'></i> Add Feed content
                </label>
                <input type="file" id="editReportFileInput" class="file-input d-none" @change="handleHomeFileUpload" />
                <div v-if="editFileName" class="text-xs text-secondary fst-italic mt-1">
                  <span class="fw-500">File name:</span> {{ editFileName }}
                </div>
                <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>
              </div>
              <div class="file-upload ms-5 my-2 mx-3 flex-grow-1 d-flex flex-column align-items-center">
                <label for="audioInput"
                  class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                  <i class='bx bx-music'></i> Upload Audio
                </label>
                <input type="file" accept="audio/*" id="audioInput" class="file-input d-none"
                  @change="handleAudioUpload" />
                <div v-if="audioError" class="text-xs text-danger fst-italic mt-1">{{ audioError }}</div>
              </div>
              <div class="d-flex flex-column align-items-center my-2 flex-grow-1">
                <div class="d-flex flex-row align-items-center mb-3">
                  <button type="button"
                    class="btn btn-primary text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center"
                    @click="startRecording" v-if="!isRecording">
                    <i class='bx bx-microphone'></i> Record
                  </button>
                  <button type="button"
                    class="btn btn-danger text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center"
                    @click="stopRecording" v-if="isRecording">
                    <i class='bx bx-stop'></i> Stop
                  </button>
                </div>
                <div v-if="audio_file_name" class="text-xs text-secondary fw-500 mb-3 audio-file">
                  <i class='bx bx-music'></i> Recorded: {{ audio_file_name }}
                </div>
              </div>
            </div>
            <div class="d-flex flex-column" v-if="edit_documents.report_files && edit_documents.report_files.length">
              <div class="row g-3">
                <div v-for="(fileObj, index) in edit_documents.report_files" :key="index" class="col-4 mb-3">
                  <img
                    v-if="fileObj.file.file_url.includes('.jpg') || fileObj.file.file_url.includes('.png') || fileObj.file.file_url.includes('.jpeg')"
                    :src="fileObj.file.file_url" alt="File Image" class="img-fluid rounded-5"
                    style="max-height: 150px; object-fit: cover;" />
                  <audio v-else-if="fileObj.file.file_url.includes('.mp3') || fileObj.file.file_url.includes('.wav')"
                    controls class="w-100 rounded-5">
                    <source :src="fileObj.file.file_url" type="audio/mp3">
                    Your browser does not support the audio element.
                  </audio>

                  <video v-else controls class="img-fluid rounded-5 w-100"
                    style="max-height: 150px; object-fit: cover;">
                    <source :src="fileObj.file.file_url" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <StickyNav />

<!-- Voice Modal -->




<!-- Commenting modal -->
<ReportCommentModal v-if="comment_modal_visible" :report_data="report_data"
  @hide_comment_comment="hide_comment_comment" />

<ExchangeHubCommentModal v-if="xHub_comment_modal_visible" :exchange_hubs="exchange_hubs"
  @hide_xhub_comment_comment="hide_xhub_comment_comment" />
<div>
  <Sidebar ref="sidebar"></Sidebar>
  <section class="home-section">
    <Navbar @toggle-sidebar="toggleSidebar"></Navbar>

    <div class="card bg-white shadow-sm mt-5 rounded-5 border-0">
      <div class="card-header bg-white rounded-top-5 mv-5 shadow bg-dark-purple text-white">
        <span class="fw-500">Groups Created</span>
        <button class="btn btn-warning text-white text-xs rounded-5 shadow-lg fw-500 float-end" data-bs-toggle="modal"
          data-bs-target="#create_group">
          <i class='bx bx-plus'></i> Create Group
        </button>
      </div>
      <div class="modal fade" v-for="group in groups" :key="group.id" :id="'edit_group_' + group.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <p class="modal-title text-xs fw-500" id="exampleModalLabel">Edit Group</p>
              <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="editGroup(group.id)">
              <div class="modal-body">
      
                <div class="mb-3">
                  <label or="edit_group_name" class="form-label text-xs fw-500">Group Name <span
                      class="text-danger">*</span></label>
                  <input type="text" class="form-control rounded-5 text-xs" id="edit_group_name" v-model="edit_group">
                  <p v-if="errors.group_name" class="text-danger text-xs">{{ errors.group_name }}</p>
                </div>
                <QuillEditor theme="snow" v-model="description" ref="quillGroupEditorRef" class="quill-editor"
                  placeholder="Enter your description here..." toolbar="full" />
                <div class="form-check mt-2">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="public" value="public"
                    v-model="selectedVisibility">
                  <label class="form-check-label text-xs fw-500" for="flexRadioDefault1">
                    Public <br>
                    <span class="text-xxs text-secondary">Is the group public ?</span>
                  </label>
                </div>
                <div class="form-check mt-2">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="private" value="private"
                    v-model="selectedVisibility">
                  <label class="form-check-label text-xs fw-500" for="private">
                    Private <br>
                    <span class="text-xxs text-secondary">Is the group private ?</span>
                  </label>
                </div>
      
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="card-body table-responsive">
        <table class="table table-striped text-xs mt-3">
          <thead class="bg-dark text-white">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Group Name</th>
              <th scope="col">Created By</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(group, index) in groups" :key="group.id">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ group.name }}</td>
              <td>{{ group.created_by.first_name }} {{ group.created_by.last_name }}</td>
              <td>
                <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow">
                  <i class="bi bi-eye"></i> View
                </button>
                <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" data-bs-toggle="modal"
                  :data-bs-target="'#edit_group_' + group.id" @click="editGroupName(group)">
                  <i class="bi bi-pencil-square"></i> Edit
                </button>
                <button @click="deleteGroup(group.id)" class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow">
                  <i class="bi bi-trash"></i> Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal fade" id="create_group" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create a Group</p>
              <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="createGroup">
              <div class="modal-body">
      
                <div class="mb-3">
                  <label for="group_name" class="form-label text-xs fw-500">Group Name <span
                      class="text-danger">*</span></label>
                  <input type="text" class="form-control rounded-5 text-xs" id="group_name" placeholder="Enter group name"
                    v-model="group_name">
                    <p v-if="errors.group_name" class="text-danger text-xs">{{ errors.group_name }}</p>
                </div>
                <QuillEditor theme="snow" v-model="description" ref="quillGroupEditorRef" class="quill-editor"
                  placeholder="Enter your description here..." toolbar="full" @text-change="handleTextChange"/>
                <div class="form-check mt-2">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="public" value="public"
                    v-model="selectedVisibility">
                  <label class="form-check-label text-xs fw-500" for="flexRadioDefault1">
                    Public <br>
                    <span class="text-xxs text-secondary">Is the group public ?</span>
                  </label>
                </div>
                <div class="form-check mt-2">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="private" value="private"
                    v-model="selectedVisibility">
                  <label class="form-check-label text-xs fw-500" for="private">
                    Private <br>
                    <span class="text-xxs text-secondary">Is the group private ?</span>
                  </label>
                </div>
      
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
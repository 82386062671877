<Loader :loading="loading" />
<HomeNavBar />

<div class="p-2 scroll-container">
  <h2 class="fw-bold text-center text-uppercase mt-2">
   <span class="fw-bolder">Assets</span>
</h2>
  <div>
    <div class="event-group mx-5">
      <div class="d-flex align-items-center temporary-post" v-if="temporary_asset_post" id="temp-post">
        <p class="text-xs fw-bold"> Editing...</p>
        <div class="spinner-border ms-auto  text-secondary" role="status" aria-hidden="true"></div>
      </div>
      <div v-for="community_asset in community_assets" :key="community_asset.id" 
           class="card event-card lh-sm mt-2 border-0 border-bottom-primary position-relative">
      
        <!-- Arrow pointing to the icon -->
        <div class="event-arrow"></div>

        <div class="card-body">
          <div class="dropend float-end" @click.stop>
       <a href="" class="fw-bold text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown"
          aria-expanded="false">
         <i class="bi bi-three-dots-vertical"></i>
       </a>
       <ul class="dropdown-menu custom-dropdown lh-1" aria-labelledby="dropdownMenuButton">
         <li>
           <a class="dropdown-item text-xs fw-bold" href=""  @click.prevent="edit_asset(community_asset)">
             <i class='bx bxs-edit fs-6'></i> Edit
           </a>
         </li>
         <li>
           <a class="dropdown-item text-xs fw-bold" href="" @click.prevent="delete_asset(community_asset.id)">
             <i class='bx bxs-trash-alt fs-6'></i> Delete
           </a>
         </li>
       </ul>
     </div>
          <p class="card-title fw-bold">{{ community_asset.name }}</p>
          <p class="text-muted text-xs">
            <i class="bi bi-geo-alt text-danger"></i> {{ community_asset.location }}
          </p>
          <span href="#" @click.stop="select_asset(community_asset)" data-bs-toggle="modal" data-bs-target="#assetModal" class="badge bg-primary mt-0">
            See more
          </span>
        </div>
      </div>
    </div>
  </div>
</div>




<div class="modal fade" id="assetModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content rounded">
      <div class="modal-header">
        <h5 class="modal-title fw-bold">{{ selected_asset.name }}</h5>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body modal-body-scroll">
        <div>
          <p><strong>Description:</strong><span v-html="selected_asset.description"></span></p>
        </div>

        <div v-if="selected_asset.community_asset_files && selected_asset.community_asset_files.length" class="d-flex flex-column align-items-center position-relative">
            <!-- Image Container with position relative -->
            <img v-if="selected_asset.community_asset_files[0].file.file_url.includes('.jpg') || 
                       selected_asset.community_asset_files[0].file.file_url.includes('.png') || 
                       selected_asset.community_asset_files[0].file.file_url.includes('.jpeg')"
                 :src="selected_asset.community_asset_files[0].file.file_url"
                 alt="Community Asset Image"
                 class="img-fluid rounded-5 my-3 w-80"
                 :class="{ 'zoomed-in': isZoomed }"
                 ref="image" />

            <!-- Zoom Button -->
            <button @click="toggleZoom" class="zoom-btn btn btn-light position-absolute top-50 start-50 translate-middle m-2 bg-transparent rounded-5">
                <i :class="isZoomed ? 'bx bxs-zoom-out fs-5 text-white' : 'bx bxs-zoom-in fs-5 text-white'"></i>
            </button>

            <!-- Fullscreen Button -->
            <button @click="toggleFullscreen" class="fullscreen-btn btn btn-light position-absolute top-0 start-0 m-2">
                <i class="bi bi-arrows-fullscreen"></i>
            </button>
        </div>

        <p><strong>Location:</strong> {{ selected_asset.location }}</p>
      </div>

      <div class="modal-footer justify-content-center">
        <button class="btn btn-warning btn-lg text-white text-xs rounded-5 shadow-lg fw-500"
        v-if="!selected_asset.subscribed" :id="'subscribe-button-' + selected_asset.id" 
        @click="subscribeCommunityAsset(selected_asset)">Subscribe</button>
        <button class="btn btn-warning btn-lg text-white text-xs rounded-5 shadow-lg fw-500" 
        v-if="selected_asset.subscribed" :id="'subscribed-button-' + selected_asset.id" 
        @click="unsubscribeCommunityAsset(selected_asset)">Unsubscribe</button>
      </div>
    </div>
  </div>
</div>



<div class="offcanvas offcanvas-start" tabindex="-1" id="community_assets" aria-labelledby="community_assets_label">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="community_assets_label">Community Assets</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <table v-if="community_assets" class="table mb-0">
      <tbody>
        <tr v-for="community_asset in community_assets" :key="community_asset.id" class="hover-row border-0 ">
          <td class="border-0">
            <span class="text-decoration-none text-dark"  @click="display_community_asset(community_asset)">
              <p class="portal-icon mb-0 d-flex align-items-center">
                <a class="rounded-circle bg-yellow p-1 shadow-sm text-change"><i class='bx bx-command fs-5'></i></a>
                <span class="text-xs fw-500 mx-2">{{ community_asset.name }}</span>
              </p>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<!-- <FloatingButtonComponent  /> -->

<div class="modal fade" id="edit_asset"  tabindex="-1" aria-labelledby="editAssetModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="editAssetModalLabel">Edit Community Asset</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="updateCommunityAsset">
        <div class="modal-body">
          <div class="mb-3">
            <label for="edit_asset_name" class="form-label text-xs fw-500">Community Asset Name <span class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="edit_asset_name" v-model="edit_asset_name" required>
          </div>
          <div class="mb-3">
            <label for="edit_location" class="form-label text-xs fw-500">Community Asset Location <span class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="edit_location" v-model="edit_asset_location" required>
          </div>
          <div class="mb-3">
            <label for="edit_location" class="form-label text-xs fw-500">subscription_fee <span class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="edit_subscription_fee" v-model="edit_subscription_fee" required>
          </div>

          <div class="mb-3">
            <label for="category_id" class="form-label text-xs fw-500">Select a Category<span class="text-danger">*</span></label>
            <select class="form-select rounded-5 text-xs" id="category_id" v-model="edit_asset_category">
              <option selected hidden>Select a Category</option>
              <option v-for="category in community_asset_categories" :value="category.id" :key="category.id">{{ category.name }}</option>
            </select>
          </div>

          <div class="mb-3">
            <QuillEditor theme="snow" v-model="edit_description" ref="quillEventEditorRef" class="quill-editor"
            placeholder="Enter your description here..." toolbar="full" />
          </div>

          <div v-if="selected_asset.community_asset_files && selected_asset.community_asset_files.length" class="d-flex flex-column align-items-center position-relative">
            <!-- Image Container with position relative -->
            <img v-if="selected_asset.community_asset_files[0].file.file_url.includes('.jpg') || 
                       selected_asset.community_asset_files[0].file.file_url.includes('.png') || 
                       selected_asset.community_asset_files[0].file.file_url.includes('.jpeg')"
                 :src="selected_asset.community_asset_files[0].file.file_url"
                 alt="Community Asset Image"
                 class="img-fluid rounded-5 my-3 w-65"
                 :class="{ 'zoomed-in': isZoomed }"
                 ref="image" />
          </div>
          <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-center">
            <label @click="triggerFileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
              <i class='bx bx-image-add file-upload-icon'></i> Add Asset content
            </label>
            
            <input type="file" id="fileInput" ref="fileInput" class="d-none" @change="handleAssetFileUpload" />
            
            <div v-if="asset_file_name" class="text-xs text-secondary fst-italic mt-1">
              <span class="fw-500">File name:</span> {{ asset_file_name }}
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Update Asset</button>
        </div>
      </form>
    </div>
  </div>
</div>

import {get_token,get_user_details} from "@/router";
import Swal from 'sweetalert2';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import 'quill-mention';


const GOOGLE_MAP_API_KEY = process.env.VUE_APP_GOOGLE_MAP_API_KEY;
const domain = window.location.hostname;
export default {
    data() {
      return ({
        token_exists: false,
        user_details: {
          "user_role": '',
          "user_id": '',
        },
        temporaryPost: null,
        temporary_hub_post:null,
        isSubmitting: false,
        isDropdownVisible: false,
        fileName:'',
        imagePreview: null,
        topics:[],
        created_by_id:this.created_by,
        portals:[],
        isMaster: false,
        selectedPortals: [],
        selectedMasterPortal: '',
        categories: [],
        selectedPortalId: null,
        selectedTopicId: null,
        selectedVisibility: 'public',
        searchQuery:"",
        errors: {
          voice_description:null,
          voice_name:null,
          audio_file :null,
          portal: null,
          segment: null,
          report_description: null
        },
        users:[],
        selectedUserIds: [],
        portal_users:[],
        cbo_domain:false,

        isRecording: false,
        audio_file: null,
        mediaRecorder: null,
        recordedChunks: [],
        audio_file_name: '',
        upload_media: null,
        fileError : '',
        audioError: '',
        comments_off: true,
        showSuggestions: false,
        currentTagPosition: -1,
        portal_types:[],
        selectedPortalType:null,
        contactEmail: '',
        payload:{},
        latitude: null,
        longitude: null,
        report_title:null,
        location:null,
        incident_description:null,

        document_name: '',
        document_description: '',
        selected_reviewers: [],
        reviewers: [],
        event_types:[],
        event_type: '',
      });
    },
    props: {
      group_id: {
        type: String,
        required: false,
        default: null
      },
      created_by: {
        type: String,
        required: false,
        default: null
      },
      is_public: {
        type: Boolean,
        default: false,
      }
    },

    mounted() {
      this.token_exists = get_token();
      this.user_details=get_user_details();
      this.get_community_assets_category();
      this.loadGoogleMapEvent();
      this.fecthPortalTypes();
      this.fetchPortalMembers();
      this.fetch_users();
      this.$nextTick(() => {
        this.initQuillEditor();
      });
      if(this.$route.name === 'view_portal'){
        this.selectedPortalId = this.$route.params.portal_id
        this.fecthTopics(this.selectedPortalId);
      }
      if(this.$route.name === 'view_incidents'){
        this.payload={
          filter_values: {
            portal_type__name:'incident'
          }
        }
      }
      else{
        this.payload={}
      }

      if (domain.includes('cbo')) {
        this.cbo_domain = true;
      }

      this.fetchPortals();

      const feedModal = $('#feed_modal');
      const voiceModal = $('#voice_modal');
      const createCommunityAssetModal = $('#create_community_asset_modal');
      const groupVoiceModal = $('#group_voice_modal');
      const groupFeedModal = $('#group_feed_modal');

      feedModal.on('show.bs.modal', () => {
        this.resetPortalSelection();
      });

      voiceModal.on('show.bs.modal', () => {
        this.resetPortalSelection();
      });

      createCommunityAssetModal.on('show.bs.modal', () => {
        this.resetPortalSelection();
      });

      groupVoiceModal.on('show.bs.modal', () => {
        this.resetPortalSelection();
      });

      groupFeedModal.on('show.bs.modal', () => {
        this.resetPortalSelection();
      });
    },
    watch: {
      '$route.params.portal_id'() {
        this.fetch_users();
        this.fecthTopics(this.$route.params.portal_id);
      }
    },

    computed: {
      portal_id() {
        return this.$route.params.portal_id;
      },
      isPublic() {
        return this.selectedVisibility === 'public';
      },
      isHomePage() {
        return this.$route.name === 'home'; // Assuming your home page route is named 'home'
      },
      isPortalPage() {
        return this.$route.name === 'home_filter';
      },
      isViewPortalPage() {
        return this.$route.name === 'view_portal';
      },
      isGroupPage() {
        return this.$route.name === 'view_group';
      },
      isIncidentPage(){
        return this.$route.name === 'view_incidents'
      },
      isEventsPage(){
        return this.$route.name === 'view_events'
      },
      isCommunityAssetPage(){
        return this.$route.name === 'community_asset'
      },
      filteredUsers() {
        if (!this.searchQuery) {
          return this.users;
        }
        const query = this.searchQuery.toLowerCase();
        return this.users.filter(user =>
          user.first_name.toLowerCase().includes(query) ||
          user.last_name.toLowerCase().includes(query)
        );
      },
      areAllSelected() {
        const filteredIds = this.filteredUsers.map(user => user.id);
        return filteredIds.every(id => this.selectedUserIds.includes(id)) && filteredIds.length > 0;
      },
      filteredPortals() {
        if (!this.searchQuery) {
            return this.portals.filter(portal =>
                !portal.portal_type || portal.portal_type.name !== 'incident'
            );
        }
        const query = this.searchQuery.toLowerCase();
        return this.portals.filter(portal =>
            (!portal.portal_type || portal.portal_type.name !== 'incident') &&
            portal.name.toLowerCase().includes(query)
        );
    },
    areAllSubPortalsSelected() {
      const filteredIds = (this.filteredPortals || []).map(portal => portal.id);
      return filteredIds.length > 0 && filteredIds.every(id => this.selectedPortals.includes(id));
  },
    },

    methods: {
      resetPortalSelection() {
        this.selectedPortalId = '';
        this.selectedTopicId = '';
        this.errors.portal = '';
        this.errors.segment = '';
        this.errors.name = '';
        this.fileName = '';
        // this.errors.report_name = '';
        this. audio_file_name = '';
        this.fileError = '';
        this.report_description = '';
      },
      toggleDropdown() {
        this.isDropdownVisible = !this.isDropdownVisible;
      },
      handleCustomer(){
        const role =this.user_details.user_role
        if(role === 'customer'){
          return false
      }
      else {
        return true
      }
    },
    toggleSelectAll() {
      const filteredIds = this.filteredUsers.map(user => user.id);
      if (this.areAllSelected) {

        this.selectedUserIds = this.selectedUserIds.filter(id => !filteredIds.includes(id));
      } else {
        this.selectedUserIds = [...new Set([...this.selectedUserIds, ...filteredIds])];
      }
    },
    toggleSelectAllSubPortals() {
      const filteredIds = (this.filteredPortals || []).map(portal => portal.id);

      if (this.areAllSubPortalsSelected) {
          this.selectedPortals = this.selectedPortals.filter(id => !filteredIds.includes(id));
      } else {
          this.selectedPortals = [...new Set([...this.selectedPortals, ...filteredIds])];
      }
  },
    fecthPortalTypes() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_portal_types`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (response) => {
          var status = response.status
          if (status == "success") {
            this.portal_types=response.portal_types
          }
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },
    handleFileUpload(event) {
      const file = event.target.files[0];

      if (file) {
        if (file.type.startsWith("audio/")) {
          this.fileError = "Audio files are not allowed.";
          this.upload_media = null;
          this.fileName = "";
          this.imagePreview = null; // Reset preview if an invalid file is selected
          event.target.value = ""; // Clear file input
        } else {
          this.fileName = file.name;
          this.fileError = "";
          this.upload_media = file;

          // Check if the file is an image before previewing
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (e) => {
              this.imagePreview = e.target.result;
            };
            reader.readAsDataURL(file);
          } else {
            this.imagePreview = null; // Reset preview for non-image files
          }
        }
      } else {
        this.fileName = "";
        this.fileError = "";
        this.upload_media = null;
        this.imagePreview = null;
      }
    },
    handleApprovalFileUpload(event) {
      const file = event.target.files[0];

      if (file) {
        if (file.type.startsWith("audio/")) {
          this.fileError = "Audio files are not allowed.";
          this.upload_media = null;
          this.fileName = "";
          this.imagePreview = null; // Reset preview if an invalid file is selected
          event.target.value = ""; // Clear file input
        } else {
          this.fileName = file.name;
          this.fileError = "";
          this.upload_media = file;

          // Check if the file is an image before previewing
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (e) => {
              this.imagePreview = e.target.result;
            };
            reader.readAsDataURL(file);
          } else {
            this.imagePreview = null; // Reset preview for non-image files
          }
        }
      } else {
        this.fileName = "";
        this.fileError = "";
        this.upload_media = null;
        this.imagePreview = null;
      }
    },
      onPortalChange() {
        if (this.selectedPortalId) {
          this.fecthTopics(this.selectedPortalId);
        }
      },
      getToken() {
        return get_token();
      },
      startRecording() {
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then((stream) => {
            this.mediaRecorder = new MediaRecorder(stream);

            this.mediaRecorder.ondataavailable = (event) => {
              if (event.data.size > 0) {
                this.recordedChunks.push(event.data);
              }
            };

            this.mediaRecorder.onstop = () => {
              const audioBlob = new Blob(this.recordedChunks, { type: 'audio/wav' });
              this.audio_file = audioBlob;
              this.audio_file_name = 'recording.wav';
            };

            this.mediaRecorder.start();
            this.isRecording = true;
          })
          .catch((error) => {
            if (error.name === 'NotAllowedError') {
              Swal.fire({
                icon: 'error',
                title: 'Microphone Access Denied',
                text: 'Please allow microphone access in your browser settings and refresh the page.',
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Error accessing microphone: ${error.message}`
              });
            }
          });
      },

      stopRecording() {
        if (this.mediaRecorder && this.isRecording) {
          this.mediaRecorder.stop();
          this.isRecording = false;
        }
      },
      handleAudioUpload(event) {
        const file = event.target.files[0];
        if (file) {
          if (!file.type.startsWith('audio/')) {
            this.audioError = 'Only audio files are allowed.';
            this.audio_file = null;
            this.audio_file_name = '';
            event.target.value = '';
          } else {
            this.audio_file_name = file.name;
            this.audioError = '';
            this.audio_file = file;
          }
        } else {
          this.audio_file_name = '';
          this.audioError = '';
          this.audio_file = null;
        }
      },
      fetchPortals() {
        this.isLoading = true;
        $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_portals_api/`,
        method: 'POST',
        headers: {
            'Authorization': `Token ${this.getToken()}`,
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(this.payload),

        success: (data) => {
          if(this.user_details.user_role==="admin"){
              this.portals = data.portals;

            }else{
              const portal=data.portals
              this.portals=portal.filter(portal=>portal.has_access=="approved")

            }
        },
        complete: () => {
            this.isLoading = false;
        }
        });
    },
    fetch_users() {
      return new Promise((resolve) => {

        const groupId = this.$route.params.group_id;
      $.ajax({
        type: 'GET',
        data:{group_id:groupId},
        url: process.env.VUE_APP_BACKEND_API_URL + '/system_management/retrieve_users/',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
        this.users = response.data.users_list.filter(user => user.id != this.user_details.user_id);
          resolve();
        },
      });
    });
    },
    fecthTopics(portal_id) {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_topics_api`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: {portal_id: portal_id},
        success: (response) => {
          var status = response.status
          if (status == "success") {
            this.topics = response.topics
          }
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },
    validationFeedForm() {
      this.errors = {};
      let valid = true;

      if (this.$route.name !== 'view_portal' && !this.selectedPortalId) {
        this.errors.portal = "You must tag a portal.";
        valid = false;
      }

      if (!this.selectedTopicId) {
        this.errors.segment = "You must tag a segment.";
      }
      return valid;
    },
    submit_form() {
      if(!this.validationFeedForm()) {
        return;
      }  
      this.temporaryPost = {
        report_name: this.report_name,
        isTemp: true, 
      };
      this.$nextTick(() => {
        const tempPostElement = document.getElementById('temp-post');
        if (tempPostElement) {
          tempPostElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });

      this.isSubmitting = true;
      const formData = new FormData();
      if (this.report_url) {
        formData.append('report_link', this.report_url);
      }
      if(this.$route.name === 'view_portal'){
        this.selectedPortalId = this.$route.params.portal_id
        formData.append('portal_id', this.selectedPortalId);
      }
      const baseUrl = `${window.location.origin}${this.$route.path}`;
      const reportUrl = `${baseUrl}?report_id=dynamic_placeholder`;
      formData.append('base_url', reportUrl);

      formData.append('portal_id', this.selectedPortalId);
      formData.append('topic_id', this.selectedTopicId);
      // this.report_description = this.$refs.quillReportEditorRef.getHTML()
      const quill = this.$refs.quillReportEditorRef.quill;
      if (!quill) {
        return;
      }

      // Get the HTML content of the editor
      let content = quill.root.innerHTML;

      content = content.replace(
        /<span class="mention" data-value="([^"]*)"[^>]*>@[^<]*<\/span>/g,
        '@$1'
      );
      
      if (!this.report_url && !this.upload_media && !this.audio_file && content.trim() === "<p><br></p>") {

        this.errors.report_details = "Please fill in at least one of the required fields";
        $('#feed_modal').modal('show');
        this.$emit('remove_temp_post');

        return; 
      }
    this.$emit('new_temp_post', this.temporaryPost);

      
  formData.append('description', content);
      formData.append('is_public', this.isPublic);
      formData.append('can_comment', this.comments_off);
      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }
      if (this.audio_file) {
        formData.append('file', this.audio_file,this.audio_file_name);
      }
      $('#feed_modal').modal('hide');

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_report_api/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {     
          const status = response.status;
          if (status === "success") {
              this.$emit('new_report', response.report_file_data);
              this.$nextTick(() => {
                const newPostElement = document.getElementById(`post-${response.report_file_data.id}`);
                if (newPostElement) {
                  newPostElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
              });
              this.temporaryPost = null; 
              this.report_description = '';
              quill.root.innerHTML = '';
              this.isDropdownVisible = false;
              $('#feed_modal').modal('hide');
          }
      
        },
        error: (xhr) => {
            let errorResponse = JSON.parse(xhr.responseText);
            Swal.fire({
              icon: 'error',
              title: 'Bad Request!',
              text: errorResponse.message || 'There was an issue with your form submission. Please try again later.',
              confirmButtonText: 'ok',
            });
        },
        complete: () => {
          this.isSubmitting = false;
        }
      })
    },
    create_group_feed() {
      const formData = new FormData();
      formData.append('feed_name', this.group_report_name);
      this.feed_report_description = this.$refs.quillFeedReportEditorRef.getHTML()
      formData.append('description', this.feed_report_description);
      formData.append('group_id',this.group_id)
      formData.append('can_comment', this.comments_off);
      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }
      if (!this.group_report_name) {
        this.errors.report_name = 'Feed name is required';
        return;
      }
      if (this.audio_file) {
        formData.append('file', this.audio_file,this.audio_file_name);
      }
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/create_group_feed/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        beforeSend: function () {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading()
            }
          });
        },
        success: (response) => {
          Swal.close();
          const status = response.status;
          this.$emit('new_feed',response.feed_data)


          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Feed Created',
              showConfirmButton:false,
              timer:2000,

            }).then(() => {

              $('#group_feed_modal').modal('hide');
              this.group_report_name = '';
              this.isDropdownVisible = false;
            });

          }
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;
          if (response_data.status==="error") {
            this.errors.report_name=response_data.message
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      })
    },

    create_group_voice() {
      const formData = new FormData();
      formData.append('voice_name', this.group_voice_name);
      this.group_voice_description = this.$refs.quillVoiceDescriptionRef.getHTML();
      formData.append('description', this.group_voice_description);
      formData.append('group_id', this.group_id);
      formData.append('can_comment', this.comments_off);
      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }
      if (this.audio_file) {
        formData.append('file', this.audio_file, this.audio_file_name);
      }

      if (!this.group_voice_name) {
        this.errors.voice_name = 'voice name is required';
        return;
      }

      if (this.errors.audio_file ||this.errors.upload_media) {
        return;
      }

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/create_group_voice/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        beforeSend: function() {
          Swal.fire({
            text: 'Creating Group Voice...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {

          const status = response.status;
          this.$emit('new_voice',response.voice_data)


          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Group Voice Created',
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              $('#group_voice_modal').modal('hide');
              Swal.close();
              this.group_voice_name = '';
              this.isDropdownVisible = false;
            });
          }
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;
          if (response_data.status === "error") {
            this.errors.voice_name = response_data.message;
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },

    validationHubForm() {
      this.errors = {};
      let valid = true;

      if (!this.name) {
        this.errors.name = 'Your voice name is required';
        valid = false;
      }

      if (!this.selectedPortalId) {
        this.errors.portal = "You must tag a portal.";
      }

      if (!this.selectedTopicId) {
        this.errors.segment = "You must tag a segment.";
      }
      return valid;
    },
    createHub() {
      if(!this.validationHubForm()) {
        return;
      }
      this.temporary_hub_post = {
        report_name: this.name,
        isTemp: true,
      };

      this.$emit('new_temphub_post', this.temporary_hub_post);
      $('#voice_modal').modal('hide');

      this.$nextTick(() => {
        const tempPostElement = document.getElementById('temphub-post');
        if (tempPostElement) {
          tempPostElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('topic', this.selectedTopicId);
      formData.append('creater', this.selectedTopicId);
      formData.append('can_comment', this.comments_off);
      formData.append('portal', this.selectedPortalId);
      this.description = this.$refs.quillHubRef.getHTML()

      formData.append('description', this.description);
      if (this.upload_media) {
        formData.append('document', this.upload_media);
      }
      if (this.audio_file) {
        formData.append('document', this.audio_file,this.audio_file_name);
      }

      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_exchange_hub_api/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {

          this.$emit('new_hub',response.exchange_hub)

          this.$nextTick(() => {
            const newPostElement = document.getElementById(`hub-${response.exchange_hub.id}`);
            if (newPostElement) {
              newPostElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          });

          this.temporary_hub_post = null;
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;
          if (response_data.status==="error") {
            const message=response_data.message
            if(message.includes('id')){
              this.errors.segment="You must tag a segment."
            }
            else{
              this.errors.name=response_data.message
            }

          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    validationPortalForm() {
      this.errors = {};
      let valid = true;

      if (!this.portal_name) {
        this.errors.portal_name = 'Portal name is required';
        valid = false;
      }

      return valid;
    },
    addPortalMembers() {
      if (this.selectedUserIds.length === 0) {
        this.errors.user_ids = "Please select at least one member.";
        return;
      }
      $('#portal_member_modal').modal('hide');
    },
    confirmSelectedPortals() {
      $('#select_portals_modal').modal('hide');
    },
    createPortal() {

      if(!this.validationPortalForm()) {
        return;
      }

      this.isSubmitting = true;
      const formData = new FormData();
      formData.append('portal_name', this.portal_name);
      formData.append('portal_type',this.selectedPortalType.id)
      formData.append('is_master',this.isMaster)
      formData.append('master_portal_id',this.selectedMasterPortal)
      formData.append('contact_email',this.contactEmail)
      formData.append('is_public', this.isPublic);
      this.selectedPortals.forEach(portal_id => {
        formData.append('sub_portals', portal_id);});
      this.selectedUserIds.forEach(user_id => {
        formData.append('user_id', user_id);
    });

      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_portal_api/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          this.$emit('addPortal',response.message)
          $('#portal_modal').modal('hide');
          this.isDropdownVisible = false;
          const portalId = response.message.id;
          const portalName = response.message.name;
          const is_public=response.message.is_public
          this.$router.push(`/view_portal/${portalId}/${portalName}/${is_public}`);
        },
        error: (response) => {
          const response_error=response.responseJSON
          Swal.close();
          if(response_error.status==="error"){
            this.errors.portal_name=response_error.message
          }
        },
        complete: () => {
          this.isSubmitting = false;
          this.selectedUserIds=[]
          this.portal_name=''
          this.selectedMasterPortal=null
          this.isMaster=null
          this.selectedPortalType=null
          this.isPublic=true
        }
      });
    },
    validationGroupForm() {
      this.errors = {};
      let valid = true;

      if (!this.group_name) {
        this.errors.group_name = 'Group name is required';
        valid = false;
      }

      return valid;
    },
    createGroup() {
      if(!this.validationGroupForm()) {
        return;
      }

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append('name', this.group_name);
      this.description = this.$refs.quillGroupEditorRef.getHTML()
      formData.append('description', this.description);
      formData.append('is_public', this.isPublic);

      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/create_group/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          this.$emit('addGroup',response.group)
          $('#group_modal').modal('hide')
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;

          if (response_data.status==="error") {

            this.errors.group_name=response_data.message
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    validationCommunityForm() {
      this.errors = {};
      let valid = true;

      if (!this.asset_name) {
        this.errors.asset_name = 'Asset name is required';
        valid = false;
      }

      if (!this.location) {
        this.errors.location = 'Location is required';
        valid = false;
      }

      if (!this.category_id) {
        this.errors.category_id = 'Category selection is required';
        valid = false;
      }
      return valid;
    },
    createcommunity_asset() {
      if(!this.validationCommunityForm()) {
        return;
      }
      $('#create_community_asset_modal').modal('hide');

      this.isSubmitting = true;
      const formData = new FormData();
      formData.append('name', this.asset_name);
      this.description = this.$refs.quillAssetEditorRef.getHTML()
      formData.append('description', this.description);
      formData.append('location', this.location);
      formData.append('category_id', this.category_id);
      formData.append('subscription_fee', this.subscription_fee);
      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_community_asset/`,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: formData,
        processData: false,
        contentType: false,

        success: (response) => {
          this.$emit('newAsset',response.community_asset_data
            )
          const status = response.status;
          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Community asset Created Successfully',
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              $('#create_community_asset_modal').modal('hide');
              this.isDropdownVisible = false;
            });
          }
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;
          if (response_data.status === "error") {
            this.errors.asset_name  = response_data.message

          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    get_community_assets_category() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_all_community_assets_category/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (response) => {
          this.categories = response.data.categories;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },
    validationSegmentForm() {
      this.errors = {};
      let valid = true;

      if (!this.topic_name) {
        this.errors.topic_name = 'Segment name is required';
        valid = false;
      }

      if (!this.selectedPortalId) {
        this.errors.portal = "You must tag a portal.";
      }

      return valid;
    },
    add_group_member() {
      if (this.selectedUserIds.length === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'Please select at least one user.',
          showConfirmButton: true
        });
        return;
      }
      const data ={
        group_id :this.group_id,
        user_id :this.selectedUserIds
      }
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_group_member/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(data),
        success: (response) => {
          const status = response.status;
          if (status === "success") {

            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Group member(s) added',
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              window.location.reload();
            });
          }
        },
        error: () => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text:"User already Exist" ,
            showConfirmButton: false,
            timer: 2000
          })

        }
      });
  },
    createSegment() {
      if(!this.validationSegmentForm()) {
        return;
      }
       $('#segment_modal').modal('hide')

      this.isSubmitting = true;

      const requestData = {
          topic_name: this.topic_name,
          portal_id: this.$route.params.portal_id
        };

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_topic_api/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(requestData),
        success: (response) => {
          this.$emit('newSegment',response.topic)

          const status = response.status;
          if (status === "success") {

            this.topics.push(response.topic);

            this.topic_name = '';
            this.errors.topic_name = '';
          }
        },
        error: (response) => {
          const response_data = response.responseJSON;
          if(response_data.status=="error"){
           this.errors.topic_name=response_data.message
          }
      },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    initQuillEditor() {
      const quill = new Quill('#quillEditor', {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link', 'video', 'image'],
            ['clean']
          ],
          mention: {
            allowedChars: /^[A-Za-z0-9_]+$/,
            mentionDenotationChars: ['@'],
            source: (searchTerm, renderList) => {
              const matches = this.users
                .filter(user => {
                  const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
                  return fullName.includes(searchTerm.toLowerCase());
                })
                .map(user => ({
                  id: user.id,
                  value: `${user.first_name} ${user.last_name}`
                }));
              renderList(matches);
            },
            renderItem(item) {
              return `${item.value}`;
            }
          }
        },
        placeholder: 'Type your text here...',
      });

      this.$refs.quillReportEditorRef.quill = quill;

      quill.on('text-change', () => {
        const text = quill.root.innerText;
        const lastAtSymbol = text.lastIndexOf('@');

        if (lastAtSymbol !== -1) {
          this.currentTagPosition = lastAtSymbol;
          this.showSuggestions = true;
        } else {
          this.showSuggestions = false;
        }
      });

      quill.on('mention-selected', (user) => {
        this.selectUser(user);
      });
    },

    filterUsers(query) {
      if (!query) {
        this.filteredUsers = this.users;
        return;
      }
      this.filteredUsers = this.users.filter(user => {
        const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
        return fullName.includes(query.toLowerCase());
      });
    },

    selectUser(user) {
      const quill = this.$refs.quillReportEditorRef.quill;
      const beforeTag = this.description ? this.description.slice(0, this.currentTagPosition) : '';
      this.description = `${beforeTag}@${user.first_name}_${user.last_name} `;

      quill.root.textContent  = `${beforeTag}@${user.first_name}_${user.last_name}`;
      this.$nextTick(() => {
        this.showSuggestions = false;
      });
    },

    loadGoogleMapEvent() {
      if (!isNaN(this.latitude) && !isNaN(this.longitude)) {
        if (typeof window.google === 'undefined' || !window.google.maps) {

          const script = document.createElement('script');
          script.id = 'googleMapsScript';
          script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places&callback=initMap&v=weekly`;
          script.async = true;
          script.defer = true;
          document.head.appendChild(script);

          window.initMap = () => {
            this.initializeMap();
          };
        }
      }
    },
    initializeMap() {
      const defaultLat = parseFloat(this.latitude || 0);
      const defaultLng = parseFloat(this.longitude || 0);

      const mapOptions = {
        center: { lat: defaultLat, lng: defaultLng },
        zoom: 8,
        mapTypeId: 'roadmap',
      };

      const mapElement = document.querySelector('#mapSearchBox');
      if (mapElement) {
        const map = new window.google.maps.Map(mapElement, mapOptions);

        let marker = null;

        const input = document.getElementById('mapSearchBox');
        const searchBox = new window.google.maps.places.SearchBox(input);

        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

        map.addListener('bounds_changed', () => {
          searchBox.setBounds(map.getBounds());
        });

        searchBox.addListener('places_changed', () => {
          const places = searchBox.getPlaces();

          if (!places || places.length === 0) {
            return;
          }

          const place = places[0];
          if (!place.geometry) {
            return;
          }

          // Update map center and zoom
          map.setCenter(place.geometry.location);
          map.setZoom(14);

          // Remove the existing marker, if any
          if (marker) {
            marker.setMap(null);
          }

          marker = new window.google.maps.Marker({
            position: place.geometry.location,
            map: map,
            title: place.name,
          });
          this.location = input.value;
        });
      }
    },
    clearErrors(){
      this.errors.incident_title = '';
      this.errors.incident_description = '';
      this.errors.location = '';
  },

  createIncidentReport(){
        this.clearErrors()

    const mapInput = document.getElementById('mapSearchBox');
    const baseUrl = `${window.location.origin}/view_incident_reports?incident_report_id=`;
    if (mapInput) {
        this.location = mapInput.value;
    }
        const formData = new FormData();
        formData.append('report_title', this.report_title);

        const rawDescription = this.$refs.quillReportEditorRef.getHTML();
        const plainDescription = rawDescription.replace(/<\/?[^>]+(>|$)/g, '');
        this.incident_description = plainDescription.trim();

        formData.append('description', this.incident_description);
        formData.append('portal_id', this.selectedPortalId);
        formData.append('location',this.location);
        formData.append('base_url',baseUrl)
        if (this.upload_media) {
          formData.append('files', this.upload_media);
        }

        if (!this.report_title) {
          this.errors.incident_title = 'Incident title is required';
          return;
        }

        if (!this.incident_description || this.incident_description.trim() === '<p><br></p>') {
            this.errors.incident_description = 'Incident description is required.';
            return;
        }
        if (!this.location || this.location.trim() === '') {
            this.errors.location = 'Incident location is required.';
            return;
        }

        $.ajax({
          type: "POST",
          url: `${process.env.VUE_APP_BACKEND_API_URL}/report_incident_management/create_incident_report/`,
          data: formData,
          processData: false,
          contentType: false,
          headers: {
            'Authorization': `Token ${this.getToken()}`,
          },
          beforeSend: function () {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading()
              }
            });
          },
          success: (response) => {

            Swal.close();
            const status = response.status;

            if (status === "success") {
              Swal.fire({
                icon: 'success',
                title: 'Incident Report Created',
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.$emit('newIncidentReport',response.report_data)
              });
            }
          },
          error: (response) => {
            Swal.close();
            const response_data = response.responseJSON;
            if (response_data.status === "error") {
              this.errors.incident_title = response_data.message;
            }
          },
          complete: () => {
            this.isSubmitting = false;
          }
        });
      },



      createDocumentApproval() {
        if (!this.validateDocumentForm()) {
          return;
        }

        this.temporaryPost = {
          document_name: this.document_name,
          isTemp: true,
        };
        this.$emit('new_temp_post', this.temporaryPost);
        $('#document_approval_modal').modal('hide');

        this.$nextTick(() => {
          const tempPostElement = document.getElementById('temp-post');
          if (tempPostElement) {
            tempPostElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });


        this.isSubmitting = true;
        const formData = new FormData();
        formData.append("document_name", this.document_name);
        this.document_description = this.$refs.quillDocumentEditorRef.getHTML()
        formData.append("description", this.document_description);
        formData.append("portal_id", this.portal_id)


        formData.append("reviewer", this.selected_reviewers);

        const baseUrl = `${window.location.origin}${this.$route.path}`;
        const documentUrl = `${baseUrl}?portal_id=${this.portal_id}`;
        formData.append('base_url', documentUrl);
        if (this.upload_media) {
          formData.append('file', this.upload_media);
        }

        $.ajax({
          type: "POST",
          url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/request_document_approval/`,
          data: formData,
          processData: false,
          contentType: false,
          headers: {
            Authorization: `Token ${this.getToken()}`,
          },
          success: (response) => {

            if (response.status === "success") {
              this.$emit("new_document", response.data);

              this.$nextTick(() => {
                const newPostElement = document.getElementById(`post-${response.data.id}`);
                if (newPostElement) {
                  newPostElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
              });

              this.temporaryPost = null;
            }
          },
          error: (response) => {
            Swal.close();
            const response_data = response.responseJSON;
            if (response_data.status === "error") {
              this.errors.document_name = response_data.message;
            }
          },
          complete: () => {
            this.isSubmitting = false;
          },
        });
      },

      resetForm() {
        this.document_name = "";
        this.document_description = "";
        this.selected_reviewers = [];
        this.uploaded_files = [];
        this.fileName = "";

        // Reset file input
        this.$nextTick(() => {
          document.getElementById("fileInput").value = "";
        });
      },

      validateDocumentForm() {
        this.errors = {};

        if (!this.document_name.trim()) {
          this.errors.document_name = "Document name is required.";
        }

        if (this.selected_reviewers.length === 0) {
          this.errors.selected_reviewers = "At least one reviewer is required.";
        }

        return Object.keys(this.errors).length === 0;
      },

      fetchPortalMembers() {
            this.isLoading = true;
            $.ajax({
              url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_portal_members/`,
              method: "GET",
              data: { portal_id: this.$route.params.portal_id },
              headers: {
                "Authorization": `Token ${this.getToken()}`,
                "Content-Type": "application/json"
              },
              success: (response) => {

                if (response.status === "success") {
                  this.reviewers = response.data.members;
                }
              },
              error: (xhr) => {
                if (xhr.status === 403) {
                  this.$router.push("/");
                  Swal.fire({
                    icon: "error",
                    title: "Access Denied",
                    text: "You are not authorized to be in this portal.",
                    showConfirmButton: false,
                    timer: 3000
                  });
                }
              },
              complete: () => {
                this.isLoading = false;
              }
            });
          },
  },
};







<section class="image-background">
  <div class="form-bg">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8 col-sm-6">
              <div class="form-container rounded-5 shadow p-4 p-md-5">
                <img :src="$clientLogo" class="img-fluid mx-auto d-block w-50 w-md-150" alt="">
                <h3 class="title text-center">Sign-up</h3>

                <form>
                  <div class="row mb-2">
                    <div class="col-12 col-md-6">
                      <label for="first_name" class="form-label text-sm fw-500">First Name<span class="text-danger">*</span></label>
                      <input type="text" class="form-control rounded-5 text-sm p-2" placeholder="Enter your first name" id="first_name" v-model="first_name" required>
                      <span v-if="errors.first_name" class="text-danger text-xs">{{ errors.first_name }}</span>
                    </div>
                    <div class="col-12 col-md-6 mt-2 mt-md-0">
                      <label for="last_name" class="form-label text-sm fw-500">Last Name<span class="text-danger">*</span></label>
                      <input type="text" class="form-control rounded-5 text-sm p-2" id="last_name" placeholder="Enter last name" v-model="last_name" required>
                      <span v-if="errors.last_name" class="text-danger text-xs">{{ errors.last_name }}</span>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="email" class="form-label text-sm fw-500">Email<span class="text-danger">*</span></label>
                    <input type="email" class="form-control rounded-5 text-sm p-2" placeholder="example@gmail.com" id="email" v-model="email" required>
                    <span v-if="errors.email" class="text-danger text-xs">{{ errors.email }}</span>
                  </div>

                  <div class="mb-3">
                    <label for="phone_number" class="form-label text-sm fw-500">Phone number<span class="text-danger">*</span></label>
                    <input type="text" class="form-control rounded-5 text-sm p-2" id="phone_number" placeholder="Enter phone number" v-model="phone_number" required>
                    <span v-if="errors.phone_number" class="text-danger text-xs">{{ errors.phone_number }}</span>
                  </div>

                  <div class="row mb-2">
                  <div class="col-12 col-md-6">
                    <label for="password" class="form-label text-sm fw-500">Password<span class="text-danger">*</span></label>
                    <input type="password" class="form-control rounded-5 text-sm p-2" id="password" placeholder="Enter password" v-model="password" required>
                    <span v-if="errors.password" class="text-danger text-xs">{{ errors.password }}</span>
                  </div>

                  <div class="col-12 col-md-6 mt-2 mt-md-0">
                    <label for="confirm_password" class="form-label text-sm fw-500">Confirm password<span class="text-danger">*</span></label>
                    <input type="password" class="form-control rounded-5 text-sm p-2" id="confirm_password" placeholder="Confirm password" v-model="confirm_password" required>
                    <span v-if="errors.confirm_password" class="text-danger text-xs">{{ errors.confirm_password }}</span>
                  </div>
                </div>

                  <button type="button" class="btn btn-primary rounded-5 shadow-sm w-100 mt-3" @click="register_user">Sign up</button>
                </form>
              </div>

            </div>
        </div>
    </div>
</div>
</section>

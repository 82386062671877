<div>
    <Sidebar ref="sidebar"></Sidebar>
    <section class="home-section">
      <Navbar @toggle-sidebar="toggleSidebar"></Navbar>
  
      <div class="card bg-white shadow-sm mt-5 rounded-5 border-0">
        <div class="card-header bg-white border-0 rounded-5">
          <ul class="pills-billing-tab nav nav-pills mb-3" id="pills-billib-tab" role="tablist">
            <li class="nav-item text-sm" role="presentation">
              <button class="nav-link active" id="top_loans_tab" data-bs-toggle="pill" data-bs-target="#top_loans_info" type="button" role="tab" aria-controls="top_loans_info" aria-selected="true">Segments</button>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="top_loans_info" role="tabpanel" aria-labelledby="top_loans_tab">
              <button class="btn btn-primary text-xs rounded-5 shadow mb-3" data-bs-toggle="modal" data-bs-target="#segment_modal">Create Segment</button>
              <div class="table-responsive">
                <table class="table table-striped text-xs">
                  <thead class="bg-dark text-white">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Title</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(topic, index) in topics" :key="index">
                      <th scope="row">{{ index + 1 }}</th>
                      <td>{{ topic.name }}</td>
                      <td>
                            <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow"><i class="bi bi-eye"></i>
                              View</button>
                        <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow-sm" @click="editTopic(topic.id)"><i class="bi bi-pencil"></i> Edit</button>
                        <button class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow-sm" @click="deleteTopic(topic.id)"><i class="bi bi-trash"></i> Delete</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tab-pane fade" id="consolidation_loan_info" role="tabpanel" aria-labelledby="consolidation_loan_tab">
              <button class="btn btn-primary text-xs rounded-5 shadow mb-3" data-bs-toggle="modal" data-bs-target="#create_hub">Create Your Voice</button>
        <div class="table-responsive">  
                <table class="table table-striped text-xs">
                  <thead class="bg-dark text-white">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Your Voice</th>
                      <th scope="col">Date Created</th>
                      <th scope="col">Created By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(exchange_hub, index) in exchange_hubs" :key="exchange_hub.id">
                      <th scope="row">{{ index + 1 }}</th>
                      <td>{{ exchange_hub.name }}</td>
                      <td>{{ exchange_hub.publication_date }}</td>
                      <td>{{ exchange_hub.creater.first_name }} {{ exchange_hub.creater.last_name }}</td>
                      <td>
        
                        <button @click="delete_exchange_hub(exchange_hub.id)" class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow"> <i class="bi bi-trash"></i> Delete</button>
                      </td>
                    </tr>
                  </tbody>
                  
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="segment_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create a Segment</p>
                  <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="createSegment">
                  <div class="modal-body">

                    <div class="mb-3">
                      <label for="topic_name" class="form-label text-xs fw-500">Segment Name <span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control rounded-5 text-xs" id="topic_name" placeholder="Enter topic name"
                        v-model="topic_name">
                        <p v-if="errors.topic_name" class="text-danger text-xs">{{ errors.topic_name }}</p>
                    </div>

                  </div>
                  <div class="modal-footer">
                    <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

      </div>
    </section>
  </div>
import { get_token } from "@/router";
import Swal from 'sweetalert2';
const GLOBAL_URL = process.env.VUE_APP_BACKEND_API_URL;

export default {

    name: "GroupVoiceCommentModal",

    props: {
        title: {
            type: String,
            default: "Modal Title",
        },
        voice_data: {
            type: Object,
        },
    },

    data() {
        var data = {
            comments_data: {},
            user_id:null,
            isZoomed: false,
            isFullscreen: false,
            comment_count: 0,
            editing_comment_text: "",
            editing_comment_id: null,
            expandedVoiceComments: [],
        };

        return data;
    },

    mounted() {
        this.user_id = this.get_user_id();
        this.show_modal();
        this.get_comments();
    },
    methods: {
        toggleZoom() {
            this.isZoomed = !this.isZoomed;
          },
          toggleFullscreen() {
            const image = this.$refs.image[0];
            // Check if fullscreen is already active
            if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
              // Request fullscreen for the image element (using the correct method for various browsers)
              if (image.requestFullscreen) {
                image.requestFullscreen();
              } else if (image.mozRequestFullScreen) { /* Firefox */
                image.mozRequestFullScreen();
              } else if (image.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                image.webkitRequestFullscreen();
              } else if (image.msRequestFullscreen) { /* IE/Edge */
                image.msRequestFullscreen();
              }
            } else {
              // Exit fullscreen
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
              } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
              }
            }
          },

        get_user_id() {
            const user_id = localStorage.getItem('user_id') || '';
            return user_id;
        },
        getToken() {
            return get_token();
        },
        show_modal() {
            $("#comment_modal").modal("show");
        },

        hide_modal() {
            $("#comment_modal").modal("hide");
            this.$emit('hide_voice_comment_comment')

            const baseUrl = ``;
            this.$router.replace({ path: baseUrl });
        },

        submitComment() {

            const commentText = $("#comment_text_area").val().trim();

            if (commentText === "") {
                Swal.fire({
                    icon: 'error',
                    title: 'Please write a comment before submitting.',
                    timer: 2000,
                    showConfirmButton: false,
                });
                return;
            }

            const requestData = {
                group_voice_id: this.voice_data.id,
                comment_text: commentText,
            };

            $.ajax({
                method: "POST",
                url: `${GLOBAL_URL}/group_management/add_group_voice_comment/`,
                contentType: "application/json",
                headers: {
                    'Authorization': `Token ${this.getToken()}`,
                },
                data: JSON.stringify(requestData),
                success: (response) => {
                  if (response.status === "success") {
                    const response_data = response.data;
                    this.comments_data.push(response_data.comment_data);
                    this.comment_count +=1;

                    $("#comment_text_area").val("");
                    return;
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Could not add comment',
                            text: 'Please try again later.',
                            timer: 3000,
                            showConfirmButton: false,
                        });
                    }
                },
            });
        },
        get_comments() {
          var request_data = {
              "group_voice_id": this.voice_data.id
          }

          $.ajax({
              type: "GET",
              url: `${GLOBAL_URL}/group_management/retrieve_group_voice_comment/`,
              contentType: "application/json",
              headers: {
                  'Authorization': `Token ${this.getToken()}`,
              },
              data: request_data,
              success: (response) => {
                if (response.status === "success") {
                    const response_data = response.data;
                    this.comments_data = response_data;
                    this.comment_count = this.comments_data.length;
                  }

              },
              complete: () => {
                  this.isLoading = false;
              }
          });
      },
      edit_comment(comment_data) {
        this.editing_comment_id = comment_data.id;
        this.editing_comment_text = comment_data.comment_text;
        
    },

        save_edit_comment(comment_id) {
            const data = {
                comment_id: comment_id,
                comment_text: this.editing_comment_text
            };
            $.ajax({
                type: 'POST',
                url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/update_group_voice_comment/`,
                headers: {
                    'Authorization': `Token ${this.getToken()}`,},
                contentType: 'application/json',
                data: JSON.stringify(data),
                success: (response) => {
                    if (response.status === "success") {
                        const comment = this.comments_data.find(comment => comment.id === comment_id);
                        comment.comment_text = this.editing_comment_text;
                        this.editing_comment_id = null;
                        this.editing_comment_text = "";
                    } 
                },
            });
        },
        delete_comment(comment_id) {
            Swal.fire({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes,delete it'
            }).then((result) => {
                if (result.isConfirmed) {
                    $.ajax({
                        type: "DELETE",
                        url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/delete_group_voice_comment/`,
                        data: JSON.stringify({ comment_id: comment_id }),
                        contentType: 'application/json',
                        headers: {
                            'Authorization': `Token ${this.getToken()}`,},
                        success: (response) => {
                            if (response.status === "success") {
                                Swal.fire({
                                    title: 'Deleted!',
                                    text: 'The comment has been deleted.',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1000
                                })
                                .then(() => {
                                    this.get_comments();
                                    this.comment_count -= 1;

                                });
                            }
                        },
                    });
                }
            });
        },
        readMore(commentsId) {
            const index = this.expandedVoiceComments.indexOf(commentsId);
            if (index === -1) {
                this.expandedVoiceComments.push(commentsId);
            } else {
                this.expandedVoiceComments.splice(index, 1);
            }
            },
        toggler_comment_input(comment_id) {
            $(`#reply_toggler_section_${comment_id}`).addClass("d-none");
            $(`#reply_container_${comment_id}`).removeClass("d-none");
        },

        submit_reply(comment_id) {

            var reply_text_input = $(`#new_reply_text${comment_id}`);
            var reply_text = reply_text_input.val();

            if (reply_text.trim() === "") {
                Swal.fire({
                    icon: "error",
                    title: "Can't submit an empty reply.",
                    timer: 2000,
                    showConfirmButton: false,
                });
                return;
            }

            var request_data = {
                "reply_text": reply_text,
                "comment_id": comment_id,
            }

            $.ajax({
                url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/add_voice_comment_reply/`,
                method: 'POST',
                contentType: "application/json",
                headers:{
                    'Authorization': `Token ${this.getToken()}`,
                },
                data: JSON.stringify(request_data),
                success: (response) => {

                    if (response.status === "success") {

                        var response_data = response.data;
                        var comment_data = response_data.comment_data;
                        const comment = this.comments_data.find(comment => comment.id === comment_id);

                        if (comment) {
                            if (!comment.replies) {
                                comment.replies = [];
                            }
                        comment.replies.push(response_data.comment_data);
                        comment.total_replies ++;
                        }
                        const reply_time = comment_data.time;
                        const reply_text = comment_data.comment_reply;
                        const reply_name = comment_data.user.first_name;

                        var reply_template = $("#reply_template").clone()

                        reply_template.find(".comment_text").text(reply_text);
                        reply_template.find(".reply_time").text(reply_time);
                        reply_template.find(".reply_Name").text(reply_name);

                        var reply_section = $(`#reply_section_${comment_id}`);

                        reply_section.append(reply_template);
                        reply_section.removeClass("d-none");
                        reply_template.removeClass("d-none");

                        reply_text_input.val("");

                    }

                },
                complete: () => {
                    this.isLoading = false;
                }
            });
        },
        get_comment_replies(comment_id) {
            const request_data = {
                "comment_id": comment_id,
            };
            $.ajax({
                url: `${GLOBAL_URL}/group_management/retrieve_group_voice_comment_reply/`,
                method: 'GET',
                headers: {"Authorization":`Token ${this.getToken()}`},
                data: request_data,
                success: (response) => {
                    if (response.status === "success") {
                        var response_data = response.data;
                        var comment_replies = response_data.replies_data;
        
                        const reply_container = $(`#reply_section_${comment_id}`);
                        reply_container.empty();
        
                        const repliesToShow = 2;
                        comment_replies.forEach((comment_reply, index) => {
                            var reply_template = $("#reply_template").clone();
                            reply_template.attr("id", `reply_${comment_reply.id}`);
        
                            // Fill the reply content
                            const reply_time = comment_reply.time;
                            const reply_text = comment_reply.comment_reply;
                            const reply_name = comment_reply.user.first_name;
                            const reply_last_name =  comment_reply.user.last_name;
                            const reply_user_id = comment_reply.user.id;
                            const logged_in_user_id = this.user_id;
        
                            const textLength = reply_text.length;
                            const maxLength = 25;
                            
                            reply_template.find(".reply_last_name").text(reply_last_name);
                            reply_template.find(".reply_time").text(reply_time);
                            reply_template.find(".reply_Name").text(reply_name);
        
                            // Add "Read More" and "Read Less" functionality for individual replies
                            if (textLength > maxLength) {
                                const readMoreTemplate = $("#read_more_template").clone();
                                readMoreTemplate.removeClass("d-none");
                                reply_template.find(".reply_text_container").append(readMoreTemplate);
        
                                reply_template.find(".comment_text").text(reply_text.substring(0, maxLength) + "...");
                                readMoreTemplate.find('.read-more-button').on('click', function() {
                                    reply_template.find(".comment_text").text(reply_text);
                                    $(this).addClass('d-none');
                                    readMoreTemplate.find('.read-less-button').removeClass('d-none');
                                });
        
                                readMoreTemplate.find('.read-less-button').on('click', function() {
                                    reply_template.find(".comment_text").text(reply_text.substring(0, maxLength) + "...");
                                    $(this).addClass('d-none');
                                    readMoreTemplate.find('.read-more-button').removeClass('d-none');
                                });
                            } else {
                                reply_template.find(".comment_text").text(reply_text);
                            }
                            reply_template.find(".edit_reply").click(() => {
                                reply_template.find(".edit_reply_input").removeClass("d-none");
                                reply_template.find(".edit_reply_text").val(comment_reply.comment_reply);
                                reply_template.find(".save_edit_reply").on("click", () => {
                                    var reply_text = reply_template.find(".edit_reply_text").val();
                                    let editing_comment_id = comment_reply.id;

                                    this.save_edit_reply(editing_comment_id, reply_text);
                                    reply_template.find(".comment_text").text(reply_text);
                                    reply_template.find(".edit_reply_input").addClass("d-none");
                                });
                            });
                            reply_template.find(".delete_reply").on("click", () => {
                                this.deleteVoiceReply(comment_reply.id);
                            });
                            // Toggle actions based on user id
                            if (String(reply_user_id) === String(logged_in_user_id)) {
                                reply_template.find(".reply_dropdown").removeClass("d-none");
                            } else {
                                reply_template.find(".reply_dropdown").addClass("d-none");
                            }
        
                            reply_template.removeClass("d-none");
        
                            if (index < repliesToShow) {
                                reply_template.addClass('show-reply');
                            } else {
                                reply_template.addClass('hidden-reply d-none');
                            }
        
                            reply_container.append(reply_template);
                        });
        
                        // Add "Read More" and "Read Less" functionality for replies
                        if (comment_replies.length > repliesToShow) {
                            var read_more_template = $("#read_more_template").clone();
                            read_more_template.removeClass("d-none");
        
                            read_more_template.find('.read-more-button').on('click', function() {
                                reply_container.find('.hidden-reply').removeClass('d-none');
                                $(this).addClass('d-none');
                                read_more_template.find('.read-less-button').removeClass('d-none');
                            });
        
                            read_more_template.find('.read-less-button').on('click', function() {
                                reply_container.find('.hidden-reply').addClass('d-none');
                                $(this).addClass('d-none');
                                read_more_template.find('.read-more-button').removeClass('d-none');
                            });
        
                            reply_container.append(read_more_template);
                        }
        
                        reply_container.removeClass("d-none");
                        this.toggler_comment_input(comment_id);
                    }
                },
                complete: () => {
                    this.isLoading = false;
                }
            });
        },
        reply_edit(reply) {
            this.editing_comment_id = reply.id;
            this.editing_comment_text = reply.comment_reply;

            $(`#edit_reply_text_area_${reply.id}`).val(this.editing_comment_text);
            $(`#edit_reply_section_${reply.id}`).removeClass("d-none");
            $('#edit_reply_modal').modal('show');
        },

        save_edit_reply(reply_id,comment_reply) {
            const request_data = { reply_id: reply_id, comment_reply: comment_reply };
            $.ajax({
                url: `${GLOBAL_URL}/group_management/update_group_voice_comment_reply/`,
                method: 'POST',
                headers: {"Authorization": `Token ${this.getToken()}`},
                data: JSON.stringify(request_data),
                contentType: 'application/json',
                success: (response) => {
                    const updatedReply = response.data.reply_data;
                    if (Array.isArray(this.comments_data)) {
                        const comment = this.comments_data.find(comment =>
                            Array.isArray(comment.replies) && comment.replies.some(reply => reply.id === updatedReply.id)
                            );

                        if (comment) {
                            const reply = comment.replies.find(reply => reply.id === updatedReply.id);
                            if (reply) {
                                reply.comment_reply = updatedReply.comment_reply;
                                $(`#comment_reply_${reply.id}`).text(updatedReply.comment_reply);
                                }
                            }
                        }
                    $(`#edit_reply_section_${reply_id}`).addClass("d-none");
                    $(`#edit_reply_text_area_${reply_id}`).val('');
                    $("#edit_reply_modal").modal("hide");
                    }
                });
            },
            deleteVoiceReply(reply_id) {
                Swal.fire({
                  title: 'Are you sure?',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                  if (result.isConfirmed) {
                    $.ajax({
                      type: "DELETE",
                      url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/delete_group_voice_comment_reply/`,
                      data: JSON.stringify({ reply_id: reply_id }),
                      headers: {
                        'Authorization': `Token ${this.getToken()}`,
                        'Content-Type': 'application/json'
                      },
                      success: (response) => {
                        const status = response.status;
                        if (status === "success") {
                          Swal.fire({
                            title: 'Deleted!',
                            text: 'The reply has been deleted.',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                          }).then(() => {
                            $(`#reply_${reply_id}`).remove();
                        });
                        }
                      }
                    });
                  }
                });
              },
        }
    }
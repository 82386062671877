<section class="image-background">
  <div class="form-bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4 col-sm-6">
          <div class="form-container rounded-5 shadow p-5 top-20">
            <img :src="$clientLogo"  class="img-fluid mx-auto d-block w-150" alt="">
            <h3 class="title text-center">Password Change</h3>
            <form @submit.prevent="submitNewPassword">
              <div class="mb-3 position-relative">
                <label for="new_password" class="form-label text-xs fw-500">
                  New Password<span class="text-danger">*</span>
                </label>
                <div class="position-relative">
                  <input
                    :type="newPasswordVisible ? 'text' : 'password'"
                    class="form-control rounded-5 text-xs p-2 mb-1 pr-5" 
                    id="new_password"
                    placeholder="Enter new password"
                    v-model="new_password"
                    required
                  />
                  <span
                    class="eye-toggle"
                    @click="togglePasswordVisibility('new_password')"
                  >
                    <i :class="newPasswordVisible ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
                  </span>
                </div>
                <div v-if="errors.new_password" class="text-danger mt-1">{{ errors.new_password }}</div>
              </div>
            
              <div class="mb-3 position-relative">
                <label for="confirm_password" class="form-label text-xs fw-500">
                  Confirm Password<span class="text-danger">*</span>
                </label>
                <div class="position-relative">
                  <input
                    :type="confirmPasswordVisible ? 'text' : 'password'"
                    class="form-control rounded-5 text-xs p-2 mb-1 pr-5"
                    id="confirm_password"
                    placeholder="Confirm new password"
                    v-model="confirm_password"
                    required
                  />
                  <span
                    class="eye-toggle"
                    @click="togglePasswordVisibility('confirm_password')"
                  >
                    <i :class="confirmPasswordVisible ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
                  </span>
                </div>
                <div v-if="errors.confirm_password" class="text-danger mt-1">{{ errors.confirm_password }}</div>
              </div>
            
              <button
                type="submit"
                class="btn btn-primary rounded-5 shadow-sm w-100 mt-3 mb-1"
                :disabled="isLoading"
              >
                Change Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<nav class="navbar navbar-expand-lg navbar-light border-bottom bg-gradient topbar static-top shadow mb-1 sticky-navbar w-100 p-0">
  <div class="container-fluid d-flex justify-content-between w-100 ">
    <!-- Left Section: Sidebar Toggle, Logo -->
    <div class="d-flex align-items-center">     
      <!-- Logo -->
      <a class="navbar-brand d-flex align-items-center" href="/">
        <img :src="$clientLogo" class="img-fluid logo" alt="Logo" />
      </a>
    </div>

    <!-- Center Section: Search Bar (Desktop) -->
    <div class="d-none d-lg-flex flex-grow-1 justify-content-center position-relative">
      <input v-if="token_exists" v-model="searchQuery" type="text" class="form-control search-input text-xs w-25 rounded-pill p-2 shadow" placeholder="Search..." />
      <div v-if="filteredProfiles.length" class="search-results position-absolute">
        <ul class="list-group">
          <li v-for="profile in filteredProfiles" :key="profile.id" class="list-group-item">
            <span class="text-sm mx-3">
              <a :href="getProfileLink(profile.user)" class="text-decoration-none text-dark">
                {{ profile.user.first_name }} {{ profile.user.last_name }}
              </a>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <!-- Right Section: Icons, Notifications, and User Menu -->
    <div class="d-flex align-items-center">
      <FloatingButton class="me-4" v-if="token_exists" @new_report="$emit('new_report', $event)"  @new_hub="$emit('new_hub', $event)" @addPortal="$emit('addPortal', $event)" @newSegment="$emit('newSegment', $event)" @new_temp_post="$emit('new_temp_post', $event)"/>
      <!-- Mobile Search Icon -->
      <div v-if="token_exists" class="d-flex d-lg-none align-items-center position-relative me-1">
        <i id="search-icon" class="bx bx-search-alt-2 fs-3 text-dark" @click="toggleSearch"></i>
      </div>

      <!-- Notification Dropdown -->
      <NotificationDropdown class="d-none d-xl-block me-3" />

      <!-- User Menu -->
      <ul class="navbar-nav text-xs fw-500 me-3" v-if="token_exists">
        <li class="nav-item dropdown no-arrow">
          <a class="nav-link dropdown-toggle rounded-circle shadow bg-change text-change fw-bold" href="#" id="messages_dropdown"
            role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ user_details.first_name.charAt(0) }}
          </a>
          <div class="dropdown-menu dropdown-menu-end shadow animated--grow-in p-2 border-0">
            <div class="text-xs shadow p-1 rounded-5">
              {{ user_details.first_name.charAt(0) }} {{ user_details.last_name }}
            </div>
            <li>
              <a class="dropdown-item text-xs" :href="user_details.user_role === 'customer' ? '/customer_profile' : '/profile'">
                <i class="bi bi-person-square"></i> Profile
              </a>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li>
              <button class="dropdown-item text-xs" @click="logout_user()">
                <i class="bx bx-log-out"></i> Log Out
              </button>
            </li>
          </div>
        </li>
      </ul>

      <!-- Auth Links (If No Token) -->
      <ul :class="['navbar-nav d-flex flex-row align-items-center text-xs fw-500 ms-auto', token_exists ? 'd-none' : '']">
        <li class="nav-item ms-2">
          <router-link class="nav-link btn btn-primary text-white text-xs shadow-sm rounded-5 p-2" to="sign_up_finale">
            Sign up
          </router-link>
        </li>
        <li class="nav-item mx-2">
          <a class="nav-link btn btn-primary text-white text-xs shadow-sm rounded-5 p-2" href="/sign_in_Finale">
            Login
          </a>
        </li>
      </ul>
    </div>
  </div>

  <!-- Second Row: Mobile Search Input -->
  <div v-if="showSearch" class="container-fluid mt-2">
    <input v-model="searchQuery" type="text" class="form-control" placeholder="Search..." @blur="toggleSearch" />
    <div v-if="filteredProfiles.length" class="mobile-search-results position-absolute">
      <ul class="list-group">
        <li v-for="profile in filteredProfiles" :key="profile.id" class="list-group-item">
          <span class="text-sm mx-3">
            <a :href="getProfileLink(profile.user)" class="text-decoration-none text-dark">
              {{ profile.user.first_name }} {{ profile.user.last_name }}
            </a>
          </span>
        </li>
      </ul>
    </div>
  </div>
</nav>

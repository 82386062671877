import { get_token ,get_user_details} from "@/router";
import NotificationDropdown from '@/components/Notifications/Notification.vue'
import MenuSideBar from '@/components/MenuSidebar/MenuSidebar.vue'
import Swal from 'sweetalert2';
const domain = window.location.hostname;


export default {
    props: {
      activeTab: String
    },
    components: {
      NotificationDropdown,
      MenuSideBar
    },
    computed: {
        isHomePage() {
          return this.$route.name === 'home'; // Assuming your home page route is named 'home'
        },
        isHomeFilterPage() {
          return this.$route.name === 'home_filter'; 
        },
        isPortalPage() {
          return this.$route.name === 'view_portal'; 
        },
        is_group_page() {
          return this.$route.name === 'view_group'; 
        },
      },
      mounted() {
        this.token_exists = get_token();
        this.user_details=get_user_details();
        this.fetchPortals();
        if (domain.includes('workstreams') || domain.includes('workbook')) {
          this.stagging_domain = true;
        }
      },
      data() {
        return ({
          token_exists: false,
          user_details: {
            "user_role": '',
            "first_name": '',
            "last_name": '',
            stagging_domain: false
          },
          portals:[]
          });
      },
      methods:{
        getToken() {
          return get_token();
        },
        fetchPortals() {
          this.token_exists = this.getToken();
          $.ajax({
          url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_portals_api/`,
          method: 'POST',
          headers: this.token_exists
          ? {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
          }
          : {},
          data: JSON.stringify(this.payload),
          success: (data) => {
            if (data.portals) {
              this.portals = this.token_exists
                  ? data.portals
                  : data.portals.filter(portal => portal.is_public);
          } else {
              this.portals = [];
          }

          setTimeout(() => {
            this.loading = false;
        }, 1000);
          },
          complete: () => {
              this.isLoading = false;
          }
          });
      },
      handle_unauthenticated() {
        if (!this.token_exists) {
            this.$router.push({ name: 'sign_in_finale'});
          return false;
        }
        return true;
      },
    open_portal(portal_id,portal_name,is_public) {
      if (this.$route.name === 'view_incidents') {
        this.$router.push({ name: 'view_incidents', params: { portal_id: portal_id, portal_name: portal_name,} });
      } else {
        this.$router.push({ name: 'view_portal', params: { portal_id: portal_id, portal_name: portal_name, is_public: is_public } });
        this.filterReports(portal_id);
      }
      },
      filterReports(portal_id) {
        this.loading = true;
        const payload = {
          filter_values: {
            portal_id: portal_id
          }
        }
        $.ajax({
          url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/filter_reports_api/`,
          method: 'POST',
          headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
          },
          data: JSON.stringify(payload),
          success: (response) => {
            if(response.status === "success") {
              this.$emit('portal_selected', response.data.report_data);
            }

            setTimeout(() => {
              this.loading = false;
          }, 1000);

          },
          complete: () => {
              this.isLoading = false;
          }
        });
      },
       handlePortalClick(portal) {
                
                if (!this.handle_unauthenticated()) {
                  return;
                }
                this.is_public = portal.is_public
      
      
                if (portal.has_access == "approved") {
                  this.$emit('portal_profile', portal);
                  this.open_portal(portal.id, portal.name, portal.is_public);
      
                  // this.get_portal_access_requested_users();
                  $("#request_access").modal('hide');
                }
      
                else if (portal.has_access == "pending") {
                  $("#request_access").modal('hide');
                  Swal.fire({
                    icon: 'info',
                    title: 'Info',
                    text: 'Access request already submitted.',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  return;
                }
      
                else {
                  $("#request_access").modal('show');
                  this.selectedPortal = portal;
                  return;
                }
      
              },
      }
}
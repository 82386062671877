import { clear_token } from "@/router";
const domain=window.location.hostname;
export default {
    mounted() {
      this.initSidebar();
      if(domain.includes('cbo')){
        this.cbo_domain=true
      }
      if (domain.includes('workbook')) {
        this.workbook_domain = true;
      }
      if (domain.includes('workstreams') || domain.includes('demo')){
        this.stagging_domain = true;
      }
    },

  data() {
    return ({
      cbo_domain:false,
      workbook_domain: false,
      stagging_domain: false
    });
  },

    methods: {
      initSidebar() {
        let arrows = document.querySelectorAll(".arrow");
        arrows.forEach(arrow => {
          arrow.addEventListener("click", (e) => {
            let arrowParent = e.target.parentElement.parentElement;
            arrowParent.classList.toggle("show-menu");
          });
        });
      },
      log_out(){
        clear_token()
        this.$router.push({ path:'/',});
      }
    }
  }
<div :class="['home-sidebar card legacy-card bg-blue border-0 shadow', { 'open': isSidebarOpen }]">
  <div class="card-body text-white">
    <ul class="nav-links">
      <li>
        <div class="icon-link">
          <a href="/home_filter">
            <span v-if="cbo_domain" class="link-name">Memberships</span>
            <span v-else class="link-name">Portals</span>
          </a>
        </div>
      </li>
      <li>
        <div class="icon-link">
          <a href="/view_group">
            <span class="link-name">Groups</span>
          </a>
        </div>
      </li>
      <li>
        <div class="icon-link">
          <a href="/view_events">
            <span class="link-name">Events</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</div>
<div class="offcanvas offcanvas-start" tabindex="-1" id="users" aria-labelledby="users_label">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="users_label">Users</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <table v-if="filteredUsers.length" class="table mb-0">
      <tbody>
        <tr v-for="user in filteredUsers" :key="user.id" class="hover-row border-0 ">
          <td class="border-0">
            <span class="text-decoration-none text-dark" data-bs-dismiss="offcanvas">
              <p class="user-icon mb-0 d-flex align-items-center">
                <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple"><i class='bx bx-command fs-5'></i></a>
                <span class="text-xs fw-500 mx-2">{{ user.first_name }} {{user.last_name }} </span>
              </p>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="d-none d-xl-block mt-4">
  <div v-if="token_exists"class="mx-5 rounded-5 p-2 pb-5 position-sticky top-20px">
    <h6 class='text-start mx-1'>Users</h6>
    <hr />
    <!-- Create user Modal -->
    <div class="table-container">
      <table v-if="filteredUsers.length" class="table mb-0">
        <tbody>
          <tr v-for="user in filteredUsers" :key="user.id" class="hover-row border-0 ">
            <td class="border-0">
              <span class="text-decoration-none text-dark"
              >
                <p class="portal-icon  mb-0 d-flex align-items-center" >
                  <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple"><i class='bx bx-command fs-5'></i></a>
                  <span class="text-xs fw-500 mx-2" >{{ user.first_name }} {{user.last_name}}</span>
                </p>
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <p v-else class="text-secondary text-xs text-center">
        No UsersAvailable
      </p>
    </div>
  </div>
</div>



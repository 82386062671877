    <div class="offcanvas offcanvas-end custom-offcanvas-lg" tabindex="-1" id="sprint_offcanvas" aria-labelledby="offcanvasRightLabel" ref="offcanvas" >
      <div class="offcanvas-header">
        
        <h6 id="offcanvasRightLabel">
          <span >
            <img class="ai-agent-icon cursor-pointer"src="@/assets/images/ai_loading.gif" alt="Summary" />
          </span>{{sprint_data.name}} daily insight</h6>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        
        <div v-if="!isSubmitting && user_details.user_role === 'admin' || portal_admin" class="input-group mb-3 rounded-5">
          <input
            type="number"
            class="form-control text-xs rounded-start"
            v-model="day_number"
            placeholder="Enter day number"
          />
          <span
            class="input-group-text bg-transparent p-2 bg-light rounded-end"
            @click="generate(sprint_data)"
          >
            <i class="bi bi-send"></i>
          </span>
        </div>
        <div v-if="errors.day_number" class="text-danger text-xs mt-0">
          {{ errors.day_number }}
        </div>
        <div v-if="isLoading" class="d-flex justify-content-center align-items-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-if="isSubmitting" class="d-flex justify-content-center align-items-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-else-if="results.length">
          <div v-for="result in results" :key="result._id" class="small mb-0 text-xs text-break card shadow card-hover bg-white border-0 mb-4 report-card p-3 rounded-5 d-flex flex-column full-width-mobile">
            <h6 class="text-primary fw-bold">
              Sprint Day: {{ result.day_number+1 }}
            </h6>
            <p class="text-dark flex-grow-1" v-html="formatText(isExpanded[result._id] ? result.daily_insights : truncatedText(result.daily_insights))"></p>
            <span v-if="result.daily_insights.length > maxLength" class="text-primary text-xxs mt-1" @click="toggleText(result._id)">
              {{ isExpanded[result._id] ? "Read Less" : "Read More" }}
              <i :class="isExpanded[result._id] ? 'bx bx-up-arrow-circle' : 'bx bx-down-arrow-circle'"></i>
              <div class="d-flex justify-content-end">
                <span v-if="(isExpanded[result._id] || result.daily_insights.length <= maxLength) && sprint_data.portal.id != sprint_data.portal.master_portal.id &&( user_details.user_role === 'admin' || portal_admin)" 
                      class="d-inline-flex align-items-center px-3 py-1 rounded-pill bg-secondary-subtle text-secondary border border-secondary"
                      @click.prevent="submitReport(result)">
                  <i class="bi bi-check-lg me-1"></i>
                  Push to Master
                </span>
              </div>
            </span>
            
          </div>
        </div>
        <div v-else class="small mb-0 text-xs text-break card shadow card-hover bg-white border-0 mb-4 report-card p-3 rounded-5 d-flex flex-column full-width-mobile">
          <p class="text-dark flex-grow-1 text-center">
            No Available Sprint Insight
          </p>
        </div>
       </div>
    </div>

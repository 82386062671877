<div>
    <Sidebar ref="sidebar"></Sidebar>
    <section class="home-section">
      <Navbar @toggle-sidebar="toggleSidebar"></Navbar>

      <!-- Sections and Questions Management with Tabs -->
      <div class="card bg-white shadow-sm mt-5 rounded-5 border-0">
        <div class="card-header bg-white rounded-top-5 mv-5 shadow bg-dark-purple text-white">
          <span class="fw-500">Section created</span>
          <button class="btn btn-warning text-white text-xs rounded-5 shadow-lg fw-500 float-end" data-bs-toggle="modal"
            data-bs-target="#create_section">
            <i class='bx bx-plus'></i> Create Section
          </button>
        </div>
        <div class="card-body tab-content" id="sectionTabsContent">
          <!-- Sections Tab -->
          <div class="tab-pane fade show active" id="sections" role="tabpanel" aria-labelledby="sections-tab">
            <table class="table table-striped text-xs mt-3">
              <thead class="bg-dark text-white">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Section Name</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody class="text-black">
                <tr v-for="(section, index) in sections" :key="section.id">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ section.name }}</td>
                  <td>
                    <router-link :to="'view_dashboard_section/' + section.id">
                      <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow"><i class="bi bi-eye"></i>
                        View</button>
                    </router-link>
                    <button 
                      class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow"
                      data-bs-toggle="modal"
                      :data-bs-target="'#edit_section_' + section.id"
                      @click="editSectionName(section)"
                    >
                      <i class="bi bi-pencil-square"></i> Edit
                    </button>
                    <button @click="deleteSection(section.id)" class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow">
                      <i class="bi bi-trash"></i> Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Questions Tab -->
          <div class="tab-pane fade" id="questions" role="tabpanel" aria-labelledby="questions-tab">
            <button class="btn btn-warning text-white text-xs rounded-5 shadow-lg fw-500 float-end" data-bs-toggle="modal"
              data-bs-target="#create_question">
              <i class='bx bx-plus'></i> Add Question
            </button>
            <table class="table table-striped text-xs mt-3">
              <thead class="bg-dark text-white">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Question Text</th>
                  <th scope="col">Question Type</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody class="text-black">
                <tr v-for="(question, index) in questions" :key="question.id">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ question.question }}</td>
                  <td>{{ question.question_type.question_type }}</td>
                  <td>
                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow">
                      <i class="bi bi-pencil-square"></i> Edit
                    </button>
                    <button @click="deleteQuestion(question.id)" class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow">
                      <i class="bi bi-trash"></i> Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="modal fade" id="create_section" tabindex="-1" aria-labelledby="create_sectionModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create Section</p>
                <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <form @submit.prevent="createSection">
                <div class="modal-body">

                  <div class="mb-3">
                    <label for="section_name" class="form-label text-xs fw-500">Section Name <span
                        class="text-danger">*</span></label>
                    <input type="text" class="form-control rounded-5 text-xs" id="section_name"
                      placeholder="Enter section name" v-model="section_name">
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      <div v-for="section in sections" :key="section.id" >
      <div 
        class="modal fade" 
        :id="'edit_section_' + section.id" 
        tabindex="-1" 
        aria-labelledby="edit_sectionModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <p class="modal-title text-xs fw-500">Edit Section</p>
              <button 
                type="button" 
                class="btn-close text-xs fw-500" 
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label class="form-label text-xs fw-500">
                  Section Name <span class="text-danger">*</span>
                </label>
                <input 
                  type="text" 
                  class="form-control rounded-5 text-xs" 
                  v-model="edit_section"
                  placeholder="Enter section name"
                >
              </div>
            </div>
            <div class="modal-footer">
              <button @click="editSection(section)" type="submit" class="btn btn-primary text-xs rounded-5">Post
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  </div>
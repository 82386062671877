import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';
import { get_token } from "@/router";
import Swal from 'sweetalert2';

export default {
  components: {
    Sidebar,
    Navbar
  },
  data() {
    return ({
      isSubmitting : false,
        members:[],
        verifiedMember:[],
        rsvp_code:'',
        message: '',
        isVerified: false,
        errors: {}
    });
  },
  methods: {
      getToken() {
      return get_token();
    },
    toggleSidebar() {
      this.$refs.sidebar.$el.classList.toggle("close");
    },
    exportToCSV() {
      if (!this.members || this.members.length === 0) {
        alert("No members selected for export.");
        return;
      }

      const csvHeader = "Name,Last Name ,Email,Phone number,Event, Event extras\n";
      const csvRows = this.members.map(member =>
        `${member.user.first_name},${member.user.last_name},${member.user.email},${member.user.phone_number},${member.event.event_name}, "${member.rsvp_items.map(item => item.item?.item_name)}"`

      );
      const csvContent = csvHeader + csvRows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "members.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },

    filterRSVP() {
        this.isSubmitting = true;
        return new Promise((resolve) => {
            $.ajax({
                url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_all_rsvp/`,
          method: 'GET',
          headers: {
            'Authorization': `Token ${this.getToken()}`,
            'Content-Type': 'application/json'
          },
          success: (data) => {
            this.members = data.rsvp_data;
            resolve(); 
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      });
    },
    verifyRsvpCode(){
      this.isSubmitting=true;
      const formData = new FormData();
      if (!this.rsvp_code) {
        this.message = 'RSVP Code is required';
        return;
    } 
      formData.append('provided_rsvp_code', this.rsvp_code);
      $.ajax({
              type: "POST",
              url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/verify_rsvp_code/`,
              data: formData,
              processData: false,
              contentType: false,
              headers: {
                'Authorization': `Token ${this.getToken()}`,
              },
              success: (response) => {
                const status=response.status
                this.isVerified = true;
                this.verifiedMember=response.rsvp_data
                this.message="RSVP code verified!"
                if (status === "success") {
                            Swal.fire({
                              icon: 'success',
                              title: this.message,
                              showConfirmButton: false,
                              timer: 2000,
                            })}
              },
              error: (response) => {
                this.isVerified=false;
                Swal.close();
                const response_data = response.responseJSON;
                this.message=response_data.message
              },
              complete: () => {
                this.isSubmitting = false;
              }
            });
    },
    resetForm() {
      this.rsvp_code = '';
      this.message = '';
      this.isVerified = false;
      this.verifiedMember = null;
  },
  },
  mounted() {
    this.filterRSVP().then(() => {
      this.$nextTick(() => {
        $('.table').DataTable({
          paging: true,
          searching: true, 
          ordering: true,
          info: true,
          lengthChange: true,
          pageLength: 10,
          language: {
            paginate: {
              previous: "Previous",
              next: "Next"
            },
            info: "Showing _START_ to _END_ of _TOTAL_ entries",
            infoEmpty: "No entries available"
          },
           sortColumn: '',
           sortDirection: 'asc'
        });
      });
    });
  }
};

import { get_token } from "@/router";
import Swal from 'sweetalert2';
import HomeNavBar from "@/components/HomeNavBar/HomeNavBar.vue";

export default {
  mounted() {
    this.fetchcommunity_assets();
    this.get_community_assets_category();
    this.token_exists = get_token();
  },
  components: {
    HomeNavBar,
  },
  data() {
    return ({
      asset_name:'',
      description:'',
      location:'',
      category:'',
      category_id:'',
      file_name: '',
      upload_media: null,
      community_assets: [],
      community_asset_categories: [],
      community_asset_name: '',
      community_asset_description: '',
      community_asset_modal_visible: false,
      community_asset_data: {},
      is_public: true,
      isLoading: false,
      token_exists: false,
      errors: {},
      selected_asset:{},
      isZoomed: false,
      showModal: false,
      asset_id: null,
      edit_asset_name: "",
      edit_asset_location: "",
      edit_description: "",
      edit_subscription_fee: null,
      categories: [],
      edit_asset_files :[],
      temporary_asset_post : null,
      asset_file_name:'',
      fileInputTriggered: false,
    });
  },
  methods: {
    getToken() {
      return get_token();
    },
    fetchcommunity_assets() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_community_asset/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (data) => {

          this.community_assets = data.community_assets;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
  },
    handleAssetFileUpload(event) {

      const file = event.target.files[0];

      if (file) {
        if (file.type.startsWith("audio/")) {
          this.fileError = "Audio files are not allowed.";
          this.upload_media = null;
          this.asset_file_name = "";
          this.imagePreview = null; // Reset preview if an invalid file is selected
          event.target.value = ""; // Clear file input
        } else {
          this.asset_file_name = file.name;
          this.fileError = "";
          this.upload_media = file;

          // Check if the file is an image before previewing
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (e) => {
              this.imagePreview = e.target.result;
            };
            reader.readAsDataURL(file);
          } else {
            this.imagePreview = null; // Reset preview for non-image files
          }
        }
      } else {
        this.asset_file_name = "";
        this.fileError = "";
        this.upload_media = null;
        this.imagePreview = null;
      }
    },

    createcommunity_asset() {
      this.isSubmitting = true;
      const formData = new FormData();
      formData.append('name', this.asset_name);
      formData.append('description', this.description);
      formData.append('location', this.location);
      formData.append('category_id', this.category_id);
      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_community_asset/`,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: formData,
        processData: false,
        contentType: false,
        success: (response) => {
          const status = response.status;
          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Community asset Created Successfully',
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              window.location.reload();
              $('#create_community_asset_modal').modal('hide');
            });
          }
        },
        error: (response) => {
          const response_data = response.responseJSON;
          if (response_data.status === "error") {
            this.errors.community_asset_name = response_data.message
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    handle_unauthenticated() {
      if (!this.token_exists) {
        this.$router.push({ name: 'sign_in_finale' });
        return false;
      }
      return true;
    },

    display_community_asset(community_asset_data) {
      if(!this.handle_unauthenticated()){
        return;
      }
      this.community_asset_modal_visible = false;

      setTimeout(() => {
        this.community_asset_data = community_asset_data;
        this.community_asset_modal_visible = true;
      }); 
    },



  hide_community_asset() {
      this.community_asset_data = {};
      this.community_asset_modal_visible = false;
    },

    get_community_assets_category() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_all_community_assets_category/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (response) => {
          this.community_asset_categories = response.data.categories;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },
    select_asset(asset) {
      this.selected_asset = asset;
    },
    subscribeCommunityAsset(communityAsset) {
      const communityAssetId = communityAsset.id;    
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/subscribe_community_asset/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ community_asset_id: communityAssetId }),
        success: (response) => {
          if (response.status === "success") {
            communityAsset.subscribed = true;

            const subscribeButton = $(`#subscribe-button${communityAssetId}`);
            const subscribedButton = $(`#subscribed-button${communityAssetId}`);

            if (subscribeButton.length) {
              subscribeButton.addClass('d-none');
            }

            if (subscribedButton.length) {
              subscribedButton.removeClass('d-none');
            }
          }
        },
      });
    },
    unsubscribeCommunityAsset(communityAsset) {
      const communityAssetId = communityAsset.id;
      $.ajax({
          type: "POST",
          url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/subscribe_community_asset/`,
          contentType: "application/json",
          headers: {
              'Authorization': `Token ${this.getToken()}`,
          },
          data: JSON.stringify({ community_asset_id: communityAssetId }),
          success: (response) => {
              if (response.status === "success") {
                  communityAsset.subscribed = false;

                  const subscribeButton = $(`#subscribe-button${communityAssetId}`);
                  const subscribedButton = $(`#subscribed-button${communityAssetId}`);

                  if (subscribeButton.length) {
                      subscribeButton.removeClass('d-none');
                  }

                  if (subscribedButton.length) {
                      subscribedButton.addClass('d-none');
                  }
              }
          },
      });
  },
  toggleZoom() {
    this.isZoomed = !this.isZoomed;
  },
  toggleFullscreen() {
    const image = this.$refs.image;
    
    if (!document.fullscreenElement && !document.mozFullScreenElement 
        && !document.webkitFullscreenElement
        && !document.msFullscreenElement) {
      if (image.requestFullscreen) {
        image.requestFullscreen();
      } else if (image.mozRequestFullScreen) { 
        image.mozRequestFullScreen();
      } else if (image.webkitRequestFullscreen) {
        image.webkitRequestFullscreen();
      } else if (image.msRequestFullscreen) {
        image.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  },
  delete_asset(community_asset_id) {
  
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            $.ajax({
              type: "DELETE",
              url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_community_asset/`,
              data: {
                community_asset_id: community_asset_id
              },
              headers: {
                'Authorization': `Token ${this.getToken()}`,
              },
              success: (response) => {
                const status = response.status;
                if (status === "success") {
                  Swal.fire({
                    title: 'Deleted!',
                    text: 'The Assert has been deleted.',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                  }).then(() => {
                    this.community_assets = this.community_assets.filter(asset => asset.id !== community_asset_id);
                  });
                } else {
                  Swal.fire(
                    'Failed!',
                    response.message || 'An error occurred. Please try again.',
                    'error'
                  );
                }
              },
            });
          }
        });
      },
      edit_asset(asset) {
        this.asset_id = asset.id;
        this.edit_asset_name = asset.name;
        this.edit_asset_location = asset.location;
        this.edit_description = asset.description;
        this.edit_subscription_fee = asset.subscription_fee;
        this.edit_asset_category = asset.category.id;
        this.edit_asset_files = asset.community_asset_files || [];
    
        const TextDescription = asset.description.replace(/<\/?[^>]+(>|$)/g, '');
    
        this.$nextTick(() => {
            if (this.$refs.quillEventEditorRef) {
                const quillEditor = this.$refs.quillEventEditorRef.getQuill();
                if (quillEditor) {
                    quillEditor.setContents([{ insert: TextDescription || "" }]);
                }
            }
        });
    
        $('#edit_asset').modal('show');
    },

  updateCommunityAsset() {
    $('#edit_asset').modal('hide');

      if (this.$refs.quillEventEditorRef) {
          const quillEditor = this.$refs.quillEventEditorRef.getQuill();
          this.edit_description = quillEditor.root.innerHTML.replace(/<\/?[^>]+(>|$)/g, '');
      }
  
      this.temporary_asset_post = {
        asset_name: this.edit_asset_name,
        isTemp: true, 
      };
      // Create FormData to send the file and other fields
      this.isLoading = true;
      const formData = new FormData();
      formData.append('community_asset_id', this.asset_id);
      formData.append('community_asset_name', this.edit_asset_name);
      formData.append('location', this.edit_asset_location);
      formData.append('description', this.edit_description);
      formData.append('subscription_fee', this.edit_subscription_fee);
      formData.append('category_id', this.edit_asset_category);
  
      if (this.upload_media) {
        formData.append('file', this.upload_media);
    }

  
      $.ajax({
          type: 'PUT',
          url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/update_community_asset/`,
          headers: {
              'Authorization': `Token ${this.getToken()}`,
          },
          contentType: false,
          processData: false,
          data: formData,
          success: (response) => {
              if (response.status === "success") {
                this.temporary_asset_post= null;
                  Swal.fire({
                      icon: 'success',
                      title: 'Asset Updated',
                      showConfirmButton: false,
                      timer: 1000
                  }).then(() => {
                    $('#edit_asset').modal('hide');
                      this.community_assets.forEach(asset => {
                          if (asset.id === this.asset_id) {
                              asset.name = this.edit_asset_name;
                              asset.location = this.edit_asset_location;
                              asset.description = this.edit_description;
                          }
                      });
  
                      
                  });
              }
          },
          error: () => {
              Swal.fire({
                  icon: 'error',
                  title: 'Update Failed',
                  text: 'There was an error updating the asset.',
              });
          }
      });
  },

    
  }
}
<div>
    <Sidebar ref="sidebar"></Sidebar>
    <section class="home-section">
      <Navbar @toggle-sidebar="toggleSidebar"></Navbar>
  
      <div class="card bg-white shadow-sm mt-5 rounded-5 border-0">
        <div class="card-header bg-white rounded-top-5 mv-5 shadow bg-dark-purple text-white">
          <span class="fw-500">Question created</span>
          <button class="btn btn-warning text-white text-xs rounded-5 shadow-lg fw-500 float-end" data-bs-toggle="modal"
            data-bs-target="#create_question">
            <i class='bx bx-plus'></i> Add Question
          </button>
        </div>
        <div class="card-body">
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane show active" id="questions" role="tabpanel" aria-labelledby="questions-tab">
              <table class="table table-striped text-xs mt-3">
                <thead class="bg-dark text-white">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Question Text</th>
                    <th scope="col">Question Type</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody class="text-black">
                  <tr v-for="(question, index) in questions" :key="question.id">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ question.question }}</td>
                    <td>{{ question.question_type.question_type }}</td>
                    <td>
                      <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow">
                        <i class="bi bi-pencil-square"></i> Edit
                      </button>
                      <button class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow">
                        <i class="bi bi-trash"></i> Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

      <!-- Create Question Modal -->
      <div class="modal fade" id="create_question" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-xs fw-500" id="exampleModalLabel">Create Question</h5>
              <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal"
              aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="createQuestion">
                <div class="mb-3">
                  <label for="question_name" class="form-label text-xs fw-500">Question Text <span
                      class="text-danger">*</span></label>
                  <input type="text" class="form-control rounded-5 text-xs" id="question_name"
                    placeholder="Enter question name" v-model="question_name">
                </div>
                <div class="mb-3">
                  <label class="form-label text-xs fw-500">Question Type</label>
                  <select class="form-control rounded-5 text-xs" v-model="question_type" required>
                    <option value="" disabled selected>Select Question Type</option>
                    <option v-for="type in question_types" :key="type.id" :value="type.id">{{ type.question_type }}</option>
                  </select>
                </div>
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary text-xs rounded-5">Save Question</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  
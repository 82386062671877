import {get_token, get_user_details } from "@/router";

export default {

    data() {
      return ({
        user_details: {
          "user_role": '',
          "first_name":'',
          "last_name":'',
          "phone_number":'',
        },
        token_exists: false,
      })
    },
    mounted() {
      this.user_details = get_user_details();
      this.token_exists = get_token();
    },

    methods: {
      toggleSidebar() {
        this.$emit('toggle-sidebar');
      },

      getProfileLink() {

        if (this.user_details.user_role === 'admin') {

          window.location=`/profile?user_id=${this.user_details.user_id}`;
        } else if (this.user_details.user_role=== 'customer') {
          window.location=`/customer_profile?user_id=${this.user_details.user_id}`
        } else {

          window.location=`/profile?user_id=${this.user_details.user_id}`;
        }
      }
    }
  }
import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';
import Swal from 'sweetalert2';
import { get_token } from "@/router";

export default {
    components: {
        Sidebar,
        Navbar
    },

    mounted() {
        this.fetch_forms();
    },

    data() {
        return ({
            forms: [],
            form_name: '',
            form_description: '',
            errors: {},
            isSubmitting: false,
            isLoading: false,
            token_exists: false,
        });
    },

    methods: {
        getToken() {
            return get_token();
        },
        toggleSidebar() {
            this.$refs.sidebar.$el.classList.toggle("close");
        },
        createForm() {
            if (!this.validateForm()) {
                return;
            }

            Swal.fire({
                title: 'Submitting...',
                text: 'Please wait while we process your request.',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            this.isSubmitting = true;

            const formData = new FormData();
            formData.append('form_name', this.form_name);
            formData.append('description', this.form_description);
            formData.append('membership_id', this.$route.params.membership_id);

            $.ajax({
                type: 'POST',
                url: `${process.env.VUE_APP_BACKEND_API_URL}/membership_management/create_form/`,
                data: formData,
                processData: false,
                contentType: false,
                headers: {
                    'Authorization': `Token ${this.getToken()}`,
                },
                success: (response) => {
                    if (response.status === "success") {
                        Swal.fire({
                            icon: "success",
                            title: "Form created",
                            showConfirmButton: false,
                            timer: 1000
                        }).then(() => {
                            this.fetch_forms();
                            this.resetForm();
                            $('#create_form').modal('hide');
                        });
                    }
                },
                error: (response) => {
                    Swal.close();
                    const response_data = response.responseJSON;
                    if (response_data) {
                        this.errors = response_data;
                    }
                },
                complete: () => {
                    this.isSubmitting = false;
                },
            });
        },

        fetch_forms() {
            this.token_exists = get_token();

            $.ajax({
                url: `${process.env.VUE_APP_BACKEND_API_URL}/membership_management/filter_forms/`,
                method: "POST",
                headers: this.token_exists
                    ? {
                        Authorization: `Token ${this.getToken()}`,
                        "Content-Type": "application/json",
                    }
                    : {},
                success: (response) => {
                    this.forms = response.data.forms;

                    this.$nextTick(() => {
                        $('.table').DataTable().destroy();
                        $('.table').DataTable({
                            paging: true,
                            searching: true,
                            ordering: true,
                            info: true,
                            lengthChange: true,
                            pageLength: 10,
                            language: {
                                paginate: {
                                    previous: "Previous",
                                    next: "Next"
                                },
                                info: "Showing _START_ to _END_ of _TOTAL_ entries",
                                infoEmpty: "No entries available"
                            }
                        });
                    });
                },
                complete: () => {
                    this.isLoading = false;
                },
            });
        },

        validateForm() {
            this.errors = {};
            let isValid = true;
            if (!this.form_name.trim()) {
                this.errors.form_name = 'Form name is required.';
                isValid = false;
            }

            return isValid;
        },

        resetForm() {
            this.form_name = '';
            this.form_description = '';
            this.errors = {};
        },
    }
}

import { get_token, get_user_details} from "@/router";
import Swal from 'sweetalert2';

export default {
  props: {
    is_public: {
      type: Boolean,
      default: false,
    },
  },
      computed: {
        portal_id() {
          return this.$route.params.portal_id
        },
        isHomePage() {
          return this.$route.name === 'home'; // Assuming your home page route is named 'home'
        },
        isHomeFilter(){
          return this.$route.name === 'home_filter';
        }
      },

      mounted() {
        this.user_details = get_user_details();
        this.token_exists = get_token();
        this.user_id = this.get_user_id();
        this.get_portal_access_requested_users();
        this.fetch_Portal_admin();
      },

      data() {

        return ({
            user_details: {
              "user_role": '',
              "user_id": null,
            },
            requested_users: [],
            token_exists: false,
            portal_admin: false,

        })
      },
      watch:{
        portal_id() {
          this.get_portal_access_requested_users();
        }
        },

      methods:{
        getToken() {
          return get_token();
      },
      get_user_id() {
        const user_id = localStorage.getItem('user_id') || '';
        return user_id;
      },
        get_portal_access_requested_users() {


          if(!this.portal_id) return;

            this.isLoading = true;
            $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_portal_access_requested_users/`,
            method: 'GET',
            data: {portal_id: this.portal_id},
            headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
            },
            success: (response) => {
                this.requested_users = response.data;

                setTimeout(() => {
                  this.loading = false;
              }, 1000);
            },
            complete: () => {
                this.isLoading = false;
            }
            });
        },

        getProfileLink(member) {
            // Check if the user is an admin
            if (member.role.role === 'admin') {
              // Return the link for admin profiles
              return `/profile?user_id=${member.id}`;
            } else if (member.role.role === 'customer') {
              // Return the link for customer profiles
              return `/customer_profile?user_id=${member.id}`;
            } else {
              // Return a default profile link for other roles
              return `/profile?user_id=${member.id}`;
            }
          },


          approve_portal_access_request(user_id) {
                  const baseUrl = `${window.location.origin}${this.$route.path}`
                  this.isLoading = true;
                  $.ajax({
                    url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/approve_portal_access_request/`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${this.getToken()}`,
                        'Content-Type': 'application/json'
                    },
                    data: JSON.stringify({
                        user_id: user_id,
                        portal_id: this.$route.params.portal_id,
                        base_url: baseUrl
                    }),
                    success: (data) => {

                        if (data.status === "success") {
                          Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Access request approved successfully.',
                            showConfirmButton: false,
                            timer: 1000
                          }).then(() => {
                        this.$emit("portalAccepted");
                            this.get_portal_access_requested_users()
                            Swal.close()

                          });
                        }
                    },
                    complete: () => {
                        this.isLoading = false;
                    }
                  });
                },

                reject_portal_access_request(user_id) {
                  this.isLoading = true;
                  $.ajax({
                    url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/reject_portal_access_request/`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${this.getToken()}`,
                        'Content-Type': 'application/json'
                    },
                    data: JSON.stringify({
                        user_id: user_id,
                        portal_id: this.$route.params.portal_id
                    }),
                    success: (data) => {
                        if (data.status === "success") {
                          Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Access request declined successfully.',
                            showConfirmButton: false,
                            timer: 1000
                          }).then(() => {
                            this.get_portal_access_requested_users()
                          });
                        }
                    },
                    complete: () => {
                        this.isLoading = false;
                    }
                  });
                },


                approve_all_portal_access_requested_users() {
                          const PORTAL_ID = this.$route.params.portal_id;
                          const baseUrl = `${window.location.origin}${this.$route.path}`
                          Swal.fire({
                              title: 'Are you sure you want to approve all users?',
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonText: 'Yes, Approve all users'
                          }).then((result) => {
                              if (result.isConfirmed) {

                                  $.ajax({
                                      type: 'POST',
                                      url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/approve_all_portal_access_requested_users/`,
                                      data: JSON.stringify({portal_id:PORTAL_ID,base_url:baseUrl
                                      }),
                                      headers: {
                                        'Authorization': `Token ${this.getToken()}`,
                                        'Content-Type': 'application/json'
                                    },
                                      contentType: 'application/json',
                                      success: (response) => {
                                          if (response.status === "success") {
                                              Swal.fire({
                                                  title: 'Approved!',
                                                  text: response.data.message,
                                                  icon: 'success',
                                                  showConfirmButton: false,
                                                  timer: 2000
                                              }).then(() => {
                                                this.get_portal_access_requested_users()
                                              });
                                          } else {
                                              Swal.fire(
                                                  'Failed!',
                                                  response.message || 'An error occurred. Please try again.',
                                                  'error'
                                              );
                                          }
                                      }
                                  });
                              }
                          });
                      },
                      reject_all_portal_access_requested_users() {
                        const PORTAL_ID = this.$route.params.portal_id;
                        Swal.fire({
                            title: 'Are you sure you want to Reject all users?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Yes, Reject all users'
                        }).then((result) => {
                            if (result.isConfirmed) {
                              this.isLoading = true;
                                $.ajax({
                                    type: 'POST',
                                    url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/reject_all_portal_access_requested_users/`,
                                    data: JSON.stringify({portal_id:PORTAL_ID}),
                                    headers: {
                                      'Authorization': `Token ${this.getToken()}`,
                                      'Content-Type': 'application/json'
                                  },
                                    contentType: 'application/json',
                                    success: (response) => {
                                        if (response.status === "success") {
                                            Swal.fire({
                                                title: 'Rejected!',
                                                text: response.data.message,
                                                icon: 'success',
                                                showConfirmButton: false,
                                                timer: 2000
                                            }).then(() => {
                                              this.get_portal_access_requested_users()
                                            });
                                        } else {
                                            Swal.fire(
                                                'Failed!',
                                                response.message || 'An error occurred. Please try again.',
                                                'error'
                                            );
                                        }
                                    }
                                });
                            }
                        });
                    },
                    fetch_Portal_admin() {
                      const portal_id = this.$route.params.portal_id;
                    
                      const payload = {
                        filter_values: {
                          portal_id: portal_id,
                          user_id: this.user_id
                        }
                      };
                    
                      $.ajax({
                        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_plartform_admin_api/`,
                        method: 'POST',
                        headers: {
                          'Authorization': `Token ${this.getToken()}`,
                          'Content-Type': 'application/json'
                        },
                        data: JSON.stringify(payload),
                        success: () => {
                          this.portal_admin=true
                        },
                        error: () => {
                    
                          this.portal_admin=false
            
                        },
                        complete: () => {
                          this.isLoading = false;
                        }
                      });
                    },
      }
    }
import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';
import { get_token, get_user_details } from "@/router";
import Swal from "sweetalert2";


export default {
  components: {
    Sidebar,
    Navbar,
  },
  mounted() {
    this.user_id = this.$route.query.user_id
    this.user_details = get_user_details();
    this.token_exists = get_token();
    this.fetch_races();
    this.get_profile();
    this.fetch_gender();
    this.fetch_title();
    this.fetch_province();
    this.fetch_type_of_organization();

    if (this.user_details) {
      this.first_name = this.user_details.first_name || '';
      this.last_name = this.user_details.last_name || '';
    }
  },
  data() {
    return ({
      first_name: '',
      last_name: '',
      email: '',
      gender: '',
      date_of_birth: '',
      phone_number: '',
      races: [],
      race_id: '',
      genders: [],
      gender_id: '',
      titles: [],
      title_id: '',
      street_address: '',
      town: '',
      postal_code: '',
      provinces: [],
      province_id: '',
      cv: null,
      latitude: null,
      longitude: null,
      cv_url: null,
      local_file_url:null,
      fileType: '',
      file_name: '',
      isLocalFile: false,
      showPreview: false,
      user_details: {
        "user_role": '',
      },
      // company
      company_name: '',
      registration_number: '',
      type_of_organization: [],
      organization_id: '',
      logo: '',
      symbol: '',
      primary_color: '',
      background_color: '',
      tel_number: '',
      slogan: '',
      token_exists: true,
      isLoading: false,
      profile: {},
      errors: {},
      user_id: '',
    });
  },
  methods: {
    /**
     * Toggle the sidebar when the button is clicked
     */
    toggleSidebar() {
      this.$refs.sidebar.$el.classList.toggle('close');
    },
    handleFileUpload(event) {
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0];
        if (this.validateFile(file)) {
          this.cv = file;
          this.file_name = file.name
          this.fileType = file.type;
          this.local_file_url = URL.createObjectURL(file);
          this.isLocalFile = true
          this.showPreview = true
          this.errorMessage = '';
        } else {
          this.cv = null;
          this.local_file_url = null;
          this.fileType = '';
          this.file_name = '';
          this.isLocalFile = false
          
          this.errorMessage = 'Invalid file type or size';
        }
      } else {
          this.errorMessage = 'Please select a file';
          this.cv = null;
          this.local_file_url = null; 
          this.fileType = '';
          this.file_name = ''; 
          this.isLocalFile = false
      }
    },
    validateFile(file) {
      const allowedTypes = ['application/pdf', 'application/msword'];

      return allowedTypes.includes(file.type)
    },
    validationProfileForm() {
      this.errors = {};
      let valid = true;

      if (this.first_name === '') {
        this.errors.first_name = 'First name is required';
        valid = false;
      }

      if (this.last_name === '') {
        this.errors.last_name = 'Last name is required';
        valid = false;
      }

      if (this.date_of_birth === '') {
        this.errors.date_of_birth = 'Date of birth is required';
        valid = false;
      }
      if (this.race_id === '') {
        this.errors.race_id = 'Race is required';
        valid = false;
      }
      if (this.gender_id === '') {
        this.errors.gender_id = 'Gender is required';
        valid = false;
      }
      if (this.title_id === '') {
        this.errors.title_id = 'Title is required';
        valid = false;
      }
      if (this.street_address === '') {
        this.errors.street_address = 'Street address is required';
        valid = false;
      }
      if (this.town === '') {
        this.errors.town = 'Town is required';
        valid = false;
      }
      if (this.postal_code === '') {
        this.errors.postal_code = 'Postal code is required';
        valid = false;
      }
      if (this.province_id === '') {
        this.errors.province_id = 'Province is required';
        valid = false;
      }
      if (!this.cv) {
        this.errors.cv = 'CV is required';
        valid = false;
      }
      return valid;
    },
    getLocation() {
      if (navigator.geolocation) {
        Swal.fire({
          title: 'Fetching Location...',
          text: 'Please wait while we retrieve your location.',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            Swal.close();
            Swal.fire({
              title: 'Location Retrieved!',
              text: `Success`,
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            });
            this.locationError = '';

          },
        );
      } else {
        this.locationError = 'Geolocation is not supported by this browser.';
      }
    },

    saveProfile() {
      if(!this.validationProfileForm()) {
        return;
      }

      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we create the profile.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this.isLoading = true;

      const formData = new FormData();

      formData.append('first_name', this.first_name);
      formData.append('last_name', this.last_name);
      formData.append('date_of_birth', this.date_of_birth);
      formData.append('gender_id', this.gender_id);
      formData.append('latitude', this.latitude);
      formData.append('longitude', this.longitude);
      formData.append('race_id', this.race_id);
      formData.append('title_id', this.title_id);
      formData.append('phone_number', this.phone_number);
      formData.append('street_address', this.street_address);
      formData.append('town', this.town);
      formData.append('postal_code', this.postal_code);
      formData.append('province_id', this.province_id);

      if (this.cv) {
        if (this.cv instanceof File) {
          formData.append('file', this.cv);
        }
      }
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/create_user_profile/`,
        type: 'POST',
        headers: {
          Authorization: `Token ${get_token()}`,
        },
        data: formData,
        processData: false,
        contentType: false,
        success: (body) => {
          if (body.status === 'success') {

            this.fetch_races();
            this.fetch_gender();
            this.fetch_title();
            this.fetch_province();
            this.get_profile();

            Swal.fire({
              title: 'Success',
              text: 'Profile Created Successfully',
              icon: 'success',
              timer: 1000,
              showConfirmButton: false,
            }).then(() => {
              window.location.reload();
            });
          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });

    this.isLoading = true;


    $.ajax({
      url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/update_user_profile/`,
      type: 'POST',
      headers: {
        Authorization: `Token ${get_token()}`,
      },
      data: formData,
      processData: false,
      contentType: false,


      success: (data) => {
        if (data.status === 'success') {
          this.fetch_races();
          this.fetch_gender();
          this.fetch_title();
          this.fetch_province();
          this.get_profile();

          Swal.fire({
            title: 'Success',
            text: 'Profile Updated Successfully',
            icon: 'success',
            timer: 1000,
            showConfirmButton: false,
          }).then(() => {
            window.location.reload();
          });
        }
      },
      error: (jqXHR) => {
          Swal.fire({
          title: 'Error',
          text: `Failed to update profile: ${jqXHR.responseText}`,
          icon: 'error',
          confirmButtonText: 'Okay',
        })
      },
      complete: () => {
        this.isLoading = false;
      },
    })

    

    },

    saveCompanyProfile() {
      this.isLoading = true;

      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we create the profile.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      const formData = new FormData();

      formData.append('company_name', this.company_name);
      formData.append('registration_number', this.registration_number);
      formData.append('organization_id', this.organization_id);
      formData.append('primary_color', this.primary_color);
      formData.append('background_color', this.background_color);
      formData.append('tel_number', this.tel_number);
      formData.append('slogan', this.slogan);
      formData.append('street_address', this.street_address);
      formData.append('town', this.town);
      formData.append('postal_code', this.postal_code);
      formData.append('province_id', this.province_id);
      

      if (this.$refs.logo.files[0]) {
        formData.append('logo', this.$refs.logo.files[0]);
      }
      if (this.$refs.symbol.files[0]) {
        formData.append('symbol', this.$refs.symbol.files[0]);
      }

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_user_company/`,
        type: 'POST',
        headers: {
          Authorization: `Token ${get_token()}`,
        },
        data: formData,
        processData: false,
        contentType: false,
        success: (data) => {
          if (data.status === 'success') {
            this.fetch_province();
            this.fetch_type_of_organization();

            Swal.fire({
              title: 'Success',
              text: 'Profile Created Successfully',
              icon: 'success',
              timer: 1000,
              showConfirmButton: false,
            }).then(() => {
              window.location.reload();
            });
          }
        },
        complete: () => {
          this.isLoading = false;
          Swal.close;
        },
      });

      this.isLoading = true;

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/update_user_company/`,
        type: 'POST',
        headers: {
          Authorization: `Token ${get_token()}`,
        },
        data: formData,
        processData: false,
        contentType: false,
        success: (data) => {
          if (data.status === 'success') {
            this.fetch_province();
            this.fetch_type_of_organization();
            this.get_profile();

            Swal.fire({
              title: 'Success',
              text: 'Profile Created Successfully',
              icon: 'success',
              timer: 1000,
              showConfirmButton: false,
            }).then(() => {
              window.location.reload();
            });
          }
        },
        complete: () => {
          this.isLoading = false;
          Swal.close;
        },
      });

    },

    get_profile() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/get_profile/`,
        type: 'GET',
        headers: {
          Authorization: `Token ${get_token()}`,
          'Content-Type': 'application/json',
        },
        data: {
          user_id: this.user_id
        },
        success: (data) => {
          if (data.status === 'success' && data.data.profile_data.user) {
            const profile = data.data.profile_data;
            this.first_name = data.data.profile_data.user.first_name || '';
            this.last_name = data.data.profile_data.user.last_name || '';
            this.email = data.data.profile_data.user.email || '';
            this.date_of_birth = data.data.profile_data.date_of_birth || '';
            this.phone_number = data.data.profile_data.user.phone_number || '';
            this.street_address = data.data.profile_data.street_address || '';
            this.town = data.data.profile_data.town || '';
            this.postal_code = data.data.profile_data.postal_code || '';
            this.province_id = data.data.profile_data.province_id || '';
            this.company_name = data.data.profile_data.company_name || '';
            this.registration_number = data.data.profile_data.registration_number || '';
            this.slogan = data.data.profile_data.slogan || '';
            this.tel_number = data.data.profile_data.tel_number || '';
            this.primary_color = data.data.profile_data.primary_color || '';
            this.background_color = data.data.profile_data.background_color || '';
            this.logo = data.data.profile_data.logo || '';
            this.latitude = profile.latitude || '';
            this.longitude = profile.longitude || '';

            if (profile.profile_files && profile.profile_files.length > 0) {
              this.cv_url = profile.profile_files[0].file.file_url;
              this.presigned_url = profile.profile_files[0].file.presigned_url;
              this.cv = profile.profile_files[0].file;
              this.file_name = profile.profile_files[0].file.name;

            } else {
              this.cv = []; 
            }
            for (const race of this.races) {
              if (race.race === data.data.profile_data.race) {
                this.race_id = race.id;
                break;
              }
            }

            for (const gender of this.genders) {
              if (gender.gender === data.data.profile_data.gender) {
                this.gender_id = gender.id;
                break;
              }
            }

            for (const province of this.provinces) {
              if (province.province_name === data.data.profile_data.province) {
                this.province_id = province.id;
                break;
              }
            }

            for (const title of this.titles) {
              if (title.title === data.data.profile_data.title) {
                this.title_id = title.id;
                break;
              }
            }

            for (const org of this.type_of_organization) {
              if (org.type_of_organization === data.data.profile_data.organization) {
                this.organization_id = org.id;
                break;
              }
            }

          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },
    previewDocument() {
      if(this.cv_url) {
        this.showPreview = true;
        this.isLocalFile = false;
      }
    },
    closeModal() {
      this.showPreview = false;
      URL.revokeObjectURL(this.cvUrl); 
      this.cv_url = '';
    },
    fetch_races() {
      this.isLoading = true;

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/get_races/`,
        type: 'GET',
        headers: {
          Authorization: `Token ${get_token()}`,
          'Content-Type': 'application/json',
        },
        success: (response) => {
          if (response.status === 'success') {
            this.races = response.data.race_data;
            this.get_profile();
          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },

    fetch_gender() {
      this.isLoading = true;

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/get_genders/`,
        type: 'GET',
        headers: {
          Authorization: `Token ${get_token()}`,
          'Content-Type': 'application/json',
        },
        success: (response) => {
          if (response.status === 'success') {
            this.genders = response.data.gender_data;
            this.get_profile();
          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },

    fetch_title() {
      this.isLoading = true;

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/get_titles/`,
        type: 'GET',
        headers: {
          Authorization: `Token ${get_token()}`,
          'Content-Type': 'application/json',
        },
        success: (response) => {
          if (response.status === 'success') {
            this.titles = response.data.title_data;
            this.get_profile();
          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },

    fetch_province() {
      this.isLoading = true;

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/get_provinces/`,
        type: 'GET',
        headers: {
          Authorization: `Token ${get_token()}`,
          'Content-Type': 'application/json',
        },
        success: (response) => {
          if (response.status === 'success') {
            this.provinces = response.data.province_data;
            this.get_profile();
          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },

    fetch_type_of_organization() {
      this.isLoading = true;

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/get_type_of_organization/`,
        type: 'GET',
        headers: {
          Authorization: `Token ${get_token()}`,
          'Content-Type': 'application/json',
        },
        success: (response) => {
          if (response.status === 'success') {
            this.type_of_organization = response.data.type_of_organization_data;
            this.get_profile();
          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },
  },
};
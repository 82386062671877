<div class="card border-0 bg-transparent d-none d-xl-block " v-if="token_exists">
  <h6 class="card-header bg-transparent">Your events</h6>
  <div class="maximum-height overflow-auto">
<div class="mt-2 border-bottom " v-for="user_event in filteredEvents">
  <div class="d-flex justify-content-between">
    <span class="fw-500 text-xs">{{user_event.event.event_name}}</span>
    <span class="fw-500 text-xxs">{{user_event.event.start_date}}</span>
  </div>
  <img src="@/assets/images/calendar.png" class="w-50 float-end" alt="">
  <div>
    <p class="text-xs">{{user_event.event.location}}<br>{{user_event.start_time}}</p>
  </div>
</div>
    
</div>
<div v-if="user_events.length > visibleCount" class=" mt-2">
  <button class="btn btn-sm btn-transparent text-secondary" @click="toggleShowMore">
   
    <!-- Show down arrow when "See Less" is visible (i.e., showAll = true) -->
    <i v-if="showAll" class="bx bxs-up-arrow-circle ms-2"></i> 
    <!-- Show up arrow when "See More" is visible (i.e., showAll = false) -->
    <i v-if="!showAll" class="bx bxs-down-arrow-circle ms-2"></i>

    {{ showAll ? 'See Less' : 'See More' }}
  </button>
</div>
</div>
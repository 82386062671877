import { get_token, get_user_details } from "@/router";
import Swal from 'sweetalert2';

export default {

    components: {
    },
    mounted() {
        this.token_exists = get_token();
        this.user_id = this.get_user_id();
        this.user_details=get_user_details();
        this.fetchIncidentButtons();
    },
    data() {
        return ({
        description: '',
        name: '',
        contact_email: '',
        contact_phone: '',
        latitude: null,
        longitude: null,
        fileName: null,
        report_title:null,
        location:null,
        incident_description:null,
        upload_media:null,
        incident_button:null,
        fileError : '',
        errors: {
          incident_title: '',
          location: '',
          incident_description: '',
        },

        errors: {},
        reports: [],
        isLoading: false,
        });
    },
    
    methods: {
        get_user_id() {
        const user_id = localStorage.getItem('user_id') || '';
        return user_id;
        },
        getToken() {
        return get_token();
        },
        async validateForm() { 
            this.errors = {}; 
            let isValid = true; 

            if (!this.name.trim()) { 
                this.errors.name = 'Name is required.'; 
                isValid = false;
            }

            const editorContent = this.$refs.quillSubmitEditorRef.getHTML();
            if (!editorContent || !editorContent.trim() || editorContent === '<p><br></p>') {
                this.errors.description = 'Description is required.';
                isValid = false;
            }

            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!this.contact_email.trim()) {
                this.errors.contact_email = 'Email is required.';
                isValid = false;
            } else if (!emailPattern.test(this.contact_email.trim())) {
                this.errors.contact_email = 'Enter a valid email address.';
                isValid = false;
            }
            return isValid;
        },
        
        submitIncident() {
            this.errors = {};
            const baseUrl = `${window.location.origin}${this.$route.path}`
 

            if (!(this.validateForm())) {
                return;
            }
            Swal.fire({
                title: 'Submitting...',
                text: 'Please wait while we process your request.',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                    }
                });

            this.isSubmitting = true;

            const formData = new FormData();
            formData.append('name', this.name);
            formData.append('contact_email', this.contact_email);
            formData.append('contact_phone', this.contact_phone);
            formData.append('base_url', baseUrl)

            const rawDescription = this.$refs.quillSubmitEditorRef.getHTML();
            const plainDescription = rawDescription.replace(/<\/?[^>]+(>|$)/g, '');
            this.description = plainDescription.trim();

            formData.append('description', this.description);

            $.ajax({
                type: 'POST',
                url: `${process.env.VUE_APP_BACKEND_API_URL}/report_incident_management/submit_incident_report/`,
                data: formData,
                processData: false,
                contentType: false,
                headers: {
                'Authorization': `Token ${this.getToken()}`,
                },
                success: () => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Incident button created',
                        showConfirmButton: false,
                        timer: 1000
                    }).then(() => {
                        window.location.reload();
                    });
                    },
                complete: () => {
                this.isSubmitting = false;
                }
            });
            },

            handleFileUpload(event) {
                const file = event.target.files[0];
                if (file) {
                    // Check if the file type is an image
                    if (!file.type.startsWith('image/')) {
                        this.fileError = 'Only image files are allowed.';
                        this.upload_media = null;
                        this.fileName = '';
                        event.target.value = ''; // Clear the input value
                    } else {
                        this.fileName = file.name;
                        this.fileError = '';
                        this.upload_media = file;
                    }
                } else {
                    this.fileName = '';
                    this.fileError = '';
                    this.upload_media = null;
                }
            },

            clearErrors(){
                this.errors.incident_title = '';
                this.errors.incident_description = '';
                this.errors.location = '';
            },

            createIncidentReport(){
                this.clearErrors()
        
            const mapInput = document.getElementById('mapSearchBox');
            const baseUrl = `${window.location.origin}/view_incident_reports?incident_report_id=`;
            if (mapInput) {
                this.location = mapInput.value;
            }
                const formData = new FormData();
                formData.append('report_title', this.report_title);
        
                const rawDescription = this.$refs.quillReportEditorRef.getHTML();
                const plainDescription = rawDescription.replace(/<\/?[^>]+(>|$)/g, '');
                this.incident_description = plainDescription.trim();
        
                formData.append('description', this.incident_description);
                formData.append('submit_incident_id', this.incident_button);
                formData.append('location',this.location);
                formData.append('base_url',baseUrl)
                if (this.upload_media) {
                  formData.append('files', this.upload_media);
                }
        
                if (!this.report_title) {
                  this.errors.incident_title = 'Incident title is required';
                  return;
                }
        
                if (!this.incident_description || this.incident_description.trim() === '<p><br></p>') {
                    this.errors.incident_description = 'Incident description is required.';
                    return;
                }
                if (!this.location || this.location.trim() === '') {
                    this.errors.location = 'Incident location is required.';
                    return;
                }
        
                $.ajax({
                  type: "POST",
                  url: `${process.env.VUE_APP_BACKEND_API_URL}/report_incident_management/create_incident_report/`,
                  data: formData,
                  processData: false,
                  contentType: false,
                  headers: {
                    'Authorization': `Token ${this.getToken()}`,
                  },
                  beforeSend: function () {
                    Swal.fire({
                      text: 'Loading ...',
                      allowEscapeKey: false,
                      allowOutsideClick: false,
                      didOpen: () => {
                        Swal.showLoading()
                      }
                    });
                  },
                  success: (response) => {
                    Swal.close();
                    const status = response.status;
              
                    if (status === "success") {
                      Swal.fire({
                        icon: 'success',
                        title: 'Incident Report Created',
                        showConfirmButton: false,
                        timer: 2000,
                      }).then(() => {
                        window.location.reload();
                      });
                    }
                  },
                  error: (response) => {
                    Swal.close();
                    const response_data = response.responseJSON;
                    if (response_data.status === "error") {
                      this.errors.incident_title = response_data.message;
                    }
                  },
                  complete: () => {
                    this.isSubmitting = false;
                  }
                });
              },
            fetchIncidentButtons() {
                this.isLoading = true;
                const reportId = this.report_id;
                $.ajax({
                    url: `${process.env.VUE_APP_BACKEND_API_URL}/report_incident_management/get_submitted_incident_report/`,
                    method: 'GET',
                    headers: {
                    'Authorization': `Token ${this.getToken()}`,
                    'Content-Type': 'application/json'
                    },
                    data: { submit_incident_id: reportId },
                    success: (data) => {
                    var status =data.status
                    if(status === "success"){
                        this.reports = data.data;
                    }
                    },
                    complete: () => {
                    this.isLoading = false;
                    }
                });
                },

        openReport(report){

            $('#incidentReportModal').modal('show');
            this.incident_button=report.id
        }
        }
}
import { get_token, get_user_details} from "@/router";
import Loader from "@/components/Loader/LoaderView";


import Swal from 'sweetalert2';

const domain = window.location.hostname;
export default {

  components: {
    Loader,
  },
  computed: {
    isHomePage() {
      return this.$route.name === 'home'; // Assuming your home page route is named 'home'
    }
  },
    mounted() {
      this.path_route_name = this.$route.name;
      if(this.$route.name!=='home'){
        this.filterPortals();
      }
      if(this.$route.name === 'view_incidents'){
        this.incident_page=true
        this.payload={
          filter_values: {
            portal_type__name:'incident'
          }
        }
      }
      else{
        this.payload={}
      }
      if(this.$route.name === 'my_community') {
      this.filterPortals();
      }
      else {
        this.fetchPortals();
      }


      if(domain.includes('cbo')){
        this.cbo_domain=true
        
      }
      
      this.user_details = get_user_details();
      this.token_exists = get_token();
      this.user_id = this.get_user_id();
      this.fetch_Portal_admin();
    },

    data() {
      return ({
        portal_name: '',
        cbo_domain: false,
        your_portals:{},
        is_public: true,
        portals: [],
        payload:{},
        errors: {},
        isLoading: false,
        loading: true,
        token_exists: false,
        user_details: {
          "user_role": '',
          "user_id": null,
        },
        selectedPortal: null,
        requestedPortals: [],
        requested_users: [],
        path_route_name: '',
        showAll: false, 
        visibleCount: 4, 
        request_access:false,
        incident_page:false
      });
    },
    methods:{
        getToken() {
            return get_token();
        },
        get_user_id() {
          const user_id = localStorage.getItem('user_id') || '';
          return user_id;
      },
      toggleShowMore() {
        this.showAll = !this.showAll; // Toggle between showing all and limiting to visibleCount
      },
        createPortal(){
          if (!this.validatePortalForm()) {
            return;
          }

          $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_portal_api/`,
            method: 'POST',
            headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                portal_name: this.portal_name,
                is_public: this.is_public
            }),
            success: (data) => {
                if (data.status === "success") {
                  Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Portal created successfully.',
                    showConfirmButton: false,
                    timer: 1500
                  }).then(() => {
                    window.location.reload();
                    $('#create_portal').modal('hide');
                  })
                }
            },
            error: () => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to create portal.',
                    showConfirmButton: false,
                    timer: 1500
                  }).then(() => {
                    window.location.reload();
                    $('#create_portal').modal('hide');
                  })
            }

          })

        },
        validatePortalForm() {
          this.errors = {};
          let isValid = true;
          if (!this.portal_name.trim()) {
            this.errors.portal_name = 'Portal name is required.';
            isValid = false;
          }

          return isValid;
        },
        fetchPortals() {
            this.token_exists = this.getToken();
            $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_portals_api/`,
            method: 'POST',
            headers: this.token_exists
            ? {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
            }
            : {},
            data: JSON.stringify(this.payload),
            success: (data) => {
              if (data.portals) {
                this.portals = this.token_exists
                    ? data.portals
                    : data.portals.filter(portal => portal.is_public);
            } else {
                this.portals = [];
            }

            setTimeout(() => {
              this.loading = false;
          }, 1000);
            },
            complete: () => {
                this.isLoading = false;
            }
            });
        },
        handlePortalClick(portal) {
          if (!this.handle_unauthenticated()) {
            return;
          }
          this.fetch_Portal_admin();
          this.is_public = portal.is_public


          if (portal.has_access == "approved") {
            this.$emit('portal_profile', portal);
            this.open_portal(portal.id, portal.name, portal.is_public);

            // this.get_portal_access_requested_users();
            $("#request_access").modal('hide');
          }

          else if (portal.has_access == "pending") {
            $("#request_access").modal('hide');
            Swal.fire({
              icon: 'info',
              title: 'Info',
              text: 'Access request already submitted.',
              showConfirmButton: false,
              timer: 3000
            })
            return;
          }

          else {
            this.selectedPortal = portal;
            $("#request_access").modal('show');
          }

        },
        getProfileLink(member) {
          // Check if the user is an admin
          if (member.role.role === 'admin' || member.role.role === 'portal_admin') {
            // Return the link for admin profiles
            return `/profile?user_id=${member.id}`;
          } else if (member.role.role === 'customer') {
            // Return the link for customer profiles
            return `/customer_profile?user_id=${member.id}`;
          } else {
            // Return a default profile link for other roles
            return `/profile?user_id=${member.id}`;
          }
        },

        requestAccess() {
          const PORTAL_ID = this.selectedPortal ? this.selectedPortal.id : null;
          const portal_name = this.selectedPortal ? this.selectedPortal.name : null;
          const is_public = this.selectedPortal ? this.selectedPortal.is_public : true;
          const BASE_URL = `${window.location.origin}/view_portal/${PORTAL_ID}/${portal_name}/${is_public}`;

          this.request_access = true;
          $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/request_access_private_portal/`,
            method: 'POST',
            headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                portal_id: PORTAL_ID,
                base_url: BASE_URL,
            }),
            success: (data) => {
                if (data.status === "success") {
                  Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Access request submitted successfully.',
                    showConfirmButton: false,
                    timer: 3600
                  })


                  this.request_access = false;
                }
            },
            error: (response) => {
                Swal.fire({
                    icon: 'info',
                    title: 'Request Already Sent',
                    text: response.responseJSON.message,
                    showConfirmButton: false,
                    timer: 3000
                  })
            },
            complete: () => {
                this.isLoading = false;
                this.selectedPortal = null;
                $("#request_access").modal('hide');
            }
          });
        },
        handle_unauthenticated() {
            if (!this.token_exists) {
                this.$router.push({ name: 'sign_in_finale'});
              return false;
            }
            return true;
        },

        open_portal(portal_id,portal_name,is_public) {
          if (this.$route.name === 'view_incidents') {
            this.$router.push({ name: 'view_incidents', params: { portal_id: portal_id, portal_name: portal_name,} });
          } else {
            this.$router.push({ name: 'view_portal', params: { portal_id: portal_id, portal_name: portal_name, is_public: is_public } });
            this.filterReports(portal_id);
          }
        },

        filterReports(portal_id) {
          this.loading = true;
          const payload = {
            filter_values: {
              portal_id: portal_id
            }
          }
          $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/filter_reports_api/`,
            method: 'POST',
            headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(payload),
            success: (response) => {
              if(response.status === "success") {
                this.$emit('portal_selected', response.data.report_data);
              }

              setTimeout(() => {
                this.loading = false;
            }, 1000);

            },
            complete: () => {
                this.isLoading = false;
            }
          });
        },

        filterPortals() {
          const user_id=this.user_details.user_id
          $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/filter_portals_api/`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
            },
            data:{user_id:user_id},
            success: (response) => {
              if(response.status === "success") {
                this.your_portals = response.portals.map(portal => ({
                  ...portal, 
                  has_access: "approved"
              }));
                setTimeout(() => {
                  this.loading = false;
              }, 1000);
              }
            },
            complete: () => {
                this.isLoading = false;
            }
          });
        },
        fetch_Portal_admin() {
          const portal_id=this.$route.params.portal_id
          const payload = {
            filter_values: {
              portal_id: portal_id,
              user_id: this.user_id
            }
          };
          $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_plartform_admin_api/`,
            method: 'POST',
            headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
            },
            data: JSON.stringify(payload),
            success: () => {
              this.portal_admin=true
            },
            error: (xhr,) => {
              if (xhr.status === 404) {
                this.portal_admin=false
            } else if (xhr.status === 500) {
              this.portal_admin=false
            } else {
              this.portal_admin=false
            }

            },
            complete: () => {
              this.isLoading = false;
            }
          });
        },
    }
}
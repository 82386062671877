<div>
    <Sidebar ref="sidebar"></Sidebar>
    <section class="home-section">
        <Navbar @toggle-sidebar="toggleSidebar"></Navbar>

        <!-- Membership Management -->
        <div class="card bg-white shadow-sm mt-3 rounded-5 border-0">
            <div class="card-body">
                <div class="d-flex justify-content-between align-items-center"><label
                        class="text-sm text-secondary fw-bold">Membership Management</label>
                    <div class="d-flex gap-2">
                        <div class="form mx-2">
                            <input type="text" class="form-control form-input text-xs rounded-pill p-2 shadow"
                                placeholder="Search">
                        </div><button class="btn btn-sm btn-secondary text-xs rounded-pill" data-bs-toggle="modal"
                            data-bs-target="#create_membership"> Add Membership </button>
                    </div>
                </div>
                <hr class="text-primary">
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade active show" id="pills-subscriptions" role="tabpanel"
                        aria-labelledby="pills-subscriptions-tab">
                        <div class="card card-primary-outline mt-2">
                            <div v-for="(membership, index) in memberships" :key="membership.id"  class="card-body subscription-card">
                                <div class="d-flex justify-content-between mb-2">
                                    <div><label for="" class="mb-2 text-primary text-xl fw-bold">{{ membership.name }}</label>
                                        <p class="text-l m-0">{{ membership.description }}</p>
                                    </div>
                                    <div class="dropdown"><button
                                            class="btn btn-transparent shadow-none shadow dropdown-toggle" type="button"
                                            id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false"><i
                                                class="bi bi-three-dots-vertical"></i></button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="">
                                            <li><a class="dropdown-item">Edit</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <label for="" class="mb-2">
                                    <span class="fw-bold text-xl">
                                        {{ (membership.membership_pricing || []).length > 0 ? 'R' + parseFloat(membership.membership_pricing[0].price).toFixed(2) : 'N/A' }}
                                      </span>
                                    <span class="text-secondary text-s">/monthly</span>
                                </label>
                                <ul class="list-unstyled text-s">
                                    <li class="my-1"><i class="bi bi-check-circle text-primary"></i> Unlimited wofi</li>
                                </ul>
                                <div>
                                    <button class="btn btn-sm btn-secondary text-xs rounded" data-bs-toggle="modal" data-bs-target="#manage_perks_modal"> Manage Perks
                                    </button>
                                    <router-link :to="'view_membership/' + membership.id ">
                                        <button class="btn btn-sm btn-secondary text-xs mx-1 rounded">
                                            Create Form</button>
                                    </router-link>
                                    </div>
                                <div class="mt-2">
                                    <div class="card rounded-2 p-2 text-center">
                                        <div class="card-body">
                                            <div class="d-flex justify-content-center align-items-center"><i
                                                    class="bi bi-folder text-muted"></i></div><label
                                                class="text-xs text-muted">No promotions created</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal for Creating Membership -->
        <div class="modal fade" id="create_membership" tabindex="-1" aria-labelledby="createMembershipLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <p class="modal-title text-xs fw-500">Create Membership</p>
                        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <form @submit.prevent="createMembership">
                        <div class="modal-body">
                            <div class="mb-3">
                                <label for="membership_name" class="form-label text-xs fw-500">
                                    Membership Name <span class="text-danger">*</span>
                                </label>
                                <input type="text" class="form-control rounded-5 text-xs" id="membership_name"
                                    v-model="membership_name" placeholder="Enter membership name">
                                <p v-if="errors.membership_name" class="text-danger text-xs">{{ errors.membership_name
                                    }}</p>
                            </div>

                            <div class="mb-3">
                                <label for="membership_description"
                                    class="form-label text-xs fw-500">Description</label>
                                <textarea class="form-control rounded-5 text-xs" id="membership_description"
                                    v-model="membership_description" placeholder="Enter description"></textarea>
                            </div>

                            <div class="mb-3">
                                <label for="membership_type" class="form-label text-xs fw-500">
                                    Membership Type <span class="text-danger">*</span>
                                </label>
                                <select class="form-select rounded-5 text-xs" id="membership_type"
                                    v-model="membership_type">
                                    <option selected hidden>Select membership type</option>
                                    <option v-for="type in membership_types" :key="type.id" :value="type.id">
                                        {{ type.name }}
                                    </option>
                                </select>
                                <p v-if="errors.membership_type" class="text-danger text-xs">{{ errors.membership_type
                                    }}</p>
                            </div>

                            <!-- Membership Pricing -->
                            <div class="mb-3">
                                <label for="membership_price" class="form-label text-xs fw-500">
                                    Membership Price <span class="text-danger">*</span>
                                </label>
                                <input type="number" class="form-control rounded-5 text-xs" id="membership_price"
                                    v-model="membership_price" placeholder="Enter price" min="0">
                                <p v-if="errors.membership_price" class="text-danger text-xs">{{ errors.membership_price
                                    }}</p>
                            </div>

                            <div class="col-md-3 mb-3">
                                <label for="payment_date" class="form-label text-xs fw-500">Payment Date <span
                                    class="text-danger">*</span></label>
                                <input type="date" class="form-control rounded-5 text-xs" id="payment_date" v-model="payment_date">
                                <p v-if="errors.payment_date" class="text-danger text-xs">{{ errors.payment_date }}</p>
                              </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary text-xs rounded-5">Create Membership</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="manage_perks_modal" tabindex="-1" aria-labelledby="manage_perks_modal"
            style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="manage_perks_modal_label">Manage Perks</h5>
                        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body"><button class="btn btn-secondary mb-3 float-end btn-sm text-xs"
                            type="button"><i class="bi bi-plus"></i> Add Perk </button>
                        <div class="input-group mb-3"><label for="perkName" class="form-label">Perk Name:</label>
                            <div class="input-group"><input type="text" class="form-control"
                                    placeholder="Enter perk"><!----></div>
                        </div>
                    </div>
                    <div class="modal-footer"><button type="button" class="btn btn-primary btn-sm text-xs"> Save
                        </button></div>
                </div>
            </div>
        </div>

    </section>
</div>
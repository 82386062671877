import { get_token, get_user_details} from "@/router";
import HomeNavBar from "@/components/HomeNavBar/HomeNavBar.vue"
import AllPortals from "@/components/GetPortals/PortalsComponent.vue"
import FloatingButton from "@/components/FloatingButton/FloatingButtonComponent.vue"
import StickyNav from "@/components/BottomNavbar/StickyNavbar.vue"
import Loader from "@/components/Loader/LoaderView";
import Swal from 'sweetalert2';



export default {
  components: {
    HomeNavBar,
    AllPortals,
    FloatingButton,
    StickyNav,
    Loader

  },


  mounted() {

    this.token_exists = get_token();
    this.fetchIncidentReports();
    this.user_details=get_user_details();
  },

  data() {
    return ({
      token_exists: false,
      reports:[],
      isUpdating: false,
      edit_report_name: '',
      report_name: '',
      report_title: '',
      latitude: null,
      longitude: null,
      location:'',
      upload_media: null,
      user_details: {
        "user_id": '',
      },
    });
  },
  methods: {

    getToken() {
      return get_token();
  },
    addNewPortal(newPortal) {
      if (newPortal && newPortal.portal_type.name==='incident') {
        newPortal.has_access="approved"
        this.$refs.portals.portals.unshift(newPortal)
      }
    },
    newIncidentReport(newReport){
      this.reports.unshift(newReport)

    },

    fetchIncidentReports(){
          $.ajax({
          method: 'GET',
          url: `${process.env.VUE_APP_BACKEND_API_URL}/report_incident_management/retrieve_incident_report/`,
          headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
          },
          success: (response) => {
            
            this.reports = response.data
          },
          });
      

  },
  verifyReport(reportId, statusId) {
    this.submitAction(reportId, statusId, true);
    },

  declineReport(reportId, statusId) {
      this.submitAction(reportId, statusId, false);
  },
  
  submitAction(reportId, statusId, isVerified) {
    this.isSubmitting = true;
    const formData = new FormData();
    formData.append('report_id', reportId);
    formData.append('new_status_id', statusId);
    formData.append('is_verified', isVerified);
  
    $.ajax({
      type: "POST",
      url: `${process.env.VUE_APP_BACKEND_API_URL}/report_incident_management/update_incident_report_status/`,
      data: formData,
      processData: false,
      contentType: false,
      headers: {
        'Authorization': `Token ${this.getToken()}`,
      },
      success: (response) => {
        if (response.status === "success") {
          this.$emit('status_updated', response.data);
  
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.message,
            showConfirmButton: false,
            timer: 2000
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.message || 'An error occurred.',
            showConfirmButton: false,
            timer: 2000
          });
        }
      },
      error: (response) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.responseJSON.message || 'An error occurred.',
          showConfirmButton: false,
          timer: 2000
        });
      },
      complete: () => {
        this.isSubmitting = false;
      }
    });
  },

  edit_report(report){
    this.report_title=report.report_title
    this.location = report.location;
    const TextDescription = report.description.replace(/<[^>]*>/g, "");
    this.report_id=report.id
    this.edit_documents=report
    this.$nextTick(() => {
      if (this.$refs.quillReportRef) {
        const quillEditor = this.$refs.quillReportRef.getQuill();
        if (quillEditor) {
          quillEditor.setContents([
            { insert:TextDescription || "" },
          ]);
        }
      }
    });
  }, 
  edit_report_data() {
    Swal.fire({
      title: 'Submitting...',
      text: 'Please wait while we process your request.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.isSubmitting = true;
    const formData = new FormData();
    this.report_description  =this.$refs.quillReportRef.getText()
    formData.append('report_title', this.report_title);
    formData.append('description', this.report_description);
    formData.append('report_id', this.report_id);
    formData.append('location', this.location);
    if (this.upload_media) {
      formData.append('file', this.upload_media);
    }

    $.ajax({
      type: 'POST',
      url: `${process.env.VUE_APP_BACKEND_API_URL}/report_incident_management/update_report_api/`,
      data: formData,
      processData: false,
      contentType: false,
      headers: {
        'Authorization': `Token ${this.getToken()}`,
      },
      success: (response) => {
        if (response.status === 'success') {
          const updatedReport = response.updated_data;
          updatedReport.total_comments=updatedReport.total_comments||0
          const reportIndex = this.reports.findIndex(
            (hub) => hub.id === updatedReport.id
          );

          if (reportIndex !== -1) {
            this.reports.splice(reportIndex, 1, updatedReport);
          }
          Swal.fire({
            icon: 'success',
            title: response.message,
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            $('#edit_report_modal').modal('hide');
          });
        }
      },
      error: (response) => {
        Swal.close()
        const response_data = response.responseJSON;
        this.errors=response_data
      },
      complete: () => {
        this.isSubmitting = false;
      }
    });
  },
  deleteReport(report_id) {
        Swal.fire({
          title: 'Are you sure?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            $.ajax({
              type: "DELETE",
              url: `${process.env.VUE_APP_BACKEND_API_URL}/report_incident_management/delete_report_api/`,
              data: JSON.stringify({ report_id: report_id }),
              headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
              },
              success: (response) => {
                const status = response.status;
                if (status === "success") {
                  this.reports = this.reports.filter(report => report.id !== report_id);
                  Swal.fire({
                    title: 'Deleted!',
                    text: 'The feed has been deleted.',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                  })
                } else {
                  Swal.fire(
                    'Failed!',
                    response.message || 'An error occurred. Please try again.',
                    'error'
                  );
                }
              },
              error: () => {
                Swal.fire(
                  'Failed!',
                  'An error occurred. Please try again.',
                  'error'
                );
              }
            });
          }
        });
      },
},
};
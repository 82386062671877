import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';
import { get_token } from "@/router";

export default {
  components: {
    Sidebar,
    Navbar
  },

  methods: {
    getToken() {
      return get_token();
    },
      
    }
  };
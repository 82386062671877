import {get_token,get_user_details} from "@/router";
import Swal from 'sweetalert2';

export default {
  mounted() {
    this.fecthTopics();
    this.fetchPortals();
    this.token_exists = get_token();
    this.user_details=get_user_details();
  },
  props: {
    topics: {
      type: Array,
      required: true
    }},

    watch: {
      '$route.params.portal_id'() {
        this.fecthTopics();
        this.fetch_users();
      }
    },

  data() {
    return ({
      topics:[],
      portals: [],
      members: [],
      users:[],
      topic:'',
      topic_id:'',
      portal_id:'',
      is_public: true,
      isLoading: false,
      token_exists: false,
      topic_name: '',
      isSubmitting: false,
      errors: {},
      searchQuery: "",
      portal_creater:'',
      user_details: {
        "user_role": '',
        "first_name": '',
        "last_name": '',
        
      },
    });
  },


  methods: {
    getToken() {
      return get_token();
    },
    get_user_id() {
      const user_id = localStorage.getItem('user_id') || '';
      return user_id;
  },
  getProfileLink(member) {
    // Check if the user is an admin
    if (member.role.role === 'admin') {
      // Return the link for admin profiles
      return ``;
    } else if (member.role.role === 'customer') {
      // Return the link for customer profiles
      return `/customer_profile?user_id=${member.id}`;
    } else {
      // Return a default profile link for other roles
      return ``;
    }
  },
  

  handleTopicClick(topicId) {
    this.$emit('topic_selected', topicId); 
  },

    fecthTopics() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_topics_api`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: {portal_id: this.$route.params.portal_id},
        success: (response) => {
          var status = response.status
          if (status == "success") {
            this.topics = response.topics
          }

        },

        complete: () => {
          this.isLoading = false;
        }
      });
    },

    fetchPortals() {
      this.isLoading = true;
      this.portal_id=this.$route.params.portal_id
      $.ajax({
      url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_portals_api/`,
      method: 'POST',
      headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
      },
      success: (data) => {
          this.portals = data.portals;
          const visible=this.portals.find(portal_id => portal_id.id === parseInt(this.portal_id, 10));
          if (visible) {
            this.is_public = visible.is_public;
          }
      },
      complete: () => {
          this.isLoading = false;
      }
      });
  },

  fetch_users() {
    return new Promise((resolve) => {

      const portalId = this.$route.params.portal_id;
    $.ajax({
      type: 'GET',
      data:{portal_id:portalId},
      url: process.env.VUE_APP_BACKEND_API_URL + '/system_management/retrieve_users/',
      headers: {
        'Authorization': `Token ${this.getToken()}`,
      },
      success: (response) => {
        this.users = response.data.users_list;
        resolve();
      },
    });
  });
  },

    handleSubmit() {
      if (this.validateTopicForm()) { 
        this.isSubmitting = true;

        const requestData = {
          topic_name: this.topic_name,
          portal_id: this.$route.params.portal_id
        };
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_topic_api/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(requestData),
        success: (response) => {
          
          const status = response.status;
          
          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Segment Created Successfully',
              showConfirmButton: false,
              timer: 1000
            }).then(() => {
              window.location.reload();
              $('#create_topic').modal('hide');
            });
          } else if (status === "error") {
            Swal.fire({
              icon: 'error',
              title: 'Creation Failed',
              text: response.message || 'An error occurred. Please try again.',
              confirmButtonText: 'Retry'
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Unexpected Response',
              text: 'The server returned an unexpected response.',
              confirmButtonText: 'Okay'
            });
          }
        },
        error: (response) => {
          const response_data = response.responseJSON;
          if(response_data.status=="error"){
           this.errors.topic_name=response_data.message
          }
      },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    }
   },


  validateTopicForm() {
    this.errors = {};
    let isValid = true;
    if (!this.topic_name.trim()) {
      this.errors.topic_name = 'Segment name is required.';
      isValid = false;
    }
    return isValid;
  },
  },
};

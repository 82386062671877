import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';
import { get_token } from "@/router";
import Swal from 'sweetalert2';

export default {
  components: {
    Sidebar,
    Navbar
  },
  data() {
    return ({
      groups: [],
      group_id: '',
      group_name: '',
      description: '',
      edit_group: '',
      edit_description: '',
      is_public: true,
      token_exists: false,
      errors: {},
      selectedVisibility: 'public',
    });
  },
  mounted() {
    this.token_exists = get_token(); 
    this.fetchGroups().then(() => {
      this.$nextTick(() => {
        $('.table').DataTable({
          paging: true,
          searching: true,
          ordering: true,
          info: true,
          lengthChange: true,
          pageLength: 10,
          language: {
            paginate: {
              previous: "Previous",
              next: "Next"
            },
            info: "Showing _START_ to _END_ of _TOTAL_ entries",
            infoEmpty: "No entries available"
          },
          sortColumn: '',
          sortDirection: 'asc'
        });
      });
    });
  },
  computed: {
    isPublic() {
      return this.selectedVisibility === 'public';
    },
  },
  methods: {
    getToken() {
      return get_token();
    },
    toggleSidebar() {
      this.$refs.sidebar.$el.classList.toggle("close");
    },
    handle_unauthenticated() {
      if (!this.token_exists) {
        this.$router.push({ name: 'sign_in_finale' });
        return false;
      }
      return true;
    },
    validationGroupForm() {
      this.errors = {};
      let valid = true;

      if (!this.group_name) {
        this.errors.group_name = 'Group name is required';
        valid = false;
      }

      return valid;
    },
    createGroup() {
      if (!this.validationGroupForm()) {
        return;
      }
      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append('name', this.group_name);
      this.description = this.$refs.quillGroupEditorRef.getHTML()
      formData.append('description', this.description);
      formData.append('is_public', this.isPublic);

      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/create_group/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            title: 'Group Created',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            this.groups.unshift(response.group);
            $('#create_group').modal('hide');
          });
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;

          if (response_data.status === "error") {

            this.errors.group_name = response_data.message
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },

    fetchGroups() {
      this.isLoading = true;
      return new Promise((resolve) => {
        $.ajax({
          url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_all_group/`,
          method: 'GET',
          headers: {
            'Authorization': `Token ${this.getToken()}`,
            'Content-Type': 'application/json'
          },
          success: (data) => {
            this.groups = data.groups;
            resolve();
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      });
    },

    deleteGroup(groupId) {
      Swal.fire({
        title: 'Are you sure?',
        text: "This action cannot be undone!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          try {
            const response = await fetch(`${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_group/`, {
              method: 'DELETE',
              headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ group_id: groupId })
            });
            if (response.ok) {
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Group has been deleted.',
                showConfirmButton: false,
                timer: 1000
              }).then(() => {
                this.groups = this.groups.filter(group => group.id !== groupId);
              });
            } else {
              throw new Error();
            }
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'An error occurred while deleting the Group. Please try again!'
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
    editGroupName(group) {

      this.edit_group = group.name
      this.edit_description = group.description;
      this.is_public = group.is_public
    },
    editGroup(groupId) {

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append('name', this.edit_group);
      this.description = this.$refs.quillGroupEditorRef.getHTML()
      formData.append('description', this.description);
      formData.append('is_public', this.isPublic);
      formData.append('group_id', groupId);

      $.ajax({
        type: 'PUT',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/update_group/`,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        processData: false,
        contentType: false,
        data: formData,
        success: (response) => {
          const status = response.status;
          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Updated',
              text: 'Group successfully updated!',
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              this.groups.forEach(group => {
                if (group.id === groupId) {
                  group.name = this.edit_group;
                  group.description = this.description;
                  group.is_public = this.selectedVisibility;
                }
              });
              $(`#edit_group_${groupId}`).modal('hide');
            });
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },

    openEditModal(group) {
      this.edit_group = group.name;
      this.edit_description = group.description;
      this.selectedVisibility = group.is_publi;

      $('#edit_group_' + group.id).modal('show');    }
  }
};


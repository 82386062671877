
<Loader :loading="loading" />

<div class="mt-4 border-1 d-none d-xl-block" v-if="!isHomePage">
  <div v-if="!incident_page && user_details.user_role !== 'admin'"class="mx-3 rounded-5 p-2 pb-5 w-100 "> 
    <h6 v-if="cbo_domain"class='text-start mx-1'>Your Memberships</h6>
    <h6 v-else class='text-start mx-1 mt-2 card-header bg-transparent w-75'>Your Portals</h6>
    <div class="table-container mt-3 w-100" :class="{'table-container-public': is_public, 'table-container-private': !is_public}">
      <table v-if="your_portals.length" class="table mb-0">
        <tbody>
          <tr v-for="(portal, index) in your_portals" :key="portal.id" class="hover-row border-0" v-show="index < visibleCount || showAll">
            <td class="border-0">
              <span class="text-decoration-none text-dark" @click="handlePortalClick(portal)">
                <p class="portal-icon mb-0 d-flex align-items-center">
                  <a class="rounded-circle bg-yellow p-1 shadow-sm text-change">
                    <i class='bx bx-command fs-5'></i>
                  </a>
                  <span class="text-xs fw-500 mx-2">
                    {{ portal.name }}
                    <i v-if="!portal.is_public" class='bx bxs-lock text-primary text-xxs'></i>
                  </span>
                </p>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else class="text-secondary text-xs text-center">
        No Available Portals
      </p>
      <div v-if="your_portals.length > visibleCount" class=" mt-2">
        <button class="btn btn-sm btn-transparent text-secondary" @click="toggleShowMore">
         
          <!-- Show down arrow when "See Less" is visible (i.e., showAll = true) -->
          <i v-if="showAll" class="bx bxs-up-arrow-circle ms-2"></i> 
          <!-- Show up arrow when "See More" is visible (i.e., showAll = false) -->
          <i v-if="!showAll" class="bx bxs-down-arrow-circle ms-2"></i>

          {{ showAll ? 'See Less' : 'See More' }}
        </button>
      </div>

     
    </div>
  </div>
  <div class="mx-3 rounded-5 w-100  ">
    <div class="d-flex justify-content-between">
    <h6 v-if="cbo_domain"class='text-start mx-1 mt-2'>Memberships</h6>
    <h6 v-else class='text-start mx-1 card-header bg-transparent w-75'> All Portals</h6>
  </div>  
    <div class=" mt-3 maximum-height overflow-auto" :class="{'table-container-public': is_public, 'table-container-private': !is_public}">
      <table v-if="portals.length" class="table mb-0">
        <tbody>
          <tr v-for="(portal, index) in portals" :key="portal.id" class="hover-row border-0" v-show="index < visibleCount || showAll">
            <td class="border-0">
              <span class="text-decoration-none text-dark" @click="handlePortalClick(portal)">
                <p class="portal-icon mb-0 d-flex align-items-center">
                  <a class="rounded-circle bg-yellow p-1 shadow-sm text-change">
                    <i class='bx bx-command fs-5'></i>
                  </a>
                  <span class="text-xs fw-500 mx-2">
                    {{ portal.name }}
                    <i v-if="!portal.is_public" class='bx bxs-lock text-primary text-xxs'></i>
                  </span>
                </p>
              </span>
            </td>
          </tr>
        </tbody>
      </table>  
      <p v-else class="text-secondary text-xs text-center">
        No Available Portals
      </p>
      <div v-if="portals.length > visibleCount" class="justify-content-end">
        <button class="btn btn-sm btn-transparent text-secondary" @click="toggleShowMore">
         
          <!-- Show down arrow when "See Less" is visible (i.e., showAll = true) -->
          <i v-if="showAll" class="bx bxs-up-arrow-circle ms-2"></i> 
          <!-- Show up arrow when "See More" is visible (i.e., showAll = false) -->
          <i v-if="!showAll" class="bx bxs-down-arrow-circle ms-2"></i>
  
          {{ showAll ? 'See Less' : 'See all' }}
        </button>
      </div>
      

    </div>
  </div>

  
</div>

<div class="modal fade" id="request_access" tabindex="-1" aria-labelledby="request_accessModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content rounded-5">
      <div class="modal-header">
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body d-flex justify-content-center">
        <p class="text-md fw-500">This is a private portal. Would you like to request access?</p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button class="btn btn-primary text-xs rounded-5 shadow" v-if="request_access">
          <div class="spinner-border text-light spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
        <button @click="requestAccess" v-else class="btn btn-primary text-xs rounded-5 shadow">Request Access</button>
         
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="create_portal" tabindex="-1" aria-labelledby="create_portalModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content rounded-5">
      <form @submit.prevent="createPortal">
        <div class="modal-body">
          <h6 class="modal-title mb-3" id="create_portalModalLabel">Create Portal</h6>

          <div class="mb-3">
            <label for="portal_name" class="form-label text-xs fw-500">Portal name <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="portal_name" placeholder="Enter portal name"
              v-model="portal_name" required>
            <p v-if="errors.portal_name" class="text-danger text-xs">{{ errors.portal_name }}</p>
          </div>
          <div class="mb-3">
            <label for="is_public" class="form-label text-xs fw-500">Visibility<span
                class="text-danger">*</span></label>
            <select class="form-select rounded-5 text-xs" id="is_public" v-model="is_public" required>
              <option selected hidden>Select visibility type</option>
              <option value="false">Private</option>
              <option value="true">Public</option>
            </select>
          </div>
          <div class="float-end mb-3">
            <button type="button" class="btn btn-primary text-xs rounded-5 shadow mx-1"
              data-bs-dismiss="modal">Cancel</button>
            <button type="submit" class="btn btn-primary text-xs rounded-5 shadow">Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="offcanvas offcanvas-start" tabindex="-1" id="requested_members" aria-labelledby="portal_members_label">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="portal_label"></h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
      <h6 class='text-start mx-1 d-flex align-items-center'>Portal Request Members
        <i
            class="bx bx-plus-circle fs-5 text-primary ms-2 cursor-pointer"
            data-bs-toggle="modal"
          data-bs-target="#add_portal_member_modal"
            title="Add Member" v-if="user_details.user_role === 'admin' || portal_admin">
          </i>
          <i
            class="bx bx-trash fs-5 text-danger ms-2 cursor-pointer"
            data-bs-toggle="modal"
          data-bs-target="#delete_portal_member_modal"
            title="Delete Member" v-if="user_details.user_role === 'admin' || portal_admin">
          </i>
        </h6>

      <div class="table-container ">
        <table v-if="requested_users.length" class="table mb-0">
          <tbody>
            <tr v-for="member in requested_users" :key="member.id" class="hover-row border-0">
              <td class="border-0">
                <span class="text-decoration-none text-dark">
                  <p class="portal-icon mb-0 d-flex align-items-center">
                    <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple">
                      <i class='bx bx-user fs-5'></i>
                    </a>
                    <span class="text-xs fw-500 mx-2 color-blue">{{ member.first_name }} {{ member.last_name }}</span>
                  </p>
                </span>
              </td>
              <td class="border-0 text-end">
                <button @click="approve_portal_access_request(member.id)" class="btn p-0 m-0 transparent" title="Admit User">
                  <i class="bi bi-check-circle fs-2 text-success"></i>
                </button>
                <button @click="declineRequest(member.id)" class="btn p-0 m-0 transparent ms-1" title="Decline User">
                  <i class="bi bi-x-circle fs-2 text-danger"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else class="text-secondary text-xs text-center">
          No Portal members Available
        </p>
      </div>
  </div>
</div>
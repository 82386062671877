<div class="sidebar shadow-sm d-flex flex-column justify-content-between">
  <div>
    <div class="logo-details">
      <img :src="$clientLogo" class="img-fluid mx-auto d-block mt-2 w-70px" alt="">
    </div>
    <ul class="nav-links">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
        aria-expanded="true" aria-controls="collapseUtilities">
        <span class="text-primary text-xs fw-500">Dashboard</span>
      </a>
      <li>
        <a href="/dashboard">
          <i class='bx bx-grid-alt'></i>
          <span class="link-name text-xs">Dashboard</span>
        </a>
      </li>
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
        aria-expanded="true" aria-controls="collapseUtilities">
        <span v-if="cbo_domain" class="text-primary text-xs fw-500">Manage Membership</span>
        <span v-else class="text-primary text-xs fw-500">Manage Portals</span>
      </a>
      <li>
        <div class="icon-link">
          <a href="/create_portal">
            <i class='bx bx-collection'></i>
            <span v-if="cbo_domain"class="link-name">Memberships</span>
            <span v-else class="link-name">Portals</span>
          </a> 
          <a v-if="workbook_domain || stagging_domain" href="/manage_roles">
            <i class="bi bi-person-gear"></i>
            <span class="link-name">Manage roles</span>
          </a>
        </div>
      </li>

      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
      aria-expanded="true" aria-controls="collapseUtilities">
      <span class="text-primary text-xs fw-500">Manage Event</span>
    </a>
    <li>
      <div class="icon-link">
        <a href="/get_rsvps">
          <i class='bx bx-user-check'></i>
          <span class="link-name">RSVP</span>
        </a>
      </div>
    </li>
      <a v-if="!cbo_domain" class="d-none nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
        aria-expanded="true" aria-controls="collapseUtilities">
        <span class="text-primary text-xs fw-500">Manage External Reports</span>
      </a>
      <li>
        <div v-if="!cbo_domain" class="d-none icon-link">
          <a href="/create_external_report">
            <i class='bx bx-book-alt'></i>
            <span class="link-name">External Reports</span>
          </a>
        </div>
      </li>
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
        aria-expanded="true" aria-controls="collapseUtilities">
        <span class="text-primary text-xs fw-500">Manage Profiles</span>
      </a>
      <li>
        <div class="icon-link">
          <a href="/profile">
            <i class='bx bx-book-alt'></i>
            <span class="link-name">Profile</span>
          </a>
        </div>
      </li>
      <a v-if="!cbo_domain"class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
        aria-expanded="true" aria-controls="collapseUtilities">
        <span class="text-primary text-xs fw-500">Group management</span>
      </a>
      <li>
        <div v-if="!cbo_domain" class="icon-link">
          <a href="/create_group">
            <i class='bx bx-group'></i>
            <span class="link-name">Groups</span>
          </a>
        </div>
      </li>
      <a v-if="!cbo_domain" class="d-none collapsed" href="#" data-toggle="collapse" data-target="#collapseAssets"
      aria-expanded="true" aria-controls="collapseAssets">
      <span class="text-primary text-xs fw-500">Manage Community Assets</span>
    </a>
    <li>
      <div v-if="!cbo_domain" class="d-none icon-link">
        <a href="/create_asset">
          <i class='bx bx-cube'></i>
          <span class="link-name">Community Assets</span>
        </a>
      </div>
    </li>
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
        aria-expanded="true" aria-controls="collapseUtilities">
        <span class="text-primary text-xs fw-500">Manage Users</span>
      </a>
      <li>
        <div class="icon-link">
          <a href="/create_user">
            <i class='bx bx-plug'></i>
            <span class="link-name">User Management</span>
          </a>
        </div>
      </li>
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
        aria-expanded="true" aria-controls="collapseUtilities">
        <span class="text-primary text-xs fw-500">Manage Membership</span>
      </a>
      <li>
        <div class="icon-link">
          <a href="/create_membership">
            <i class="bi bi-ticket"></i>
            <span class="link-name">Membership Management</span>
          </a>
        </div>
      </li>
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
        aria-expanded="true" aria-controls="collapseUtilities">
        <span class="text-primary text-xs fw-500">Manage Questions</span>
      </a>
      <li>
        <div class="icon-link">
          <a href="/create_section">
            <i class="bi bi-ticket"></i>
            <span class="link-name">Question Management</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
  <div class="d-flex justify-content-center mb-3">
    <button @click="log_out" class="btn btn-primary shadow text-xs fw-500 fw-bold rounded-5 mb-5 mx-auto">
      <i class='bx bx-cog'></i>
      <span class="link-name">Logout</span>
    </button>
  </div>
</div>


<div class="dropdown">
  <button 
    class="dropdown-toggle bg-transparent border-0 text-dark" 
    type="button" 
    id="dropdownMenuButton1" 
    data-bs-toggle="dropdown" 
    aria-expanded="false"
    data-bs-auto-close="outside"
  >
  <i class='bx bx-plus fs-3 text-dark'></i>
  </button>

  <!-- Dropdown Menu -->
  <ul class="dropdown-menu dropdown-menu-end rounded-5 shadow border-0" aria-labelledby="dropdownMenuButton1">
    <li v-if="!isGroupPage && !isIncidentPage && !isEventsPage && !isCommunityAssetPage">
      <a class="dropdown-item border-bottom" href="#" data-bs-toggle="modal" data-bs-target="#feed_modal">
        <i class='bx bxs-report fs-6'></i>Feed
      </a>
    </li>
    <li v-if="!isGroupPage && !isIncidentPage && !isEventsPage && !isCommunityAssetPage">
      <a class="dropdown-item border-bottom" href="#" data-bs-toggle="modal" data-bs-target="#voice_modal">
        <i class='bx bxs-message-dots fs-6'></i>Voice
      </a>
    </li>
    <li v-if="!isPortalPage && !isGroupPage && !isIncidentPage && !isHomePage && !isHomeFilter && !isEventsPage && !isViewPortalPage || isCommunityAssetPage">
      <a class="dropdown-item border-bottom " href="#" data-bs-toggle="modal" data-bs-target="#create_community_asset_modal">
        <i class='bx bxs-message-dots fs-6'></i>Community Asset
      </a>
    </li>
    <li v-if="!isHomePage && !isPortalPage && !isViewPortalPage && !isIncidentPage && !isEventsPage && !isCommunityAssetPage">
      <a class="dropdown-item border-bottom" href="#" data-bs-toggle="modal" data-bs-target="#group_feed_modal">
        <i class='bx bxs-message-dots fs-6'></i>Group Feed
      </a>
    </li>
    <li v-if="!isHomePage && !isPortalPage && !isViewPortalPage && !isIncidentPage && !isEventsPage && !isCommunityAssetPage">
      <a class="dropdown-item border-bottom" href="#" data-bs-toggle="modal" data-bs-target="#group_voice_modal">
        <i class='bx bxs-message-dots fs-6'></i>Group Voice
      </a>
    </li>
    <li v-if="!isPortalPage && !isViewPortalPage && !isIncidentPage && !isHomePage && !isHomeFilter && !isEventsPage && !isCommunityAssetPage">
      <a class="dropdown-item border-bottom" href="#" data-bs-toggle="modal" data-bs-target="#group_modal">
        <i class='bx bxs-message-dots fs-6'></i>Group
      </a>
    </li>
    <li v-if="!isGroupPage && !isEventsPage && user_details.user_role !== 'customer' && !isCommunityAssetPage" >
      <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#portal_modal">
        <i class='bx bxs-message-dots fs-6'></i>Portal
      </a>
    </li>
    <li v-if="!isHomePage && !isEventsPage && !isIncidentPage && !isGroupPage && user_details.user_role !== 'customer' && !isCommunityAssetPage">
      <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#segment_modal">
        <i class='bx bxs-message-dots fs-6'></i>Segment
      </a>
    </li>
    <li v-if="!isHomePage && !isEventsPage && !isIncidentPage && !isGroupPage && !isCommunityAssetPage">
      <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#document_approval_modal">
        <i class='bx bxs-message-dots fs-6'></i>Document/Media Approval
      </a>
    </li>
    <li v-if="!isHomePage && !isEventsPage && !isIncidentPage && isViewPortalPage && !isPortalPage && created_by_id == user_details.user_id && !isCommunityAssetPage">
      <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#group_member_modal">
        <i class='bx bxs-message-dots fs-6'></i>Group Member
      </a>
    </li>
    <li v-if="!isHomePage && !isEventsPage && !isPortalPage && !isViewPortalPage && !isCommunityAssetPage">
      <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#incidentReportModal">
        <i class='bx bxs-message-dots fs-6'></i>Incident
      </a>
    </li>
    <li v-if="!isHomePage && !isHomeFilter && !isPortalPage && !isViewPortalPage && !isCommunityAssetPage">
      <a v-if="user_details.user_role === 'admin' || !cbo_domain" class="dropdown-item" data-bs-toggle="modal"
           data-bs-target="#event_modal">Add event 
      </a>
    </li>
  </ul>
</div>

  
<!-- Group feed modal -->
<div class="modal fade" id="group_feed_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create your feed</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="create_group_feed">
        <div class="modal-body">

          <div class="mb-3">
            <label for="report_name" class="form-label text-xs fw-500">Feed Name <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="group_report_name" placeholder="Enter feed name"
              v-model="group_report_name">
              <p v-if="errors.report_name" class="text-danger text-xs">{{ errors.report_name}}</p>
          </div>
          <QuillEditor theme="snow" v-model="feed_report_description" ref="quillFeedReportEditorRef" class="quill-editor"
            placeholder="Enter your description here..." toolbar="full" />
            <div class="d-flex mt-2 mx-1 justify-content-between align-items-start">
              <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-center">
                <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                  <i class='bx bx-image-add file-upload-icon'></i> Add feed content
                </label>
                <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
                <div v-if="fileName" class="text-xs text-secondary fst-italic mt-1">
                  <span class="fw-500">File name:</span> {{ fileName }}
                </div>
                <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>

                <div v-if="imagePreview" class="mt-2">
                  <img :src="imagePreview" alt="Preview" class="img-fluid rounded" style="max-width: 200px; max-height: 200px;" />
                </div>
              </div>
            
              <div class="file-upload ms-5 my-2 mx-3 flex-grow-1 d-flex flex-column align-items-center">
                <label for="audioInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                  <i class='bx bx-music'></i> Upload Audio
                </label>
                <input type="file" accept="audio/*" id="audioInput" class="file-input d-none" @change="handleAudioUpload" />
                <div v-if="audioError" class="text-xs text-danger fst-italic mt-1">{{ audioError }}</div>
              </div>
            
              <div class="d-flex flex-column align-items-center my-2 flex-grow-1">
                <div class="d-flex flex-row align-items-center mb-3">
                  <button type="button" class="btn btn-primary text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center" @click="startRecording" v-if="!isRecording">
                    <i class='bx bx-microphone'></i> Record
                  </button>
                  <button type="button" class="btn btn-danger text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center" @click="stopRecording" v-if="isRecording">
                    <i class='bx bx-stop'></i> Stop
                  </button>
                </div>
                <div v-if="audio_file_name" class="text-xs text-secondary fw-500 mb-3 audio-file">
                  <i class='bx bx-music'></i> Recorded: {{ audio_file_name }}
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3">
              <label for="toggle-comments" class="form-label mb-0 me-2 text-xs fw-500">Allow comments:</label>
              <div class="form-check form-switch ">
                <input class="form-check-input btn-secondary" type="checkbox" id="toggle-comments" v-model="comments_off">
                <label class="form-check-label" for="toggle-comments">{{ comments_off ? 'Yes' : 'No' }}</label>
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Group Voice modal -->
<div class="modal fade" id="group_voice_modal" tabindex="-1" aria-labelledby="groupVoiceModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="groupVoiceModalLabel">Create a Group Voice</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="create_group_voice">
        <div class="modal-body">
          <div class="mb-3">
            <label for="voice_name" class="form-label text-xs fw-500">Voice Name <span class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="voice_name" placeholder="Enter voice name"
              v-model="group_voice_name">
              <p v-if="errors.voice_name" class="text-danger text-xs">{{ errors.voice_name }}</p>
          </div>
          <div class="mb-3">
            <label for="voice_description" class="form-label text-xs fw-500">Description <span class="text-danger">*</span></label>
            <QuillEditor theme="snow" v-model="group_voice_description" ref="quillVoiceDescriptionRef" class="quill-editor"
              placeholder="Enter your description here..." toolbar="full" />
              <p v-if="errors.voice_description" class="text-danger text-xs">{{ errors.voice_description }}</p>
              <div class="d-flex mt-2 mx-1 justify-content-between align-items-start">
                <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-center">
                  <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                    <i class='bx bx-image-add file-upload-icon'></i> Add voice content
                  </label>
                  <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
                  <div v-if="fileName" class="text-xs text-secondary fst-italic mt-1">
                    <span class="fw-500">File name:</span> {{ fileName }}
                  </div>
                  <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>

                  <div v-if="imagePreview" class="mt-2">
                    <img :src="imagePreview" alt="Preview" class="img-fluid rounded" style="max-width: 200px; max-height: 200px;" />
                  </div>
                </div>
              
                <div class="file-upload ms-5 my-2 mx-3 flex-grow-1 d-flex flex-column align-items-center">
                  <label for="audioInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                    <i class='bx bx-music'></i> Upload Audio
                  </label>
                  <input type="file" accept="audio/*" id="audioInput" class="file-input d-none" @change="handleAudioUpload" />
                  <div v-if="audioError" class="text-xs text-danger fst-italic mt-1">{{ audioError }}</div>
                </div>
              
                <div class="d-flex flex-column align-items-center my-2 flex-grow-1">
                  <div class="d-flex flex-row align-items-center mb-3">
                    <button type="button" class="btn btn-primary text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center" @click="startRecording" v-if="!isRecording">
                      <i class='bx bx-microphone'></i> Record
                    </button>
                    <button type="button" class="btn btn-danger text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center" @click="stopRecording" v-if="isRecording">
                      <i class='bx bx-stop'></i> Stop
                    </button>
                  </div>
                  <div v-if="audio_file_name" class="text-xs text-secondary fw-500 mb-3 audio-file">
                    <i class='bx bx-music'></i> Recorded: {{ audio_file_name }}
                  </div>
                </div>
              </div>
            </div>  
            <div class="d-flex align-items-center mb-3">
              <label for="toggle-comments" class="form-label mb-0 me-2 text-xs fw-500">Allow comments:</label>
              <div class="form-check form-switch ">
                <input class="form-check-input btn-secondary" type="checkbox" id="toggle-comments" v-model="comments_off">
                <label class="form-check-label" for="toggle-comments">{{ comments_off ? 'Yes' : 'No' }}</label>
              </div>
            </div>
        </div>

        <!-- Modal Footer -->
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Feed Modal -->
<div class="modal fade" id="feed_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-fullscreen-md-down modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create your feed</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="submit_form">
        <div class="modal-body">

          <div class="d-flex flex-row justify-content-between mx-1">
            <div v-if="$route.name !== 'view_portal'">
              <label for="portals" class="form-label text-xs fw-500"><span class="text-danger">*</span>Tag a portal :</label>
              <select class="text-xs btn-primary fw-500 border-0 rounded-5 btn-sm mb-3 border-end"
                v-model="selectedPortalId" @change="onPortalChange" id="portals">
                <option value="" selected hidden>
                  * Tag a portal
                </option>
                <option v-for="portal in portals" :value="portal.id" :key="portal.id">{{ portal.name }}</option>
              </select>
              <p v-if="errors.portal" class="text-danger text-xs">{{ errors.portal }}</p>
            </div>


            <div v-if="topics.length > 0">
              <label for="segment" class="form-label text-xs fw-500">Tag a segment :</label>
              <select class="text-xs btn-primary fw-500 border-0 rounded-5 btn-sm mb-3 border-end "
                v-model="selectedTopicId" id="topic">
                <option value="" selected hidden>Tag a segment</option>
                <option v-for="topic in topics" :value="topic.id" :key="topic.id">
                  {{ topic.name }}
                </option>
              </select>
            </div>
          </div>
          <div id="quillEditor" ref="quillReportEditorRef"></div>
            <div v-if="showSuggestions && filteredUsers.length > 0" class="suggestions-dropdown modal-body-scroll">
              <div v-for="user in filteredUsers" 
                   :key="user.id" 
                   class="suggestion-item"
                   @mousedown="selectUser(user)">
                {{ user.first_name }} {{ user.last_name }}
              </div>
            </div>

            <div v-if="errors.report_description" class="error-message">
              {{ errors.report_description }}
            </div>
            <div class="d-flex mt-2 mx-1 justify-content-between align-items-start border-bottom">
              <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-center">
                <label for="fileInput" class="text-xs text-white fw-500 border-0 rounded-3 shadow  mb-3 bg-primary p-2 d-flex align-items-center justify-content-center">
                  <i class='bx bx-image-add file-upload-icon'></i>
                </label>
                <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
                <div v-if="fileName" class="text-xs text-secondary fst-italic mt-1">
                  <span class="fw-500">File name:</span> {{ fileName }}
                </div>
                <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>

                <!-- Image preview -->

                <div v-if="imagePreview" class="mt-2">
                  <img :src="imagePreview" alt="Preview" class="img-fluid rounded" style="max-width: 200px; max-height: 200px;" />
                </div>
              </div>
            
              <div class="file-upload ms-5 my-2 mx-3 flex-grow-1 d-flex flex-column align-items-center">
                <label for="audioInput" class="text-xs text-white shadow p-2 fw-500 border-0 rounded-3  mb-3 bg-primary d-flex align-items-center justify-content-center">
                  <i class='bx bx-music'></i>
                </label>
                <input type="file" accept="audio/*" id="audioInput" class="file-input d-none" @change="handleAudioUpload" />
                <div v-if="audioError" class="text-xs text-danger fst-italic mt-1">{{ audioError }}</div>
              </div>
            
              <div class="d-flex flex-column align-items-center my-2 flex-grow-1">
                <div class="d-flex flex-row align-items-center mb-3">
                  <button type="button" class=" border-0 text-white p-2 bg-primary shadow text-xs rounded-3 d-flex align-items-center justify-content-center" @click="startRecording" v-if="!isRecording">
                    <i class='bx bx-microphone'></i>
                  </button>
                  <button type="button" class="text-xs bg-danger p-2 text-white shadow border rounded-3 d-flex align-items-center justify-content-center" @click="stopRecording" v-if="isRecording">
                    <i class='bx bx-stop'></i> 
                  </button>
                </div>
                <div v-if="audio_file_name" class="text-xs text-secondary fw-500 mb-3 audio-file">
                  <i class='bx bx-music'></i> Recorded: {{ audio_file_name }}
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3">
              <label for="toggle-comments" class="form-label mb-0 me-2 text-xs fw-500">Allow comments:</label>
              <div class="form-check form-switch ">
                <input class="form-check-input btn-secondary" type="checkbox" id="toggle-comments" v-model="comments_off">
                <label class="form-check-label" for="toggle-comments">{{ comments_off ? 'Yes' : 'No' }}</label>
              </div>
            </div> 
        </div>
        <div class="modal-footer">
          <p v-if="errors.report_details" class="text-danger text-xs">{{ errors.report_details }}</p>
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Voice Modal -->
<div class="modal fade" id="voice_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Create your voice</h5>
        <button type="button" class="btn-close  text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="createHub">
        <div class="modal-body">

          <div class="mb-3">
            <label for="name" class="form-label text-xs fw-500">Voice Name <span class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="name" placeholder="Enter voice name"
              v-model="name">
              <p v-if="errors.name" class="text-danger text-xs">{{ errors.name }}</p>
          </div>
          <div class="d-flex flex-row justify-content-between mx-1">
            <div>
              <label for="portals" class="form-label text-xs fw-500"><span class="text-danger">*</span>Tag a portal :
              </label>
              <select class="text-xs btn-primary fw-500 border-0 rounded-5 btn-sm mb-3 border-end"
                v-model="selectedPortalId" @change="onPortalChange" id="portals">
                <option value="" selected hidden>
                  * Tag a portal
                </option>
                <option v-for="portal in portals" :value="portal.id" :key="portal.id">{{portal.name }}</option>
              </select>
              <p v-if="errors.portal" class="text-danger text-xs">{{ errors.portal }}</p>
            </div>

            <div>
              <label for="segment" class="form-label text-xs fw-500"><span class="text-danger">*</span>Tag a segment :
              </label>
              <select class="text-xs btn-primary fw-500 border-0 rounded-5 btn-sm mb-3 border-end "
                v-if="topics.length > 0" v-model="selectedTopicId" id="topic">
                <option value="" selected hidden>
                  <span class="text-danger">*</span> Tag a segment
                </option>
                <option v-for="topic in topics" :value="topic.id" :key="topic.id">
                  {{ topic.name }}
                </option>
              </select>
              <p v-if="errors.segment" class="text-danger text-xs">{{ errors.segment }}</p>
            </div>

          </div>
          <QuillEditor theme="snow" v-model="description" ref="quillHubRef" class="quill-editor"
            placeholder="Enter your description here..." toolbar="full" />
            <div class="d-flex mt-2 mx-1 justify-content-between align-items-start">
              <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-center">
                <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                  <i class='bx bx-image-add file-upload-icon'></i> Add voice content
                </label>
                <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
                <div v-if="fileName" class="text-xs text-secondary fst-italic mt-1">
                  <span class="fw-500">File name:</span> {{ fileName }}
                </div>
                <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>

                <div v-if="imagePreview" class="mt-2">
                  <img :src="imagePreview" alt="Preview" class="img-fluid rounded" style="max-width: 200px; max-height: 200px;" />
                </div>
              </div>
            
              <div class="file-upload ms-5 my-2 mx-3 flex-grow-1 d-flex flex-column align-items-center">
                <label for="audioInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                  <i class='bx bx-music'></i> Upload Audio
                </label>
                <input type="file" accept="audio/*" id="audioInput" class="file-input d-none" @change="handleAudioUpload" />
                <div v-if="audioError" class="text-xs text-danger fst-italic mt-1">{{ audioError }}</div>
              </div>
              <div class="d-flex flex-column align-items-center my-2 flex-grow-1">
                <div class="d-flex flex-row align-items-center mb-3">
                  <button type="button" class="btn btn-primary text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center" @click="startRecording" v-if="!isRecording">
                    <i class='bx bx-microphone'></i> Record
                  </button>
                  <button type="button" class="btn btn-danger text-xs rounded-5 mx-2 d-flex align-items-center justify-content-center" @click="stopRecording" v-if="isRecording">
                    <i class='bx bx-stop'></i> Stop
                  </button>
                </div>
                <div v-if="audio_file_name" class="text-xs text-secondary fw-500 mb-3 audio-file">
                  <i class='bx bx-music'></i> Recorded: {{ audio_file_name }}
                </div>
              </div>
            </div> 
            <div class="d-flex align-items-center mb-3">
              <label for="toggle-comments" class="form-label mb-0 me-2 text-xs fw-500">Allow comments:</label>
              <div class="form-check form-switch ">
                <input class="form-check-input btn-secondary" type="checkbox" id="toggle-comments" v-model="comments_off">
                <label class="form-check-label" for="toggle-comments">{{ comments_off ? 'Yes' : 'No' }}</label>
              </div>
            </div> 
          </div>  
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Portal Modal -->
<div class="modal fade" id="portal_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create a Portal</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="createPortal">
        <div class="modal-body">

          <!-- Portal Name Field -->
          <div class="mb-3">
            <label for="portal_name" class="form-label text-xs fw-500">Portal Name <span class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="portal_name" placeholder="Enter portal name"
              v-model="portal_name">
            <p v-if="errors.portal_name" class="text-danger text-xs">{{ errors.portal_name }}</p>
          </div>

          <!-- Portal Types as Horizontal Radio Buttons -->
          <div class="mb-3">
            <label class="form-label text-xs fw-500">Portal Type</label>
            <div class="d-flex gap-3">
              <div v-for="type in portal_types" :key="type.id" class="form-check">
                <input type="radio" class="form-check-input" :id="'portal_type_' + type.id" :value="type"
                  v-model="selectedPortalType">
                <label class="form-check-label text-xs" :for="'portal_type_' + type.id">
                  {{ type.name }}
                </label>
              </div>
            </div>
            <p v-if="errors.portal_type" class="text-danger text-xs">{{ errors.portal_type }}</p>
          </div>

          <!-- Contact Email (Only if "incident" is selected) -->
          <div v-if="selectedPortalType && selectedPortalType.name === 'incident'">
            <div class="mb-3">
              <label for="contact_email" class="form-label text-xs fw-500">Authority Email</label>
              <input type="email" id="contact_email" class="form-control rounded-5 text-xs" v-model="contactEmail">
              <p v-if="errors.contact_email" class="text-danger text-xs">{{ errors.contact_email }}</p>
            </div>
          </div>

          <!-- Show Master/Is Master only if type is "Engagement" -->
          <div v-if="selectedPortalType && selectedPortalType.name === 'engagement'">
            <!-- Is Master Checkbox -->
            <div class="mb-3">
              <label class="form-label text-xs fw-500">Is Master Portal?</label>
              <div class="d-flex gap-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="is_master_yes" :value="true" v-model="isMaster">
                  <label class="form-check-label text-xs" for="is_master_yes">Yes</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="is_master_no" :value="false" v-model="isMaster">
                  <label class="form-check-label text-xs" for="is_master_no">No</label>
                </div>
              </div>
            </div>

            <!-- Select Sub-Portals if Master -->
            <div class="mb-3" v-if="isMaster">
              <a href="#" 
              class="text-primary text-xs fw-500" 
              data-bs-toggle="modal" 
              data-bs-target="#select_portals_modal">
             + Add Sub-Portals
           </a>
           <div class="text-xs">
            Selected sub-portals ({{ selectedPortals.length || 0 }})
          </div>
            </div>

            <!-- Select Master Portal if Not Master -->
            <div class="mb-3" v-if="!isMaster">
              <label class="form-label text-xs fw-500">Select Master Portal</label>
              <select class="form-control rounded-5 text-xs" v-model="selectedMasterPortal">
                <option value="" disabled selected>Select master portal</option>
                <option v-for="portal in portals" :key="portal.id" :value="portal.id">
                  {{ portal.name }}
                </option>
              </select>
              <p v-if="errors.master_portal" class="text-danger text-xs">{{ errors.master_portal }}</p>
            </div>
            <!-- Public/Private Radio Buttons -->

          <div class="d-flex gap-3 mt-2">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="public" value="public"
                v-model="selectedVisibility">
              <label class="form-check-label text-xs fw-500" for="public">
                Public <br>
                <span class="text-xxs text-secondary">Is the portal public?</span>
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="private" value="private"
                v-model="selectedVisibility">
              <label class="form-check-label text-xs fw-500" for="private">
                Private <br>
                <span class="text-xxs text-secondary">Is the portal private?</span>
              </label>
            </div>
          </div>


          </div>


          <!-- User Selection Field -->
          <div v-if="selectedVisibility === 'private'" class="mt-3">
            <a href="#" class="text-primary text-xs fw-500" data-bs-toggle="modal" data-bs-target="#portal_member_modal">+ Add Portal members</a>
            <p v-if="errors.user_ids" class="text-danger text-xs">{{ errors.user_ids }}</p>
            <div class="text-xs">
              Tagged Members ({{ selectedUserIds.length || 0 }})
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>


<div class="modal fade" id="portal_member_modal" tabindex="-1" aria-labelledby="portalMemberLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="portalMemberLabel">Add Portal Members</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="addPortalMembers">
        <div class="modal-body table-responsive">
          <div class="mb-3">
            <input
              type="text"
              v-model="searchQuery"
              class="form-control text-xs"
              placeholder="Search members by name..."
            />
          </div>
          <table class="table table-striped text-xs">
            <thead class="bg-dark text-white">
              <tr>
                <th scope="col">#</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Email</th>
                <th scope="col">Action<span class="ms-2"><input  class="form-check-input" type="checkbox" id="select_all_checkbox" :checked="areAllSelected" @change="toggleSelectAll"/></span></th>
              </tr>
            </thead>
            <tbody class="text-black">
              <tr v-for="(user, index) in filteredUsers" :key="user.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ user.first_name }}</td>
                <td>{{ user.last_name }}</td>
                <td>{{ user.email }}</td>
                <td>
                  <div class="form-check">
                    <input 
                      class="form-check-input" 
                      type="checkbox" 
                      :id="'portal_member_check_' + user.id" 
                      :value="user.id" 
                      v-model="selectedUserIds"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Add Members</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" id="select_portals_modal" tabindex="-1" aria-labelledby="selectPortalsLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="selectPortalsLabel">Select Sub-Portals</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>

      <form @submit.prevent="confirmSelectedPortals">
        <div class="modal-body">
          <!-- Search Input -->
          <div class="mb-3">
            <input
              type="text"
              v-model="searchQuery"
              class="form-control text-xs"
              placeholder="Search portals by name..."
            />
          </div>

          <!-- Portals Table -->
          <table class="table table-striped text-xs">
            <thead class="bg-dark text-white">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Portal Name</th>
                <th scope="col">
                  Action 
                  <span class="ms-2">
                    <input  
                      class="form-check-input" 
                      type="checkbox" 
                      id="select_all_checkbox" 
                      :checked="areAllSubPortalsSelected" 
                      @change="toggleSelectAllSubPortals"
                    />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody class="text-black">
              <tr v-for="(portal, index) in filteredPortals" :key="portal.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ portal.name }}</td>
                <td>
                  <div class="form-check">
                    <input 
                      class="form-check-input" 
                      type="checkbox" 
                      :id="'portal_check_' + portal.id" 
                      :value="portal.id" 
                      v-model="selectedPortals"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Add Portals</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Group Modal -->
<div class="modal fade" id="group_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create a Group</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="createGroup">
        <div class="modal-body">

          <div class="mb-3">
            <label for="group_name" class="form-label text-xs fw-500">Group Name <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="group_name" placeholder="Enter group name"
              v-model="group_name">
              <p v-if="errors.group_name" class="text-danger text-xs">{{ errors.group_name }}</p>
          </div>
          <QuillEditor theme="snow" v-model="description" ref="quillGroupEditorRef" class="quill-editor"
            placeholder="Enter your description here..." toolbar="full" />
          <div class="form-check mt-2">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="public" value="public"
              v-model="selectedVisibility">
            <label class="form-check-label text-xs fw-500" for="flexRadioDefault1">
              Public <br>
              <span class="text-xxs text-secondary">Is the group public ?</span>
            </label>
          </div>
          <div class="form-check mt-2">
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="private" value="private"
              v-model="selectedVisibility">
            <label class="form-check-label text-xs fw-500" for="private">
              Private <br>
              <span class="text-xxs text-secondary">Is the group private ?</span>
            </label>
          </div>

        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Group member modal -->
<div class="modal fade" id="group_member_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Add a group member</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="add_group_member">
        <div class="modal-body">
          <table class="table table-striped text-xs">
            <thead class="bg-dark text-white">
              <tr>
                <th scope="col">#</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody class="text-black">
              <tr v-for="(user, index) in users" :key="user.id" v-show="user.role.role === 'customer'">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ user.first_name }}</td>
                <td>{{ user.last_name }}</td>
                <td>
                  <div class="form-check">
                    <input 
                      class="form-check-input" 
                      type="checkbox" 
                      :id="'member_check_' + user.id" 
                      :value="user.id" 
                      v-model="selectedUserIds"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Add member</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Segment Modal -->
<div class="modal fade" id="segment_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create a Segment</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="createSegment">
        <div class="modal-body">

          <div class="mb-3">
            <label for="topic_name" class="form-label text-xs fw-500">Segment Name <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="topic_name" placeholder="Enter topic name"
              v-model="topic_name">
              <p v-if="errors.topic_name" class="text-danger text-xs">{{ errors.topic_name }}</p>
          </div>

        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Community Asset Modal -->
<div class="modal fade" id="create_community_asset_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create Community Assets</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="createcommunity_asset">
        <div class="modal-body">
 
          <div class="mb-3">
            <label for="asset_name" class="form-label text-xs fw-500">Community Asset Name <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="asset_name" placeholder="Enter community asset name"
              v-model="asset_name">
              <p v-if="errors.asset_name" class="text-danger text-xs">{{ errors.asset_name }}</p>
          </div>
          <div class="mb-3">
            <label for="asset_name" class="form-label text-xs fw-500">Community Asset Location <span
                class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="location" placeholder="Enter community asset name"
              v-model="location" :class="{'is-invalid': errors.location}">
              <p v-if="errors.location" class="text-danger text-xs">{{ errors.location }}</p>
          </div>
          <div class="mb-3">
            <label for="category_id" class="form-label text-xs fw-500">Select a Category<span class="text-danger">*</span></label>
            <select class="form-select rounded-5 text-xs" id="category_id" v-model="category_id">
              <option selected hidden>Select a Category</option>
              <option v-for="category in categories" :value="category.id" :key="category.id">{{ category.name }}</option>
            </select>
            <p v-if="errors.category_id" class="text-danger text-xs">{{ errors.category_id }}</p>
          </div>
          <div class="mb-3">
            <label for="asset_name" class="form-label text-xs fw-500">Subscription fee</label>
            <input type="text" class="form-control rounded-5 text-xs" id="subscription_fee" placeholder="R0"
              v-model="subscription_fee">
              <p v-if="errors.subscription_fee" class="text-danger text-xs">{{ errors.subscription_fee }}</p>
          </div>
          <QuillEditor theme="snow" v-model="description" ref="quillAssetEditorRef" class="quill-editor"
            placeholder="Enter your description here..." toolbar="full" />
          <div class="mt-2 mx-1">
            <div class="d-flex flex-row justify-content-between">
              <div class="file-upload">
                <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light"><i
                    class='bx bx-image-add file-upload-icon'></i> Add community content</label>
                <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
              </div>
              <div v-if="fileName" class="text-xs mt-2 text-secondary text-danger fst-italic"><span class="fw-500">File
                  name:</span>{{ fileName }}</div>
            </div>
            <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>

            <div v-if="imagePreview" class="mt-2">
              <img :src="imagePreview" alt="Preview" class="img-fluid rounded" style="max-width: 200px; max-height: 200px;" />
            </div>
          </div>
 
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- <div class="modal fade" id="incidentReportModal" tabindex="-1" aria-labelledby="incidentReportModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content"> -->
      <!-- Modal Header -->
      <!-- <div class="modal-header">
        <h5 class="modal-title text-xs fw-500" id="incidentReportModalLabel">Create Incident Report for <span id="incidentReportCardType"></span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> -->
      <!-- Modal Form -->
      <!-- <form @submit.prevent="createIncidentReport">
        <div class="modal-body">
  
          <div class="mb-4">
            <label class="form-label text-xs fw-500">Report Name <span class="text-danger">*</span></label>
            <input
              type="text"
              id="incidentReportName"
              class="form-control text-xs rounded-5"
              placeholder="Enter report name here..."
              v-model="report_title"
            />
            <p v-if="errors.incident_title" class="text-danger text-xs">{{ errors.incident_title}}</p>
          </div>

          <div class="d-flex flex-row justify-content-between mx-1">
            <div >
              <label for="portals" class="form-label text-xs fw-500"><span class="text-danger">*</span>Tag a portal :</label>
              <select class="text-xs btn-primary fw-500 border-0 rounded-5 btn-sm mb-3 border-end"
                v-model="selectedPortalId" @change="onPortalChange" id="portals">
                <option value="" selected hidden>
                  * Tag a portal
                </option>
                <option v-for="portal in portals " :value="portal.id" :key="portal.id">{{ portal.name }}</option>
              </select>
              <p v-if="errors.portal" class="text-danger text-xs">{{ errors.portal }}</p>
            </div>
          </div>
          <div class="mb-4">
            <label class="form-label text-xs fw-500">Incident Description <span class="text-danger">*</span></label>
            <QuillEditor
              theme="snow"
              ref="quillReportEditorRef" class="quill-editor"
              placeholder="Enter description here..."
              toolbar="full"
            />
          </div>
          <p v-if="errors.incident_description" class="text-danger text-xs">{{ errors.incident_description }}</p>
   -->
          <!-- Contact Persons Section -->
          <!-- <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-start">
            <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
              <i class='bx bx-image-add file-upload-icon'></i> Add Report content
            </label>
            <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
            <div v-if="fileName" class="text-xs text-secondary fst-italic mt-1">
              <span class="fw-500">File name:</span> {{ fileName }}
            </div>
            <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>
          </div>
  
          <div class="mb-4">
            <label for="mapSearchBox" class="form-label text-xs fw-500 d-block">Search for Location</label>
            <input id="mapSearchBox" type="text" class="form-control map-search-box" placeholder="Search location..." v-model="location" />
            <p  v-if="errors.location" class="text-danger text-xs">{{ errors.location }}</p>
          </div>
          <div class="map"></div>
        </div>
   -->
        <!-- Modal Footer -->
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
          <button type="submit" class="btn btn-primary btn-sm">Create Report</button>
        </div>
      </form>
    </div>
  </div>
  </div> -->


<!-- Document Modal -->
<div class="modal fade" id="document_approval_modal" tabindex="-1" aria-labelledby="documentApprovalModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title text-xs fw-500" id="documentApprovalModalLabel">Document Approval</p>
        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="createDocumentApproval">
        <div class="modal-body modal-body-scroll">

          <!-- Document Name -->
          <div class="mb-3">
            <label for="document_name" class="form-label text-xs fw-500">Document Name <span class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="document_name" placeholder="Enter document name" v-model="document_name">
            <p v-if="errors.document_name" class="text-danger text-xs">{{ errors.document_name }}</p>
          </div>

          <!-- Select Reviewer -->
          <div class="d-flex flex-row justify-content-between mx-1">
            <div class="mb-3">
              <label for="reviewer" class="form-label text-xs fw-500"><span class="text-danger">*</span>Assign reviewers :</label>
              <select class="text-xs btn-primary fw-500 border-0 rounded-5 btn-sm mb-3 border-end" v-model="selected_reviewers" id="reviewer">
                <option value="" selected hidden>* Reviewers</option>
                <option v-for="reviewer in reviewers" :value="reviewer.id" :key="reviewer.id">{{ reviewer.first_name }}</option>
              </select>
              <p v-if="errors.reviewer" class="text-danger text-xs">{{ errors.reviewer }}</p>
            </div>
          </div>

         <!-- Document Review Section (Editor or Description) -->
         <QuillEditor theme="snow" v-model="document_description" ref="quillDocumentEditorRef" class="quill-editor"
         placeholder="Enter your description here..." toolbar="full" />

          <!-- File Upload for Document -->
          <div class="d-flex mt-2 mx-1 justify-content-between align-items-start border-bottom">
            <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-center">
              <label for="approvalFileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                <i class='bx bx-image-add file-upload-icon'></i> Upload Document/Media
              </label>
              <input type="file" id="approvalFileInput" class="file-input d-none" @change="handleApprovalFileUpload" />
              <div v-if="fileName" class="text-xs text-secondary fst-italic mt-1">
                <span class="fw-500">File name:</span> {{ fileName }}
              </div>
              <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>

              <!-- Image or Document Preview -->
              <div v-if="filePreview" class="mt-2">
                <a :href="filePreview" target="_blank" class="text-xs text-primary">Preview Document</a>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Submit for Approval</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="offcanvas offcanvas-end" tabindex="-1" id="topics" aria-labelledby="topics_label">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="portals_label">Segments</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body"> 
    <div class="reports">
      <hr />
      <div class="table-container">
        <table v-if="topics.length" class="table mb-0">
          <tbody>
            <tr v-for="topic in topics" :key="topic.id" class="hover-row border-0">
              <td class="border-0">
                
                <a class="text-decoration-none text-dark" href="#" @click.prevent="handleTopicClick(topic.id)" data-bs-dismiss="offcanvas" >
                 
                  <p class="portal-icon mb-0 d-flex align-items-center">
                    <span class="rounded-circle bg-yellow p-1 shadow-sm color-purple">
                      <i class='bx bxs-bulb fs-5'></i>
                    </span>
                    <span class="text-xs fw-500 mx-2">{{ topic.name || topic_name.name }}</span>
                  </p>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else class="text-secondary text-xs text-center">
          No Segments Available
        </p>
      </div>
    </div>
  </div>

</div>
<div class="d-none d-xl-block">
  <!-- Segments Section -->
  <div class="card w-100 rounded-3 mt-2 shadow-sm bg-white border-0">
    <div class="card-header bg-transparent">
      <h6 class="mb-0 card-header bg-transparent border-0">Segments</h6>
    </div>
    <div class="card-body ">
      <table v-if="topics.length" class="table mb-0">
        <tbody>
          <tr v-for="topic in topics" :key="topic.id" class="border-0">
            <td class="border-0 align-middle">
              <a
                class="text-decoration-none text-dark d-flex align-items-center"
                href="#"
                @click.prevent="handleTopicClick(topic.id)"
              >
                <span class="rounded-circle bg-yellow p-2 shadow-sm color-purple d-flex align-items-center justify-content-center me-2" >
                  <i class="bx bxs-bulb fs-5 text-change"></i>
                </span>
                <span class="text-xs fw-500">{{ topic.name || topic_name.name }}</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else class="text-secondary text-xs text-center">No Segments Available</p>
    </div>
  </div>
  
</div>


<div class="offcanvas offcanvas-start" tabindex="-1" id="portal_members" aria-labelledby="portal_members_label">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="portal_label"></h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
      <h6 class='text-start mx-1 d-flex align-items-center'>Portal Members
        <i 
          class="bx bx-plus-circle fs-5 text-primary ms-2 cursor-pointer" 
          data-bs-toggle="modal" 
        data-bs-target="#add_portal_member_modal"
          title="Add Member" v-if="user_details.user_role=='admin'">
        </i>
        <i
          class="bx bx-trash fs-5 text-danger ms-2 cursor-pointer"
          data-bs-toggle="modal" 
        data-bs-target="#delete_portal_member_modal"
          title="Delete Member" v-if="user_details.user_role=='admin'">
        </i>
        </h6>

      <div class="table-container ">
        <table v-if="members.length" class="table mb-0">
          <tbody>
            <tr v-for="member in members" :key="member.id" class="hover-row border-0">
              <td class="border-0">
                <span class="text-decoration-none text-dark">
                  <p class="portal-icon mb-0 d-flex align-items-center">
                    <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple">
                      <i class='bx bx-user fs-5'></i>
                    </a>
                    <span class="text-sm fw-bold mx-3">
                      <a :href="getProfileLink(member)"
                         class="text-decoration-none text-dark">
                        {{ member.first_name }} {{ member.last_name }}
                      </a>
                    </span>
                  </p>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else class="text-secondary text-xs text-center">
          No Portal members Available
        </p>
      </div>
  </div>
</div>
import { get_token, get_user_details } from "@/router";
import HomeNavBar from "@/components/HomeNavBar/HomeNavBar.vue"
import ReportCommentModal from "@/components/ReportCommentModal/ReportCommentModal.vue";
import ExchangeHubCommentModal from "@/components/ExchangeHubCommentModal/ExchangeHubCommentModal.vue";
import AllPortals from "@/components/GetPortals/PortalsComponent.vue"
import FloatingButton from "@/components/FloatingButton/FloatingButtonComponent.vue"
import StickyNav from "@/components/BottomNavbar/StickyNavbar.vue"
import UserComponent from '@/components/UserComponent/UserComponent.vue'
import HomeSidebar from '@/components/HomeSidebar/HomeSidebar.vue'
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue'
import MenuSideBar from '@/components/MenuSidebar/MenuSidebar.vue'
import AllGroups from "@/components/GetGroups/GroupsComponent.vue"
import UserEvents from "@/components/UserEvents/UserEvents.vue"

import Swal from 'sweetalert2';


export default {

  components: {
    ReportCommentModal,
    ExchangeHubCommentModal,
    HomeNavBar,
    AllPortals,
    FloatingButton,
    StickyNav,
    UserComponent,
    HomeSidebar,
    ProfileCard,
    MenuSideBar,
    AllGroups,
    UserEvents
  },
  mounted() {

    this.fetchReports();
    this.fetchExchangeHubs();
    this.token_exists = get_token();
    this.user_id = this.get_user_id();
    this.getReportLikes();
    this.getExchangeHubLikes();
    document.addEventListener('click', this.handleClickOutside);
    this.user_details=get_user_details();

    const editFeedModal=$('#edit_report_modal');
    const editVoiceModal=$('#edit_voice_modal');


    editFeedModal.on('show.bs.modal',()=>{
      this.resetSelection();
    });

    editVoiceModal.on('show.bs.modal',()=>{
      this.resetSelection();
    });

  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  props: ['topic_id'],
  data() {
    return ({
      reports:[],
      report: {
        name: '', // This will bind to the input field for the report name
        description: '', // This will bind to the textarea for the report description
      },
      temporaryPost: null, 
      report_id:null,
      report_description:"",
      report_name:"",
      topic_id:'',
      external_reports:[],
      exchange_hub_data:[],
      isLoading: false,
      token_exists: false,
      user_id:null,
      edit_report_name: '',
      edit_report_id: '',
      edit_report_description: '',
      user_details: {
        "user_role": '',
        "first_name": '',
        "last_name": '',

      },
      report_data: {},
      exchange_hubs: {},
      errors:{
        edit_voice_name:'',
        editFileError:null,
        editAudioError:null,
        editFileName:null,
      },
      comment_modal_visible: false,
      xHub_comment_modal_visible: false,
      expandedReports: [],
      expandedHub: [],
      reportLikes: {},
      likedExchangeHubs: [],
      edit_name:'',
      edit_description:'',
      edit_exchange_hub_id:null,
      edit_documents:[],
      isRecording: false,
      audio_file: null,
      mediaRecorder: null,
      recordedChunks: [],
      audio_file_name: '',
      upload_media: null,
      fileError : '',
      audioError: '',
      editFileName:'',
      pdf_file: null,
      pdf_file_url: null,
    });
  },

  methods: {
    addTemporaryPost(post) {
      this.temporaryPost = post;
    },
    get_user_id() {
      const user_id = localStorage.getItem('user_id') || '';
      return user_id;
  },
  baseUrl(post, type) {
    const baseUrl = `${window.location.origin}${this.$route.path}`;
    // Dynamically build the URL based on the type (either 'hub' or 'report')
    if (type === 'exchange_hub') {
      return `${baseUrl}?hub_id=${post.id}`;
    } else if (type === 'report') {
      return `${baseUrl}?report_id=${post.id}`;
    }
    return baseUrl
  },
  getIdFromURL(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  },
  closeMenu(reportId) {
    const checkbox = document.getElementById('menu-open-' + reportId);
    if (checkbox) {
      checkbox.checked = false;
    }
  },

  closeFeedMenu( exchange_hubID) {
    const checkbox = document.getElementById('menu-open-' + exchange_hubID);
    if (checkbox) {
      checkbox.checked = false;
    }
  },

  resetSelection(){
    this.editFileName='';
    this.edit_name='';
    this.edit_description='';
    this.edit_documents = [];
    this.edit_voice_id =null;
    this.edit_exchange_hub_id=null;

    this.editFileName='';
    this.edit_report_name= '',
    this.edit_report_id=null,
    this.edit_report_description='',

    this.upload_media='';
    this.audio_file = null;
    this.errors={}

  },

  fetchPostsByPortal(reports){
    this.reports = reports;
  },
  handleClickOutside(event) {
    const menus = document.querySelectorAll('.menu-open');
    menus.forEach(menu => {
      if (menu.checked && !menu.closest('.menu').contains(event.target)) {
        menu.checked = false;
      }
    });
  },
  addReportToHome(newReport) {
    if (newReport) {

      newReport.total_comments = newReport.total_comments || 0;
      newReport.like_count = newReport.like_count || 0;
      if(newReport.portal.is_public===true){
        this.reports.unshift(newReport);
      }
    }
    this.temporaryPost = null;
  },
  addNewGroup(newGroup) {
    if (newGroup) {
      this.$refs.groups.groups.unshift(newGroup)
    }
  },
  addNewAsset(newAsset) {
    if (newAsset) {
    this.$refs.assets.community_assets.unshift(newAsset)
    }
  },
  addNewExchangeHub(newExchangeHub) {
    if (newExchangeHub) {
      this.exchange_hub_data.unshift(newExchangeHub);
    }
  },
  getFileIcon(fileUrl) {
    if (fileUrl.includes('.pdf')) return 'https://cdn-icons-png.flaticon.com/128/337/337946.png';
    if (fileUrl.includes('.doc') || fileUrl.includes('.docx')) return 'https://cdn-icons-png.flaticon.com/128/337/337932.png';  // Word icon
    if (fileUrl.includes('.xls') || fileUrl.includes('.xlsx')) return 'https://cdn-icons-png.flaticon.com/128/337/337958.png';  // Excel icon
    return 'https://cdn-icons-png.flaticon.com/128/337/337940.png';
  },
  getFileName(fileUrl) {
    const decodedUrl = decodeURIComponent(fileUrl);
    const cleanUrl = decodedUrl.split('?')[0]; 
    return cleanUrl.substring(cleanUrl.lastIndexOf('/') + 1);
  },
    getToken() {
      return get_token();
    },
    deleteReport(report_id) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            type: "DELETE",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/delete_report_api/`,
            data: JSON.stringify({ report_id: report_id }),
            headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
            },
            success: (response) => {
              const status = response.status;
              if (status === "success") {
                this.reports = this.reports.filter(report => report.id !== report_id);
                Swal.fire({
                  title: 'Deleted!',
                  text: 'The feed has been deleted.',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1000
                })
              } else {
                Swal.fire(
                  'Failed!',
                  response.message || 'An error occurred. Please try again.',
                  'error'
                );
              }
            },
            error: () => {
              Swal.fire(
                'Failed!',
                'An error occurred. Please try again.',
                'error'
              );
            }
          });
        }
      });
    },

    fetchExchangeHubs() {

      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_exchange_hubs_api/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },

        success: (response) => {
          var status =response.status
          if(status== "success"){
            this.exchange_hub_data = response.exchange_hubs

            this.exchange_hub_data.forEach(exchangeHub => {
              if (exchangeHub && exchangeHub.id) {

                if (exchangeHub.document && Array.isArray(exchangeHub.document) && exchangeHub.document.length > 0) {
                  const firstFile = exchangeHub.document[0].file;
                  // Extract the file URL and other properties
                  this.pdf_file_url = firstFile.file_url || '';
                  this.pdf_file = firstFile || {};
                  this.file_name = firstFile.name || '';
                } else {
                  // If no files are associated, reset the properties
                  this.pdf_file = [];
                  this.pdf_file_url = '';
                  this.file_name = '';
                }
                this.getExchangeHubLikes(exchangeHub);
              }
            });

          const hub_id = this.getIdFromURL('hub_id');
          if (hub_id) {
            const exchangeHub = this.exchange_hub_data.find(hub => hub.id === parseInt(hub_id,10));
            if (exchangeHub) {
              this.display_xHub_comment(exchangeHub);
            }
          }

          }
        },
        complete: () => {
          this.isLoading = false;
        },

      });

    },

    fetchReports() {
      this.isLoading = true;

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/filter_reports_api/`,
        method: 'POST',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json',
        },
        success: (data) => {
          const status = data.status;

          if (status === 'success') {
            // Safely assign report data or default to an empty array
            this.reports = Array.isArray(data.data.report_data) ? data.data.report_data : [];

            this.reports.forEach(report => {
              if (report?.id) {
                // Check for associated profile files
                if (Array.isArray(report.report_files) && report.report_files.length > 0) {
                  const firstFile = report.report_files[0];
                  report.pdf_file_url = firstFile?.file_url || ''; // Add to the specific report object
                  report.pdf_file = firstFile || {};
                  report.file_name = firstFile?.name || '';
                } else {
                  // Reset file properties on the report
                  report.pdf_file_url = '';
                  report.pdf_file = null;
                  report.file_name = '';
                }

                // Fetch likes for the report
                this.getReportLikes(report);
              }
            });

            // Handle report ID from URL
            const reportId = this.getIdFromURL('report_id');
            if (reportId) {
              const selectedReport = this.reports.find(r => r.id === parseInt(reportId, 10));
              if (selectedReport) {
                this.display_comment_modal(selectedReport);
              }
            }
          }
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },


    togglePinReport(report) {
      const newPinStatus = !report.is_pinned;

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/pin_report_api/`,
        method: 'POST',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          post_id: report.id,
          is_pinned: newPinStatus
        }),
        success: (response) => {
          if (response.status === 'success') {
            report.is_pinned = newPinStatus;

            const index = this.reports.indexOf(report);
            if (index > -1) {
              this.reports.splice(index, 1);
            }
            if (newPinStatus) {
              this.reports.unshift(report);
            } else {
              this.fetchReports();
            }
      }
        },
      });
    },

    display_comment_modal(report_data) {
      this.report_data = report_data;
      this.comment_modal_visible = true;
    },

    display_xHub_comment(exchange_hubs) {
      this.exchange_hubs = exchange_hubs;
      this.xHub_comment_modal_visible = true;
    },

    handleAudioUpload(event) {
      const file = event.target.files[0];
      if (file) {
        if (!file.type.startsWith('audio/')) {
          this.audioError = 'Only audio files are allowed.';
          this.audio_file = null;
          this.audio_file_name = '';
          event.target.value = '';
        } else {
          this.audio_file_name = file.name;
          this.audioError = '';
          this.audio_file = file;
        }
      } else {
        this.audio_file_name = '';
        this.audioError = '';
        this.audio_file = null;
      }
    },

    handleHomeFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.type.startsWith('audio/')) {
          this.fileError = 'Audio files are not allowed.';
          this.upload_media = null;
          this.editFileName = '';
          event.target.value = '';
        } else {
          this.editFileName = file.name;
          this.fileError = '';
          this.upload_media = file;
        }
      } else {
        this.editFileName = '';
        this.fileError = '';
        this.upload_media = null;
      }
    },

    startRecording() {
            navigator.mediaDevices.getUserMedia({ audio: true })
              .then((stream) => {
                this.mediaRecorder = new MediaRecorder(stream);

                this.mediaRecorder.ondataavailable = (event) => {
                  if (event.data.size > 0) {
                    this.recordedChunks.push(event.data);
                  }
                };

                this.mediaRecorder.onstop = () => {
                  const audioBlob = new Blob(this.recordedChunks, { type: 'audio/wav' });
                  this.audio_file = audioBlob;
                  this.audio_file_name = 'recording.wav';
                };

                this.mediaRecorder.start();
                this.isRecording = true;
              })
              .catch((error) => {
                if (error.name === 'NotAllowedError') {
                  Swal.fire({
                    icon: 'error',
                    title: 'Microphone Access Denied',
                    text: 'Please allow microphone access in your browser settings and refresh the page.',
                  });
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Error accessing microphone: ${error.message}`
                  });
                }
              });
          },

          stopRecording() {
            if (this.mediaRecorder && this.isRecording) {
              this.mediaRecorder.stop();
              this.isRecording = false;
            }
          },

    edit_voice(exchange_hub){
      this.edit_name = exchange_hub.name;
      this.edit_description=exchange_hub.description;
      const TextDescription = exchange_hub.description.replace(/<[^>]*>/g, "");
      this.edit_documents=exchange_hub
      this.edit_exchange_hub_id=exchange_hub.id


      this.$nextTick(() => {
        if (this.$refs.quillHubRef) {
          const quillEditor = this.$refs.quillHubRef.getQuill();
          if (quillEditor) {
            quillEditor.setContents([
              { insert:TextDescription || "" },
            ]);
          }
        }
      });
    },

    edit_createHub() {
          Swal.fire({
            title: 'Submitting...',
            text: 'Please wait while we process your request.',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });

          this.isSubmitting = true;
          const formData = new FormData();
          this.edit_description=this.$refs.quillHubRef.getText();
          formData.append('name', this.edit_name);
          formData.append('description', this.edit_description);
          formData.append('exchangehub_id', this.edit_exchange_hub_id);
          if (this.upload_media) {
            formData.append('document', this.upload_media);
          }
          if (this.audio_file) {
            formData.append('document', this.audio_file,this.audio_file_name);
          }
          if(!this.edit_name){
            this.errors.edit_voice_name = 'voice name is required';
            Swal.close();
            return;
        }
          $.ajax({
            type: 'POST',
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/update_exchange_hub_api/`,
            data: formData,
            processData: false,
            contentType: false,
            headers: {
              'Authorization': `Token ${this.getToken()}`,
            },
            success: (response) => {

              if (response.status === 'success') {
                const updatedHub = response.exchange_hub;

                const hubIndex = this.exchange_hub_data.findIndex(
                  (hub) => hub.id === updatedHub.id
                );
                if (hubIndex !== -1) {
                  this.exchange_hub_data.splice(hubIndex, 1, updatedHub); // Replace the old hub with the updated one
                }

                Swal.fire({
                  icon: 'success',
                  title: response.message,
                  showConfirmButton: false,
                  timer: 1000
                }).then(() => {
                  $('#edit_voice_modal').modal('hide');
                  this.edit_name = '';
                  this.edit_description = '';
                });
              }
            },
            error: (response) => {
              Swal.close()
              const response_data = response.responseJSON;
              this.errors.edit_name=response_data.message
            },
            complete: () => {
              this.isSubmitting = false;
            }
          });
        },
        edit_report(report){
          this.report_name=report.name
          const TextDescription = report.description.replace(/<[^>]*>/g, "");
          this.report_id=report.id
          this.edit_documents=report
          this.$nextTick(() => {
            if (this.$refs.quillReportRef) {
              const quillEditor = this.$refs.quillReportRef.getQuill();
              if (quillEditor) {
                quillEditor.setContents([
                  { insert:TextDescription || "" },
                ]);
              }
            }
          });
        },
        edit_report_data() {
          Swal.fire({
            title: 'Submitting...',
            text: 'Please wait while we process your request.',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
          this.isSubmitting = true;
          const formData = new FormData();
         this.report_description  =this.$refs.quillReportRef.getText()
          formData.append('name', this.report_name);
          formData.append('description', this.report_description);
          formData.append('report_id', this.report_id);
          if (this.upload_media) {
            formData.append('file', this.upload_media);
          }
          if (this.audio_file) {
            formData.append('file', this.audio_file,this.audio_file_name);
          }
          if(!this.report_name){
            this.errors.edit_report_name = 'feed name is required';
            Swal.close();
            return;
        }

          $.ajax({
            type: 'POST',
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/update_report_api/`,
            data: formData,
            processData: false,
            contentType: false,
            headers: {
              'Authorization': `Token ${this.getToken()}`,
            },
            success: (response) => {
              if (response.status === 'success') {
                const updatedReport = response.updated_data;
                updatedReport.total_comments=updatedReport.total_comments||0
                const reportIndex = this.reports.findIndex(
                  (hub) => hub.id === updatedReport.id
                );

                if (reportIndex !== -1) {
                  this.reports.splice(reportIndex, 1, updatedReport);
                }
                Swal.fire({
                  icon: 'success',
                  title: response.message,
                  showConfirmButton: false,
                  timer: 1000
                }).then(() => {
                  $('#edit_report_modal').modal('hide');
                  this.edit_name = '';
                  this.edit_description = '';
                });
              }
            },
            error: (response) => {
              Swal.close()
              const response_data = response.responseJSON;
              this.errors.edit_name=response_data.message
            },
            complete: () => {
              this.isSubmitting = false;
            }
          });
        },

    hide_comment_comment() {
      this.report_data = {};
      this.comment_modal_visible = false;
    },
    hide_xhub_comment_comment() {
      this.exchange_hubs = {};
      this.xHub_comment_modal_visible = false;
    },
    handle_unauthenticated() {
      if (!this.token_exists) {
        window.location.href = escape("/sign_in_Finale");;
        return false;
      }
      return true;
    },
    truncateDescription(description, maxLength = 25) {
      const words = description.split(/\s+/);
      if (words.length > maxLength) {
        return words.slice(0, maxLength).join(" ") + '...';
      }
      return description;
    },
  
    getDisplayedReportDescription(report) {
      const description = report.description;

      if (this.isReportExpanded(report.id)) {
        return description;
      }
  
      return this.truncateDescription(description);
    },
  
    toggleReportReadMore(reportId) {
      const index = this.expandedReports.indexOf(reportId);
      if (index === -1) {
        this.expandedReports.push(reportId);
      } else {
        this.expandedReports.splice(index, 1);
      }
    },
  
    isReportExpanded(reportId) {
      return this.expandedReports.includes(reportId);
    },
  
    shouldShowReadMore(report) {
      const description = report.description;
      const wordCount = description.split(/\s+/).length;

      return wordCount > 25;
    },

    truncateHubDescription(description, maxLength = 25) {
      const words = description.split(/\s+/);
      if (words.length > maxLength) {
        return words.slice(0, maxLength).join(" ") + '...';
      }
      return description;
    },
  
    getDisplayedHubDescription(exchange_hub) {
      const description = exchange_hub.description;
  
      if (this.isHubExpanded(exchange_hub.id)) {
        return description;
      }
  
      return this.truncateHubDescription(description);
    },
  
    toggleHubReadMore(hubId) {
      const index = this.expandedHub.indexOf(hubId);
      if (index === -1) {
        this.expandedHub.push(hubId);
      } else {
        this.expandedHub.splice(index, 1);
      }
    },
  
    isHubExpanded(hubId) {
      return this.expandedHub.includes(hubId);
    },

    showReadMore(exchange_hub) {
      const description = exchange_hub.description;
      const wordCount = description.split(/\s+/).length;

      return wordCount > 25;
    },

    likeReport(report) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      const reportId = report.id;

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_report_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ report_id: reportId }),
        success: (response) => {
          if (response.status === "success") {

          const liked_report = this.reports.find(like => like.id === reportId);
          if (liked_report) {
            liked_report.like_count++;
          }
            const likeButton = document.getElementById(`like-button${reportId}`);
            const likedButton = document.getElementById(`liked-button${reportId}`);


            if (likeButton) {
              likeButton.classList.add('d-none');
            }

            if (likedButton) {

              likedButton.classList.remove('d-none');
            }
        }
      }
      });
    },

    like_report_auth(report_id,report) {

      if (!this.handle_unauthenticated()) {
        return;
      }
      this.likeReport(report)
      this.$router.push({ name: 'home',params: {report_id: report_id,report:report}});

    },
    unlikeReport(report) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      const reportId = report.id;
      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_report_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ report_id: reportId }),
        success: (response) => {
          if (response.status === "success") {
            const likeButton = document.getElementById(`like-button${reportId}`);
            const likedButton = document.getElementById(`liked-button${reportId}`);

          const unliked_report = this.reports.find(like => like.id === report.id);
          if (unliked_report) {
            unliked_report.like_count--;
          }

            if (likeButton) {
              likeButton.classList.remove('d-none');
            }

            if (likedButton) {
              likedButton.classList.add('d-none');
            }
          }
        }
      });
    },
    isLoggedIn() {
      return !!this.getToken();
    },
    getReportLikes(report) {
      if (!report || !report.id) {
        return;
      }
      $.ajax({
        type: "GET",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/retrieve_report_likes/`,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: { report_id: report.id },
        success: (response) => {
          if (response.status === "success") {
            const likes = response.data;
            report.like_count = likes.length;

            const userId = this.get_user_id();
            const isLiked = likes.some(like => like.user.id === parseInt(userId, 10));

            const likeButton = document.getElementById(`like-button${report.id}`);
            const likedButton = document.getElementById(`liked-button${report.id}`);

            if (likeButton) {
              likeButton.classList.toggle('d-none', isLiked);
            }

            if (likedButton) {
              likedButton.classList.toggle('d-none', !isLiked);
            }
          }
        },
      });
    },
    likeExchangeHub(exchangeHub) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      const exchangeHubId = exchangeHub.id;

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_exchange_hub_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ exchangehub_id: exchangeHubId }),
        success: (response) => {
          if (response.status === "success") {

            exchangeHub.like_count++;
            const reactButton = document.getElementById(`react-button${exchangeHubId}`);
            const reactedButton = document.getElementById(`reacted-button${exchangeHubId}`);

            if (reactButton) {
              reactButton.classList.add('d-none');
            }

            if (reactedButton) {
              reactedButton.classList.remove('d-none');
            }
          }
        }
      });
    },

    like_exchangehub_auth(exchangehub_id, exchangeHub) {
      if (!this.handle_unauthenticated()) {
        return;
      }

      this.likeExchangeHub(exchangeHub);

      this.$router.push({ name: 'home', params: { exchangehub_id: exchangehub_id, exchangeHub: exchangeHub } });
    },
    unlikeExchangeHub(exchangeHub) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      const exchangeHubId = exchangeHub.id;

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_exchange_hub_like/`,
        contentType: "application/json",
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: JSON.stringify({ exchangehub_id: exchangeHubId }),
        success: (response) => {
          if (response.status === "success") {
            exchangeHub.like_count--;
            const reactButton = document.getElementById(`react-button${exchangeHubId}`);
            const reactedButton = document.getElementById(`reacted-button${exchangeHubId}`);

            if (reactButton) {
              reactButton.classList.remove('d-none');
            }

            if (reactedButton) {
              reactedButton.classList.add('d-none');
            }
          }
        }
      });
    },
    getExchangeHubLikes(exchangeHub) {
      if (!exchangeHub || !exchangeHub.id) {
        return;
      }

      $.ajax({
        type: "GET",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/retrieve_exchange_hub_likes/`,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: { exchangehub_id: exchangeHub.id },
        success: (response) => {
          if (response.status === "success") {
            const likes = response.exchange_hub_likes;
            exchangeHub.like_count = likes.length;
            const userId = this.get_user_id();
            const isLiked = likes.some(like => like.user.id === parseInt(userId, 10));

            const reactButton = document.getElementById(`react-button${exchangeHub.id}`);
            const reactedButton = document.getElementById(`reacted-button${exchangeHub.id}`);

            if (reactButton) {
              reactButton.classList.toggle('d-none', isLiked);
            }

            if (reactedButton) {
              reactedButton.classList.toggle('d-none', !isLiked);
            }
          }
        },
      });
    },
    deleteExchangeHub(exchangehub_id) {
      if (!this.token_exists) {
        this.$router.push('/sign_in_finale');
        return;
      }
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            type: "DELETE",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/delete_exchange_hub_api/`,
            data: JSON.stringify({ exchange_hub_id: exchangehub_id }),
            headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
            },
            success: (response) => {
              const status = response.status;
              if (status === "success") {
                Swal.fire({
                  icon: 'success',
                  title: 'Voice deleted',
                  showConfirmButton: false,
                  timer: 1000
                }).then(() => {
                  window.location.reload();
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Failed to delete the voice. Please try again.',
                });;
              }
            }
          });
        }
      });
    },

    copyReportLinkToClipboard(report) {
      const link = this.baseUrl(report, 'report');
      navigator.clipboard.writeText(link).then(() => {
        this.showToast('Link copied to clipboard!');
      })
    },
    showToast(message) {
    document.querySelector('#copyToast .toast-body').textContent = message;

    $('#copyToast').fadeIn(300).addClass('show');
    setTimeout(function() {
      $('#copyToast').fadeOut(300).removeClass('show');
    }, 3000); 
  },
  copyVoiceLinkToClipboard(exchange_hub) {
    const link = this.baseUrl(exchange_hub, 'exchange_hub');
      navigator.clipboard.writeText(link).then(() => {
        this.showTheToast('Link copied to clipboard!');
      })
    },
    showTheToast(message) {
    document.querySelector('#copyTheToast .toast-body').textContent = message;

    $('#copyTheToast').fadeIn(300).addClass('show');
    setTimeout(function() {
      $('#copyTheToast').fadeOut(300).removeClass('show');
    }, 3000);
  },
  }
}
import { get_token } from '@/router'
import Swal from "sweetalert2";


export default {
    mounted(){
      this.retrieve_otp();
    },
    methods: {
        retrieve_otp() {
            $.ajax({
                type: "GET",
                url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/retrieve_otp/`,
                headers: {
                    'Authorization': `Token ${get_token()}`,
                    'Content-Type': 'application/json'
                  },
                data: JSON.stringify({
                    email:this.email 
                }),
                success: () => {      
                  $('#otp_email').removeClass('d-none')
                },
                error: () => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'An error occurred while processing your request. Please try again later.',
                    });
                }
            });
        },
        submit_otp() {
            $.ajax({
                type: "POST",
                url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/submit_otp/`,
                headers: {
                    'Authorization': `Token ${get_token()}`,
                    'Content-Type': 'application/json'
                  },
                data: JSON.stringify({
                    one_time_password:this.otp 
                }),
                success: (response) => {    
                    var status = response.status
                    var redirect_url = response.data.redirect_url
                    if(status =="success"){
                        this.$router.push({ path: redirect_url }); 
                    }else if(status=='error'){
                        var message = response.message
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: message,
                        });
                    }
                },
                error: (response) => {
                    var message = response.message
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: message,
                    });
                }
            });
        }
    }
};

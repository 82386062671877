import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';
import Swal from 'sweetalert2';
import { get_token } from "@/router";

export default {
    components: {
        Sidebar,
        Navbar
    },

    mounted() {
        this.fetchSections();
        this.fetchQuestions();
        this.get_question_types();
    },

    data() {
        return ({
            sections: [],
            questions: [],
            question_types: [],
            section_name: '',
            question_text: '',
            question_type: '',
            isLoading: false,
            token_exists: false,
            edit_section: '',

        });
    },

    methods: {
        getToken() {
            return get_token();
        },
        toggleSidebar() {
            this.$refs.sidebar.$el.classList.toggle("close");
        },

        get_question_types(){
            this.token_exists = get_token();
            $.ajax({
                url: `${process.env.VUE_APP_BACKEND_API_URL}/membership_management/get_question_types/`,
                method: "GET",
                headers: this.token_exists
                    ? { Authorization: `Token ${this.getToken()}` }
                    : {},
                success: (response) => {
                    this.question_types = response.data.question_types;
                },
                error: () => {
                    Swal.fire({
                        icon: "error",
                        title: "Failed to load question types",
                    });
                }
            });
        },
        fetchSections() {
            this.token_exists = get_token();
            $.ajax({
                url: `${process.env.VUE_APP_BACKEND_API_URL}/membership_management/filter_sections/`,
                method: "POST",
                headers: this.token_exists
                    ? { Authorization: `Token ${this.getToken()}` }
                    : {},
                success: (response) => {
                    this.sections = response.data.sections;
                },
                error: () => {
                    Swal.fire({
                        icon: "error",
                        title: "Failed to load sections",
                    });
                }
            });
        },
        fetchQuestions() {
            this.token_exists = get_token();
            $.ajax({
                url: `${process.env.VUE_APP_BACKEND_API_URL}/membership_management/filter_questions/`,
                method: "POST",
                headers: this.token_exists
                    ? { Authorization: `Token ${this.getToken()}` }
                    : {},
                success: (response) => {
                    this.questions = response.data.questions;
                },
                error: () => {
                    Swal.fire({
                        icon: "error",
                        title: "Failed to load questions",
                    });
                }
            });
        },
        createSection() {
            if (!this.section_name) {
                Swal.fire({
                    icon: 'error',
                    title: 'Section name is required',
                });
                return;
            }

            const formData = new FormData();
            formData.append('section_name', this.section_name);

            $.ajax({
                url: `${process.env.VUE_APP_BACKEND_API_URL}/membership_management/create_section/`,
                method: "POST",
                headers: { Authorization: `Token ${this.getToken()}` },
                data: formData,
                processData: false,  // Prevent jQuery from processing FormData
                contentType: false,  // Prevent jQuery from setting Content-Type
                success: (response) => {
                    if (response.status === "success") {
                        Swal.fire({
                            icon: "success",
                            title: "Section created",
                            showConfirmButton: false,
                            timer: 1000
                        }).then(() => {
                            this.fetchSections();
                            this.resetForm();
                            $('#create_section').modal('hide');
                        });
                    }
                },
                error: () => {
                    Swal.fire("Error", "Failed to create section.", "error");
                }
            });
        },


        createQuestion() {
            if (!this.question_text || !this.question_type) {
                Swal.fire({
                    icon: 'error',
                    title: 'Question text and type are required',
                });
                return;
            }
            const formData = new FormData();
            formData.append('question_text', this.question_text);
            formData.append('question_type', this.question_type);
            $.ajax({
                url: `${process.env.VUE_APP_BACKEND_API_URL}/membership_management/create_question/`,
                method: "POST",
                headers: { Authorization: `Token ${this.getToken()}` },
                data: formData,
                processData: false,
                contentType: false,
                success: (response) => {
                    const status = response.status;
                    if (status === "success") {
                        Swal.fire({
                            icon: "success",
                            title: "Question created",
                            showConfirmButton: false,
                            timer: 1000
                        }).then(() => {
                            this.fetchQuestions();
                            this.resetForm();
                            $('#create_question').modal('hide');
                        });
                    }
                },
                error: () => {
                    Swal.fire("Error", "Failed to create question.", "error");
                }
            });
        },
        deleteSection(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "This will permanently delete the section.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!"
            }).then((result) => {
                if (result.isConfirmed) {
                    $.ajax({
                        url: `${process.env.VUE_APP_BACKEND_API_URL}/membership_management/delete_section/${id}/`,
                        method: "DELETE",
                        headers: { Authorization: `Token ${this.getToken()}` },
                        success: () => {
                            this.fetchSections();
                            Swal.fire("Deleted!", "The section has been deleted.", "success");
                        },
                        error: () => {
                            Swal.fire("Error", "Failed to delete section.", "error");
                        }
                    });
                }
            });
        },
        deleteQuestion(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "This will permanently delete the question.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!"
            }).then((result) => {
                if (result.isConfirmed) {
                    $.ajax({
                        url: `${process.env.VUE_APP_BACKEND_API_URL}/membership_management/delete_question/${id}/`,
                        method: "DELETE",
                        headers: { Authorization: `Token ${this.getToken()}` },
                        success: () => {
                            this.fetchQuestions();
                            Swal.fire("Deleted!", "The question has been deleted.", "success");
                        },
                        error: () => {
                            Swal.fire("Error", "Failed to delete question.", "error");
                        }
                    });
                }
            });
        },

        resetForm() {
            this.section_name = '';
            this.question_text = '';
            this.question_type = '';
            this.errors = {};
        },

    editSectionName(section) {
        if (section) {
        this.edit_section = section.name;
        this.edit_section_id = section.id;
        }
    },
    
    editSection(section) {
        const payload = {
            section_id: section.id,
            update_values: {
                name: this.edit_section
            },
        };
    
        $.ajax({
            type: 'POST',
            url: `${process.env.VUE_APP_BACKEND_API_URL}/membership_management/update_section/`,
            headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
            },
            contentType: 'application/json',
            data: JSON.stringify(payload),
            success: (response) => {
                const status = response.status;
                if (status === "success") {
                    const updatedSection = this.sections.find(s => s.id === section.id);
                    if (updatedSection) {
                        updatedSection.name = this.edit_section;
                    }
    
                    Swal.fire({
                        icon: 'success',
                        title: 'Updated',
                        text: 'Portal has been updated.',
                        showConfirmButton: false,
                        timer: 1000
                    });
                    $(`#edit_section_${section.id}`).modal('hide');
                }
            },
            error: () => {
                Swal.fire("Error", "Failed to update section.", "error");
            }
        });
    }
    }
};

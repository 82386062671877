<template>
  <router-view/>
</template>

<style>
 
:root {
  --primary-color: #fed700;
  --secondary-color: #43285b ;
  --tertiary-color: #365b6d;
  --black-color: #010101;
  --bg-card-color: #f4f4f4;  
  --light-grey-color: #333;
  --bg-color: #9a9d9f1a;
  --bg-light-blue: #e6f0f1;
  --grey-color:#0000006b;
  --dark-blue-color: hsl(216deg 50% 16%);
  --cream-white:#f0f0f0;
  --grey-color: #0000006b;
  --success-color:#ef0707;
  --sidebar-background: #eaf3f3;
}

  html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--sidebar-background);
}

nav {
  padding: 30px;
}  
.dataTables_wrapper .dataTables_length select{
  border-radius: 10px !important;
}
.dataTables_length label,.dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info,.dataTables_wrapper .dataTables_paginate{
  font-size: 12px;
} 

.dataTables_wrapper .dataTables_filter input{
  border-radius:12px !important;
}
.dataTables_wrapper .dataTables_filter{
  font-size:12px !important;
}

.swal2-modal{
  border-radius:2rem!important;
}
.swal2-confirm{
  background-color: var(--secondary-color) !important;
  border-radius:0.5rem!important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
  font-size:12px!important;
}
.swal2-cancel {
   background-color: #dc3545 !important;
  border-radius:0.5rem!important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
  font-size:12px!important;
}
.swal2-title{
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  
}
</style>



<!-- Modal for editing document approval -->
<div class="modal fade" id="edit_document_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">edit your feed</h5>
        <button type="button" class="btn-close  text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form @submit.prevent="edit_document_data">
        <div class="modal-body">
          <div class="mb-3">
            <label for="name" class="form-label text-xs fw-500">Feed Name <span class="text-danger">*</span></label>
            <input type="text" class="form-control rounded-5 text-xs" id="document_name"value="document_name" placeholder="Enter voice name"
              v-model="document_name">
            <p v-if="errors.edit_document_name" class="text-danger text-xs">{{ errors.edit_document_name }}</p>
          </div>
          <QuillEditor theme="snow"
          v-model="document_description"
          ref="quillDocumentEditorRef"
          class="quill-editor"
          toolbar="full" />
          <div class="d-flex mt-2 mx-1 justify-content-between align-items-start">
            <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-center">
              <label for="editdocumentFileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                <i class='bx bx-image-add file-upload-icon'></i> Add Feed content
              </label>
              <input type="file" id="editdocumentFileInput" class="file-input d-none" @change="handleHomeFilterFileUpload"/>
              <div v-if="editFileName" class="text-xs text-secondary fst-italic mt-1">
                <span class="fw-500">File name:</span> {{ editFileName }}
              </div>
              <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>
            </div>
          </div>
          <div class="d-flex flex-column" v-if="edit_documents.document_approval_files && edit_documents.document_approval_files.length">
            <div class="row g-3">
              <div v-for="(fileObj, index) in edit_documents.document_approval_files" :key="index" class="col-4 mb-3">
                <img v-if="fileObj.file.file_url.includes('.jpg') || fileObj.file.file_url.includes('.png') || fileObj.file.file_url.includes('.jpeg')"
                     :src="fileObj.file.file_url"
                     alt="File Image"
                     class="img-fluid rounded-5"
                     style="max-height: 150px; object-fit: cover;"/>
                <audio v-else-if="fileObj.file.file_url.includes('.mp3') || fileObj.file.file_url.includes('.wav')"
                       controls
                       class="w-100 rounded-5">
                  <source :src="fileObj.file.file_url" type="audio/mp3">
                  Your browser does not support the audio element.
                </audio>

                <video v-else
                       controls
                       class="img-fluid rounded-5 w-100"
                       style="max-height: 150px; object-fit: cover;">
                  <source :src="fileObj.file.file_url" type="video/mp4">
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
        </div>
      </form>
          </div>
    </div>
</div>


<div class="d-flex align-items-center temporary-post" v-if="temporaryPost" id="temp-post">
  <p class="text-xs fw-bold">{{ temporaryPost.document_name }} - Posting...</p>
  <div class="spinner-border ms-auto  text-secondary" role="status" aria-hidden="true"></div>
</div>
<div v-if="document_approvals.length">
  <div
    v-for="document in document_approvals"
    :key="document.id"
    class="card shadow card-hover bg-white border-0 mb-4 report-card p-3 rounded-5 d-flex flex-column full-width-mobile"
  >
    <div class="row flex-grow-1">
      <div class="col">
        <div class="d-flex flex-start">
          <div class="flex-grow-1 flex-shrink-1">
            <div class="d-flex justify-content-between align-items-center">
              <!-- Document name and date -->
              <div>
                <h5 class="fw-500 text-xs mb-1 fw-bold">{{ document.name }}</h5>
                <span class="text-muted small">{{ form_date(document.date_created) }}</span>
              </div>
              <div class="dropstart" @click.stop>
                <a href="#" class="fw-bold text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown">
                  <i class="bi bi-three-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu custom-dropdown">
                  <li v-if="user_details.user_role === 'admin' || user_details.user_role === 'portal_admin'" class="dropdown-item text-xs border-bottom fw-bold" @click.prevent="togglePinDocument(document)">
                    <i :class="document.is_pinned ? 'bx bx-pin' : 'bx bxs-pin'"></i>
                    {{ document.is_pinned ? 'Unpin' : 'Pin' }}
                  </li>
                  <li>
                    <a class="dropdown-item text-xs fw-bold" href="#" data-bs-toggle="modal" data-bs-target="#edit_document_modal" @click="edit_document(document)">
                      <i class="bx bxs-edit"></i> Edit
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item text-xs fw-bold" href="#" @click.prevent="deleteDocument(document.id)">
                      <i class='bx bxs-trash-alt'></i> Delete
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="text-xs display-6 mt-3" v-html="document.description"></div>

        <div v-if="document.document_approval_files.length" class="d-flex flex-column mt-3">
          <div v-for="(fileObj, index) in document.document_approval_files" :key="index" class="mb-3">
            <!-- PDF -->
            <div class="uploaded-file" @click="previewDocument(fileObj)">
              <img :src="getFileIcon(fileObj.file.file_url)" class="file-icon small-icon" />
              <span class="file-name">{{ getFileName(fileObj.file.file_url) }}</span>
              <a :href="fileObj.file.file_url" download :title="getFileName(fileObj.file.file_url)" class="download-btn" @click.stop>
                <img src="https://cdn-icons-png.flaticon.com/128/724/724933.png" class="download-icon" />
              </a>
            </div>
          </div>
        </div>

        <!-- Sent by Section -->
        <div class="mt-3 border-bottom">
          <p><strong>Sent by:</strong> {{ document.creater.first_name }} {{ document.creater.last_name }}</p>
          <p><strong>Approval Required from:</strong></p>
          <ul class="list-unstyled">
            <li v-for="approver in document.reviewer" :key="approver.id">
              {{ approver.first_name }} ({{ approver.role.role }}):
              <span ><b>{{ document.status.name}}</b></span>
            </li>
          </ul>
        </div>

        <!-- Approve, Reject, Request for Changes buttons -->
        <div v-if="isReviewer && document.status.name == 'pending'" class="d-flex gap-2 mt-2">
          <button class="btn btn-success btn-xs rounded-3" @click="approve_document(document.id)">Approve</button>
          <button class="btn btn-warning btn-xs rounded-3 " @click="requestChanges(document.id)">Request for Changes</button>
          <button class="btn btn-danger btn-xs rounded-3" @click="reject_document(document.id)">Reject</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div v-else class="text-center">No available document approvals</div>

<!-- Document Preview Modal -->
<div class="modal fade" id="document_preview_modal" tabindex="-1" aria-labelledby="documentPreviewModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="documentPreviewModalLabel">Document Preview</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <!-- Dynamically display document content -->
        <div v-if="previewDocumentType === 'pdf'">
          <embed :src="previewDocumentUrl" type="application/pdf" width="100%" height="600px">
        </div>
        <div v-else-if="previewDocumentType === 'word'">
          <!-- Word document viewer -->
          <iframe :src="previewDocumentUrl" width="100%" height="600px"></iframe>
        </div>
        <div v-else-if="previewDocumentType === 'excel'">
          <!-- Excel document viewer -->
          <iframe :src="previewDocumentUrl" width="100%" height="600px"></iframe>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>



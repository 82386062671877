import {get_token,get_user_details} from "@/router";

export default {
  computed: {
    filteredEvents() {
      return this.showAll ? this.user_events : this.user_events.slice(0, this.visibleCount);
    }
  }
,  
  mounted() {
    this.fetch_user_events();
    this.token_exists = get_token();

  },

  data() {
    return ({
    user_events :[],
    showAll: false, 
    visibleCount: 4,
    token_exists: false,

    });
  },

  methods: {

    getToken() {
        return get_token();
      },
    toggleShowMore() {
      this.showAll = !this.showAll; // Toggle between showing all and limiting to visibleCount
    },  

    fetch_user_events(){

      const userDetails = get_user_details();
      const userId = userDetails.user_id;

        var value = JSON.stringify({
          filter_values: {user_id: userId},
         })
        $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/filter_event_rsvp/`,
            method: 'POST',
            headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
            },
            data:value,
            success: (response) => {
              if (response.status === 'success') {
                this.user_events = response.rsvp_data;

          }
            },
          }); 
    }

  },
};


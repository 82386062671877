<div class="border-0 legacy-card mt-3 d-none d-xl-block">
    <div class="card-body px-4">
      <ul class="list-unstyled mb-0">  
        <li class="mb-2" v-if="token_exists && user_details.user_role == 'admin' ">
          <a class="d-flex align-items-center justify-content-start text-decoration-none text-dark text-xs fw-500" href="/dashboard">
            <img src="@/assets/images/dashboard.png" class="w-50" alt="">
            <span class=" ms-2">Dashboard</span>
          </a>
        </li>
        <li class="mb-2 mt-4">
          <a href="/home_filter" class ="d-flex align-items-center justify-content-start text-decoration-none text-xs text-dark fw-500">
            <img src="@/assets/images/job-portal.png" class="w-50" alt="">
            <span v-if="cbo_domain" class="link-name">Memberships</span>
            <span v-else class="link-name  ms-2">Portals</span>
          </a>
        </li>
 
        <li class="mb-2 mt-4 d-none">
          <a href="/view_group" class="d-flex align-items-center justify-content-start text-decoration-none text-dark text-xs fw-500 ">
            <img src="@/assets/images/human-resources.png" class="w-50" alt="">
            <span class="link-name  ms-2">Groups</span>
          </a>
        </li>

        <li class="mb-2 mt-4">
          <a href="/view_events" class="d-flex align-items-center justify-content-start text-decoration-none text-dark text-xs fw-500">
            <img src="@/assets/images/calendar.png" class="w-50" alt="">
            <span class="link-name  ms-2">Events</span>
          </a>
        </li>
        <li class="mb-2 mt-4 pointer-events-none">
          <a class="d-flex align-items-center justify-content-start text-decoration-none text-gray text-xs fw-500 ">
            <img src="@/assets/images/orcaaai-icon.png" class="w-50" alt="">
            <span class=" ms-2">My Skill Passport</span>
          </a>
        </li>
  
        
        
        <li class="mb-2 mt-3">
          <a class="d-flex align-items-center justify-content-start text-decoration-none text-gray text-xs fw-500 " href="#">
            <img src="@/assets/images/alert-icon-1551.png" class="w-50" alt="">
            <span class="   ms-2">Maintenance</span>
          </a>
        </li>

        <li class="mb-2 mt-3" v-if="community_domain">
          <a class="d-flex align-items-center justify-content-start text-decoration-none text-gray text-xs fw-500 " href="/community_asset">
            <img src="@/assets/images/community_assets.webp" class="w-50" alt="">
            <span class="   ms-2">Assets</span>
          </a>
        </li>
      </ul>
      
    </div>
</div>

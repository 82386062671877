import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import {clear_token} from './router';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
 
 
import AllCommunityAssets from './components/GetCommunityAssets/CommunityAsset.vue';
import CommunityAssetModal from './components/CommunityAssetModal/CommunityAssetModal.vue';
import FloatingButton from './components/FloatingButton/FloatingButtonComponent.vue';
 
// Set up the global AJAX handler
$(document).ajaxComplete(function(event, xhr) {
    if (xhr.status === 401) {
        clear_token();
        window.location.href = escape("/");
    }
});
 
const domain = window.location.hostname;
let client;
 
if (domain.includes('workstreams') || domain.includes('demo')) {
    client = 'Workstreams';
    import ('@/assets/css/new_style.css');
}
else if (domain.includes('workbook') ) {
    client = 'Workbook';
    import ('@/assets/css/new_style.css');
}

else if (domain.includes('cbo') ) {
    client = 'CBO';
    import ('@/assets/css/new_style.css');
}
else if (domain.includes('919')) {
    client = 'Sandton';
    import ('@/assets/css/new_style.css');
} else if (domain.includes('chamber')) {
    client = 'Chamber';
    import ('@/assets/css/new_style.css');
} else if (domain.includes('community')) {
    client = 'Community';
    import ('@/assets/css/funax.css');
} else if (domain.includes('legacy')) {
  client = 'Legacy';
}
 
// Set client-specific data attribute
if (client) {
    document.documentElement.setAttribute('data-client', client);
}
 
// Create Vue app instance
const app = createApp(App);
 
app.component('AllCommunityAssets', AllCommunityAssets);
app.component('CommunityAssetModal', CommunityAssetModal);
app.component('FloatingButton', FloatingButton);
 
 
// Store client-specific logo in a global property
app.config.globalProperties.$clientLogo =
  client === 'Chamber' ? require('@/assets/images/ictchamberlogo.png') :
  client === 'Workstreams' ? require('@/assets/images/white_workstreams_logo.png') :
  client === 'Sandton' ? require('@/assets/images/919_logo.png') :
  client === 'Community' ? require('@/assets/images/funaxlogo.png') :
  client === 'Legacy' ? require('@/assets/images/legacy_build.png') :
  client === 'Workbook' ? require('@/assets/images/aic_logo.png') :
  client === 'CBO' ? require('@/assets/images/new_cbo_logo.png') :
  require('@/assets/images/workstreams_color_logo.png'); // Default logo
 
// Store client-specific background image in a global property
app.config.globalProperties.$clientBgImage =
  client === 'Workstreams' ? require('@/assets/images/workstream_background.png') :
  client === 'Sandton' ? require('@/assets/images/new_background.png') :
  client === 'Chamber' ? require('@/assets/images/chamber_background.png') :
  client === 'Community' ? require('@/assets/images/funaxbackground.png') :
  client === 'Legacy' ? require('@/assets/images/legacy_background.png') :
  client === 'IC Workbook' ? require('@/assets/images/aic_background.png') :
  client === 'CBO' ? require('@/assets/images/cbo_background.png') :
  require('@/assets/images/aic_background.png'); // Default background image
 
// Store client-specific background section in a global property
app.config.globalProperties.$backgroundSection =
  client === 'Workstreams' ? require('@/assets/images/workstreams_banner.png') :
  client === 'Sandton' ? require('@/assets/images/919_bg.jpg') :
  client === 'Chamber' ? require('@/assets/images/chamber_background.png') :
  client === 'Community' ? require('@/assets/images/funaxback.jpg') :
  client === 'Legacy' ? require('@/assets/images/legacy_background.png') :
  client === 'Workbook' ? require('@/assets/images/ai_banner.jpg') :
  client === 'CBO' ? require('@/assets/images/cbo_banner.jpg') :
  require('@/assets/images/EPF.png'); // Default background section
 
// Store client-specific welcome text in a global property
app.config.globalProperties.$welcomeText =
  client === 'Workstreams' ? 'Building Thriving Communities Effortlessly' :
  client === 'Sandton' ? 'Welcome to  Digital Sandton!!' :
  client === 'Chamber' ? 'Welcome to ICT Chamber' :
  client === 'Community' ? 'Welcome to Funax' :
  client === 'Legacy' ? 'Welcome to Legacy' :
  client === 'Workbook' ? 'Welcome to IC Workbook' :
  client === 'CBO' ? 'Welcome to CBO' :
  'Welcome to Workstreams'; // Default welcome text
 
// Store client-specific title in a global property
app.config.globalProperties.$title =
  client === 'Workstreams' ? 'Workstreams' :
  client === 'Sandton' ? 'Digital Sandton' :
  client === 'Chamber' ? 'ICT Chamber' :
  client === 'Community' ? 'Funax' :
  client === 'Legacy' ? 'Legacy Builder' :
  client === 'Workbook' ? 'IC Workbook' :
  client === 'CBO' ? 'CBO' :
  'Workstreams'; // Default title
 
 
const updateFavicon = () => {
    const favicon = document.querySelector("link[rel='icon']");
 
    if (favicon) {
        favicon.href =
            client === 'Chamber' ? require('../public/images/ict-chamber-logo.png') :
            client === 'Workstreams' ? require('../public/images/probafav.png') :
            client === 'Sandton' ? require('../public/images/sandton_digital_favicon.png') :
            client === 'Community' ? require('@/assets/images/funaxlogo.png') :
            client === 'Legacy' ? require('@/assets/images/legacy_build.png') :
            client === 'Workbook' ? require('../public/images/1.svg') :
            client === 'CBO' ? require('../public/images/cbo_favicon.png') :
            require('../public/images/ictchamberlogo.png'); // Default favicon
    }
};
 
// Call the function to update favicon
updateFavicon();  
 
app.mixin({
mounted() {
    if (this.$title) {
    document.title = this.$title;
    }
}
});
app.component('QuillEditor', QuillEditor);
// After mounting the app, set the background image via CSS variable
app.use(router).mount('#app');
 
// Set the background image as a CSS variable after app is mounted
document.documentElement.style.setProperty('--client-bg-image', `url(${app.config.globalProperties.$clientBgImage})`);
document.documentElement.style.setProperty('--client-bg-section', `url(${app.config.globalProperties.$backgroundSection})`);
 

import { set_token,set_user_details } from "@/router";
import Swal from "sweetalert2";

const PASSWORD_RESET_URL = process.env.VUE_APP_RESET_URL;

export default {
    data(){
        return ({
            email: '',
            password: '',
            passwordVisible: false,
            reset_url: "",
        });
    },
    mounted() {
        this.reset_url = PASSWORD_RESET_URL;

    },
    methods: {
        togglePasswordVisibility() {
            this.passwordVisible = !this.passwordVisible;
        },
        handleSubmit() {

            this.isSubmitting = true;

            $.ajax({
                type: "POST",
                url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/login/`,
                contentType: "application/json",
                data: JSON.stringify({
                    email: this.email.toLowerCase(),
                    password: this.password
                }),
                success: (response) => {

                    const status = response.status;

                    if (status === "success") {
                        Swal.fire({
                            icon: 'success',
                            title: 'Login Successful',
                            showConfirmButton: false,
                            timer: 1000 
                        }).then(() => {

                            var reponse_data = response.data;

                            set_token(reponse_data.token);
                            set_user_details(reponse_data.user_details);

                            const nextUrl = sessionStorage.getItem('next') || '/';
                            window.location = nextUrl;


                        });
                    }
                },
                error: () => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Incorrect Email or Password',
                        });
                    

                },
                complete: () => {
                    this.isSubmitting = false;
                }
            });
        }

    }
};
import { get_token, get_user_details } from "@/router";
import Swal from 'sweetalert2';

export default {
  props: {
    is_public: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    portal_id() {
      return this.$route.params.portal_id;
    },
    isReviewer() {
      if (!this.document_approvals || this.document_approvals.length === 0) {
        return false;
      }

      const reviewerIds = new Set(
        this.document_approvals.flatMap(approval => {
          approval.reviewer?.map(r => r.id) || [];

          return approval.reviewer.map(r => {
            return typeof r.id === "object" ? r.id.value || JSON.stringify(r.id) : r.id;
          });
        })
      );


      const found = Array.from(reviewerIds).some(id => {
        return id === parseInt(this.user_id, 10);
      });

      if (found) {
        return true;
      }

      return false;
    }
  },

  watch: {
    '$route.params.portal_id'() {
      this.fetch_document_approval_request();
    }
  },
  mounted() {
    this.user_details = get_user_details();
    this.token_exists = get_token();
    this.user_id = this.get_user_id();
    this.fetch_document_approval_request();
    this.$forceUpdate();
  },

  data() {

    return ({
      user_details: {
        "user_role": '',
        "user_id": null,
      },
      document_approvals: [],
      file: null,

      reviewers: [],
      edit_documents: [],
      fileName: '',
      token_exists: false,
      errors: {

      },
      editFileName: '',
      previewFileUrl: '',
      previewFileType: ''

    })
  },

  methods: {
    getToken() {
      return get_token();
    },
    get_user_id() {
      const user_id = localStorage.getItem('user_id') || '';
      return user_id;
    },

    fetch_document_approval_request() {

      if (!this.portal_id) return;
      const payload = {
        filter_values: {
          portal_id: this.portal_id

        }
      }

      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/filter_document_approval/`,
        method: 'POST',
        data: JSON.stringify(payload),
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (response) => {
          this.document_approvals = response.data.document_approvals;

          setTimeout(() => {

            this.loading = false;
          }, 1000);
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },

    form_date(dateString) {
      return new Date(dateString).toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    getFileIcon(fileUrl) {
      if (fileUrl.includes('.pdf')) return 'https://cdn-icons-png.flaticon.com/128/337/337946.png';
      if (fileUrl.includes('.doc') || fileUrl.includes('.docx')) return 'https://cdn-icons-png.flaticon.com/128/337/337932.png';  // Word icon
      if (fileUrl.includes('.xls') || fileUrl.includes('.xlsx')) return 'https://cdn-icons-png.flaticon.com/128/337/337958.png';  // Excel icon
      return 'https://cdn-icons-png.flaticon.com/128/337/337940.png' ;
    },

    getFileName(fileUrl) {
      const decodedUrl = decodeURIComponent(fileUrl);
      const cleanUrl = decodedUrl.split('?')[0];
      return cleanUrl.substring(cleanUrl.lastIndexOf('/') + 1);
    },

    // Reset form method
    resetForm() {
      this.document_name = "";
      this.document_description = "";
      this.selected_reviewers = [];
      this.uploaded_files = [];
      this.fileName = "";

      // Reset file input
      this.$nextTick(() => {
        document.getElementById("fileInput").value = "";
      });
    },

    // Handle File Uploads
    handleApprovalFileUpload(event) {
      const file = event.target.files[0];

      if (file) {
        if (file.type.startsWith("audio/")) {
          this.fileError = "Audio files are not allowed.";
          this.upload_media = null;
          this.fileName = "";
          this.imagePreview = null; // Reset preview if an invalid file is selected
          event.target.value = ""; // Clear file input
        } else {
          this.fileName = file.name;
          this.fileError = "";
          this.upload_media = file;

          // Check if the file is an image before previewing
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (e) => {
              this.imagePreview = e.target.result;
            };
            reader.readAsDataURL(file);
          } else {
            this.imagePreview = null; // Reset preview for non-image files
          }
        }
      } else {
        this.fileName = "";
        this.fileError = "";
        this.upload_media = null;
        this.imagePreview = null;
      }
    },

    // Validate form before submitting

    edit_document(document) {
      this.document_name = document.name
      this.document_description = document.description
      this.document_id = document.id
      this.edit_documents = document
      this.$nextTick(() => {
        if (this.$refs.quilldocumentRef) {
          const quillEditor = this.$refs.quillDocumentEditorRef.getQuill();
          if (quillEditor) {
            quillEditor.setContents([
              { insert: document.description || "" },
            ]);
          }
        }
      });
    },

    edit_document_data() {
      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      this.isSubmitting = true;
      const formData = new FormData();
      const plainText = this.$refs.quillDocumentEditorRef.getText().trim();
      this.document_description = plainText;
      formData.append('document_name', this.document_name);
      formData.append('description', this.document_description);
      formData.append('document_approval_id', this.document_id);
      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }

      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/update_document_approval/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          if (response.status === 'success') {
            const updatedDocument = response.updated_data;
            const documentIndex = this.documents.findIndex(
              (hub) => hub.id === updatedDocument.id
            );

            if (documentIndex !== -1) {
              this.documents.splice(documentIndex, 1, updatedDocument);
            }
            Swal.fire({
              icon: 'success',
              title: response.message,
              showConfirmButton: false,
              timer: 1000
            }).then(() => {
              $('#edit_document_modal').modal('hide');
              this.resetSelection();
            });
          }
        },
        error: (response) => {
          Swal.close()
          const response_data = response.responseJSON;
          this.errors.edit_name = response_data.message
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },

    resetSelection() {
      this.editFileName = '';
      this.edit_name = '';
      this.edit_description = '';
      this.edit_documents = [];

      this.editFileName = '';
      this.edit_document_name = '',
        this.edit_document_approval_id = null,
        this.edit_document_description = '',

        this.upload_media = '';
      this.audio_file = null;
      this.errors = {}

    },

    approve_document(document_approval_id) {
      this.isLoading = true;
      const PORTAL_ID = this.$route.params.portal_id;
      const baseUrl = `${window.location.origin}`
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/approve_document_approval/`,
        method: 'POST',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          portal_id: PORTAL_ID,
          base_url: baseUrl, 
          document_approval_id: document_approval_id
        }),
        success: (data) => {
          if (data.status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Document approved successfully.',
              showConfirmButton: false,
              timer: 1000
            });

            // Update document status correctly (if `this.document_approvals` is an array)
            this.document_approvals = this.document_approvals.map(doc =>
              doc.id === document_approval_id ? { ...doc, status: { ...doc.status, name: "approved" } } : doc
            );
          }
        },
        error: (xhr) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: xhr.responseJSON?.message || 'Failed to approve document. Please try again.',
            confirmButtonText: 'OK'
          });
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },


    reject_document(document_approval_id) {
      this.isLoading = true;
      const PORTAL_ID = this.$route.params.portal_id;
      const baseUrl = `${window.location.origin}${this.$route.path}`
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/reject_document_approval/`,
        method: 'POST',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          portal_id: PORTAL_ID,
          base_url: baseUrl,
          document_approval_id: document_approval_id
        }),
        success: (data) => {
          if (data.status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Document rejected successfully.',
              showConfirmButton: false,
              timer: 1000
            });

            // Update document status correctly (if `this.document_approvals` is an array)
            this.document_approvals = this.document_approvals.map(doc =>
              doc.id === document_approval_id ? { ...doc, status: { ...doc.status, name: "rejected" } } : doc
            );
          }
        },
        error: (xhr) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: xhr.responseJSON?.message || 'Failed to reject document. Please try again.',
            confirmButtonText: 'OK'
          });
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },

    requestChanges(document_approval_id) {
      Swal.fire({
        title: 'Request Changes',
        input: 'textarea',
        inputPlaceholder: 'Enter your comments...',
        inputAttributes: {
          'aria-label': 'Enter your comments'
        },
        showCancelButton: true,
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        preConfirm: (comment) => {
          if (!comment) {
            Swal.showValidationMessage('Comment is required.');
          }
          return comment;
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          const PORTAL_ID = this.$route.params.portal_id;
          const baseUrl = `${window.location.origin}${this.$route.path}`;

          $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/request_document_change/`,
            method: 'POST',
            headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
            },
            data: JSON.stringify({
              portal_id: PORTAL_ID,
              base_url: baseUrl,
              document_approval_id: document_approval_id,
              reason: result.value  // Include the comment in the request
            }),
            success: (data) => {
              if (data.status === "success") {
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Document change request submitted successfully.',
                  showConfirmButton: false,
                  timer: 1000
                });

                // Update document status correctly (if `this.document_approvals` is an array)
                this.document_approvals = this.document_approvals.map(doc =>
                  doc.id === document_approval_id ? { ...doc, status: { ...doc.status, name: "request changes" } } : doc
                );
              }
            },
            error: (xhr) => {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: xhr.responseJSON?.message || 'Failed to submit change request. Please try again.',
                confirmButtonText: 'OK'
              });
            },
            complete: () => {
              this.isLoading = false;
            }
          });
        }
      });
    },

    previewDocument(fileObj) {
      if (fileObj && fileObj.file && fileObj.file.file_url) {
        const fileUrl = fileObj.file.file_url;
        const fileType = this.getFileType(fileUrl);
        // Set the modal data
        this.previewDocumentUrl = fileUrl;
        this.previewDocumentType = fileType;
        $("#document_preview_modal").modal("show");
      } else {
        console.error("File object or file URL is missing");
      }
    },

    getFileType(url) {
      if (typeof url !== 'string') {
        return 'unknown';
      }

      if (url.includes('.pdf')) {
        return 'pdf';
      } else if (url.includes('.docx') || url.includes('.doc')) {
        return 'word';
      } else if (url.includes('.xlsx') || url.includes('.xls')) {
        return 'excel';
      }
      return 'unknown'; // Default for unsupported types
    },


    deleteDocument(document_id) {
      Swal.fire({
              title: 'Are you sure?',
              text: 'You won\'t be able to revert this!',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
              if (result.isConfirmed) {
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_document_approval/`,
        method: 'DELETE',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          document_approval_id: document_id
        }),
        success: (data) => {
          if (data.status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Document deleted successfully.',
              showConfirmButton: false,
              timer: 1000
            }).then(() => {
              Swal.close();
              this.fetch_document_approval_request();
            });
          }
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    }
    });
    },
  }
};

<div class="card shadow-sm border-0" v-if="!isHomePage && !isHomeFilter && !is_public && token_exists && (user_details.user_role === 'admin' || user_details.user_role === 'client'||portal_admin)">
  <div class="card-header bg-white d-flex justify-content-between align-items-center py-3">
    <h6 class="mb-0">Portal Requests</h6>
    <div class="d-flex gap-2" v-if="requested_users.length">
      <button class="btn text-success btn-sm" @click.prevent="approve_all_portal_access_requested_users" title="Approve All Users">
        <i class="bi bi-check-circle me-1"></i> Approve All
      </button>
      |
      <button class="btn text-danger btn-sm" @click.prevent="reject_all_portal_access_requested_users" title="Decline All Users">
        <i class="bi bi-x-circle me-1"></i> Decline All
      </button>
    </div>
  </div>
  
  <div class="card-body p-0">
    <div class="list-group list-group-flush" v-if="requested_users.length">
      <div v-for="member in requested_users" :key="member.id" class="list-group-item px-3 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <div class="avatar-wrapper rounded-circle bg-light p-2 me-3">
              <i class="bx bxs-user fs-5 text-primary"></i>
            </div>
            <div>
              <p class="mb-0 fw-medium">
                <a :href="getProfileLink(member)" class="text-decoration-none text-dark">
                  {{ member.first_name }} {{ member.last_name }}
                </a>
              </p>
              <small class="text-muted" v-if="member.email">{{ member.email }}</small>
              <small class="text-muted" v-else>Request pending</small>
            </div>
          </div>
          
          <div class="d-flex gap-2">
            <button @click="approve_portal_access_request(member.id)" 
                    class="text-xxs bg-success text-white shadow border-0 rounded-3" 
                    title="Approve User">
              <i class="bi bi-check-lg"></i>
            </button>
            <button @click="reject_portal_access_request(member.id)" 
                    class="text-xxs bg-danger text-white shadow border-0 rounded-3" 
                    title="Decline User">
              <i class="bi bi-x-lg"></i> 
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <div v-else class="text-center py-4 text-muted">
      <i class="bi bi-people fs-1"></i>
      <p class="mb-0 mt-2">No pending requests available</p>
    </div>
  </div>
</div>
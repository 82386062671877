const domain = window.location.hostname;
export default {
  mounted() {
    this.initSidebar();
    if (domain.includes('cbo')) {
      this.cbo_domain = true

    }
  },
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return ({
      cbo_domain: false,
    });
  },
  methods: {
    initSidebar() {
      let arrows = document.querySelectorAll(".arrow");
      arrows.forEach(arrow => {
        arrow.addEventListener("click", (e) => {
          let arrowParent = e.target.parentElement.parentElement;
          arrowParent.classList.toggle("show-menu");
        });
      });
    },

  }
}
import { get_token ,get_user_details} from "@/router";
import Swal from 'sweetalert2';


export default {
    name: "SprintAgentModal",
    props: {
      sprint_data: Object,
    },
    data() {
      return ({
        token_exists: false,
        offcanvasInstance: null,
        user_details: {
          "user_role": '',
          "first_name": '',
          "last_name": '',
        },
        day_number:null,
        report_name:'',
        isSubmitting : false,
        isLoading:false,
        results: {},
        maxLength: 100,
        isExpanded: {},
        insightExpanded: false,
        portal_admin:false,
        selectedPortalId: null,
        selectedTopicId: '',
        report_url: '',
        isPublic: false,
        file_name:'',
        comments_off: true,
        errors:{},
      });
    },

    mounted() {
      this.errors.day_number=''
        this.token_exists = get_token();
        this.user_details=get_user_details();
        this.fetch_Portal_admin();
    },

    methods: {
    getToken() {
        return get_token();
        },
      openOffcanvas() {
        this.$nextTick(() => {
            this.fetchInsights(this.sprint_data.id);
            let name= this.sprint_data.report_files[0].file.name
            this.report_name=name.split('.').slice(0, -1).join('.');
        });

        
      },
      generate(report_data){
        this.errors.day_number = '';
        this.isLoading=true
        const base_url=`${window.location.origin}${this.$route.path}`;
        const formData = new FormData();
        formData.append('report_id', report_data.id);
        formData.append('day_number', this.day_number);
        formData.append('redirect_url',base_url)

        $.ajax({
            type: 'POST',
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/generate_summary/`,
            data: formData,
            processData: false,
            contentType: false,
            headers: {
            'Authorization': `Token ${this.getToken()}`,
            },
            success: (response) => {
              response.day_number = Number(response.structured_data.day_number) || 0;
              const response_data = response;

              if (!Array.isArray(this.results)) {
                this.results = [];
              }
            
              this.results.unshift(response_data);
              this.day_number = null;

            },
            error: (response) => {
              this.isLoading=false

                  const response_error=response.responseJSON
                  if(response_error.status==="error"){
                    this.errors.day_number=response_error.message
                  }
                },
            complete: () => {
              this.isLoading=false
              }
        });
      },

      fetchInsights(report_id) {
      this.isSubmitting = true;

        this.results = {};
        $.ajax({
          url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/query_insight_code/`,
          method: 'GET',
          headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
          },
          data:{report_id:report_id},
          success: (response) => {
            this.results = response;
          },
          complete: () => {
          this.isSubmitting = false;

          }
        });
      },
      submitReport(result) {
        this.isSubmitting = true;
    
        const formData = new FormData();
        this.selectedPortalId = this.sprint_data.portal.master_portal.id;

        let portalName = this.sprint_data.portal.name;
        let fileName = this.report_name;
        let dayNumber = result.day_number + 1;

        let fullReportName = `${portalName} - ${fileName} - Day ${dayNumber}`;

        formData.append('portal_id', this.selectedPortalId);
        formData.append('report_name',fullReportName)

        formData.append('topic_id', this.selectedTopicId);
        formData.append('is_public', this.isPublic);
        formData.append('can_comment', this.comments_off);

        const formattedDescription = this.formatText(result.daily_insights);
        formData.append('description', formattedDescription);
        const baseUrl = `${window.location.origin}${this.$route.path.replace(/\/\d+$/, '')}`;

        const reportUrl = `${baseUrl}?portal_id=${this.selectedPortalId}&report_id=dynamic_placeholder`;
        formData.append('base_url', reportUrl); 
        formData.append('report_url', reportUrl);
    
        $.ajax({
            type: "POST",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_report_api/`,
            data: formData,
            processData: false,
            contentType: false,
            headers: {
                'Authorization': `Token ${this.getToken()}`,
            },
            success: (response) => {
                const status = response.status;
                if (status === "success") {
                    Swal.fire({
                        icon: 'success',
                        title: 'Report Pushed to Master!',
                        text: 'Your report has been successfully created.',
                        showConfirmButton:false,
                        timer:1000,
                    });
                }
            },
            complete: () => {
                this.isSubmitting = false;
            }
        });
    },

      formatText(text) {
        if (!text) return "";
        return text
            .replace(/### (.*?)\n/g, "<h5 class='mt-3 text-primary'><strong>$1</strong></h5>") // Headings
            .replace(/\*\*(.*?)\*\*/g, "<strong class='fs-6'>$1</strong>") // Bold text (fix)
            .replace(/\n/g, "<br>"); // Line breaks
    },

      truncatedText(text) {
        return text.length > this.maxLength ? text.slice(0, this.maxLength) + "..." : text;
      },
      toggleText(id) {
        this.isExpanded[`${id}`] = !this.isExpanded[`${id}`];
      },

  fetch_Portal_admin() {

    const portal_id = this.$route.params.portal_id;
    const user_id = localStorage.getItem('user_id') || '';

    const payload = {
      filter_values: {
        portal_id: portal_id,
        user_id: user_id
      }
    };

    $.ajax({
      url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_plartform_admin_api/`,
      method: 'POST',
      headers: {
        'Authorization': `Token ${this.getToken()}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(payload),
      success: (data) => {
        const isActive = data.portal_admin_detalails[0].user.is_active;
        if (isActive) {

          this.portal_admin = true;
        }
    },
    complete: () => {
      this.isLoading = false;
    }
  });
},
      
    },
  };

    <!-- Tabs Section -->
    <div class="container-fluid">
      <div class="row">
      <div class="card-header p-0">
        <ul class="nav nav-pills mb-3 mt-2" id="incident-tabs" role="tablist">
          <!-- Report Incident Tab -->
          <li class="nav-item text-xs me-3" role="presentation">
            <a
              class="nav-link"
              id="report-incident-tab"
              href="/incident_reporting"
              role="tab"
              aria-controls="report-incident"
              aria-selected="true"
            >
              <span class="rounded-circle bg-yellow p-1 shadow-sm color-purple">
                <i class="bx bxs-report fs-6"></i>
              </span>
              <span class="mx-2">Report Incident</span>
            </a>
          </li>

          <!-- View Incidents Tab -->
          <li class="nav-item text-xs" role="presentation">
            <a
              class="nav-link active"
              id="view-incident-tab"
              href="/view_incident_reports"
              role="tab"
              aria-controls="view-incident"
              aria-selected="false"
            >
              <span class="rounded-circle bg-yellow p-1 shadow-sm color-purple">
                <i class="bx bxs-message-dots fs-6"></i>
              </span>
              <span class="mx-2">View Incidents</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Table Section -->
  <div class="card bg-white shadow-sm mt-5 rounded-5 border-0">
    <div class="card-header bg-white rounded-top-5 mv-5 shadow bg-dark-purple text-white">
      <span class="fw-500">Incident reported</span>
    </div>
    <div class="card-body table-responsive">
      <div class="modal fade" id="create_portal" tabindex="-1" aria-labelledby="create_portalModalLabel"
        aria-hidden="true">

      </div>
      <table class="table table-striped text-xs mt-3">
        <thead class="bg-dark text-white">
          <tr>
          <th scope="col">REF</th>
          <th scope="col">Incident</th>
          <th scope="col">Status</th>
          <th scope="col">Date Created</th>
          <th scope="col">Incident Creator</th>
          <th scope="col">Location</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="report in reports">
            <td>{{report.ref_number}}</td>
            <td>{{report.submit_incident.name}}</td>
            <td>{{report.incident_status.name}}</td>
            <td>{{report.date_created}}</td>
            <td>{{report.user.first_name}} {{report.user.last_name}}</td>
            <td>{{report.location}}</td>
            <td>
              <button class="btn btn-info text-xxs rounded-5 mx-1 btn-sm shadow"v-if="user_details.user_role !='customer'" @click="openUpdateStatusModal(report)">Edit status</button>
              <button class="btn btn-secondary text-xs rounded-5 mx-1 btn-sm shadow" @click="openReportModal(report)">View</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal fade" id="viewReportModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="viewReportModalLabel" aria-hidden="true">
    <div class=" modal-dialog modal-custom">

      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title" id="viewReportModalLabel">Report Details</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div class=" text-s card card-hover bg-transparent border-0 mb-3">

          <p> <strong>Incident Category</strong>: {{selectedReport.report_title}} </p>
          <p> <strong>REF</strong>: {{selectedReport.ref_number || 'N/A'}}</p>
          <p> <strong>Status</strong>: {{selectedIncidentStatus.name}}</p>
          <p> <strong>Date Created</strong>: {{selectedReport.date_created}}</p>
          <p> <strong>Incident Creator</strong>: {{selectedUser.first_name}} {{selectedUser.last_name}}</p>
          <p> <strong>Location</strong>: {{selectedReport.location}} </p>
        </div>

        <div class="image-container position-relative">
          <div v-if="selectedReport.incident_report_files && selectedReport.incident_report_files.length">
              <div v-for="(fileObj, index) in selectedReport.incident_report_files" :key="index" class="mb-3">
                  
                  <img v-if="fileObj.file.file_url.includes('.jpg') ||
                    fileObj.file.file_url.includes('.png') ||
                    fileObj.file.file_url.includes('.jpeg')"
                    :src="fileObj.file.file_url"
                    alt="Feed Image"
                      class="img-fluid rounded-5 w-100"
                      ref="image"/>

                </div>
            </div>

        </div>
        </div>
          

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>

      </div>

    </div>
  </div>

  <div
  class="modal fade"
  id="updateStatusModal"
  tabindex="-1"
  aria-labelledby="updateStatusModalLabel"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateStatusModalLabel">{{selectedIncidentButton.name}} Report</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>

      <div class="modal-body">
        <p>Report issue with the reference no: <strong>{{selectedReport.ref_number}}</strong></p>
        <div class="form-group mt-3 p3">
          <label for="status_id" ref="status_id"class="form-label fw-500">Status</label>
          <select
            id="status_id"
            class="form-control text-xs rounded-5"
            v-model="status_id"
          >
          <option class="text-muted" value="">{{selectedIncidentStatus.name}}</option>
            <option v-for="status in report_status" :key="status.id" :value="status">
              {{ status.name }}</option>
          </select>
        </div>
      </div>
      
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary text-xs rounded-5 mx-1 btn-sm shadow"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-info text-xs rounded-5 mx-1 btn-sm shadow"
          @click="updateReportStatus"
        >
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>
<div class="legacy-card mt-2 shadow bg-white d-none d-xl-block " v-if="token_exists">
  
    <div class="profile-section"></div>
    
    <!-- Profile Content -->
    <div class="profile-content">
        <!-- Avatar Circle -->
        <div class="avatar-circle">
            <i class="bi bi-person-fill"></i>
        </div>
        
        <!-- User Info -->
        <h5 class="username">{{user_details.first_name}} {{user_details.last_name}}</h5>
        <p class="user-email">{{user_details.email}}</p>
        <p>{{user_details.phone_number}}</p>
        

    </div>
        
    <!-- Button -->
    <div class="p-3">
        <button class="btn btn-primary w-100 p-2 fw-500" @click="getProfileLink">My Profile</button>
    </div>
</div>
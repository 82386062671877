<div>
  <Sidebar ref="sidebar"></Sidebar>
  <section class="home-section">
    <Navbar @toggle-sidebar="toggleSidebar"></Navbar>


    <div class="card bg-white shadow-sm mt-5 rounded-5 border-0">
      <div class="card-header bg-white rounded-top-5 mv-5 shadow bg-dark-purple text-white">
        <span class="fw-500">Roles created</span>
        <button class="btn btn-warning text-white text-xs rounded-5 shadow-lg fw-500 float-end" data-bs-toggle="modal"
          data-bs-target="#create_position">
          <i class='bx bx-plus'></i> Create Role
        </button>
        <button class="btn btn-warning text-white text-xs rounded-5 shadow-lg fw-500 float-end" data-bs-toggle="modal"
          data-bs-target="#assign_position">
          <i class='bx bx-plus'></i> Assign roles
        </button>
      </div>
      <div class="card-body table-responsive">
        <div class="modal fade" id="create_position" tabindex="-1" aria-labelledby="create_positionModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create a Position</p>
                <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <form @submit.prevent="create_role">
                <div class="modal-body">

                  <div class="mb-3">
                    <label for="position_name" class="form-label text-xs fw-500">Position Name <span
                        class="text-danger">*</span></label>
                    <input type="text" class="form-control rounded-5 text-xs" id="position_name"
                      placeholder="Enter position name" v-model="position_name">
                    <p v-if="errors.position_name" class="text-danger text-xs">{{ errors.position_name }}</p>
                  </div>

                </div>
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <table class="table table-striped text-xs mt-3">
          <thead class="bg-dark text-white">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Portal Roles</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(position, index) in positions" :key="position.id">
              <th scope="row">{{ index + 1 }}</th>
              <td id="position_id">{{ position.name }}</td>
              <td>
                <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" data-bs-toggle="modal"
                  :data-bs-target="'#edit_position_' + position.id" @click="editpositionName(position)"><i
                    class="bi bi-pencil-square"></i> Edit</button>
                <button @click="deleteposition(position.id)" class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow"><i
                    class="bi bi-trash"></i> Delete</button>

                <div class="modal fade" :id="'edit_position_' + position.id" tabindex="-1"
                  :aria-labelledby="edit_position_ + position.id + 'Label'" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Edit position</p>
                        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal"
                          aria-label="Close"></button>
                      </div>
                      <form @submit.prevent="edit_portal_position(position)">
                        <div class="modal-body">

                          <div class="mb-3">
                            <label :for="'edit_position_name_' + position.id" class="form-label text-xs fw-500">position Name <span
                                class="text-danger">*</span></label>
                            <input type="text" class="form-control rounded-5 text-xs"
                            :id="'edit_position_name_' + position.id" v-model="edit_position">
                            <p v-if="errors.position_name" class="text-danger text-xs">{{ errors.position_name }}</p>
                          </div>

                        </div>
                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body table-responsive">
        <div class="modal fade" id="assign_position" tabindex="-1" aria-labelledby="assignPositionModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <p class="modal-title text-xs fw-500" id="assignPositionModalLabel">Assign Position</p>
                <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <form @submit.prevent="assignPosition">
                <div class="modal-body">
                  <table class="table table-striped text-xs mt-3">
                    <thead class="bg-dark text-white">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Portal name</th>
                        <th scope="col">Assign Roles</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(portal, index) in portals" :key="portal.id">
                        <th scope="row">{{ index + 1 }}</th>
                        <td id="portal_id">{{ portal.name }}</td>
                        <td>
                          <div class="mb-3">
                            <div v-if="portal.positions.length">
                              <span v-for="(pos, posIndex) in portal.positions" :key="pos.id" class="btn-primary badge bg-primary text-white me-1">
                                {{ pos.name }} 
                                <button type="button" class="btn-primary btn-close ms-1" 
                                  aria-label="Remove"
                                  @click="remove_position(pos.id, portal.id,posIndex)"></button>
                              </span>
                            </div>
                              <select 
                                  class="form-control text-xs mt-2"
                                  @click="assign_position_to_portal($event, portal.id)"
                                >
                                  <option value="">Select a position</option>
                                  <option 
                                    v-for="pos in availablePositions(portal)" 
                                    :key="pos.id" 
                                    :value="pos.id"
                                  >
                                    {{ pos.name }}
                                  </option>
                                </select>

                            <datalist id="positionsList">
                              <option v-for="pos in all_positions" :key="pos.id" :value="pos.name"
                              ></option>
                            </datalist>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<Loader :loading="loading" />
<HomeNavBar />

<div class="mx-auto ">
  <h2 class="fw-bold text-center text-uppercase mt-2">
    Upcoming <span class="fw-bolder">Events</span>
</h2>
  <!-- Filters & Search -->
    <!-- Event List -->
    <div class="p-2 scroll-container">
      <div v-for="(group, date) in filteredEvents" :key="date" class="mb-3">

        <!-- Date Header with Toggle -->
        <button class="date-header btn btn-dark w-200" @click="toggleEvents(date)">
          <span>{{ date }}</span>
          <span class="indicator ms-2">{{ activeDates[date] ? '-' : '+' }}</span>
        </button>
        
        <!-- Event Cards (Grouped by Date) -->
        <div  class="event-group mx-5">
          <div v-for="event in group" :key="event.id" 
               class="card event-card lh-sm mt-2 border-0 border-bottom-primary position-relative" 
               @click.stop>
            
            <!-- Arrow pointing to the icon -->
            <div class="event-arrow"></div>
        
            <div class="card-body">
              <div class="dropend float-end" @click.stop
                   v-if="String(event.user.id) === String(get_user_id()) || user_details.user_role === 'admin'">
                <a href="" class="fw-bold text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown"
                   aria-expanded="false">
                  <i class="bi bi-three-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu custom-dropdown lh-1" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item text-xs border-bottom fw-bold d-none" href=""
                     @click.prevent="togglePinReport(event)">
                    <i :class="event.is_pinned ? 'bx bx-pin fs-6' : 'bx bxs-pin fs-6'"></i>
                    {{ event.is_pinned ? 'Unpin' : 'Pin' }}
                  </a>
                  <li>
                    <a class="dropdown-item text-xs fw-bold" href="" @click.prevent="edit_event(event)">
                      <i class='bx bxs-edit fs-6'></i> Edit
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item text-xs fw-bold" href="" @click.prevent="deleteEvent(event.id)">
                      <i class='bx bxs-trash-alt fs-6'></i> Delete
                    </a>
                  </li>
                </ul>
              </div>
              <p class="card-title fw-bold">{{ event.event_name }}</p>
              <p class="text-muted text-xs">
                <i class="bi bi-clock text-primary"></i>
                {{ event.start_time ? form_time(event.start_time) : "" }} - 
                {{ event.end_time ? form_time(event.end_time) : "" }}
                <span>
                  <i class="bi bi-geo-alt text-danger"></i> {{ event.location }}
                </span>
              </p>
              <span href="#" @click.stop="selectEvent(event)" data-bs-toggle="modal" data-bs-target="#eventModal" class="badge bg-primary mt-0">
                See more
              </span>
            </div>
          </div>
        </div>
        
      </div>
    </div>


  <!-- Event Details Modal -->
  <div class="modal fade" id="eventModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content rounded">
        <div class="modal-header">
          <h5 class="modal-title fw-bold">{{ selectedEvent.event_name }}</h5>
          <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body modal-body-scroll">
          <p><strong>Date:</strong>
            {{ selectedEvent.start_date ? form_date(selectedEvent.start_date) : "Date TBA" }} </p>
          <p><strong>Time:</strong> {{ selectedEvent.start_time ? form_time(selectedEvent.start_time) : "" }} - {{
            selectedEvent.end_time ? form_time(selectedEvent.end_time) : "" }}</p>
          <p><strong>Location:</strong> {{ selectedEvent.location }}</p>
          <p><strong>Description:</strong><span v-html="selectedEvent.description"></span></p>
        </div>
        <div class="modal-footer">

          <a v-if="selectedEvent.event_type?.name === 'online' || selectedEvent.event_type?.name === 'hybrid' && selectedEvent.event_link"
            :class="['btn', 'btn-sm', 'fw-500', 'me-2', 'btn-primary']" :href="selectedEvent.event_link">
            Join Event
          </a>

          <span v-if="!isWithinRSVPPeriod(selectedEvent).isValid" class="d-inline-block">
            <button :class="[
                     'btn',
                     'btn-sm',
                     'fw-500',
                     selectedEvent.is_rsvped ? 'btn-warning' : 'btn-primary'
                   ]" disabled style="pointer-events: none;">
              {{ selectedEvent.is_rsvped ? 'RSVP’d' : 'RSVP Now' }}
            </button>
            <p  class="text-danger text-xs">{{ isWithinRSVPPeriod(selectedEvent).message }} </p>

          </span>

          <button v-else :class="[
                   'btn',
                   'btn-sm',
                   'fw-500',
                   selectedEvent.is_rsvped ? 'btn-warning' : 'btn-primary'
                 ]" @click="fetchEvents_extras(selectedEvent.id)">
            {{ selectedEvent.is_rsvped ? 'RSVP’d' : 'RSVP Now' }}
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="modal fade" id="rsvp_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-title text-xs fw-500" id="exampleModalLabel">RSVP</p>
                <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="addRsvp">
                <div class="modal-body modal-body-scroll">
                    <!-- Menu Selection -->
                    <div v-for="extra in eventExtras" :key="extra.id" class="form-check mb-2">
                        <label class="form-check-label text-xs" :for="extra.extra.id">{{ extra.extra.name }}</label>
                        <div v-for="item in extra.items" :key="item.item_name" class="d-flex align-items-center gap-2 mb-2">
                          <input class="form-check-input mt-1" type="checkbox"
                                 :name="item.item_name" :id="item.item_name"
                                 v-model="selectedItemId" :value="item.id">
                          <div class="d-flex justify-content-between w-100">
                              <div class="d-inline-flex flex-wrap text-muted">
                                  <strong>{{ item.item_name }}</strong>
                                  <span class="fst-italic ms-1">{{ item.description }}</span>
                              </div>
                              <div v-if="!workbook_domain" class="text-end fst-italic text-primary ms-3">
                                  (R{{ item.price }})
                              </div>
                          </div>
                      </div>


                    </div>

                    <!-- Validation Message -->
                    <p v-if="formError" class="text-danger text-xs">Please select at least one item to RSVP.</p>

                    <!-- Dietary Restrictions -->
                    <div class="mb-4 d-none">
                        <label class="form-label text-xs fw-500">Additional Restrictions</label>
                        <textarea class="form-control rounded-5 text-xs" rows="2"
                                  placeholder="Please specify any allergies or restrictions" v-model="dietaryRestrictions"></textarea>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary text-xs rounded-5" :disabled="!selectedItemId.length">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>



  <!-- Create Event -->
  <div class="modal fade" id="event_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title text-xs fw-500" id="exampleModalLabel">{{ event_id ? 'Edit Event' : 'Create your event'
            }}
          </p>
          <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form @submit.prevent="event_id ? update_event() : submit_form()">
          <div class="modal-body modal-body-scroll">

            <div class="mb-3">
              <label for="event_name" class="form-label text-xs fw-500">Event Name <span
                  class="text-danger">*</span></label>
              <input type="text" class="form-control rounded-5 text-xs" id="event_name" placeholder="Enter event name"
                v-model="event_name">
              <p v-if="errors.event_name" class="text-danger text-xs">{{ errors.event_name }}</p>
            </div>
            <div class="d-flex flex-row justify-content-between mx-1">
            </div>
            <div class="d-flex flex-row justify-content-between mx-1">
              <div v-if="$route.name !== 'home_filter' && !event_id">
                <label for="portals" class="form-label text-xs fw-500"><span class="text-danger">*</span>Tag a portal :
                </label>
                <select class="text-xs btn-primary fw-500 border-0 rounded-5 btn-sm mb-3 border-end"
                  v-model="selectedPortalId" id="portals">
                  <option value="" selected hidden>
                    * Tag a portal
                  </option>
                  <option v-for="portal in portals" :value="portal.id" :key="portal.id">{{ portal.name }}</option>
                </select>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label text-xs fw-500">Event Type <span class="text-danger">*</span></label>
              <div class="d-flex flex-row align-items-center">
                <div v-for="(type, index) in event_types" :key="index" class="form-check me-3">
                  <input type="radio" class="form-check-input" :value="type" v-model="event_type" :id="`${type}_event`"
                    :name="`event_type`" />
                  <label :for="`${type}_event`" class="form-check-label">{{ type.name }}</label>
                </div>
              </div>
              <p v-if="errors.event_type" class="text-danger text-xs">{{ errors.event_type }}</p>
            </div>

            <div class="d-flex mt-2 mx-1 justify-content-between align-items-start border-bottom">
              <div class="file-upload my-2 me-4 d-flex flex-column align-items-center">
                <label for="fileInput"
                  class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex">
                  <i class='bx bx-image-add file-upload-icon'></i> Add event content
                </label>
                <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
                <div v-if="fileName" class="text-xs text-secondary fst-italic mt-1">
                  <span class="fw-500">File name:</span> {{ fileName }}
                </div>
                <div v-if="fileError" class="text-xs text-danger fst-italic mt-1">{{ fileError }}</div>

                <div v-if="imagePreview" class="mt-2">
                  <img :src="imagePreview" alt="Preview" class="img-fluid rounded"
                    style="max-width: 200px; max-height: 200px;" />
                </div>
              </div>
            </div>
            <QuillEditor theme="snow" v-model="event_description" ref="quillEventEditorRef" class="quill-editor"
              placeholder="Enter your description here..." toolbar="full" />
            <div class="row">
              <!-- Event Start Date -->
              <div class="col-md-3 mb-3">
                <label for="start_date" class="form-label text-xs fw-500">Event Start Date <span
                    class="text-danger">*</span></label>
                <input type="date" class="form-control rounded-5 text-xs" id="start_date" v-model="start_date">
                <p v-if="errors.start_date" class="text-danger text-xs">{{ errors.start_date }}</p>
              </div>
              <div class="col-md-3 mb-3">
                <label for="start_time" class="form-label text-xs fw-500">Start Time <span
                    class="text-danger">*</span></label>
                <input type="time" class="form-control rounded-5 text-xs" id="start_time" v-model="start_time">
                <p v-if="errors.start_time" class="text-danger text-xs">{{ errors.start_time }}</p>
              </div>

              <!-- Event End Date -->
              <div class="col-md-3 mb-3">
                <label for="end_date" class="form-label text-xs fw-500">Event End Date <span
                    class="text-danger">*</span></label>
                <input type="date" class="form-control rounded-5 text-xs" id="end_date" v-model="end_date">
                <p v-if="errors.end_date" class="text-danger text-xs">{{ errors.end_date }}</p>
              </div>
              <div class="col-md-3 mb-3">
                <label for="end_time" class="form-label text-xs fw-500">End Time <span
                    class="text-danger">*</span></label>
                <input type="time" class="form-control rounded-5 text-xs" id="end_time" v-model="end_time">
                <p v-if="errors.end_time" class="text-danger text-xs">{{ errors.end_time }}</p>
              </div>

              <!-- RSVP Start Date -->
              <div class="col-md-3 mb-3">
                <label for="rsvp_start_date" class="form-label text-xs fw-500">RSVP Start Date <span
                    class="text-danger">*</span></label>
                <input type="date" class="form-control rounded-5 text-xs" id="rsvp_start_date"
                  v-model="rsvp_start_date">
                <p v-if="errors.rsvp_start_date" class="text-danger text-xs">{{ errors.rsvp_start_date }}</p>
              </div>
              <div class="col-md-3 mb-3">
                <label for="rsvp_start_time" class="form-label text-xs fw-500">RSVP Start Time <span
                    class="text-danger">*</span></label>
                <input type="time" class="form-control rounded-5 text-xs" id="rsvp_start_time"
                  v-model="rsvp_start_time">
                <p v-if="errors.rsvp_start_time" class="text-danger text-xs">{{ errors.rsvp_start_time }}</p>
              </div>

              <!-- RSVP End Date -->
              <div class="col-md-3 mb-3">
                <label for="rsvp_end_date" class="form-label text-xs fw-500">RSVP End Date <span
                    class="text-danger">*</span></label>
                <input type="date" class="form-control rounded-5 text-xs" id="rsvp_end_date" v-model="rsvp_end_date">
                <p v-if="errors.rsvp_end_date" class="text-danger text-xs">{{ errors.rsvp_end_date }}</p>
              </div>
              <div class="col-md-3 mb-3">
                <label for="rsvp_end_time" class="form-label text-xs fw-500">RSVP End Time <span
                    class="text-danger">*</span></label>
                <input type="time" class="form-control rounded-5 text-xs" id="rsvp_end_time" v-model="rsvp_end_time">
                <p v-if="errors.rsvp_end_time" class="text-danger text-xs">{{ errors.rsvp_end_time }}</p>
              </div>
            </div>

            <div class="mb-3">
              <label for="organizer" class="form-label text-xs fw-500">Organizer <span
                  class="text-danger">*</span></label>
              <input type="text" class="form-control rounded-5 text-xs" id="organizer"
                placeholder="Enter organizer name" v-model="organizer">
              <p v-if="errors.organizer" class="text-danger text-xs">{{ errors.organizer }}</p>
            </div>
            <div v-if="event_type.name === 'online' || event_type.name === 'hybrid'" class="mb-4">
              <label for="event_link" class="form-label text-xs fw-500 d-block">Event Link <span
                  class="text-danger">*</span></label>
              <input id="event_link" type="url" class="form-control rounded-5 text-xs"
                placeholder="Enter event link (e.g., Zoom, Google Meet,Teams)" v-model="event_link" />
              <p v-if="errors.event_link" class="text-danger text-xs">{{ errors.event_link }}</p>
            </div>
            <div v-if="event_type.name !== 'online'" :key="event_type.name" class="mb-4">
              <label for="mapSearchBox" class="form-label text-xs fw-500 d-block">Search for Location</label>
              <input id="mapSearchBox" type="text" class="form-control rounded-5 text-xs"
                placeholder="Search location..." v-model="location" />
              <p v-if="errors.location" class="text-danger text-xs">{{ errors.location }}</p>
            </div>

            <div class="event-extras">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h3 class="text-xs fw-500 mb-0">Event Extra</h3>
                <button type="button" class="btn btn-outline-primary btn-sm rounded-5 text-xs"
                  @click.prevent="addExtra">
                  <i class="bx bx-plus"></i>
                  Add Extra Item
                </button>
              </div>

              <div id="extraItemsList" class="mt-3">
              </div>
            </div>

          </div>

          <div class="container mt-4">
            <div v-for="(extraForm, extraIndex) in extraForms" :key="extraIndex" id="select_extra_container"
              class="border rounded-3 p-3 mt-3 bg-light">

              <input v-model="extraForm.id" type="text"  class="d-none extra_form_id">

              <div class="mb-3">
                <label class="form-label text-xs fw-500">Select Extra:</label>
                <input type="text" class="form-control selectExtra" v-model="extraForm.selectedExtra"
                  placeholder="Enter or select extra" :list="'extrasList-' + extraIndex">
                <datalist :id="'extrasList-' + extraIndex">
                  <option v-for="extra in availableExtras" :key="extra.id" :value="extra.name"></option>
                </datalist>
              </div>

              <div v-for="(item, itemIndex) in extraForm.items" :key="itemIndex + '_extra'"
                class="border rounded-3 p-2 mb-2 bg-white">

                <input v-model="item.id" type="text" class="d-none item_id">

                <div class="mb-2">
                  <label class="form-label text-xs fw-500">Item Name:</label>
                  <input v-model="item.item_name" type="text" class="form-control rounded-5 text-xs item_name"
                    placeholder="Enter item name">
                </div>


                <div class="mb-2">
                  <label class="form-label text-xs fw-500">Description:</label>
                  <textarea v-model="item.item_description" type="text" class="form-control rounded-3 text-xs item_description" rows="2"
                    placeholder="Enter description"></textarea>
                </div>


                <div class="mb-2">
                  <label class="form-label text-xs fw-500">Price:</label>
                  <input v-model="item.item_price" type="number" class="form-control rounded-5 text-xs item_price"
                    placeholder="0.00" step="0.01" min="0">
                </div>


                <button @click.prevent="removeExtraItem(extraIndex, itemIndex + 1)" class="btn btn-danger btn-sm">
                  <i class="bx bx-trash"></i>
                </button>
              </div>


              <button @click.prevent="addExtraItem(extraIndex)" class="btn btn-outline-success btn-sm mt-2">
                + Add Extra Item
              </button>


              <button @click.prevent="removeExtra(extraIndex)" class="btn btn-danger btn-sm mt-2">
                Remove Extra
              </button>

            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary text-xs rounded-5">{{ event_id ? 'Update' : 'Post' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div>
    <Sidebar ref="sidebar"></Sidebar>
    <section class="home-section">
      <Navbar @toggle-sidebar="toggleSidebar"></Navbar>
      <div class="card bg-white shadow-sm mt-5 rounded-5 border-0">
        <div class="card-header bg-white border-0 rounded-5">
          <button class="btn btn-primary text-xs rounded-5 shadow fw-500" data-bs-toggle="modal" data-bs-target="#create_ext_report"><i class='bx bx-plus'></i> Create External Feed</button>
          <div class="modal fade" id="create_ext_report" tabindex="-1" aria-labelledby="create_ext_reportModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <p class="modal-title text-xs fw-500" id="create_ext_reportModalLabel">Create External Feed</p>
                  <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="submit_form">
                  <div class="modal-body">
          
                    <div class="mb-3">
                      <label for="report_name" class="form-label text-xs fw-500">Feed Name <span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control rounded-5 text-xs" id="report_name" placeholder="Enter feed name"
                        v-model="report_name">
                        <p v-if="errors.report_name" class="text-danger text-xs">{{ errors.report_name }}</p>
                    </div>
                    <div class="mb-3">
                      <label for="report_url" class="form-label text-xs fw-500">Feed URL<span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control rounded-5 text-xs" id="report_url" placeholder="Enter feed name"
                        v-model="report_url">
                        <p v-if="errors.report_url" class="text-danger text-xs">{{ errors.report_url }}</p>
                    </div>
                    </div>
                    <div class="mt-2 mx-1 border-bottom ">
                      <div class="d-flex flex-row justify-content-between">
                        <div class="file-upload">
                          <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light"><i
                              class='bx bx-image-add file-upload-icon'></i> Add feed content</label>
                          <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
                        </div>
                        <div v-if="fileName" class="text-xs mt-2 text-secondary text-danger fst-italic"><span class="fw-500">File
                            name:</span>{{ fileName }}</div>
                      </div>
                    </div>
                  <div class="modal-footer">
                    <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body table-responsive"> 
          <table class="table table-striped text-xs">
            <thead class="bg-dark text-white ">
              <tr>
                <th scope="col">#</th>
                <th scope="col">External Feed</th>
                <th scope="col">Date Created</th>
                <th scope="col">Created By</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="(external_report, index) in external_reports" :key="external_report.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ external_report.external_report_name }}</td>
                <td>{{ external_report.date_created }}</td>
                <td>{{ external_report.user.first_name }}</td>
                <td>
                  <router-link to="create_external_report"><button class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow"   @click="delete_external_report(external_report.id)"><i class="bi bi-trash"></i> Delete</button></router-link> 
                </td>
              </tr>
            </tbody>
            
          </table>

        </div>

      </div>

    </section>
  </div>
import { get_token} from "@/router";
import Swal from 'sweetalert2';

export default {
  computed: {
    isHomePage() {
      return this.$route.name === 'home'; // Assuming your home page route is named 'home'
    },
  },
    mounted() {
      this.fetchGroups();
      this.token_exists = get_token();
      this.fetch_group_feeds();
      this.user_id = this.get_user_id();
    },
  
    data() {
      return ({
        groups:[],
        group_name:'',
        group_description:'',
        is_public:true,
        isLoading: false,
        token_exists: false,
        errors:{},
      });
    },
    methods:{
        getToken() {
            return get_token();
          },
        fetchGroups() {
            this.isLoading = true;
            $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_all_group/`,
            method: 'GET',
            headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
            },
            success: (data) => {
                
                this.groups = data.groups;
            },
            complete: () => {
                this.isLoading = false;
            }
            });
        },
        createGroup() {
          if (!this.handle_unauthenticated()) {
            return;
        }
              this.isSubmitting = true;
              const Data = {
                name: this.group_name,
                is_public:this.is_public,
              };
              if (this.group_description.trim()) {
                Data.description = this.group_description;
              }
      
              $.ajax({
                type: "POST",
                url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/create_group/`,
                contentType: "application/json",
                headers: {
                  'Authorization': `Token ${this.getToken()}`,
                  'Content-Type': 'application/json'
                },
                data: JSON.stringify(Data),
                success: (response) => {
                  const status = response.status;
                  if (status === "success") {

                    Swal.fire({
                      icon: 'success',
                      title: 'Success',
                      text: 'Group Created Successfully',
                      showConfirmButton: false,
                      timer: 2000
                    }).then(() => {
                      window.location.reload();
                      $('#create_group_modal').modal('hide');
                    });
                  }
                },
                error: (response) => {
                  const response_data = response.responseJSON;
                  if (response_data.status==="error") {
                    this.errors.group_name =response_data.message
                  }
                },
                complete: () => {
                  this.isSubmitting = false;
                }
              });

          },

        handle_unauthenticated() {
            if (!this.token_exists) {
                this.$router.push({ name: 'sign_in_finale'}); 
              return false;
            }
            return true;
          },
        baseUrl(post, type) {
          const baseUrl = `${window.location.origin}${this.$route.path}`;
          if (type === 'voice') {
            return `${baseUrl}?voice_id=${post.id}`;
          }else if(type==='feed'){
            return `${baseUrl}?feed_id=${post.id}`;
          }
          return baseUrl
        },
        getIdFromURL(param) {
          const urlParams = new URLSearchParams(window.location.search);

          return urlParams.get(param);
        },
        open_group(group_id,group_name) {
              if (!this.handle_unauthenticated()) {
                return;
              }
              this.$router.push(`/view_group/${group_id}/${group_name}`);
              this.fetch_group_feeds()
        },
        fetch_group_feeds() {
          this.isLoading = true;
          $.ajax({
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_group_feed/`, 
            method: 'GET',
            headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
            },
            data: { group_id: this.$route.params.group_id},
            success: (response) => {
              var status = response.status;
              if (status === "success") {
                this.group_feeds= response.feeds;
                const feed_id = this.getIdFromURL('feed_id');
                if (feed_id) {
                  const groupFeed = this.group_feeds.find(feed => feed.id === parseInt(feed_id,10));
                  if (groupFeed) {
                    this.display_comment_modal(groupFeed);
                  }
                }

                this.group_feeds.forEach((feed) => {
                if(feed && feed.id){
                  this.getGroupFeedLikes(feed);
                }

                });
              }
            },
            complete: () => {
              this.isLoading = false;
            }
          });
        },
        display_comment_modal(feed_data) {

          if (!this.handle_unauthenticated()) {
            return;
          }

          this.feed_data = feed_data;
          this.comment_modal_visible = true;
        },
        addNewGroupFeed(newFeed){
          if(newFeed){
            this.group_feeds.unshift(newFeed
            );
          }
        },
        getGroupFeedLikes(group_feed) {
          if (!group_feed || !group_feed.id) {
            return;
          }
          $.ajax({
            type: "GET",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/group_management/retrieve_group_feed_likes/`,
            headers: {
              'Authorization': `Token ${this.getToken()}`,
            },
            data: { group_feed_id: group_feed.id },
            success: (response) => {
              if (response.status === "success") {
                const likes = response.data;
                group_feed.like_count = likes.length;

                const userId = this.get_user_id();
                const isLiked = likes.some(like => like.user.id === parseInt(userId, 10));

                const likeButton = document.getElementById(`like-button${group_feed.id}`);
                const likedButton = document.getElementById(`liked-button${group_feed.id}`);

                if (likeButton) {
                  likeButton.classList.toggle('d-none', isLiked);
                }

                if (likedButton) {
                  likedButton.classList.toggle('d-none', !isLiked);
                }
              }
            },
          });
        },
        get_user_id() {
          const user_id = localStorage.getItem('user_id') || '';
          return user_id;
        },
    }
}
<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="comment_label"
    aria-hidden="true" id="community_asset_modal">
    <div class="modal-fullscreen modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <button type="button" class="btn-close" @click="hide_modal"></button>
            </div>

            <!-- Modal Body -->
            <section>
                <div class="row content-section">
                    <!-- Left Sidebar -->
                    <div class="col-md-3">
                        <div class="d-none d-md-block mt-4">
                            <div class="mx-5 rounded-5 p-2 pb-5 position-sticky top-20px">
                              <h6 class='text-start mx-1'>Community Assets</h6>
                              <hr />
                              <!-- Create community_asset Modal -->
                              <div class="table-container">
                                <table v-if="community_assets.length" class="table mb-0">
                                  <tbody>
                                    <tr v-for="community_asset in community_assets" :key="community_asset.id" class="hover-row border-0 ">
                                      <td class="border-0">
                                        <span class="text-decoration-none text-dark"
                                        @click="set_communit_asset(community_asset)">
                                          <p class="portal-icon  mb-0 d-flex align-items-center" >
                                            <a class="rounded-circle bg-yellow p-1 shadow-sm color-purple"><i class='bx bx-command fs-5'></i></a>
                                            <span class="text-xs fw-500 mx-2 color-purple" >{{ community_asset.name }}</span>
                                          </p>
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                          
                                <p v-else class="text-secondary text-xs text-center">
                                  No Community Assets Available
                                </p>
                              </div>
                            </div>
                          </div>
                    </div>

                    <!-- Middle Content: Image and Zoom/Fullscreen -->
                    <div class="col-md-6 mt-4 border shadow rounded-5">
                        <div class="post-section p-1 h-700 position-relative">
                            <div class="image-container position-relative">
                                <!-- Image Display -->
                                <div v-if="asset_data.community_asset_files && asset_data.community_asset_files.length">
                                    <img v-if="asset_data.community_asset_files[0].file.file_url.includes('.jpg') || 
                                               asset_data.community_asset_files[0].file.file_url.includes('.png') || 
                                               asset_data.community_asset_files[0].file.file_url.includes('.jpeg')"
                                         :src="asset_data.community_asset_files[0].file.file_url"
                                         alt="Community Asset Image"
                                         class="img-fluid mx-auto d-block rounded-5 my-3 w-80" 
                                         :class="{ 'zoomed-in': isZoomed }"
                                         ref="image" />

                                    <!-- Zoom Button -->
                                    <button @click="toggleZoom" class="zoom-btn btn btn-light position-absolute top-0 end-0 m-2 bg-transparent rounded-5">
                                        <i :class="isZoomed ? 'bx bxs-zoom-out fs-5 text-white' : 'bx bxs-zoom-in fs-5 text-white'"></i>
                                    </button>

                                    <!-- Fullscreen Button -->
                                    <button @click="toggleFullscreen" class="fullscreen-btn btn btn-light position-absolute top-0 start-0 m-2">
                                        <i class="bi bi-arrows-fullscreen"></i>
                                    </button>
                                </div>
                                <p v-else class="text-sm text-center justify-content-center">
                                    No Media for this Asset
                                  </p>
                                
                            </div>
                        </div>

                    </div>

                    <!-- Right Sidebar: Description -->
                    <div class="col-lg-3">
                        <div class="mt-4">
                            <h5 class="">{{ asset_data.name }}</h5>
                            Description :
                            <div v-if="asset_data && asset_data.description" class="text-xs m-2">
                              <span v-html="showmore ? asset_data.description : readAssetDescription(asset_data.description)"></span>
                              <span v-if="shouldShowReadMore" @click="toggleDescription" class="text-primary cursor-pointer">
                                {{ showmore ? 'Read less' : 'Read more' }}
                              </span>
                            </div>
                            <span v-else>Loading description...</span>
                                                   
                            <hr/>
                            <p>Location : {{ asset_data.location }}</p>
                        </div>
                        <button class="btn btn-warning btn-lg text-white text-xs rounded-5 shadow-lg fw-500"
                                v-if="!asset_data.subscribed" :id="'subscribe-button-' + asset_data.id" 
                                @click="subscribeCommunityAsset(asset_data)">Subscribe</button>
                        <button class="btn btn-warning btn-lg text-white text-xs rounded-5 shadow-lg fw-500" 
                                v-if="asset_data.subscribed" :id="'subscribed-button-' + asset_data.id" 
                                @click="unsubscribeCommunityAsset(asset_data)">Unsubscribe</button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

<div>
  <Sidebar ref="sidebar"></Sidebar>
  <section class="home-section">
    <Navbar @toggle-sidebar="toggleSidebar"></Navbar>


    <div class="card bg-white shadow-sm mt-5 rounded-5 border-0">
      <div class="card-header bg-white rounded-top-5 mv-5 shadow bg-dark-purple text-white">
        <span class="fw-500">Portals created</span>
        <button class="btn btn-warning text-white text-xs rounded-5 shadow-lg fw-500 float-end" data-bs-toggle="modal"
          data-bs-target="#create_portal">
          <i class='bx bx-plus'></i> Create Portal
        </button>
      </div>
      <div class="card-body table-responsive">
        <div class="modal fade" id="create_portal" tabindex="-1" aria-labelledby="create_portalModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create a Portal</p>
                <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <form @submit.prevent="createPortal">
                <div class="modal-body">

                  <div class="mb-3">
                    <label for="portal_name" class="form-label text-xs fw-500">Portal Name <span
                        class="text-danger">*</span></label>
                    <input type="text" class="form-control rounded-5 text-xs" id="portal_name"
                      placeholder="Enter portal name" v-model="portal_name">
                    <p v-if="errors.portal_name" class="text-danger text-xs">{{ errors.portal_name }}</p>
                  </div>
                  <div class="form-check mt-2">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="public" value="public"
                      v-model="selectedVisibility">
                    <label class="form-check-label text-xs fw-500" for="flexRadioDefault1">
                      Public <br>
                      <span class="text-xxs text-secondary">Is the portal public ?</span>
                    </label>
                  </div>
                  <div class="form-check mt-2">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="private" value="private"
                      v-model="selectedVisibility">
                    <label class="form-check-label text-xs fw-500" for="private">
                      Private <br>
                      <span class="text-xxs text-secondary">Is the portal private ?</span>
                    </label>
                  </div>

                </div>
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <table class="table table-striped text-xs mt-3">
          <thead class="bg-dark text-white">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Workstreams Portal</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(portal, index) in portals" :key="portal.id">
              <th scope="row">{{ index + 1 }}</th>
              <td id="portal_id">{{ portal.name }}</td>
              <td>

                <router-link :to="'view_dashboard_portal/' + portal.id">
                  <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow"><i class="bi bi-eye"></i>
                    View</button>
                </router-link>
                <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow" data-bs-toggle="modal"
                  :data-bs-target="'#edit_portal_' + portal.id" @click="editPortalName(portal)"><i
                    class="bi bi-pencil-square"></i> Edit</button>
                <button @click="deletePortal(portal.id)" class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow"><i
                    class="bi bi-trash"></i> Delete</button>

                <div class="modal fade" :id="'edit_portal_' + portal.id" tabindex="-1"
                  :aria-labelledby="edit_portal_ + portal.id + 'Label'" aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <p class="modal-title text-xs fw-500" id="exampleModalLabel">Edit Portal</p>
                        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal"
                          aria-label="Close"></button>
                      </div>
                      <form @submit.prevent="editPortal(portal)">
                        <div class="modal-body">
                          <div class="mb-3">
                            <label :for="'edit_portal_name_' + portal.id" class="form-label text-xs fw-500">
                              Portal Name <span class="text-danger">*</span>
                            </label>
                            <input type="text" class="form-control rounded-5 text-xs"
                                   :id="'edit_portal_name_' + portal.id" v-model="edit_portal">
                            <p v-if="errors.portal_name" class="text-danger text-xs">{{ errors.portal_name }}</p>
                          </div>

                          <div class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" v-model="edit_is_master">
                            <label class="form-check-label text-xs fw-500">Is Master Portal</label>
                          </div>

                          <div v-if="edit_is_master" class="mt-3">
                            <a href="#" class="text-primary text-xs fw-500" data-bs-toggle="modal" data-bs-target="#select_portals_modal">
                              + Add Sub-Portals
                            </a>
                            <div class="text-xs">
                              Selected sub-portals ({{ selectedPortals.length || 0 }})
                            </div>
                            <p v-if="errors.selectedPortals" class="text-danger text-xs">{{ errors.selectedPortals }}</p>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal fade" id="select_portals_modal" tabindex="-1" aria-labelledby="selectPortalsLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title text-xs fw-500" id="selectPortalsLabel">Select Sub-Portals</p>
            <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
    
          <form @submit.prevent="confirmSelectedPortals">
            <div class="modal-body">
    
              <!-- Portals Table -->
              <table class="table table-striped text-xs">
                <thead class="bg-dark text-white">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Portal Name</th>
                    <th scope="col">
                      Action 
                      <span class="ms-2">
                        <input  
                          class="form-check-input" 
                          type="checkbox" 
                          id="select_all_checkbox" 
                          :checked="areAllSubPortalsSelected" 
                        @change="toggleSelectAllSubPortals"
                        />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody class="text-black">
                  <tr v-for="(portal, index) in filteredPortals" :key="portal.id">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ portal.name }}</td>
                    <td>
                      <div class="form-check">
                        <input 
                          class="form-check-input" 
                          type="checkbox" 
                          :id="'portal_check_' + portal.id" 
                          :value="portal.id" 
                          v-model="selectedPortals"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
    
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary text-xs rounded-5">Add Portals</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    
  </section>
</div>
<nav class="navbar navbar-expand-md navbar-light bg-white topbar shadow-sm fixed-bottom d-xl-none" v-if="token_exists">
  <ul class="navbar-nav d-flex flex-row justify-content-center w-100 text-xs fw-500">
    
    <li v-if="!is_group_page" class="nav-item d-flex flex-column mx-3"> <!-- Increased mx spacing -->
      <a class="rounded-circle p-1 text-dark" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
        <i class='bx bx-command fs-3'></i>
      </a>
      <span class="text-xxs text-center"></span>
    </li>
    
    <li v-if="!isHomePage && !is_group_page && !isHomeFilterPage && activeTab === 'your_feed'" class="nav-item d-flex flex-column mx-3">
      <a class="rounded-circle text-center text-dark" data-bs-toggle="offcanvas" href="#topics" role="button" aria-controls="topics_label">
        <i class='bx bxs-bulb fs-3'></i>
      </a>
      <span class="text-xxs text-center"></span>
    </li>
    <li v-if="!isHomeFilterPage && !isHomePage && !isPortalPage" class="nav-item d-flex flex-column mx-3 text-center">
      <a class="rounded-circle text-dark" data-bs-toggle="offcanvas" href="#group_members" role="button" aria-controls="group_members_label">
        <i class='bx bx-group fs-3'></i>
      </a>
      <span class="text-xxs text-center"></span>
    </li>

    <li v-if="!is_group_page  && !isHomeFilterPage && !isPortalPage" class="nav-item d-flex flex-column mx-3">
      <a class="rounded-circle text-center text-dark" href="/view_events">
        <i class='bx bxs-calendar-event fs-3'></i>
      </a>
      <span class="text-xxs text-center"></span>
    </li>
    <li>
      <a
        class="nav-link text-dark text-sm "
        href="#dropdown"
        role="button"
        data-bs-toggle="offcanvas"
        aria-expanded="false"
        @click="markNotificationsAsRead"
        aria-controls="dropdown"
      > 
        <i class='bx bxs-bell fs-3 text-dark'></i>
        <span 
          v-if="unreadCount > 0" 
          class="position-absolute start-40 translate-middle badge rounded-pill bg-danger"
        >
          {{ unreadCount }}
        </span>

      </a>
    </li>
    <li v-if="!is_group_page && !isPortalPage" class="nav-item d-flex flex-column mx-3"> <!-- Increased mx spacing -->
      <a class="rounded-circle  text-dark" data-bs-toggle="offcanvas" href="#menu" role="button" aria-controls="menu_canvas">
        <i class='bx bx-menu fs-3'></i>
      </a>
      <span class="text-xxs text-center"></span>
    </li>

  </ul>
  </nav>
  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
      <h5 v-if="cbo_domain"class="offcanvas-title" id="portals_label">Memberships</h5>
      <h5 v-else class="offcanvas-title" id="portals_label">Portals</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <table v-if="portals.length" class="table mb-0">
        <tbody>
          <tr v-for="portal in portals" :key="portal.id" class="hover-row border-0 ">
            <td class="border-0">
              <span class="text-decoration-none" @click="handlePortalClick(portal)" data-bs-dismiss="offcanvas">
                <p class="portal-icon mb-0 d-flex align-items-center">
                  <a class="rounded-circle bg-yellow p-1 shadow-sm text-change"><i class='bx bx-command fs-5'></i></a>
                  <span class="text-xs fw-500 mx-2">{{ portal.name }} <i v-if="!portal.is_public"
                      class='bx bxs-lock text-change text-xxs'></i></span>
                </p>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="offcanvas offcanvas-start" tabindex="-1" id="menu" aria-labelledby="menu">
    <div class="offcanvas-header d-flex">
      <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <ul class="list-unstyled mb-1">  
        <li class="mb-2" v-if="token_exists && user_details.user_role == 'admin' ">
          <a class="d-flex align-items-center justify-content-start text-decoration-none text-dark text-xs fw-500" href="/dashboard">
            <img src="@/assets/images/dashboard.png" class="w-50" alt="">
            <span class=" ms-2">Dashboard</span>
          </a>
        </li>
     
        <li class="mb-2 mt-4 d-none">
          <a href="/view_group" class="d-flex align-items-center justify-content-start text-decoration-none text-dark text-xs fw-500 ">
            <img src="@/assets/images/human-resources.png" class="w-50" alt="">
            <span class="link-name  ms-2">Groups</span>
          </a>
        </li>
  
        <li class="mb-2 mt-4">
          <a href="/view_events" class="d-flex align-items-center justify-content-start text-decoration-none text-dark text-xs fw-500">
            <img src="@/assets/images/calendar.png" class="w-50" alt="">
            <span class="link-name  ms-2">Events</span>
          </a>
        </li>
        <li class="mb-2 mt-4 pointer-events-none">
          <a class="d-flex align-items-center justify-content-start text-decoration-none text-gray text-xs fw-500 ">
            <img src="@/assets/images/orcaaai-icon.png" class="w-50" alt="">
            <span class=" ms-2">My Skill Passport</span>
          </a>
        </li>
          
        <li class="mb-2 mt-3">
          <a class="d-flex align-items-center justify-content-start text-decoration-none text-gray text-xs fw-500 " href="#">
            <img src="@/assets/images/alert-icon-1551.png" class="w-50" alt="">
            <span class="   ms-2">Maintenance</span>
          </a>
        </li>
        <li class="mb-2 mt-3" v-if="stagging_domain">
          <a class="d-flex align-items-center justify-content-start text-decoration-none text-gray text-xs fw-500 " href="/community_asset">
            <img src="@/assets/images/community_assets.webp" class="w-50" alt="">
            <span class="   ms-2">Assets</span>
          </a>
        </li>
  
      </ul>
    </div>
  </div>



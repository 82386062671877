<ul v-if="token_exists" class="navbar-nav d-flex flex-row text-xs fw-500cls">
    <!-- Notification Dropdown -->
    <li class="nav-item dropdown position-relative">
      <a
        class="nav-link text-dark text-sm d-none d-xl-block"
        href="#"
        id="navbarDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        @click="markNotificationsAsRead"
      > 
        <i class='bx bxs-bell fs-3 text-dark'></i>
        <span 
          v-if="unreadCount > 0" 
          class="position-absolute start-40 translate-middle badge rounded-pill bg-danger"
        >
          {{ unreadCount }}
        </span>

      </a>

      
      <!-- Dropdown Menu -->
      <ul class="dropdown-menu notifications-dropdown p-2 shadow border-0" aria-labelledby="navbarDropdown">
        <li class="dropdown-item-text text-center fw-bold border-bottom pb-2 mb-2">
          Notifications
        </li>
        <li 
          v-for="notification in notifications" 
          :key="notification.id" 
          class="dropdown-item notification-item d-flex align-items-start text-xxs border-bottom"
          :class="{ 'unread': !notification.is_read }"
          @click="handleNotificationClick(notification)"
        >
          <div class="notification-content flex-grow-1 text-wrap text-xxs">
            {{ notification.message }}
          </div>
          <div class="notification-date small ms-2 text-nowrap">{{ formatDate(notification.date) }}</div>
        </li>
        <li v-if="notifications.length === 0" class="dropdown-item text-center">
          No notifications
        </li>
      </ul>


     
    </li>
</ul>

   <div class="offcanvas offcanvas-end" tabindex="-1" id="dropdown" aria-labelledby="dropdown">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel">Notifications</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="border-0" aria-labelledby="navbarDropdown">
            <li 
              v-for="notification in notifications" 
              :key="notification.id" 
              class="dropdown-item notification-item d-flex align-items-start text-xxs border-bottom"
              :class="{ 'unread': !notification.is_read }"
              @click="handleNotificationClick(notification)"
            >
              <div class="notification-content flex-grow-1 text-wrap text-xxs">
                {{ notification.message }}
              </div>
              <div class="notification-date small ms-2 text-nowrap">{{ formatDate(notification.date) }}</div>
            </li>
            <li v-if="notifications.length === 0" class="dropdown-item text-center">
              No notifications
            </li>
          </ul>
        </div>
      </div>
      
<div>
  <Sidebar ref="sidebar"></Sidebar>
  <section class="home-section">
    <Navbar @toggle-sidebar="toggleSidebar"></Navbar>

    <div class="card bg-white shadow-sm mt-5 rounded-5 border-0">
      <div class="card-header bg-white border-0 rounded-5">
        <ul class="pills-billing-tab nav nav-pills mb-3" id="pills-billib-tab" role="tablist">
          <li class="nav-item text-sm" role="presentation">
            <button class="nav-link active" id="top_loans_tab" data-bs-toggle="pill" data-bs-target="#top_loans_info" type="button" role="tab" aria-controls="top_loans_info" aria-selected="true">Your Feeds</button>
          </li>
          <li class="nav-item text-sm" role="presentation">
            <button class="nav-link" id="consolidation_loan_tab" data-bs-toggle="pill" data-bs-target="#consolidation_loan_info" type="button" role="tab" aria-controls="consolidation_loan_info" aria-selected="false">Your Voice</button>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="top_loans_info" role="tabpanel" aria-labelledby="top_loans_tab">
            <button class="btn btn-primary text-xs rounded-5 shadow mb-3" data-bs-toggle="modal" data-bs-target="#create_report">Create Feed</button>
            <div class="table-responsive">
              <table class="table table-striped text-xs">
                <thead class="bg-dark text-white">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">Visibility</th>
                    <th scope="col">Date Created</th>
                    <th scope="col">Created By</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(report, index) in reports" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ report.name }}</td>
                    <td>{{ report.is_public }}</td>
                    <td>{{ report.publication_date }}</td>
                    <td>{{ report.creater.first_name }}</td>
                    <td>
                    <button class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow-sm" @click="deleteReport(report.id)"><i class="bi bi-trash"></i> Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab-pane fade" id="consolidation_loan_info" role="tabpanel" aria-labelledby="consolidation_loan_tab">
            <button class="btn btn-primary text-xs rounded-5 shadow mb-3" data-bs-toggle="modal" data-bs-target="#create_hub">Create Your Voice</button>
      <div class="table-responsive">  
              <table class="table table-striped text-xs">
                <thead class="bg-dark text-white">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Your Voice</th>
                    <th scope="col">Date Created</th>
                    <th scope="col">Created By</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(exchange_hub, index) in exchange_hubs" :key="exchange_hub.id">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ exchange_hub.name }}</td>
                    <td>{{ exchange_hub.publication_date }}</td>
                    <td>{{ exchange_hub.creater.first_name }} {{ exchange_hub.creater.last_name }}</td>
                    <td>
      
                      <button @click="delete_exchange_hub(exchange_hub.id)" class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow"> <i class="bi bi-trash"></i> Delete</button>
                    </td>
                  </tr>
                </tbody>
                
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="create_report" ref='create_report_modal' tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <p class="modal-title text-xs fw-500" id="exampleModalLabel">Create your feed</p>
              <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="submit_form">
            <div class="modal-body">
              
              <div class="mb-3">
                <label for="report_name" class="form-label text-xs fw-500">Feed Name <span
                    class="text-danger">*</span></label>
                <input type="text" class="form-control rounded-5 text-xs" id="report_name"
                  placeholder="Enter feed name" v-model="report_name">
                  <p v-if="errors.report_name" class="text-danger text-xs">{{ errors.report_name }}</p>
              </div>

              <QuillEditor
                      theme="snow"
                      v-model="report_description"
                      ref="quillReportEditorRef"
                      class="quill-editor"
                      placeholder="Enter your description here..."
                      toolbar="full"
                    />
                  <div class="mt-2 mx-1 border-bottom ">
                    <div class="d-flex flex-row justify-content-between">
                      <div class="file-upload">
                        <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light"><i class='bx bx-image-add file-upload-icon'></i> Add feed content</label>
                        <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
                      </div>
                      <div v-if="fileName" class="text-xs mt-2 text-secondary text-danger fst-italic"><span class="fw-500">File name:</span>{{ fileName }}</div>
                    </div>
                  </div>

                  <div class="form-check mt-2">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="public" value="public"  v-model="selectedVisibility">
                    <label class="form-check-label text-xs fw-500" for="flexRadioDefault1">
                    Public <br>
                    <span class="text-xxs text-secondary">Is the feed public ?</span>
                    </label>
                  </div>
                  <div class="form-check mt-2">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="private" value="private"  v-model="selectedVisibility">
                    <label class="form-check-label text-xs fw-500" for="private">
                      Private <br>
                      <span class="text-xxs text-secondary">Is the feed private ?</span>
                    </label>
                  </div>

            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
            </div>
          </form>
          </div>
        </div>
      </div>

      <div class="modal fade" id="create_hub" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Create your voice</h5>
              <button type="button" class="btn-close  text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="createHub">
              <div class="modal-body">
    
                <div class="mb-3">
                  <label for="name" class="form-label text-xs fw-500">Voice Name <span
                      class="text-danger">*</span></label>
                  <input type="text" class="form-control rounded-5 text-xs" id="name"
                    placeholder="Enter voice name" v-model="name">
                    <p v-if="errors.name" class="text-danger text-xs">{{ errors.name }}</p>
                </div>

                <QuillEditor
                        theme="snow"
                        v-model="description"
                        ref="quillEditorRef"
                        class="quill-editor"
                        placeholder="Enter your description here..."
                        toolbar="full"
                      />
                    <div class="mt-2 mx-1 border-bottom ">
                      <div class="d-flex flex-row justify-content-between">
                        <div class="file-upload">
                          <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light"><i class='bx bx-image-add file-upload-icon'></i> Add voice content</label>
                          <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
                        </div>
                        <div v-if="fileName" class="text-xs mt-2 text-secondary text-danger fst-italic"><span class="fw-500">File name:</span>{{ fileName }}</div>
                      </div>
                    </div>
    
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
import { clear_token, get_token, get_user_details } from "@/router";
import Swal from 'sweetalert2';

import NotificationDropdown from '@/components/Notifications/Notification.vue'
import HomeSidebar from '@/components/HomeSidebar/HomeSidebar.vue'
import FloatingButton from "@/components/FloatingButton/FloatingButtonComponent.vue"

export default {

  components: {
    NotificationDropdown,
    HomeSidebar,
    FloatingButton
  },
  computed: {
    isHomePage() {
      return this.$route.name === 'home'; // Assuming your home page route is named 'home'
    },
    isHomeFilterOrViewPortal() {
      return this.$route.name === 'home_filter' || this.$route.name === 'view_portal'|| this.$route.name === 'view_events';
    },
    firstNameInitial() {
      return this.userDetails.first_name.charAt(0);
    }
    
  },
  mounted() {
    this.token_exists = get_token();
    this.user_details = get_user_details();
    this.user_id = this.get_user_id();
    this.get_all_profile();

  },

  data() {
    return ({
      token_exists: false,
      user_id:null,
      user_details: {
        "user_role":'',
        "first_name":'',
        "last_name":'',
      },
      showIcons: false,
      showSearch: false,
      profiles: [], // Add profiles data property
      filteredProfiles: [], 
      searchQuery: '',
      isSidebarOpen: false,
      isEventSidebarOpen: false, 
    });
  },

  watch: {
    searchQuery() {
      this.filterProfiles();
    }
  },
  methods: {
    toggleSidebars() {
      if (this.isEventsPage) {
        this.toggleEventSidebar(); // Toggle the event sidebar
      } else {
        this.toggleHomeSidebar(); // Toggle the main sidebar
      }
    },
    toggleEventSidebar() {
      this.isEventSidebarOpen = !this.isEventSidebarOpen; // Toggle event sidebar
    },
    toggleHomeSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen; // Toggle main sidebar
    },
    get_user_id() {
      const user_id = localStorage.getItem('user_id') || '';
      return user_id;
  },
    logout_user() {
      Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure you want to logout?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, log out!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          clear_token();
          window.location.reload();
        }
      });
    },
    toggleIcons() {
      this.showIcons = !this.showIcons;
    },

    get_all_profile() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/get_all_profile/`,
        type: 'GET',
        headers: {
          Authorization: `Token ${get_token()}`,
          'Content-Type': 'application/json',
        },
        data: {user_id: this.user_id},
        success: (data) => {
          this.profiles = data.data.profile_data;
          this.isLoading = false;
        }
      }
      )},

      filterProfiles() {
        if (this.searchQuery) {
            const searchWords = this.searchQuery.toLowerCase().trim().split(' ').filter(word => word);
            this.filteredProfiles = this.profiles.filter(profile => {
                const firstName = profile.user?.first_name?.toLowerCase() || '';
                const lastName = profile.user?.last_name?.toLowerCase() || '';
                const fullName = `${firstName} ${lastName}`;
                const skills = profile.user_skills?.map(skill => skill.skill?.name?.toLowerCase()).join(' ') || '';
    
                return searchWords.every(word =>
                    fullName.includes(word) ||
                    skills.includes(word)
                );
            });
        } else {
            this.filteredProfiles = [];
        }
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
    },

      getProfileLink(member) {
        // Check if the user is an admin
        if (member.role.role === 'admin') {
          // Return the link for admin profiles
          return ``;
        } else if (member.role.role === 'customer') {
          // Return the link for customer profiles
          return `/customer_profile?user_id=${member.id}`;
        } else {
          // Return a default profile link for other roles
          return ``;
        }
      },



    myLearningView() {
      const domain = window.location.hostname;
      let programe_name;
  
      if (domain.includes('workstreams') || domain.includes('demo')) {
          programe_name = 'workstreams learning';
      } else if (domain.includes('919')) {
          programe_name = '919 learning';
      } else if (domain.includes('chamber')) {
          programe_name = 'ICT Chamber learning';
      } else if (domain.includes('community')) {
          programe_name = 'Funax Community learning';
      }else if (domain.includes('workbook')) {
          programe_name = 'IC workbook learning';
      }
      else {
          programe_name = 'workstreams learning';
      }

  },
  
    open_maintenance() {
      if (!this.token_exists) {
          this.$router.push('/sign_in_finale');
          return;
      }
      this.$router.push('/incident_reporting');
    },

  }
};
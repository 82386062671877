import { get_token, get_user_details} from "@/router";


export default {

  mounted(){
    if(this.token_exists){

      this.fetch_users();
    }
  },
  computed:{
    filteredUsers() {
      return this.users
    },
  },

  data(){
    return({
      users: [],
      logged_in_user_id: null,
      token_exists:this.getToken(),
    });
  },

  methods:{
    getToken() {
      return get_token();
    },

    fetch_users() {
      return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: process.env.VUE_APP_BACKEND_API_URL + '/system_management/retrieve_users/',
        data: { email: this.$route.query.email },
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          this.users = response.data.users_list;
          const logged_in_user = get_user_details();

        if (logged_in_user) {
          this.logged_in_user_id = logged_in_user.user_id;
                }
          resolve();
        },
      });
    });
    },

  }
}
import { createRouter, createWebHistory } from 'vue-router'
import CryptoJS from 'crypto-js'
import Sign_in_finale from '@/views/Sign_In_Finale/Sign_in_finale.vue'
import Sign_up_Finale from '@/views/Sign_up_Finale/Sign_up_Finale.vue'
import Dashboard from '@/views/Dashboard/DashboardView.vue'
import CreatePortal from '@/views/CreatePortal/CreatePortalView.vue'
import CreateExternalReport from '@/views/CreateExternalReport/ExternalReportView.vue'
import CreateUser from '@/views/CreateUser/CreateUserView.vue'
import ViewPortal from '@/views/ViewPortal/ViewPortalView.vue'
import ViewDashboardPortal from '@/views/ViewDashboardPortal/ViewDashboardPortalView.vue'
import Home from '@/views/home/HomeView.vue'
import OneTimePin from '@/views/otp/OTPView.vue'
import ResetPassword from '@/views/reset_password/ResetView.vue'
import PasswordChange from '@/views/first_time_password/PasswordView.vue'
import ClientSettings from '@/views/client_settings/ClientSettingsView.vue'
import Profile from '@/views/Profile/Profile.vue'
import ViewTopic from '@/views/ViewTopic/ViewTopicView.vue'
import Home_Filter from '@/views/home_filter/HomeFilterView.vue'
import HomeNavBar from '@/components/HomeNavBar/HomeNavBar.vue'
import CustomerProfile from '@/views/CustomerProfile/CustomerProfile.vue'
import CreateGroup from '@/views/CreateGroup/CreateGroupView.vue'
import CreateAsset from '@/views/CreateAsset/CommunityAsset.vue'
import GroupView from '@/views/group_view/GroupView.vue'
import IncidentReporting from '@/views/IncidentReporting/IncidentReportingView.vue'
import ViewIncidentReports from '@/components/GetIncidentReports/ViewIncidentReports.vue'
import MyLearningErrorPage from '@/components/MyLearningErrorPage/MyLearningErrorPage.vue'
import MyCommunityView from '@/views/my_community/MyCommunityView.vue'
import UserComponent from '@/components/UserComponent/UserComponent.vue'
import ViewEvents from '@/views/View_Events/ViewEvents.vue'
import Proba from '@/views/proba_login/Proba.vue'
import RSVP from '@/views/RSVP_management/GET_RSVP.vue'
import ManageRoles from '@/views/ManageRoles/CreatePortalRoleView.vue'
import ViewIncidents from '@/views/ViewIncidentReport/ViewIncidentReport'
import CreateMembership from '@/views/CreateMembership/CreateMembership.vue'
import CreateForm from '@/views/CreateForm/CreateForm.vue'
import CreateSectionQuestion from '@/views/CreateSectionQuestion/CreateSectionQuestion.vue'
import ViewDashboardSection from '@/views/ViewDashboardSection/ViewDashboardSectionView.vue'
import  CommunityAsset from  '@/components/GetCommunityAssets/CommunityAsset.vue'

const encryption_key = process.env.VUE_APP_ENCRYPTION_KEY;
export function set_token(token) {
  if (!token) {
    localStorage.removeItem("encrypted_token"); // Remove token if empty
    return;
  }

  const encrypted_token = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(token),
    encryption_key
  ).toString();

  localStorage.setItem("encrypted_token", encrypted_token);
}
 
export function get_token() {
  let encrypted_token = localStorage.getItem("encrypted_token");


  // ✅ Check if the token is missing or empty
  if (!encrypted_token) return null;

  try {
    let decrypted_bytes = CryptoJS.AES.decrypt(encrypted_token, encryption_key);
    let token = decrypted_bytes.toString(CryptoJS.enc.Utf8);


    if (!token) {
      console.warn("Decryption failed: empty token");
      return null;
    }

    return token;
  } catch (error) {
    console.error("Token decryption error:", error);
    return null; // Gracefully handle decryption failure
  }
}
const routes = [

  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/sign_up_Finale',
    name: 'sign_up_Finale',
    component: Sign_up_Finale
  },
  {
    path: '/homenav',
    name: 'homenav',
    component: HomeNavBar
  },
  {
    path: '/sign_in_finale',
    name: 'sign_in_finale',
    component: Sign_in_finale
  },
  {
    path: '/first_password_change',
    name: 'first_password_change',
    component: PasswordChange,
    meta: { requiresAuth: true }
  },

  {
    path: '/client_settings',
    name: 'client_settings',
    component: ClientSettings,
    meta: { requiresAuth: true }
  },

  {
    path: '/otp',
    name: 'otp',
    component: OneTimePin,
    meta: { requiresAuth: true }
  },
  {
    path: '/reset_password',
    name: 'reset_password',
    component: ResetPassword,
    meta: { requiresAuth: true }
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },

  {
    path: '/create_portal',
    name: 'create_portal',
    component: CreatePortal,
    meta: { requiresAuth: true }
  },

  {
    path: '/manage_roles',
    name: 'manage_roles',
    component: ManageRoles,
    meta: { requiresAuth: true }
  },

  {
    path: '/get_rsvps',
    name: 'get_rsvps',
    component: RSVP,
    meta: { requiresAuth: true }
  },

  {
    path: '/view_portal/:portal_id/:portal_name/:is_public',
    name: 'view_portal',
    component: ViewPortal,
    meta: { requiresAuth: true }
  },

  {
    path: '/view_dashboard_portal/:portal_id/',
    name: 'view_dashboard_portal',
    component: ViewDashboardPortal,
    meta: { requiresAuth: true }
  },


  {
    path: '/auth/:api_token/:user_id',
    name: 'auth',
    component: Proba,
  },

  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { requiresAuth: true }
  },

  {
    path: '/create_external_report',
    name: 'create_external_report',
    component: CreateExternalReport,
    meta: { requiresAuth: true }
  },

  {
    path: '/create_user',
    name: 'create_user',
    component: CreateUser,
    meta: { requiresAuth: true }
  },

  {
    path: '/create_group',
    name: 'create_group',
    component: CreateGroup,
    meta: { requiresAuth: true }
  },
  {
    path: '/create_asset',
    name: 'create_asset',
    component: CreateAsset,
    meta: { requiresAuth: true }
  },
  {
    path: '/view_group/:group_id?/:group_name?',
    name: 'view_group',
    component: GroupView,
    meta: { requiresAuth: true },
    props: true
  },

  {
    path: '/view_topic/:topic_id',
    name: 'view_topic',
    component: ViewTopic,
    props: true
  },

  {
    path: '/home_filter/',
    name: 'home_filter',
    component: Home_Filter,
    meta: { requiresAuth: true }
  },

  {
    path: '/customer_profile',
    name: 'customer_profile',
    component: CustomerProfile
  },

  {
    path: '/incident_reporting',
    name: 'incident_reporting',
    component: IncidentReporting
  },

  {
    path: '/view_incident_reports',
    name: 'view_incident_reports',
    component: ViewIncidentReports
  },

  {
    path: '/my_learning',
    name: 'my_learning',
    component: MyLearningErrorPage
  },

  {
    path: '/my_community',
    name: 'my_community',
    component: MyCommunityView
  },

  {
    path: '/user_component',
    name: 'user_component',
    component: UserComponent
  },

  {
    path: '/view_events',
    name: 'view_events',
    component: ViewEvents,
    meta: { requiresAuth: false }
  },

  {
    path: '/view_incidents',
    name: 'view_incidents',
    component: ViewIncidents,
    meta: { requiresAuth: false }
  },

  {
    path: '/create_membership',
    name: 'create_membership',
    component: CreateMembership,
    meta: { requiresAuth: true }
  },

  {
    path: '/view_membership/:membership_id/',
    name: 'view_membership',
    component: CreateForm,
    meta: { requiresAuth: true }
  },

  {
    path: '/create_section/',
    name: 'create_section',
    component: CreateSectionQuestion,
    meta: { requiresAuth: true }
  },

  {
    path: '/community_asset',
    name: 'community_asset',
    component:CommunityAsset,
    meta: { requiresAuth: true }
  },

  {
  path: '/view_dashboard_section/:section_id/',
    name: 'view_dashboard_section',
    component: ViewDashboardSection,
    meta: { requiresAuth: true }
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {

  const token = localStorage.getItem('encrypted_token');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token) {
      next();
    } else {
      next('/sign_in_finale');
    }
  } else {
    next();
  }
});

export function clear_token() {
  localStorage.removeItem('encrypted_token');
}
export function set_user_details(user_details) {
  
  var user_role = user_details.role.role;
  var first_name = user_details.first_name;
  var last_name = user_details.last_name;
  var user_id = user_details.id;
  var first_time_login = user_details.first_time_login;
  var phone_number = user_details.phone_number;
  var email = user_details.email;

  localStorage.setItem('user_role', user_role);
  localStorage.setItem('first_name', first_name);
  localStorage.setItem('last_name', last_name);
  localStorage.setItem('user_id', user_id);
  localStorage.setItem('first_time_login', first_time_login);
  localStorage.setItem('phone_number', phone_number);
  localStorage.setItem('email', email);

}

export function get_user_details(user_details) {

  const user_role = localStorage.getItem('user_role');
  const first_name = localStorage.getItem('first_name');
  const last_name = localStorage.getItem('last_name');
  const user_id = localStorage.getItem('user_id');
  const phone_number = localStorage.getItem('phone_number');
  const email = localStorage.getItem('email');


  var user_details = {
    "user_role":user_role,
    "first_name":first_name,
    "last_name":last_name,
    "user_id":user_id,
    "phone_number":phone_number,
    "email":email
  
  }

  return user_details;

}


export default router
<div>
    <Sidebar ref="sidebar"></Sidebar>
    <section class="home-section">
        <Navbar @toggle-sidebar="toggleSidebar"></Navbar>

        <!-- Forms Management -->
        <div class="card bg-white shadow-sm mt-5 rounded-5 border-0">
            <div class="card-header bg-white rounded-top-5 shadow bg-dark-purple text-white">
                <span class="fw-500">Forms</span>
                <button class="btn btn-warning text-white text-xs rounded-5 shadow-lg fw-500 float-end"
                    data-bs-toggle="modal" data-bs-target="#create_form">
                    <i class='bx bx-plus'></i> Add Form
                </button>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-striped text-xs mt-3">
                    <thead class="bg-dark text-white">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Form Name</th>
                            <th scope="col">Description</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody class="text-black">
                        <tr v-for="(form, index) in forms" :key="form.id">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{ form.name }}</td>
                            <td>{{ form.description }}</td>
                            <td>
                                <router-link :to="'view_form/'">
                                    <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow">
                                        <i class="bi bi-eye"></i> View
                                    </button>
                                </router-link>
                                <button class="btn btn-primary text-xxs rounded-5 mx-1 btn-sm shadow"
                                    data-bs-toggle="modal">
                                    <i class="bi bi-pencil-square"></i> Edit
                                </button>
                                <button @click="deleteForm(form.id)"
                                    class="btn btn-danger text-xxs rounded-5 mx-1 btn-sm shadow">
                                    <i class="bi bi-trash"></i> Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Modal for Creating Form -->
        <div class="modal fade" id="create_form" tabindex="-1" aria-labelledby="createFormLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <p class="modal-title text-xs fw-500">Create Form</p>
                        <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <form @submit.prevent="createForm">
                        <div class="modal-body">
                            <div class="mb-3">
                                <label for="form_name" class="form-label text-xs fw-500">
                                    Form Name <span class="text-danger">*</span>
                                </label>
                                <input type="text" class="form-control rounded-5 text-xs" id="form_name"
                                    v-model="form_name" placeholder="Enter form name">
                                <p v-if="errors.form_name" class="text-danger text-xs">{{ errors.form_name }}</p>
                            </div>

                            <div class="mb-3">
                                <label for="form_description" class="form-label text-xs fw-500">Description</label>
                                <textarea class="form-control rounded-5 text-xs" id="form_description"
                                    v-model="form_description" placeholder="Enter description"></textarea>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary text-xs rounded-5">Create Form</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </section>
</div>

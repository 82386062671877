<div>
    <Sidebar ref="sidebar"></Sidebar>
    <section class="home-section">
      <Navbar @toggle-sidebar="toggleSidebar"></Navbar>
  
  
      <div class="card bg-white shadow-sm mt-5 rounded-5 border-0">
        <div class="card-header bg-white rounded-top-5 mv-5 justify-content-between shadow bg-dark-purple text-white d-flex align-items-center">
          <span class="fw-500">RSVP Members</span>
          <button class="btn btn-danger ms-auto "data-bs-toggle="modal" data-bs-target="#verifyRsvpModal">Verify RSVP</button>
      </div>
        <div class="card-body table-responsive">
          <button @click="exportToCSV" class="btn btn-primary">Export to CSV</button>
          <table class="table table-striped text-xs mt-3">
            <thead class="bg-dark text-white">
              <tr>
                <th scope="col">#</th>
                <th scope="col">First_name</th>
                <th scope="col">Last_name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Event_name</th>
                <th scope="col">Menu Item</th>

              </tr>
            </thead>
            <tbody>
              <tr v-for="(member, index) in members" :key="member.id">
                <th scope="row">{{ index + 1 }}</th> 
                <td >{{ member.user.first_name }}</td> 
                <td >{{ member.user.last_name }}</td> 
                <td >{{ member.user.email }}</td> 
                <td >{{ member.user.phone_number }}</td>
                <td>{{member.event.event_name}}</td> 
                <td>

                  <span v-if="member.rsvp_items && member.rsvp_items.length > 0">

                    <span v-for="item in member.rsvp_items" :key="item.id">
                      {{ item.item?.item_name }},
                    </span>
                  </span>

                  <span v-else>No Items</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <div class="modal fade" id="verifyRsvpModal" tabindex="-1" aria-labelledby="verifyRsvpModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content border rounded-5 shadow">
          <div class="modal-header bg-dark-purple text-white">
            <h5 class="modal-title" id="verifyRsvpModalLabel">Verify RSVP Code</h5>
            <button type="button" class="btn-close text bg-white" data-bs-dismiss="modal" aria-label="Close" @click="resetForm"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="verifyRsvpCode" class="text-center">
              <h3 class="fw-bold">RSVP Verification</h3>
              <p class="text-muted">Please enter your RSVP code</p>
          
              <div class="input-group mb-3">
                <input 
                  type="text" 
                  id="rsvpCode" 
                  v-model="rsvp_code" 
                  class="form-control text-center" 
                >
                <span class="input-group-text bg-success text-white" v-if="isVerified">
                  ✔
                </span>
              </div>
          
              <!-- Message Box -->
              <div v-if="message && !isVerified" class="alert text-center mt-2 alert-danger">
                {{ message }}
            </div>
            <div v-if="isVerified" class="alert text-center mt-2 alert-success">
                <div class="text-start">
                    <p>Event: {{ verifiedMember.event.event_name }}</p>
                    <p>Guest: {{ verifiedMember.user.first_name }} {{ verifiedMember.user.last_name }}</p>
                </div>
            </div>
          
              <button type="submit" class="btn btn-dark  mt-3 fw-bold">Verify Code</button>
            </form>
          </div>
          
        </div>
      </div>
    </div>
  </div>
import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';
import Swal from 'sweetalert2';
import { get_token } from "@/router";

export default {
  components: {
    Sidebar,
    Navbar
  },
  data() {
    return ({
      position_name: '',
      edit_position:'',
      is_public: true,
      errors: {},
      positions: [],
      isLoading: false,
      portals: [],
      portal_positions:[],
      all_positions:[]
    });
  },
  computed: {
    isPublic() {
      return this.selectedVisibility === 'public';
    },
  },
  mounted() {
    this.fetch_positions().then(() => {
      this.$nextTick(() => {
        $('.table').DataTable({
          paging: true,
          searching: true, 
          ordering: true,
          info: true,
          lengthChange: true,
          pageLength: 10,
          language: {
            paginate: {
              previous: "Previous",
              next: "Next"
            },
            info: "Showing _START_ to _END_ of _TOTAL_ entries",
            infoEmpty: "No entries available"
          },
           sortColumn: '',
           sortDirection: 'asc'
        });
      });
    });
    this.get_all_positions();
    this.fetchPositionsAssigned()
  },
  methods: {
      getToken() {
      return get_token();
    },
    toggleSidebar() {
      this.$refs.sidebar.$el.classList.toggle("close");
    },
    validationpositionForm() {
      this.errors = {};
      let valid = true;

      if (!this.position_name) {
        this.errors.position_name = 'Role name is required';
        valid = false;
      }

      return valid;
    },
    create_role() {
      if(!this.validationpositionForm()) {
        return;
      }
      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append('position_name', this.position_name.trim().charAt(0).toUpperCase() + this.position_name.trim().slice(1).toLowerCase());

      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/create_portal_position_api/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            title: 'position Created',
            text: 'position has successfully created.',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            this.positions.unshift(response);
              $('#create_position').modal('hide');
          });
        },
        error: () => {
          Swal.close();
            this.errors.position_name="Role Name Already Exists"
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    
    validatepositionForm() {
      this.errors = {};
      let isValid = true;
      if (!this.position_name.trim()) {
        this.errors.position_name = 'Role name is required.';
        isValid = false;
      } 
      return isValid;
    },

    fetch_positions() {
      this.isLoading = true;
      return new Promise((resolve) => {
        $.ajax({
          url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_portal_position_api/`,
          method: 'GET',
          headers: {
            'Authorization': `Token ${this.getToken()}`,
            'Content-Type': 'application/json'
          },
          success: (data) => {
            this.positions = data.user_positions;
            resolve(); 
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      });
    },


    deleteposition(positionId) {
      Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
    
          const formData = new FormData();
          formData.append("position_id", positionId);
    
          $.ajax({
            type: "POST",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_portal_position_api/`,
            data: formData,
            processData: false,
            contentType: false,
            headers: {
              Authorization: `Token ${this.getToken()}`,
            },
            success: () => {
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Position has been deleted.",
                showConfirmButton: false,
                timer: 1000,
              }).then(() => {
                this.positions = this.positions.filter(
                  (position) => position.id !== positionId
                );
              });
            },
            error: () => {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to delete the position.",
              });
            },
            complete: () => {
              this.isLoading = false;
            },
          });
        }
      });
    },
    
    editpositionName(position) {
      this.position_name = position.name;
      this.edit_position=position.name
      },
    edit_portal_position(position) {
      const data = {
        position_id: position.id,
        position_name: this.edit_position.trim().charAt(0).toUpperCase() + this.edit_position.trim().slice(1).toLowerCase()
      }

      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/edit_portal_position_api/`,

        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        contentType: 'application/json',
        data: JSON.stringify(data),
        success: (response) => {
          const status = response.status;
          Swal.fire({
            icon: 'success',
            title: 'Updated',
            text: 'position has been updated.',
            showConfirmButton: false,
            timer: 1000
          })

          if (status === "success") {
            const updatedposition = this.positions.find(p => p.id === position.id);
            if (updatedposition) {
              updatedposition.name = this.edit_position;
            }
            $(`#edit_position_${position.id}`).modal('hide');

          this.formHidden = true;
          }

        },
        error: () => {
          Swal.close();
            this.errors.position_name="Role Name Already Exists"
        },
      });
    },
    get_all_positions() {

      $.ajax({
        type: "GET",
        url: process.env.VUE_APP_BACKEND_API_URL + "/portal_management/get_portal_position_api/",
        headers: {
          Authorization: `Token ${this.getToken()}`,
        },
        success: (response) => {
          if (response.status === "success") {
            this.all_positions = response.user_positions;
          }
        },
      });
    },

    fetchPositionsAssigned() {
      const payload = {
        filter_values: {
          "is_public": false
        }
      }
      $.ajax({
        type: "POST",
        url: process.env.VUE_APP_BACKEND_API_URL + "/portal_management/get_portal_assigned_position_api/",
        headers: {
          Authorization: `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(payload),
        success: (response) => {
          if (response.status === "success") {
            this.portals = response.portal_positions;
          }
        },
      });
    },
    assign_position_to_portal(event, portal_id) {
      const position_id = event.target.value;
      $.ajax({
        type: "POST",
        url: process.env.VUE_APP_BACKEND_API_URL + "/portal_management/assign_portal_position_api/",
        headers: {
          Authorization: `Token ${this.getToken()}`,
        },
        data: {
          portal_id: portal_id,
          position_id: position_id,
        },
        success: (response) => {
          if (response.status === "success") {
            event.target.value = "";
            this.fetchPositionsAssigned();
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.message || "Failed to assign position.",
            });
          }
        },
      });
    },
    availablePositions(portal) {
      return this.all_positions.filter(pos => 
        !portal.positions.some(p => p.id === pos.id)
      );
    },
    remove_position(position_id,portal_id,posIndex) {
      const data = {
        portal_id: portal_id,
        position_id: position_id,
      };

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_portal_roles_api/`,
        headers: {
          Authorization: `Token ${this.getToken()}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
        dataType: "json",
        success: (response_data) => {
          if (response_data.status === "success") {
            this.portals = this.portals.map(portal => {
              if (portal.id === portal_id) {
                portal.positions = portal.positions.filter((_, index) => index !== posIndex);
              }
              return portal;
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Can not delete assigned position.',
              text: 'Please try again',
            });
          }
        }
      });
    }
  },
};

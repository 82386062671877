import {get_user_details,get_token } from "@/router";
const domain=window.location.hostname;

export default {

    data() {
      return ({
        user_details: {
          "user_role": '',
        },
        token_exists: false,
        community_domain:false
      })
    },
    mounted() {
      this.user_details = get_user_details();
      this.token_exists = get_token();
      if (domain.includes('workstreams') || domain.includes('workbook')) {
        this.community_domain = true;
      }
    },

    methods: {
      toggleSidebar() {
        this.$emit('toggle-sidebar');
      }
    }
  }
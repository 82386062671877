<section class="image-background">
    <div class="form-bg">
      <div class="container">
          <div class="row justify-content-center">
              <div class="col-md-4 col-sm-6">
                  <div class="form-container rounded-5 shadow p-5 top-20">
                    <img src="../../assets/images/919_logo.png"  class="img-fluid mx-auto d-block" alt="">
                      <h3 class="title text-center">One Time Pin</h3>
            
                      <p class="d-block text-center text-secondary">Please confirm your one time pin (OTP): <br><span class="text-xs text-info d-none" id="otp_email">Sent to your email :{{$route.query.email }}</span></p>
                      <form>
                        <div class="mb-3">
        
                          <input type="text" class="form-control rounded-5 text-xs p-2" id="otp" placeholder="OTP" v-model="otp" required>
                        </div>
                        <button type="button" class="btn btn-primary rounded-5 shadow-sm w-100 mt-3 mb-1" @click="submit_otp">Continue</button>
                          <p class="text-start text-xs">Didn't receive a One Time Pin??  <router-link to="otp" class="text-info fw-500">Resend</router-link></p>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </section>
  
  
<Loader :loading="loading" />
<HomeNavBar/>

<section>
    <div class="row content-section">
      <div class=" col-lg-3 d-none d-xl-block">
        <div class="col-lg-3 d-none d-xl-block">
          <AllPortals  ref="portals"/>
        </div>
      </div>
      <!-- Middle Content -->
      <div class="col-lg-6 col-md-12 col-12 mt-4">
        <div class="card rounded-5 border-0 shadow-sm p-1 mt-4 m-3 bg-banner-gradient">
            <div class="mt-4">
              <!-- Single Heading: Incident Reports -->
              <div class="d-flex justify-content-center w-100">
                <h2 class="text-xs text-primary px-4">
                    Incident Reports
                  </h2>
              </div>
            </div>
          </div>
          <div class="row content-section">
            <div class="post-section mt-3 overflow-auto p-3 h-150 position-relative">
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="normal_loans_info" role="tabpanel" aria-labelledby="normal_loans_tab">
                  <div>
                    <div v-if="reports.length">
                      <div v-for="report in reports" :key="report.id"
                      class="card shadow card-hover mb-4 p-3 d-flex flex-column border-bottom border-0 shadow rounded-4">
                        <div class="row flex-grow-1">
                          <div class="col">
                            <div class="d-flex flex-start">
                              <i class='bx bxs-user-circle text-secondary fs-1'></i>
                              <div class="flex-grow-1 flex-shrink-1">
                                <div>
                                  <div class="d-flex justify-content-between align-items-center">
                                    <p class="fw-500 mt-1 lh-1 text-xs">{{ report.user.first_name }} {{ report.user.last_name }}
                                      <span class="badge bg-warning text-dark mx-2 text-white shadow-sm">
                                        <i class='bx bxs-bulb'></i> {{ report.incident_status.name }}
                                      </span><br>
                                      <span class="text-xxs">
                                        <i class="bx bxs-stopwatch"></i> {{ report.date_created }}
                                      </span>
                                    </p>
                                    <div class="dropstart">
                                      <a href="" class="fw-bold text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="bi bi-three-dots-vertical"></i>
                                      </a>
                                      <ul class="dropdown-menu custom-dropdown" aria-labelledby="dropdownMenuButton">
                                        <li class="dropdown-item text-xs border-bottom fw-bold d-none" href="">
                                          <i class="bx bxs-pin fs-6"></i> Pin
                                        </li>
                                        <li>
                                          <a class="dropdown-item text-xs fw-bold" href="" data-bs-toggle="modal" data-bs-target="#edit_report_modal" @click="edit_report(report)">
                                            <i class="bx bxs-edit fs-6"></i> Edit
                                          </a>
                                        </li>
                                        <li>
                                          <a class="dropdown-item text-xs fw-bold" href="" @click.prevent="deleteReport(report.id)">
                                            <i class='bx bxs-trash-alt fs-6'></i> Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p class="fw-500 text-xs">
                              {{ report.report_title }}
                            </p>
                            <div class="text-xs display-6">
                              <span class="me-1">{{ report.description }}</span>
   
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                              <div class="d-flex gap-2" v-if="user_details.user_id==report.user.id">
                                <button
                                    class="btn btn-sm btn-outline-success" 
                                    @click="verifyReport(report.id, report.incident_status.id)"
                                    :disabled="isSubmitting">
                                    <i class='bx bx-check-circle'></i> 
                                    {{ isSubmitting ? 'Verifying...' : 'Verify' }}
                                </button>
                                <button
                                  class="btn btn-sm btn-outline-danger" 
                                  @click="declineReport(report.id, report.incident_status.id)"
                                  :disabled="isSubmitting">
                                  <i class='bx bx-x-circle'></i> 
                                  {{ isSubmitting ? 'Declining...' : 'Decline' }}
                              </button>
                              </div>
                              </div>
                            <div class="d-flex justify-content-between align-items-center">
                              <span class="text-xxs text-danger ">{{ report.location }}</span>
                              <span class="text-xxs text-muted">Ref #: {{ report.ref_number }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>

                  </div>
                  <div class="text-center">No available Reports</div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="modal fade" id="edit_report_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Edit your incident report</h5>
              <button type="button" class="btn-close  text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="edit_report_data">
              <div class="modal-body">
                <div class="mb-3">
                  <label for="report_title" class="form-label text-xs fw-500">Report Name <span class="text-danger">*</span></label>
                  <input type="text" class="form-control rounded-5 text-xs" id="report_title" value="report_title"
                    placeholder="Enter voice name" v-model="report_title">
                </div>
                <div class="mb-4">
                  <label class="form-label text-xs fw-500">Incident Description <span class="text-danger">*</span></label>
                  <QuillEditor theme="snow" v-model="report_description" ref="quillReportRef" class="quill-editor"
                    toolbar="full" />
                </div>
                <div class="d-flex mt-2 mx-1 justify-content-between align-items-start">
                  <div class="file-upload my-2 me-4 flex-grow-1 d-flex flex-column align-items-start">
                    <label for="fileInput" class="text-xs text-secondary fw-500 border-0 rounded-5 btn-sm mb-3 bg-light d-flex align-items-center justify-content-center">
                      <i class='bx bx-image-add file-upload-icon'></i> Add Report content
                    </label>
                    <input type="file" id="fileInput" class="file-input d-none" @change="handleFileUpload" />
                    <div v-if="fileName" class="text-xs text-secondary fst-italic mt-1">
                      <span class="fw-500">File name:</span> {{ fileName }}
                    </div>
                  </div>
                </div>
                <div class="mb-4">
                  <label for="mapSearchBox" class="form-label text-xs fw-500 d-block">Search for Location</label>
                  <input id="mapSearchBox" type="text" class="form-control rounded-5 text-xs"
                    placeholder="Search location..." v-model="location" />
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary text-xs rounded-5">Post</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Right Sidebar -->
      <div class="col-md-3 border-start">
      </div>
    </div>
  <FloatingButton v-if="token_exists" @newIncidentReport="newIncidentReport" @addPortal="addNewPortal"/>

  </section>
  
  <StickyNav/>
  
 
<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="comment_label"
    aria-hidden="true" id="comment_modal">
    <div class="modal-fullscreen modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title fw-bold text-center" id="comment_label">
                    {{ report_data.creater.first_name }}'s feed
                </h6>
                <button type="button" class="btn-close" @click="hide_modal"></button>
            </div>
            <div class="modal-body">
                <div class="card card-hover bg-transparent border-0 mb-3">
                    <div class="row g-0">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-7">
                                    <h6 class="text-xs fw-bold">{{ report_data.name }}</h6>
                                </div>
                                <div class="col-md-5 text-end">
                                    <span class="text-xxs"> {{ report_data.publication_date }}</span>
                                </div>
                            </div>
                            <a v-if="report_data.report_data_files" :href="report_data.report_data_link"
                                class="text-xxs d-block mb-3">
                                <span class="text-dark fw-bold"><i class="bx bx-link"></i></span>
                                {{ report_data.report_data_link }}
                            </a>
                            <div v-html="report_data.description" class="text-xs"></div>
                              <div class="image-container position-relative">
                                <div v-if="report_data.report_files && report_data.report_files.length">
                                    <div v-for="(fileObj, index) in report_data.report_files" :key="index" class="mb-3">
                                        <!-- Image -->
                                        <img v-if="fileObj.file.file_url.includes('.jpg') ||
                                          fileObj.file.file_url.includes('.png') ||
                                          fileObj.file.file_url.includes('.jpeg')"
                                             :src="fileObj.file.file_url"
                                             alt="Feed Image"
                                             class="img-fluid rounded-5 w-100 h-50"
                                             :class="{ 'zoomed-in': isZoomed }"
                                             @click="display_comment_modal(report)"
                                             ref="image"/>
                                       
                                        <!-- Audio -->
                                        <audio v-else-if="fileObj.file.file_url.includes('.mp3') || fileObj.file.file_url.includes('.wav')"
                                               controls
                                               class="w-100">
                                          <source :src="fileObj.file.file_url" type="audio/mp3">
                                          Your browser does not support the audio element.
                                        </audio>
                                       
                                        <!-- Video -->
                        <video v-else-if="fileObj.file.file_url.includes('.mp4')" controls
                        class="img-fluid rounded-5 w-100">
                        <source :src="fileObj.file.file_url" type="video/mp4">
                        Your browser does not support the video tag.
                      </video>

                      <!-- PDF -->
                      <iframe v-else-if="fileObj.file.file_url.includes('.pdf')" 
                          :src="'https://docs.google.com/viewer?url=' + encodeURIComponent(fileObj.file.file_url) + '&embedded=true'" 
                          frameborder="0" width="100%" height="500px">
                        </iframe>
                    
                        <!-- Word Document -->
                        <iframe v-else-if="fileObj.file.file_url.includes('.docx') || 
                                            fileObj.file.file_url.includes('.doc')" 
                          :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(fileObj.file.file_url)" 
                          class="img-fluid rounded-5 w-100">
                        </iframe>
                    
                        <!-- Excel Document -->
                        <iframe v-else-if="fileObj.file.file_url.includes('.xls') || 
                                            fileObj.file.file_url.includes('.xlsx') ||
                                            fileObj.file.file_url.includes('.xlsm') || 
                                            fileObj.file.file_url.includes('.xlsb')" 
                          :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(fileObj.file.file_url)" 
                          frameborder="0" width="100%" height="500px">
                        </iframe>

                                        <button v-if="!fileObj.file.file_url.includes('.mp3') &&
                                            !fileObj.file.file_url.includes('.wav')"
                                            @click="toggleZoom" class="zoom-btn btn btn-light position-absolute top-0 end-0 m-2 bg-transparent rounded-5">
                                        <i :class="isZoomed ? 'bx bxs-zoom-out fs-5 text-white' : 'bx bxs-zoom-in fs-5 text-white'"></i>
                                    </button>
                                   
                                    <!-- Fullscreen Button -->
                                    <button v-if="!fileObj.file.file_url.includes('.mp3') &&
                                                !fileObj.file.file_url.includes('.wav')"
                                            @click="toggleFullscreen" class="fullscreen-btn position-absolute btn btn-light top-0 start-0 m-2">
                                        <i class="bi bi-arrows-fullscreen"></i>
                                    </button>
                                      </div>
 
                                   
                                  </div>
                           
                                <!-- Zoom In/Out Button -->
                               
                              </div>
 
                        </div>
 
                        <div class="col-md-4 comment-section ps-2">
                            <div v-for="comment_data in comments_data" class="my-5">
                                <div class="row">
                                    <div class="col">
                                        <div class="d-flex flex-start">
                                            <i class='bx bxs-user-circle fs-1 me-2 text-secondary'></i>
                        
                                            <div class="flex-grow-1 flex-shrink-1">
                                                <div class="border-custom">
                                                    <div class="d-flex justify-content-between align-items-center gap-0">
                                                        <p class="text-xs fw-500 mb-0">
                                                            {{ comment_data.user.first_name }} {{ comment_data.user.last_name }}
                                                            <span class="text-xxs">{{ comment_data.time }}</span>
                                                        </p>
                                                        <div class="dropstart" v-if="String(comment_data.user.id) === String(user_id) || user_details.user_role == 'admin' || portal_admin && token_exists">
                                                            <a href="" class="fw-bold text-dark" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i class="bi bi-three-dots-vertical"></i><span class="small"></span>
                                                            </a>
                                                            <ul class="dropdown-menu rounded-5 border-0 shadow-sm w-100" aria-labelledby="dropdownMenuButton">
                                                                <li v-show="comment_data.total_replies === 0 && String(comment_data.user.id) === String(user_id)">
                                                                    <a class="dropdown-item text-info text-xxs fw-500" href="#!" @click="edit_comment(comment_data)">
                                                                        <i class="bi bi-pencil"></i> Edit
                                                                    </a>
                                                                </li>
                                                                <li v-if="user_details.user_role === 'admin' || portal_admin">
                                                                    <a class="dropdown-item text-danger text-xxs fw-500" href="#!" @click="delete_comment(comment_data.id)">
                                                                        <i class="bi bi-trash"></i> Delete
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <p class="small mb-0 text-xs text-break mt-0">
                                                        <span class="text-dark text-xxs flex-grow-1" v-if="comment_data.comment_text.length <= 100">
                                                            {{ comment_data.comment_text }}
                                                        </span>
                                                        <span v-else>
                                                            <span v-if="expandedReportsComments.includes(comment_data.id)">
                                                                {{ comment_data.comment_text }}
                                                                <p class="text-xs mt-3 text-warning cursor-pointer" @click="readMore(comment_data.id)">
                                                                    Read less
                                                                </p>
                                                            </span>
                                                            <span v-else>
                                                                {{ comment_data.comment_text.substring(0, 100) }}...
                                                                <p class="text-xs mt-3 text-warning cursor-pointer" @click="readMore(comment_data.id)">
                                                                    Read more
                                                                </p>
                                                            </span>
                                                        </span>
                                                    </p>
                        
                                                    <!-- Edit comment input -->
                                                    <div v-if="editing_comment_id === comment_data.id">
                                                        <div class="input-group mb-3 rounded-5">
                                                            <input type="text" class="form-control text-xs rounded-start" v-model="editing_comment_text" placeholder="Edit your comment" />
                                                            <span class="input-group-text bg-transparent p-2 bg-light rounded-end" @click="save_edit_comment(comment_data.id)">
                                                                <i class="bi bi-send"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Comment section ends -->
                        
                                <!-- Reply toggler and reply container -->
                                <div :id="'reply_toggler_section_'+comment_data.id">
                                    <!-- Show 'Commenting...' when still loading -->
                                    <div v-if="comment_data.is_loading" class=" text-xxs border-2 ms-5 mt-1 reply-toggler-button text-info">
                                        <i class="bi bi-clock"></i> Commenting...
                                    </div>

                                    <div v-else>
                                        <div v-if="comment_data.total_replies != 0" class="float-start d-flex mx-5" @click="get_comment_replies(comment_data.id, 1)">
                                            <span class="text-xxs border-2 ms-2 mt-1 reply-toggler-button text-info">
                                                <i class="bi bi-eye"></i> View {{ comment_data.total_replies }} replies
                                            </span>
                                        </div>
                                        <div v-else class="float-start d-flex mx-5" @click="toggler_comment_input(comment_data.id, 1)">
                                            <span class="text-xxs border-2 ms-2 mt-1 reply-toggler-button text-info">
                                                <i class="bi bi-reply"></i> reply
                                            </span>
                                        </div>
                                    </div>
                                </div>
                        
                                <!-- Reply section -->
                                <div class="d-none ms-5 mt-2" :id="'reply_container_'+comment_data.id">
                                    <div :id="'reply_section_' + comment_data.id"></div>
                        
                                    <div v-if="comment_data.is_loading" class="small text-warning ms-2 mt-1">Commenting...</div>

                                    <div v-else-if="token_exists" class="d-flex justify-content-between align-items-center">
                                        <div class="input-group mb-3 rounded-5 w-100">
                                            <input :id="'new_reply_text'+comment_data.id" type="text" class="form-control text-xxs rounded-start" placeholder="Write a reply" @keypress.enter.prevent="submit_reply(comment_data.id)">
                                            <span class="input-group-text bg-transparent  bg-light rounded-end" @click="submit_reply(comment_data.id)">
                                                <i class="bi bi-send"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
 
                    </div>
 
                </div>
 
            </div>
            <p v-if="!report_data.can_comment" class="text-muted">Comments switched off.</p>
            <div class="modal-footer">
                <div class="mb-3" v-if="report_data.can_comment">
                    <span>Comments ({{ report_data.total_comments }})</span>
                </div>
                <div v-if="token_exists && report_data.can_comment" class="input-group mb-3 rounded-5">
                    <input id="comment_text_area" 
                        type="text" 
                        class="form-control text-xs rounded-start border-right-0"
                        placeholder="Write a comment .." 
                        @keypress.enter.prevent="submit_comment()"
                        @input="handleInput"
                        v-model="commentText"
                    />
                    
                    <span class="input-group-text bg-transparent p-2 btn border-1 border-secondary rounded-end"
                        @click="submit_comment()">
                        <i class="bi bi-send"></i>
                    </span>
                </div>
            </div>
            <div v-if="showSuggestions && filteredUsers.length > 0" 
                class="suggestions-dropdown"  @click.stop>
               <div v-for="user in filteredUsers" 
                    :key="user.id" 
                    class="suggestion-item"
                    @mousedown="selectUser(user)">
                   {{ user.first_name }} {{ user.last_name }}
               </div>
           </div>
        </div>
    </div>
</div>
 <!-- Reply Template -->
 <div class="d-none flex-grow-1 border-custom my-2 ms-3" id="reply_template">
    <div class="d-flex flex-start">
    <i class='bx bxs-user-circle text-secondary fs-2'></i>
    <div>
        <div class="d-flex justify-content-between align-items-center">
            
            <p class="mb-1 text-xxs "> 
                <span class="me-1 fw-bold reply_Name"></span> 
                <span class="me-1 fw-bold reply_last_name"></span>
                <span class="small text-xxs fs-500 reply_time"></span>
            </p>
            <div class="dropstart reply_dropdown">
                <a href="#" class="text-dark" data-bs-toggle="dropdown">
                    <i class="bi bi-three-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu w-100 shadow rounded-5 border-0">
                    <li><a class="dropdown-item text-info edit_reply"><i class="bi bi-pencil text-xxs fw-500"></i> Edit</a></li>
                    <li><a class="dropdown-item text-danger delete_reply" v-if="user_details.user_role=='admin'&& token_exists"><i class="bi bi-trash text-xxs fw-500"></i> Delete</a></li>
                </ul>
            </div>
        </div>
        <div class="reply_text_container">
            <p class="small mb-2 text-xxs comment_text"></p>
            <div class="input-group mb-3 edit_reply_input d-none">
                <input type="text" class="form-control text-xxs rounded-start edit_reply_text" placeholder="Edit your reply" />
                <span class="input-group-text rounded-end save_edit_reply">
                    <i class="bi bi-send"></i>
                </span>
            </div>
        </div>
    </div>
  </div>
    
</div>

<div class="read-more-section text-xs d-none" id="read_more_template">
    <span class="text-primary cursor-pointer read-more-button">Read more</span>
    <span class="text-primary cursor-pointer read-less-button d-none">Read less</span>
</div>

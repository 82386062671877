import {set_token, set_user_details} from '@/router';


export default {
    data() {
        return ({
          user_id:"",
        });
      },

    mounted() {
    this.user_id = this.$route.params.user_id

    this.get_user_details(this.user_id);
    const api_token = this.$route.params.api_token;
    set_token(api_token);

    },
    methods: {
        get_user_details(user_id) {
            $.ajax({
                type: "GET",
                url: `${process.env.VUE_APP_BACKEND_API_URL}/system_management/get_user/`,
                data: { user_id: user_id },
                success: (response) => {
                  const user_details = response.user;
                   set_user_details(user_details);
                   window.location.href =escape("/");;

                },
              });
        }
    }
};


<div class="reports" v-if="!is_public">
      <div class="card shadow-sm border-0 w-100">
        <div class="card-header bg-white d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center py-3">
          <h6 class="mb-2 mb-md-0">Portal Members</h6>
          <div class="d-flex flex-row gap-2">
            <button class="bg-success text-white border-0 shadow-sm rounded-3 text-xxs" data-bs-toggle="modal" data-bs-target="#add_portal_member_modal" title="Add Member" v-if="user_details.user_role === 'admin' || portal_admin">
              <i class="bx bx-plus-circle"></i> Add Member
            </button>
            <button class="bg-danger text-white border-0 shadow-sm rounded-3 text-xxs" data-bs-toggle="modal" data-bs-target="#delete_portal_member_modal" title="Delete Member" v-if="user_details.user_role === 'admin' || portal_admin">
              <i class="bx bx-trash"></i> Delete Member
            </button>
            <button class="bg-primary text-white border-0 shadow-sm rounded-3 text-xxs" v-if="(workbook_domain || stagging_domain) && (user_details.user_role === 'admin' || portal_admin)"
                data-bs-toggle="modal" 
                data-bs-target="#assign_portal_member_modal" 
                title="Assign Member"
                @click="fetchUsersWithRoles">
              <i class="bx bx-user-plus"></i> Assign Role
            </button>
          </div>
        </div>
        <div class="card-body p-0">
        <div class="p-3 bg-light">
          <div class="input-group text-xs border-0">
            <span class="input-group-text bg-white border-end-0 border-0 shadow">
              <i class="bx bx-search"></i>
            </span>
            <input type="text" class="form-control border-start-0 text-xs border-0 shadow-sm" placeholder="Search members...">
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-hover mb-0">
            <tbody v-if="members.length > 0">
              <tr v-for="member in portal_users" :key="member.user_id" class="cursor-pointer">
                <td>
                  <div class="d-flex align-items-center"  @click="getProfileLink(member)">
                    <div class="avatar-wrapper rounded-circle bg-light p-2 me-3">
                      <i class="bx bxs-user fs-5 text-primary"></i>
                    </div>

                    <div class="d-flex justify-content-between align-items-center w-100" >
                      <div>
                        <p class="mb-0 fw-medium">{{ member.first_name }} {{ member.last_name }}</p>
                        <small class="text-muted">{{ member.email }}</small>
                      </div>
                      <div class="container">
                        <div class="row">
                          <div class="col-sm">
                            <div class="d-flex justify-content-start flex-nowrap gap-2 mt-2">
                              <span
                                v-for="(position, posIndex) in member.positions"
                                :key="position.position_id"
                                :class="[ 
                                'custom-badge', 
                                `custom-badge-${['primary-roles', 'success-roles', 'danger-roles', 'warning-roles','yelow-roles'][posIndex % 5]}`,
                                'text-nowrap', 'overflow-hidden'
                              ]"
                              >
                                {{ position.position_name }}

                              </span>

                              <span v-if="adminUserIds.has(member.id)" class="custom-badge bg-info text-dark flex-shrink-0">
                                Portal Admin
                              </span>

                            </div>
                          </div>
                          <div class="col-sm text-end">
                            <div 
                              v-if="user_details.user_role === 'admin' || (portal_admin && user_details.user_id != member.id)" 
                              class="ms-auto dropstart reply_dropdown"
                            >
                              <!-- Three dots dropdown -->
                              <a 
                                href="#" 
                                class="text-dark" 
                                data-bs-toggle="dropdown" 
                                @click.stop="get_member_id(member.id);"
                              >

                                <i class="bi bi-three-dots-vertical"
                                :class="{ 'show': activeDropdown === member.id }"></i>
                              </a>
                              <!-- Dropdown menu -->
                              <ul class="dropdown-menu w-100"
                              :class="{ 'show': activeDropdown === member.id }">
                                <li v-if="!portal_admin_button">
                                  <a 
                                  class="dropdown-item text-xs border-bottom d-flex align-items-center gap-2" 
                                  href="#" 
                                  @click.stop="assign_portal_admin(member.id)"
                                >
                                  <i class="bi bi-check tick-icon"></i>
                                  <span class="text-truncate">Assign Portal Admin</span>
                                </a>
                                </li>
                                <li v-else>
                                  <a 
                                  class="dropdown-item text-xs border-bottom d-flex align-items-center gap-2" 
                                  href="#" @click.stop="unassign_portal_admin(member.id)"
                                >
                                <i class="bi bi-x-circle tick-icon"></i>
                                  <span class="text-truncate">Unassign Portal Admin</span>
                                </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <tr v-if="!members.length">
                    <td colspan="2" class="text-center py-4 text-muted">
                      <i class="bx bx-user-x fs-1"></i>
                      <p class="mb-0 mt-2">No members available</p>
                    </td>
                  </tr>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div>
   
  
</div>

import Sidebar from '@/components/Sidebar/SidebarView.vue';
import Navbar from '@/components/Navbar/NavbarView.vue';
import Swal from 'sweetalert2';
import { get_token } from "@/router";

export default {
  components: {
    Sidebar,
    Navbar
  },
  data() {
    return ({
      portal_name: '',
      edit_is_master:false,
      edit_portal_id:null,
      selectedPortals: [],
      edit_description: '',
      edit_portal:'',
      is_public: true,
      errors: {},
      portals: [],
      isLoading: false,
      selectedVisibility: 'public',
    });
  },
  computed: {
    isPublic() {
      return this.selectedVisibility === 'public';
    },
    filteredPortals() {
      return this.portals.filter(portal => 
        portal.id !== this.edit_portal_id &&
        (!portal.master_portal || portal.master_portal.id !== this.edit_portal_id)
      );
    },
    areAllSelected() {
      const filteredIds = this.filteredUsers.map(user => user.id);
      return filteredIds.every(id => this.selectedUserIds.includes(id)) && filteredIds.length > 0;
    },
    areAllSubPortalsSelected() {
      const filteredIds = (this.filteredPortals || []).map(portal => portal.id);
      return filteredIds.length > 0 && filteredIds.every(id => this.selectedPortals.includes(id));
  },
  },
  methods: {
      getToken() {
      return get_token();
    },
    toggleSidebar() {
      this.$refs.sidebar.$el.classList.toggle("close");
    },
    validationPortalForm() {
      this.errors = {};
      let valid = true;

      if (!this.portal_name) {
        this.errors.portal_name = 'Portal name is required';
        valid = false;
      }

      return valid;
    },
    toggleSelectAll() {
      if (this.areAllSelected) {
        this.selectedPortals = [];
      } else {
        this.selectedPortals = this.availablePortals.map(portal => portal.id);
      }
    },
    toggleSelectAllSubPortals() {
      const filteredIds = (this.filteredPortals || []).map(portal => portal.id);
  
      if (this.areAllSubPortalsSelected) {
          this.selectedPortals = this.selectedPortals.filter(id => !filteredIds.includes(id));
      } else {
          this.selectedPortals = [...new Set([...this.selectedPortals, ...filteredIds])];
      }
  },
    confirmSelectedPortals() {
      $('#select_portals_modal').modal('hide');
    },
    createPortal() {
      if(!this.validationPortalForm()) {
        return;
      }
      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append('portal_name', this.portal_name);
      formData.append('is_public', this.isPublic);

      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/create_portal_api/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            title: 'Portal Created',
            text: 'Portal has successfully created.',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            this.portals.unshift(response.portal);
              $('#create_portal').modal('hide');
            
          });
        },
        error: (response) => {
          const response_error=response.responseJSON
          Swal.close();
          if(response_error.status==="error"){
            this.errors.portal_name=response_error.message
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    
    validatePortalForm() {
      this.errors = {};
      let isValid = true;
      if (!this.portal_name.trim()) {
        this.errors.portal_name = 'Portal name is required.';
        isValid = false;
      } 
      
      return isValid;
    },

    fetchPortals() {
      this.isLoading = true;
      return new Promise((resolve) => {
        $.ajax({
          url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_portals_api/`,
          method: 'POST',
          headers: {
            'Authorization': `Token ${this.getToken()}`,
            'Content-Type': 'application/json'
          },
          success: (data) => {
            this.portals = data.portals;
            resolve(); 
          },
          complete: () => {
            this.isLoading = false;
          }
        });
      });
    },
    

    async deletePortal(portalId) {
      Swal.fire({
        title: 'Are you sure?',
        text: "This action cannot be undone!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          try {
            const response = await fetch(`${process.env.VUE_APP_BACKEND_API_URL}/company_management/delete_portal_api/`, {
              method: 'DELETE',
              headers: {
                'Authorization': `Token ${this.getToken()}`,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ portal_id: portalId })
            });
            if (response.ok) {
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Portal has been deleted.',
                showConfirmButton: false,
                timer: 1000
              }).then(() => {
                this.portals = this.portals.filter(portal => portal.id !== portalId);
              });
            } else {
              throw new Error();
            }
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'An error occurred while deleting the portal. Please try again!'
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
    editPortalName(portal) {
      this.edit_portal=portal.name
      this.edit_portal_id=portal.id
      this.edit_description=portal.description
      this.edit_is_master=portal.is_master

      },
    editPortal(portal) {
      const payload = {
        portal_id: portal.id,
        update_values:{
          name: this.edit_portal,
          description: this.edit_description||'null',
          
        },
        is_master: this.edit_is_master,
        portal_ids:this.selectedPortals || [],
      }

      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/update_portal_api/`,

        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        contentType: 'application/json',
        data: JSON.stringify(payload),
        success: (response) => {
          const status = response.status;
          Swal.fire({
            icon: 'success',
            title: 'Updated',
            text: 'Portal has been updated.',
            showConfirmButton: false,
            timer: 1000
          })

          if (status === "success") {
            const updatedPortal = this.portals.find(p => p.id === portal.id);
            if (updatedPortal) {
              updatedPortal.name = this.edit_portal;
            }
            $(`#edit_portal_${portal.id}`).modal('hide');

          this.formHidden = true;
          }
        }
      });
    }
  },
  mounted() {
    this.fetchPortals().then(() => {
      this.$nextTick(() => {
        $('.table').DataTable({
          paging: true,
          searching: true, 
          ordering: true,
          info: true,
          lengthChange: true,
          pageLength: 10,
          language: {
            paginate: {
              previous: "Previous",
              next: "Next"
            },
            info: "Showing _START_ to _END_ of _TOTAL_ entries",
            infoEmpty: "No entries available"
          },
           sortColumn: '',
           sortDirection: 'asc'
        });
      });
    });
  }
};

<div class="view-portal modal fade " id="delete_portal_member_modal" tabindex="-1" aria-labelledby="portalMemberLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title text-xs fw-500" id="portalMemberLabel">Delete Portal Members</p>
          <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form @submit.prevent="deletePortalMembers">
          <div class="modal-body table-responsive">
            <div class="mb-3">
              <input
                type="text"
                v-model="searchQuery"
                class="form-control text-xs"
                placeholder="Search members by name..."
              />
            </div>
    
            <table class="table table-striped text-xs">
              <thead class="bg-dark text-white">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Action<span class="ms-2"><input class="form-check-input" type="checkbox" id="select_all_checkbox":checked="SelectAllMembers" @change="toggleSelectAllMembers"/></span></th>
                </tr>
              </thead>
              <tbody class="text-black">
                <tr v-for="(user, index) in filteredMembers" :key="user.id">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ user.first_name }}</td>
                  <td>{{ user.last_name }}</td>
                  <td>{{ user.email }}</td>
                  <td>
                    <div class="form-check">
                      <input 
                        class="form-check-input" 
                        type="checkbox" 
                        :id="'portal_member_check_' + user.id" 
                        :value="user.id" 
                        v-model="selectedUserIds"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary text-xs rounded-5">Delete Members</button>
          </div>
        </form>
      </div>
    </div>
</div>
<div class="view-portal modal fade " id="add_portal_member_modal" tabindex="-1" aria-labelledby="portalMemberLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title text-xs fw-500" id="portalMemberLabel">Add Portal Members</p>
            <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form @submit.prevent="addPortalMembers">
            <div class="modal-body table-responsive">
              <div class="mb-3">
                <input
                  type="text"
                  v-model="searchQuery"
                  class="form-control text-xs"
                  placeholder="Search members by name..."
                />
              </div>
      
              <table class="table table-striped text-xs">
                <thead class="bg-dark text-white">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Action <span class="ms-2"><input  class="form-check-input" type="checkbox" id="select_all_checkbox" :checked="areAllSelected" @change="toggleSelectAll"/></span></th>
                  </tr>
                </thead>
                <tbody class="text-black">
                  <tr v-for="(user, index) in filteredUsers" :key="user.id">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ user.first_name }}</td>
                    <td>{{ user.last_name }}</td>
                    <td>{{ user.email }}</td>
                    <td>
                      <div class="form-check">
                        <input 
                          class="form-check-input" 
                          type="checkbox" 
                          :id="'portal_member_check_' + user.id" 
                          :value="user.id" 
                          v-model="selectedUserIds"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary text-xs rounded-5">Add Members</button>
            </div>
          </form>
        </div>
      </div>
</div>
<div class="view-portal modal fade" id="assign_portal_member_modal" tabindex="-1" aria-labelledby="portalMemberLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title text-xs fw-500" id="portalMemberLabel">Assigned Roles</p>
          <button type="button" class="btn-close text-xs fw-500" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body table-responsive">
          <!-- Search Box -->
          <div class="mb-3">
            <input
              type="text"
              v-model="searchQuery"
              class="form-control text-xs"
              placeholder="Search members by name..."
            />
          </div>
  
          <!-- Table -->
          <div class="table-responsive">
            <table class="table table-striped text-xs">
              <thead class="bg-dark text-white">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Assigned Role</th>
                </tr>
              </thead>
              <tbody class="text-black">
                <tr v-for="(user, index) in portal_users" :key="user.user_id">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ user.first_name }}</td>
                  <td>{{ user.last_name }}</td>
                  <td>{{ user.email }}</td>
                  <td>

                    <span
                    v-for="(position,posIndex) in user.positions"
                    :key="position.position_id"
                    class="btn-primary badge bg-primary text-white me-1"
                  >
                    {{ position.position_name }}
                    <button
                      type="button"
                      class="btn-close text-white ms-1"
                      aria-label="Remove"
                      @click="remove_position(user.id,position.position_id, posIndex,portals_roles[0]?.id)"
                    ></button>

                  </span>
                  <div v-if="portals_roles.length">
                    <div v-for="portal in portals_roles" :key="portal.id" class="mb-4">
                      <select 
                        class="form-control text-xs mt-2"
                        @change="assign_position_to_portal_user($event, portal.id,user.id )"
                      >
                      <option value="">Select a position</option>
                      <option 
                        v-for="pos in available_positions(portal, user)"
                        :key="pos.id" 
                        :value="pos.id"
                      >
                        {{ pos.name }}
                      </option>
                      </select>
                    </div>
                  </div>
                  <p v-else>No portals or positions found.</p>

                    <div v-for="user in portal_positions" :key="user.user_id">
                      <h3>{{ user.first_name }} {{ user.last_name }}</h3>

                      <span v-if="user.positions.length === 0" class="text-muted">
                        No roles assigned
                      </span>
                    </div>
                    
                  </td>
                </tr>
                <tr v-if="portal_users.length === 0">
                  <td colspan="4" class="text-center text-muted">No users found.</td>
                </tr>
              </tbody>
            </table>
          </div> 
        </div>
      </div>
    </div>
</div>
import { clear_token, get_token, get_user_details } from "@/router";
import HomeNavBar from "@/components/HomeNavBar/HomeNavBar.vue"
import Swal from 'sweetalert2';
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import Loader from "@/components/Loader/LoaderView";

const CALENDAR_ID = process.env.VUE_APP_CALENDAR_ID;

const domain = window.location.hostname;

export default {
  components: {
    HomeNavBar,
    FullCalendar,
    Loader,
  },
  data() {
    return ({

      searchQuery: "",
      selectedEvent: {},
      activeDates: {},
      selectedItemId:null,
      cbo_domain: false,
      workbook_domain: false,
      styledCalendarId: CALENDAR_ID,
      imagePreview: null,
      event_link: "",
      event_types:[],
      event_type: '',
      events: [],
      event_id: null,
      token_exists: false,
      selectedExtraId: [],
      selectedExtraItemId: [],
      selectedItemId: [],
      eventExtras: [],
      extraItems: [],
      user_details: {
        "user_role": '',
        "first_name": '',
        "last_name": '',
      },
      currentMonthYear: "",
      selectedEvent: {},
      portals: [],
      selectedPortalId: '',
      event_name: "",
      description: "",
      start_date: "",
      start_time: '',
      end_date: "",
      end_time: '',
      rsvp_start_date: "",
      rsvp_start_time: '',
      rsvp_end_date: "",
      rsvp_end_time: '',
      organizer: "",
      location: "",
      portal_id: "",
      files: [],
      latitude: null,
      longitude: null,
      loading: true,
      upload_media: null,
      fileName: '',
      event_description: '',
      errors: {},
      menu_name: "",
      menu_items: [],
      filteredExtras: [],
      item_name: '',
      item_description: '',
      item_price: 0,
      selectedExtraText: '',
      isSubmitting: false,
      extras: [],
      extraForms: [],
      currentDate: new Date(),
      weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      days: [],
        selectedDate: new Date(),
        calendarOptions: {
          plugins: [dayGridPlugin],
          events: [],
          currentMonth: new Date().getMonth(),
          currentYear: new Date().getFullYear(),
          selectedDate: null,
          weekdays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          daysInMonth: [], 
          initialDate: new Date().toISOString().split('T')[0],
    },
    });
  },
  mounted() {
    this.token_exists = get_token();
    const selectedEvent = JSON.parse(sessionStorage.getItem('selectedEvent'));
    const selectedExtras = JSON.parse(sessionStorage.getItem('selectedExtras')) || [];
    const selectedExtraItems = JSON.parse(sessionStorage.getItem('selectedExtraItems')) || [];
    const selectedItemId = JSON.parse(sessionStorage.getItem('selectedItemId')) || [];
    

    const nextUrl = sessionStorage.getItem('next');

    if (nextUrl && this.token_exists) {

        this.selectedEvent = selectedEvent;
        this.selectedExtraId = selectedExtras;
        this.selectedExtraItemId = selectedExtraItems;
        this.selectedItemId = selectedItemId
        if (typeof this.addRsvp === 'function') {
          this.addRsvp()
          sessionStorage.removeItem('next');
          sessionStorage.removeItem('selectedEvent'); 
          sessionStorage.removeItem('selectedExtras');
          sessionStorage.removeItem('selectedExtraItems');
          sessionStorage.removeItem('selectedItemId')
      } 
    }

    this.fetchEventTypes();
    const script = document.createElement('script');
    script.src = "https://embed.styledcalendar.com/assets/parent-window.js";
    script.async = true;
    script.type = "module";
    document.body.appendChild(script);
    this.fetchEvents();
    this.fetchPortals();
    this.user_details = get_user_details();
    
    this.fetchPortals();
    this.fetchExtras();
    this.filteredExtras = this.extras;
    const eventModal = document.getElementById('event_modal');
    eventModal.addEventListener('hidden.bs.modal', () => {
      this.clearForm();
      this.event_id = null;
    });
    this.generateCalendar();
    this.$nextTick(() => {
      const modalEl = this.$refs.calendarModal;
    
      if (modalEl) {
        $(modalEl).on('shown.bs.modal', () => {
          if (this.$refs.fullCalendar) {
            this.$refs.fullCalendar.getApi().render();
          }
        });
      }
    });
    
    this.load_events_modal();


    if (domain.includes('cbo')) {
      this.cbo_domain = true;
    }

    if (domain.includes('workbook')) {
      this.workbook_domain = true;
    }
  },
  created() {
    this.setCurrentDate();
  },
  computed: {
    monthName() {
      return ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ][this.month];
    },
    year() {
      return this.currentDate.getFullYear();
    },

    filteredEvents() {
      let groupedEvents = {};
      this.events.forEach((event) => {
        let eventDate = this.form_date(event.start_date); // Format date if needed
        if (!groupedEvents[eventDate]) {
          groupedEvents[eventDate] = [];
        }
        groupedEvents[eventDate].push(event);
      });
      return groupedEvents;
    }

  },
  watch: {
    selectedExtraText(newVal) {
      this.filterExtras(newVal);
    },
    event_name(newValue) {
      if (newValue.trim()) this.errors.event_name = null;
    },
    location(newValue) {
      if (newValue.trim()) this.errors.location = null;
    },
    organizer(newValue) {
      if (newValue.trim()) this.errors.organizer = null;
    },
    start_date() {
      this.errors.start_date = null;
    },
    end_date() {
      this.errors.end_date = null;
    },
    rsvp_start_date() {
      this.errors.rsvp_start_date = null;
    },
    rsvp_end_date() {
      this.errors.rsvp_end_date = null;
    },
    event_type: {
      handler(newType) {
        if (newType.name !== "online") {
          this.$nextTick(() => {
            this.initializeMap();

          });
        }
      },
      deep: true
    }
  },
  methods: {

    setCurrentDate() {
      const today = new Date();

      // Format current month and year (e.g., "February 2025")
      this.currentMonthYear = today.toLocaleString("en-US", { month: "long", year: "numeric" });
    },
    formatRelativeDate(date) {
      const today = new Date();
      const diffTime = today - date; // Difference in milliseconds
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Difference in days

      if (diffDays === 0) {
        return "Today"; // Same day
      } else if (diffDays === 1) {
        return "Yesterday"; // 1 day ago
      } else if (diffDays < 7) {
        return `${diffDays} days ago`; // Less than a week ago
      } else if (diffDays < 30) {
        return `${Math.floor(diffDays / 7)} week(s) ago`; // Less than a month ago
      } else {
        return date.toLocaleString("en-US", { weekday: "long", day: "numeric", month: "long", year: "numeric" }); // Else display full date
      }
    },
    addMenuItem() {
      this.menu_items.push("");
    },
    removeMenuItem(index) {
      this.menu_items.splice(index, 1);
    },
    filterExtras() {
      const searchText = this.selectedExtraText.toLowerCase();
      this.filteredExtras = this.extras.filter(extra => extra.name.toLowerCase().includes(searchText));
    },
    getToken() {
      return get_token();
    },

    addEventToHome(newEvent) {
      if (newEvent) {
        this.events.unshift(newEvent);
      }
    },
    fetchPortals() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/company_management/get_all_portals_api/`,
        method: 'POST',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (data) => {
          this.portals = data.portals;

          setTimeout(() => {
            this.loading = false;
        }, 1000);
        },

        complete: () => {
          this.isLoading = false;
        }
      });
    },

    fetchExtras() {
      this.isLoading = true;
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_all_extra/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
          'Content-Type': 'application/json'
        },
        success: (data) => {
          this.extras = data.extras;
          setTimeout(() => {
            this.loading = false;
        }, 1000);
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    },
    addMoreExtra() {
      const extraItem = {
        selectedExtraText: this.selectedExtraText,
        item_name: this.item_name,
        item_description: this.item_description,
        item_price: this.item_price,
      };
      this.extraForms.push(extraItem);
      this.selectedExtraText = '';
      this.item_name = '';
      this.item_description = '';
      this.item_price = '';
    },

    handleCollapseToggle() {
      this.isCollapseOpen = !this.isCollapseOpen;

      if (this.isCollapseOpen) {
        this.addMoreExtra();
      }
    },
    addExtra() {
      this.extraForms.push({
        selectedExtra: "",
        items: [
          { item_name: '', item_description: '', item_price: '' }
        ]
      });
    },

    addExtraItem(extra_index) {
      const extra_form = this.extraForms.at(extra_index);

      if (extra_form) {
        const new_item = {
          item_name: '',
          item_description: '',
          item_price: 0
        };

        if (this.validate_extra_item(new_item)) {
          extra_form.items.push(new_item);
        }
      }
    },

    validate_extra_item(item) {
      return (
        typeof item === 'object' &&
        item !== null &&
        typeof item.item_name === 'string' &&
        typeof item.item_description === 'string' &&
        typeof item.item_price === 'number' &&
        item.item_price >= 0
      );
    },

    removeExtra(extraIndex) {
      this.extraForms.splice(extraIndex, 1);
    },
    addRsvp() {

      if(!this.token_exists){
        const nextUrl = this.$router.currentRoute.value.fullPath;
          if (nextUrl) {
            sessionStorage.setItem('next', nextUrl);
            sessionStorage.setItem('selectedEvent', JSON.stringify(this.selectedEvent));
            sessionStorage.setItem('selectedExtras', JSON.stringify(this.selectedExtraId));
            sessionStorage.setItem('selectedItemId',JSON.stringify(this.selectedItemId))
          }
          window.location = '/sign_in_Finale'; 
          return;
      }
      this.isSubmitting = true;
      const formData = new FormData();
      formData.append('event_id', this.selectedEvent.id);
      if (Array.isArray(this.selectedItemId)) {
        this.selectedItemId.forEach(itemId => {
            formData.append('item_id[]', itemId);
        });
    } else {
        formData.append('item_id', this.selectedItemId);
    }

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/add_event_rsvp/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          const status = response.status;
          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'RSVP Successful',
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              Swal.close();
              sessionStorage.removeItem('selectedEvent');
              sessionStorage.removeItem('selectedExtras');
              sessionStorage.removeItem('selectedItemId');

              this.selectedEvent = {};
              this.selectedExtraId = [];
              $('#rsvp_modal').modal('hide');
            });
          }
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;
          if (response_data.status === "error") {
            Swal.fire({
              icon: 'info',
              text: response_data.message,
              showConfirmButton: false,
              timer: 2000,

            }).then(() => {
              Swal.close();
              sessionStorage.removeItem('selectedEvent');
              sessionStorage.removeItem('selectedExtras');
              sessionStorage.removeItem('selectedItemId');


              this.selectedEvent = {};
              this.selectedExtraId = [];
              this.selectedItemId=null;
              $('#rsvp_modal').modal('hide');
            });
          }
        }

      })
    },


    fetchEvents_extras(event_id) {
      this.selectEvent.id = event_id;
      this.isLoading = true;
      this.token_exists = get_token();
      this.clearForm()
      this.selectedItemId=[]

      // Close the event modal before making the request
      $('#eventModal').modal('hide');

      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_event_data/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        data: { event_id: event_id },
        success: (response_data) => {
          this.eventExtras = response_data.event_extras;
          if (this.eventExtras && this.eventExtras.length > 0) {
            $('#rsvp_modal').modal('show'); // Open RSVP modal
          } else {
            this.addRsvp(); // Call addRsvp if no extra details
          }
        },
        complete: () => {
          this.isLoading = false;
        }
      });
    }
    ,
    get_user_id() {
      const user_id = localStorage.getItem('user_id') || '';
      return user_id;
    },
    deleteEvent(event_id) {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            type: "DELETE",
            url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/delete_event_api/`,
            data: JSON.stringify({ event_id: event_id }),
            headers: {
              'Authorization': `Token ${this.getToken()}`,
              'Content-Type': 'application/json'
            },
            success: (response) => {
              const status = response.status;
              if (status === "success") {
                Swal.fire({
                  title: 'Deleted!',
                  text: 'The event has been deleted.',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1000
                }).then(() => {
                  Swal.close();
                });
              } else {
                Swal.fire(
                  'Failed!',
                  response.message || 'An error occurred. Please try again.',
                  'error'
                );
              }
            },
            error: () => {
              Swal.fire(
                'Failed!',
                'An error occurred. Please try again.',
                'error'
              );
            }
          });
        }
      });
    },
    fetchEventTypes() {
      this.isLoading = true;
      this.token_exists = get_token();
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_event_types/`,
        method: "GET",
        headers: this.token_exists
          ? {
              Authorization: `Token ${this.getToken()}`,
              "Content-Type": "application/json",
            }
          : {},
        success: (response) => {
          this.event_types = response.data.event_type;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },
    load_events_modal() {
      this.fetchEvents();
    },
    fetchEvents() {
      this.isLoading = true;
      this.token_exists = get_token();
      $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/filter_events_api/`,
        method: "POST",
        headers: this.token_exists
          ? {
            Authorization: `Token ${this.getToken()}`,
            "Content-Type": "application/json",
          }
          : {},
        success: (data) => {
          this.events = data.data.events;
          const formattedEvents = this.events.map(event => ({
            title: event.event_name,
            start: new Date(event.start_date).toISOString(),
            end: new Date(event.end_date).toISOString(),
          }));
    
          this.calendarOptions.events = formattedEvents;
          setTimeout(() => {
            this.loading = false;
        }, 1000);

        },
        complete: () => {
          this.isLoading = false;
        },
      });
    },


    form_date(dateString) {
      return new Date(dateString).toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    form_time(timeString) {
      if (!timeString) return "";
      const [hour, minute] = timeString.split(":");
      const date = new Date();
      date.setHours(hour, minute);
      return date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    },

    openEditModal(event) {
      // Populate eventData for editing
      this.eventData = { ...event };
      this.fileName = event.file ? event.file.name : '';
      $('#event_modal').modal('show');

    },

    submitEvent() {
      if (this.event_id) {
        this.update_event(this.event_id);
      } else {
        this.submit_form();
      }
    },
    log_out() {
      clear_token()
      this.$router.push({ path: '/', });
    },

    initializeMap() {
      const defaultLat = parseFloat(this.latitude || 0);
      const defaultLng = parseFloat(this.longitude || 0);

      const mapOptions = {
        center: { lat: defaultLat, lng: defaultLng },
        zoom: 8,
        mapTypeId: 'roadmap',
      };

      const mapElement = document.querySelector('#mapSearchBox');
      if (mapElement) {
        const map = new window.google.maps.Map(mapElement, mapOptions);

        let marker = null;

        const input = document.getElementById('mapSearchBox');
        const searchBox = new window.google.maps.places.SearchBox(input);

        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

        map.addListener('bounds_changed', () => {
          searchBox.setBounds(map.getBounds());
        });

        searchBox.addListener('places_changed', () => {
          const places = searchBox.getPlaces();

          if (!places || places.length === 0) {
            return;
          }

          const place = places[0];
          if (!place.geometry) {
            return;
          }

          // Update map center and zoom
          map.setCenter(place.geometry.location);
          map.setZoom(14);

          // Remove the existing marker, if any
          if (marker) {
            marker.setMap(null);
          }

          marker = new window.google.maps.Marker({
            position: place.geometry.location,
            map: map,
            title: place.name,
          });
          this.location = input.value;
        });
      }
    },

    
    update_event(event_id) {
      Swal.fire({
        title: "Updating...",
        text: "Please wait while we update the event.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      var form_data = new FormData();

      form_data.append("event_id", this.event_id);
      form_data.append("event_name", this.event_name);
      this.event_description = this.$refs.quillEventEditorRef.getHTML();
      form_data.append('description', this.event_description);
      form_data.append("start_date", this.start_date);
      form_data.append("start_time", this.start_time);
      form_data.append("end_date", this.end_date);
      form_data.append("end_time", this.end_time);
      form_data.append("rsvp_start_date", this.rsvp_start_date);
      form_data.append("rsvp_start_time", this.rsvp_start_time);
      form_data.append("rsvp_end_date", this.rsvp_end_date);
      form_data.append("rsvp_end_time", this.rsvp_end_time);
      form_data.append("organizer", this.organizer);
      form_data.append("location", this.location);
      form_data.append('event_type',this.event_type.id);

      let extractedData = [];

      $("#select_extra_container").each(function (index, container) {
        // Extract the extra form ID
        let extraFormId = $(container).find(".extra_form_id").val();
        
        // Extract the selected extra (first text input)
        let selectedExtra = $(container).find(".selectExtra").val();


        // Initialize an array to store the items
        let items = [];

        $(container).find(".border.bg-white").each(function (_, itemContainer) {
            let itemId = $(itemContainer).find(".item_id").val();

            let itemName = $(itemContainer).find(".item_name").val();

            let itemDescription = $(itemContainer).find(".item_description").val();
        
            let itemPrice = $(itemContainer).find(".item_price").val();


            items.push({
                id: itemId,
                item_name: itemName,
                item_description: itemDescription,
                item_price: itemPrice
            });
        });

        // Push the extra form data into the extractedData array
        extractedData.push({
            id: extraFormId,
            selectedExtra: selectedExtra,
            items: items
        });
      });

      if (this.event_link) {
        form_data.append("event_link", this.event_link);
      }

      if (this.extras && this.extras.length > 0) {
        form_data.append("extra", JSON.stringify(extractedData));
      }

      if (this.upload_media) {
        form_data.append('file', this.upload_media);
      }

      if (this.files && this.files.length > 0) {
        this.files.forEach((file) => {
          form_data.append(`file`, file);
        });
      }


      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/update_event_api/`,
        data: form_data,
        processData: false,
        contentType: false,
        headers: {
          Authorization: `Token ${this.getToken()}`,
        },
        success: (response) => {
          if (response.status === "success") {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: response.message,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              $("#update_event_modal").modal("hide");
              $('#event_modal').modal('hide');
              this.load_event();
            });
          }
        },
        error: () => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: 'An error occurred while trying to update the Event, please try again.',
            showConfirmButton: false,
            timer: 2000,
          });
        },
      });
    },
    load_event() {

      this.fetchEvents();

      $("#update_event_modal").modal("hide");
      $('#event_modal').modal('hide');


      this.event_id = null;
      this.event_name = "";
      this.description = "";
      this.start_date = "";
      this.start_time = "";
      this.end_date = "";
      this.end_time = "";
      this.rsvp_start_date = "";
      this.rsvp_start_time = "";
      this.rsvp_end_date = "";
      this.rsvp_end_time = "";
      this.organizer = "";
      this.location = "";
      this.files = [];
    },
    validationEventForm() {
      this.errors = {};
      let valid = true;

      if (!this.event_name) {
        this.errors.event_name = 'Event name is required';
        valid = false;
      }

      return valid;
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
    
      if (file) {
        if (file.type.startsWith("audio/")) {
          this.fileError = "Audio files are not allowed.";
          this.upload_media = null;
          this.fileName = "";
          this.imagePreview = null; // Reset preview if an invalid file is selected
          event.target.value = ""; // Clear file input
        } else {
          this.fileName = file.name;
          this.fileError = "";
          this.upload_media = file;
    
          // Check if the file is an image before previewing
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (e) => {
              this.imagePreview = e.target.result;
            };
            reader.readAsDataURL(file);
          } else {
            this.imagePreview = null; // Reset preview for non-image files
          }
        }
      } else {
        this.fileName = "";
        this.fileError = "";
        this.upload_media = null;
        this.imagePreview = null;
      }
    }, 
    clearForm() {
      this.item_name = '';
      this.item_description = '';
      this.item_price = '';
      this.extraItems = [];
      this.event_name = '';
      this.start_date = '';
      this.end_date = '';
      this.event_description = '';
      this.selectedPortalId = '';
      this.organizer = '';
      this.location = '';
      this.rsvp_start_date = '';
      this.rsvp_end_date = '';
      this.upload_media = null;
      this.selectedExtraText = '';
      this.event_type = '';
      if (this.$refs.quillEventEditorRef) {
        this.$refs.quillEventEditorRef.setHTML('');

      };
      this.extraForms = [];

    },
    submit_form() {
      this.is_valid = true
      if (!this.validation_event_form()) {
        return;
      }

      Swal.fire({
        title: 'Submitting...',
        text: 'Please wait while we process your request.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      const event_url=`${window.location.origin}${this.$route.path}`

      this.isSubmitting = true;
      const newItem = {
        item_name: this.item_name,
        description: this.item_description,
        price: this.item_price
      };
      this.extraItems.push(newItem);

      const formData = new FormData();
      formData.append('event_name', this.event_name);
      formData.append('start_date', this.start_date);
      formData.append('start_time', this.start_time);
      formData.append('end_date', this.end_date);
      formData.append('end_time', this.end_time);
      this.event_description = this.$refs.quillEventEditorRef.getHTML();
      formData.append('description', this.event_description);
      formData.append('portal_id', this.selectedPortalId);
      formData.append('organizer', this.organizer);
      formData.append('location', this.location);
      formData.append('rsvp_start_date', this.rsvp_start_date);
      formData.append('rsvp_start_time', this.rsvp_start_time);
      formData.append('rsvp_end_date', this.rsvp_end_date);
      formData.append('rsvp_end_time', this.rsvp_end_time);
      formData.append('event_type',this.event_type.id);
      formData.append('event_link', this.event_link);
      formData.append('event_url',event_url)

      if (this.upload_media) {
        formData.append('file', this.upload_media);
      }

      const extras_data = [];

      this.extraForms.forEach(extra => {
        if (extra.selectedExtra && extra.selectedExtra.trim()) {
          const validItems = [];

          extra.items.forEach(item => {
            if (item.item_name && item.item_name.trim()) {
              validItems.push({
                item_name: item.item_name.trim(),
                description: item.item_description?.trim(),
                price: item.item_price || 0
              });
            }
          });

          if (validItems.length > 0) {
            extras_data.push({
              extra_name: extra.selectedExtra.trim(),
              items: validItems
            });
          }
        }
      });

      if (extras_data.length > 0) {
        formData.append('extra', JSON.stringify(extras_data));
      }

      $.ajax({
        type: "POST",
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/create_event_api/`,
        data: formData,
        processData: false,
        contentType: false,
        headers: {
          'Authorization': `Token ${this.getToken()}`,
        },
        success: (response) => {
          const status = response.status;
          this.$emit('new_event', response.event)
          Swal.close();
          if (status === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Event Created',
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              $('#event_modal').modal('hide');
              this.isDropdownVisible = false;
              this.clearForm();
              this.load_event();
            });
          }
        },
        error: (response) => {
          Swal.close();
          const response_data = response.responseJSON;
          if (response_data.status === "error") {
            this.errors.event_name = response_data.message;
          }
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    },
    removeExtraItem(extra_index, item_index) {
      const extra_form = this.extraForms.at(extra_index);

      if (extra_form && Array.isArray(extra_form.items)) {
        if (this.validate_item_index(extra_form.items, item_index)) {
          extra_form.items.splice(item_index, 1);
        }
      }
    },

    validate_item_index(items, index) {
      return Number.isInteger(index) && index >= 0 && index < items.length;
    },

    openCreateModal() {
      // Reset form fields for creating a new event
      this.event_name = '';
      this.description = '';
      this.start_date = '';
      this.start_time = '';
      this.end_date = '';
      this.end_time = '';
      this.rsvp_start_date = '';
      this.rsvp_start_time = '';
      this.rsvp_end_date = '';
      this.organizer = '';
      this.location = '';
      this.selectedPortalId = '';
      this.files = [];
      this.event_type= '';
      this.extraItems = [];
      // Show the modal
      $('#event_modal').modal('show');

    },
    formatDateInput(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      return date.toISOString().slice(0, 10); // Extracts "YYYY-MM-DD"
    },
    edit_event(event) {
      this.event_name = event.event_name;
      this.event_id = event.id;
      this.description = event.description;
      const TextDescription = event.description.replace(/<[^>]*>/g, "");
      this.$nextTick(() => {
        if (this.$refs.quillEventEditorRef) {
          const quillEditor = this.$refs.quillEventEditorRef.getQuill();
          if (quillEditor) {
            quillEditor.setContents([
              { insert: TextDescription || "" },
            ]);
          }
        }
      });
      this.start_date = event.start_date;
      this.start_time = event.start_time;
      this.end_date = event.end_date;
      this.end_time = event.end_time;
      this.rsvp_start_date = event.rsvp_start_date;
      this.rsvp_start_time = event.rsvp_start_time;
      this.rsvp_end_date = event.rsvp_end_date;
      this.rsvp_end_time = event.rsvp_end_time;
      this.organizer = event.organizer;
      this.location = event.location;
      this.event_link = event.event_link;
      this.portal_id = event.portal_id;
      this.event_type = event.event_type;
      if (event.event_extras && event.event_extras.length > 0) {
        this.extraForms = event.event_extras.map(extra => ({
          id: extra.extra.id,
          selectedExtra: extra.extra ? extra.extra.name : '',
          items: extra.items.map(item => ({
            id: item.id,
            item_name: item.item_name,
            item_description: item.description,
            item_price: item.price || 0
          }))
        }));
      } else {
        this.extraForms = [{
          selectedExtra: '',
          items: [{
            item_name: '',
            item_description: '',
            item_price: 0
          }]
        }];
      }
      // Show the modal immediately
      $('#event_modal').modal('show');


      // Handle description in quill editor if needed
      this.$nextTick(() => {
        if (this.$refs.quilEditEventRef) {
          const quillEditor = this.$refs.quilEditEventRef.getQuill();
          if (quillEditor) {
            const TextDescription = event.description.replace(/<[^>]*>/g, "");
            quillEditor.setContents([
              { insert: TextDescription || "" },
            ]);
          }
        }
      });
    },
    validation_event_form() {
      this.errors = {};
      let isValid = true;
      
      if(this.event_type.name === 'hybrid' || this.event_type.name === 'physical') {
        if (!this.location?.trim()) {
          this.errors.location = 'Location is required';
          isValid = false;
        }}
        if (this.event_type.name === "online" || this.event_type.name === "hybrid") {
          if (!this.event_link?.trim()) {
            this.errors.event_link = "Event link is required for online/hybrid events.";
            isValid = false;
          }
        }
      if (!this.organizer?.trim()) {
        this.errors.organizer = 'Organizer is required';
        isValid = false;
      }
      if (!this.event_name?.trim()) {
        this.errors.event_name = 'Event name is required';
        isValid = false;
      }

      const startDateObj = new Date(this.start_date);
      const endDateObj = new Date(this.end_date);
      const rsvpStartDateObj = new Date(this.rsvp_start_date);
      const rsvpEndDateObj = new Date(this.rsvp_end_date);
      const todayObj = new Date();
      
      todayObj.setHours(0, 0, 0, 0);
      startDateObj.setHours(0, 0, 0, 0);
      endDateObj.setHours(0, 0, 0, 0);
      rsvpStartDateObj.setHours(0, 0, 0, 0);
      rsvpEndDateObj.setHours(0, 0, 0, 0);

      if (!this.start_date || !this.end_date || !this.rsvp_start_date || !this.rsvp_end_date) {
        this.errors.start_date = this.errors.end_date = this.errors.rsvp_start_date = this.errors.rsvp_end_date = 'All dates are required';
        isValid = false;
      }

      if (startDateObj < todayObj) {
        this.errors.start_date = 'Event Start Date must be today or a future date';  
        isValid = false;  
      }
            
      if (endDateObj < startDateObj) {  
        this.errors.end_date = 'Event End Date must be after Event Start Date';  
        isValid = false;  
      }
      
      if (rsvpStartDateObj > startDateObj) {  
        this.errors.rsvp_start_date = 'RSVP Start Date must be today or before Event Start Date';  
        isValid = false;  
      }
            
      if (rsvpEndDateObj < rsvpStartDateObj || rsvpEndDateObj > startDateObj) {  
        this.errors.rsvp_end_date = 'RSVP End Date must be after RSVP Start Date and on or before Event Start Date';  
        isValid = false;  
      }

      this.extraForms.forEach((extraForm, index) => {
        if (extraForm.selectedExtra) {
          extraForm.items.forEach((item, itemIndex) => {
            if (!item.item_name) {
              this.errors[`extra_${index}_item_${itemIndex}_name`] = "Item name is required.";
            }
          });
        }
      });

      return isValid;
    },
    generateCalendar() {
      const firstDay = new Date(this.year, this.month, 1);
      const lastDay = new Date(this.year, this.month + 1, 0);
      const numDays = lastDay.getDate();
      const startDay = firstDay.getDay();
  
      let days = [];
  
      // Previous month's days
      for (let i = startDay; i > 0; i--) {
        const prevMonthDate = new Date(this.year, this.month, -i + 1);
        days.push({ dayNumber: prevMonthDate.getDate(), otherMonth: true, date: prevMonthDate });
      }
  
      // Current month's days
      for (let i = 1; i <= numDays; i++) {
        days.push({ date: new Date(this.year, this.month, i), dayNumber: i, otherMonth: false });
      }
  
      // Next month's days
      let nextMonthDayCounter = 1;
      const remainingDays = 42 - days.length; // Ensure 6 rows
      for (let i = 0; i < remainingDays; i++) {
        const nextMonthDate = new Date(this.year, this.month + 1, nextMonthDayCounter++);
        days.push({ dayNumber: nextMonthDate.getDate(), otherMonth: true, date: nextMonthDate });
      }
  
      this.days = days;
    },
    prevMonth() {
      if (this.month === 0) {
        this.year--;
        this.month = 11;
      } else {
        this.month--;
      }
      this.generateCalendar();
    },
  
    nextMonth() {
      if (this.month === 11) {
        this.year++;
        this.month = 0;
      } else {
        this.month++;
      }
      this.generateCalendar();
    },

    getFormattedMonthYear() {
      return this.selectedDate.toLocaleDateString("en-US", { month: "short", year: "numeric" });
    },

    // 🟢 Change the month (offset can be +1 or -1)
    changeMonth(offset) {
      this.selectedDate = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + offset, 1);

      this.updateDaysInMonth(); // ✅ Ensure this runs after changing month

    },
    selectDay(day) {
      if (!day.date) return; // Ensure the day has a valid date

      this.selectedDay = day.dayNumber;
      this.selectedDate = day.date;

      const saDate = new Date(this.selectedDate).toLocaleDateString("en-CA", {
        timeZone: "Africa/Johannesburg",
      });


      this.isLoading = true;
        $.ajax({
        url: `${process.env.VUE_APP_BACKEND_API_URL}/portal_management/get_events_by_date/`,
        method: 'POST',
        data: JSON.stringify({ event_start_date: saDate }),
        headers: {
            'Authorization': `Token ${this.getToken()}`,
            'Content-Type': 'application/json'
        },
        success: (response) => {
          if (response.status === 'success' && response.events && response.events.length > 0) {
            this.events = response.events;
            this.message = '';
          } else {
            this.events = [];
            this.message = 'No events found.';
          }
        },
        error: () => {
          this.events = [];
            this.message = 'No events found.';
        },
        complete: () => {
            this.isLoading = false;
        }
        });
    },
    isSameDay(day) {
      const selected = new Date(this.selectedDate);
      selected.setHours(0, 0, 0, 0); // Reset time to midnight
 
      const current = new Date(day.date);
      current.setHours(0, 0, 0, 0); // Reset time to midnight
 
      return selected.getTime() === current.getTime();
    },

    selectEvent(event) {
      this.selectedEvent = event;
    },

    toggleDateExpanded(date) {
      if (this.expandedDates.includes(date)) {
        this.expandedDates = this.expandedDates.filter(d => d !== date);
      } else {
        this.expandedDates.push(date);
      }
    },
    getEventsForDate(date) {
      return this.events.filter(event => event.date === date);
    },
    toggleEvents(date) {
      this.activeDates[date] = !this.activeDates[date];
    },

    isWithinRSVPPeriod(event) {
      const now = new Date();
      const start = new Date(`${event.rsvp_start_date}T${event.rsvp_start_time}`);
      const end = new Date(`${event.rsvp_end_date}T${event.rsvp_end_time}`);
      if (now < start) {
        return { isValid: false, message: "RSVP has not started yet." };
      }
      if (now > end) {
        return { isValid: false, message: "RSVP period has ended." };
      }
      return { isValid: true, message: "" };
    }
  },
  

};